import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../config/axios';
import styled from '@emotion/styled';
import VideoModal from './VideoModal';
import VideoPlayerPresent from './VideoPlayerPresent';
import { useTheme } from '@mui/material/styles';
import './PresentPanel.css'; 
import ReactDOM from 'react-dom';
import { API_BASE_URL } from '../config/config';
import PlanForm from './PlanForm';

// Tambahkan sebelum definisi PresentPanel
const PresentationDialog = ({ presentations, onSelect, onClose, onDelete }) => (
  <div className="presentation-dialog">
    <h3>Pilih Presentasi</h3>
    <div className="presentation-list">
      {presentations.map(pres => (
        <div key={pres.id} className="presentation-item">
          <div className="presentation-info" onClick={() => onSelect(pres)}>
            <span>{pres.name}</span>
            <span>{new Date(pres.date).toLocaleDateString()}</span>
          </div>
          <button 
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm(`Hapus presentasi "${pres.name}"?`)) {
                onDelete(pres.id);
              }
            }}
          >
            🗑️
          </button>
        </div>
      ))}
    </div>
    <button onClick={onClose}>Tutup</button>
  </div>
);

// Styled Components
const PresentContainer = styled.div`
  display: flex;
  height: 100%;
  background: ${props => props.isDarkMode ? '#1a1a1a' : '#ffffff'};
  color: ${props => props.isDarkMode ? '#ffffff' : '#000000'};
`;

const Sidebar = styled.div`
  width: 300px;
  background: ${props => props.isDarkMode ? '#2d2d2d' : '#f5f5f5'};
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid ${props => props.isDarkMode ? '#3d3d3d' : '#e0e0e0'};
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: ${props => props.isDarkMode ? '#1a1a1a' : '#ffffff'};
`;

const CategorySelect = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  background: ${props => props.isDarkMode ? '#3d3d3d' : '#ffffff'};
  color: ${props => props.isDarkMode ? '#ffffff' : '#000000'};
  border: 1px solid ${props => props.isDarkMode ? '#4d4d4d' : '#e0e0e0'};
  border-radius: 4px;
`;

const LibraryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const ItemCard = styled.div`
  background: ${props => props.selected ? '#4a90e2' : props.isDarkMode ? '#3d3d3d' : '#ffffff'};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: ${props => props.selected ? '#ffffff' : props.isDarkMode ? '#ffffff' : '#000000'};
  
  &:hover {
    transform: translateY(-2px);
    background: ${props => props.selected ? '#4a90e2' : props.isDarkMode ? '#4d4d4d' : '#f5f5f5'};
  }
`;

const VideoPreview = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
`;

const VideoInfo = styled.div`
  padding: 12px;
  
  h4 {
    margin: 0 0 8px 0;
    color: ${props => props.isDarkMode ? '#ffffff' : '#000000'};
  }
  
  p {
    margin: 4px 0;
    color: ${props => props.isDarkMode ? '#cccccc' : '#666666'};
    font-size: 14px;
  }
`;

const SelectedVideosScroll = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 15px;
  margin-bottom: 20px;
  background: ${props => props.isDarkMode ? '#2d2d2d' : '#f5f5f5'};
  border-radius: 8px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.isDarkMode ? '#1a1a1a' : '#e0e0e0'};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.isDarkMode ? '#4d4d4d' : '#888'};
    border-radius: 4px;
  }
`;

const SelectedVideoCard = styled.div`
  flex: 0 0 200px;
  background: ${props => props.isDarkMode ? '#3d3d3d' : '#ffffff'};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: ${props => props.isPlaying ? '2px solid #4a90e2' : 'none'};

  .playing-indicator {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #4a90e2;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1;
  }
`;

const PlaylistContainer = styled.div`
  margin-top: 20px;
  background: ${props => props.isDarkMode ? '#2d2d2d' : '#ffffff'};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  margin-top: 20px;
`;

const PlaylistControls = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;

  button {
    padding: 8px 16px;
    background: ${props => props.isDarkMode ? '#3d3d3d' : '#f0f0f0'};
    color: ${props => props.isDarkMode ? '#ffffff' : '#000000'};
    border: 1px solid ${props => props.isDarkMode ? '#4d4d4d' : '#e0e0e0'};
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: ${props => props.isDarkMode ? '#4d4d4d' : '#e0e0e0'};
    }

    &:disabled {
      background: ${props => props.isDarkMode ? '#2d2d2d' : '#cccccc'};
      color: ${props => props.isDarkMode ? '#666666' : '#666666'};
      cursor: not-allowed;
    }
  }
`;

const BroadcastDialog = ({ onClose, onSubmit, isDarkMode, currentPresentation, currentUser }) => {
  const [formData, setFormData] = useState({
    title: '',
    type: 'review',
    date: '',
    time: '',
    location: '',
    attendees: [],
    description: '',
    presentationLink: `${window.location.protocol}//${window.location.hostname}:3000/present/${currentPresentation?.id}`
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/users`);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentPresentation) {
      alert('Harap simpan presentasi terlebih dahulu');
      return;
    }

    try {
      const response = await axiosInstance.get('/api/auth/me');
      if (!response.data) {
        throw new Error('Pengguna saat ini tidak terdefinisi');
      }
      const organizerId = response.data.id;
      if (!organizerId) {
        throw new Error('ID pengguna tidak valid atau tidak ada');
      }

      // Format data sesuai dengan model Plan
      const planData = {
        title: formData.title,
        date: formData.date,
        time: formData.time,
        location: formData.location,
        type: 'review',
        status: 'upcoming',
        projectId: currentPresentation.projectId,
        description: `Broadcast presentasi: ${formData.presentationLink}`,
        attendees: formData.attendees, // array of user IDs
        organizerId
      };

      await axiosInstance.post(`${API_BASE_URL}/api/plans`, planData);
      onSubmit();
      onClose();
    } catch (error) {
      console.error('Error creating broadcast:', error);
      alert('Gagal membuat broadcast');
    }
  };

  return (
    <div className="broadcast-dialog">
      <h3>Jadwalkan Broadcast Presentasi</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Judul</label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => setFormData({...formData, title: e.target.value})}
            required
          />
        </div>

        <div className="form-group">
          <label>Tanggal</label>
          <input
            type="date"
            value={formData.date}
            onChange={(e) => setFormData({...formData, date: e.target.value})}
            required
          />
        </div>

        <div className="form-group">
          <label>Waktu</label>
          <input
            type="time"
            value={formData.time}
            onChange={(e) => setFormData({...formData, time: e.target.value})}
            required
          />
        </div>

        <div className="form-group">
          <label>Lokasi</label>
          <input
            type="text"
            value={formData.location}
            onChange={(e) => setFormData({...formData, location: e.target.value})}
            required
          />
        </div>

        <div className="form-group">
          <label>Peserta</label>
          {loading ? (
            <div>Loading users...</div>
          ) : (
            <select
              multiple
              value={formData.attendees}
              onChange={(e) => setFormData({
                ...formData,
                attendees: Array.from(e.target.selectedOptions, option => option.value)
              })}
              required
            >
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="form-group">
          <label>Link Presentasi</label>
          <input
            type="text"
            value={formData.presentationLink}
            readOnly
          />
        </div>

        <div className="dialog-actions">
          <button type="submit" className="submit-button">Jadwalkan</button>
          <button type="button" onClick={onClose} className="cancel-button">Batal</button>
        </div>
      </form>
    </div>
  );
};

const InviteDialog = ({ onClose, onInvite, isDarkMode, currentPresentation }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch semua users
        const usersResponse = await axiosInstance.get(`${API_BASE_URL}/api/users`);
        setUsers(usersResponse.data);

        // Jika ada currentPresentation, fetch invited users
        if (currentPresentation?.id) {
          const invitedResponse = await axiosInstance.get(
            `${API_BASE_URL}/api/presentations/${currentPresentation.id}/invites`
          );
          // Set selected users dari data yang sudah diundang
          setSelectedUsers(invitedResponse.data.map(invite => invite.userId));
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPresentation]);

  const handleUserSelect = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      }
      return [...prev, userId];
    });
  };

  return (
    <div className="invite-dialog">
      <h3>Pilih User untuk Diundang</h3>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="user-list">
          {users.map(user => (
            <div key={user.id} className="user-item">
              <label>
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleUserSelect(user.id)}
                />
                <img 
                  src={user.profileImage || '/assets/images/default-avatar.png'} 
                  alt={user.username}
                  className="user-avatar"
                />
                <span>{user.username}</span>
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="dialog-actions">
        <button onClick={() => onInvite(selectedUsers)} className="submit-button">Submit</button>
        <button onClick={onClose} className="cancel-button">Batal</button>
      </div>
    </div>
  );
};

const InviteModal = ({ isOpen, onClose, onInvite, isDarkMode, currentPresentation }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="video-modal-overlay">
      <div className="invite-modal-container">
        <div className="invite-modal-content">
          <button className="modal-close-button" onClick={onClose}>×</button>
          <InviteDialog 
            onClose={onClose}
            onInvite={onInvite}
            isDarkMode={isDarkMode}
            currentPresentation={currentPresentation}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

const BroadcastModal = ({ isOpen, onClose, onSubmit, isDarkMode, currentPresentation, currentUser }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="video-modal-overlay">
      <div className="broadcast-modal-container">
        <div className="broadcast-modal-content">
          <button className="modal-close-button" onClick={onClose}>×</button>
          <BroadcastDialog 
            onClose={onClose}
            onSubmit={onSubmit}
            isDarkMode={isDarkMode}
            currentPresentation={currentPresentation}
            currentUser={currentUser}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

// Komponen utama
const PresentPanel = ({ isDarkMode, activeProject, currentUser }) => {
  const [category, setCategory] = useState('shot');
  const [publishedVideos, setPublishedVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(0);
  const [videoPlayerRef, setVideoPlayerRef] = useState(null);
  const [selectedVideoIds, setSelectedVideoIds] = useState(new Set());
  const [isFileDropdownOpen, setIsFileDropdownOpen] = useState(false);
  const [recentFiles, setRecentFiles] = useState([]);
  const dropdownRef = useRef(null);
  const [dialogContent, setDialogContent] = useState(null);
  const [currentPresentation, setCurrentPresentation] = useState(null);
  const [users, setUsers] = useState([]);
  const [isBroadcastModalOpen, setIsBroadcastModalOpen] = useState(false);

  useEffect(() => {
    if (activeProject?.id) {
      fetchPublishedVideos();
    }
  }, [activeProject, category]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchRecentFiles = async () => {
      if (!activeProject?.id) return;
      
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations/recent`, {
          params: {
            projectId: activeProject.id,
            limit: 5
          }
        });
        
        const formattedFiles = response.data.map(pres => ({
          id: pres.id,
          name: `${pres.name}.pres`,
          date: pres.date
        }));
        
        setRecentFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching recent presentations:', error);
      }
    };

    fetchRecentFiles();
  }, [activeProject]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const fetchPublishedVideos = async () => {
    try {
      const response = await axiosInstance.get(`/api/published/${category}`, {
        params: {
          projectId: activeProject?.id
        }
      });
      
      // Proses data video yang dipublish
      const processedVideos = response.data.map(video => ({
        ...video,
        videoPath: video.videoPath || video.data?.videoPath,
        thumbnailUrl: video.thumbnailUrl || video.data?.thumbnailUrl,
        version: video.version || video.data?.version,
        isApproved: video.isApproved || video.data?.isApproved
      }));
      
      setPublishedVideos(processedVideos);
    } catch (error) {
      console.error('Error mengambil video yang dipublish:', error);
    }
  };

  const handleItemSelect = (activity) => {
    if (selectedItems.find(item => item.id === activity.id)) {
      // Hapus item dari selectedItems dan selectedVideoIds
      const newSelectedItems = selectedItems.filter(item => item.id !== activity.id);
      setSelectedItems(newSelectedItems);
      const newSelectedIds = new Set(selectedVideoIds);
      newSelectedIds.delete(activity.id);
      setSelectedVideoIds(newSelectedIds);
      
      // Update playlist jika sudah ada
      if (playlist.length > 0) {
        const newPlaylist = newSelectedItems.map(item => item.data?.videoPath);
        setPlaylist(newPlaylist);
        
        if (currentPlayingIndex >= newPlaylist.length) {
          setCurrentPlayingIndex(Math.max(0, newPlaylist.length - 1));
        }
      }
    } else {
      // Tambah item baru ke selectedItems dan selectedVideoIds
      const newSelectedItems = [...selectedItems, activity];
      setSelectedItems(newSelectedItems);
      const newSelectedIds = new Set(selectedVideoIds);
      newSelectedIds.add(activity.id);
      setSelectedVideoIds(newSelectedIds);
      
      // Update playlist jika sudah ada
      if (playlist.length > 0) {
        const newPlaylist = newSelectedItems.map(item => item.data?.videoPath);
        setPlaylist(newPlaylist);
      }
    }
  };

  const generatePlaylist = () => {
    setPlaylist(selectedItems.map(item => item.data?.videoPath));
  };

  const handlePrevVideo = () => {
    if (videoPlayerRef) {
      videoPlayerRef.current.handlePrevVideo();
    }
  };

  const handleNextVideo = () => {
    if (videoPlayerRef) {
      videoPlayerRef.current.handleNextVideo();
    }
  };

  const handleVideoEnd = () => {
    if (currentPlayingIndex < playlist.length - 1) {
      handleVideoSelect(currentPlayingIndex + 1);
    }
  };

  const handleVideoSelect = (index) => {
    setCurrentPlayingIndex(index);
    if (videoPlayerRef?.current) {
      setTimeout(() => {
        videoPlayerRef.current.play().catch(error => {
          console.error('Failed to play video:', error);
        });
      }, 100);
    }
  };

  const handleItemClick = (e, index) => {
    e.preventDefault();
    handleVideoSelect(index);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
    
    if (dragIndex === dropIndex) return;

    const newItems = [...selectedItems];
    const [draggedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, draggedItem);
    
    setSelectedItems(newItems);
    
    // Update playlist jika sudah ada
    if (playlist.length > 0) {
      const newPlaylist = newItems.map(item => item.data?.videoPath);
      setPlaylist(newPlaylist);
    }
  };

  const handleFileAction = async (action) => {
    switch(action) {
      case 'save':
        const presentationData = {
          name: prompt('Masukkan nama presentasi:'),
          date: new Date().toISOString(),
          projectId: activeProject.id,
          selectedItems,
          playlist,
          currentPlayingIndex
        };
        
        try {
          const response = await axiosInstance.post(`${API_BASE_URL}/api/presentations`, presentationData);
          const newRecentFile = {
            id: response.data.id,
            name: `${presentationData.name}.pres`,
            date: presentationData.date
          };
          setRecentFiles(prev => [newRecentFile, ...prev].slice(0, 5));
          setCurrentPresentation(response.data);
        } catch (error) {
          console.error('Error saving presentation:', error);
        }
        break;

      case 'open':
        try {
          const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations`, {
            params: { projectId: activeProject.id }
          });
          
          // Tampilkan dialog pemilihan presentasi
          setDialogContent(
            <PresentationDialog 
              presentations={response.data}
              onSelect={handleSelectPresentation}
              onClose={() => setDialogContent(null)}
              onDelete={handleDeletePresentation}
            />
          );
        } catch (error) {
          console.error('Error loading presentations:', error);
        }
        break;

      default:
        if (action.startsWith('open-recent-')) {
          const fileName = action.replace('open-recent-', '');
          try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations/byName/${fileName}`);
            const presentation = response.data;
            
            setSelectedItems(presentation.selectedItems);
            setPlaylist(presentation.playlist);
            setCurrentPlayingIndex(presentation.currentPlayingIndex);
            setCurrentPresentation(presentation);
          } catch (error) {
            console.error('Error loading recent presentation:', error);
          }
        }
    }
    setIsFileDropdownOpen(false);
  };

  const handleDeletePresentation = async (id) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/presentations/${id}`);
      // Refresh daftar presentasi
      const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations`, {
        params: { projectId: activeProject.id }
      });
      setDialogContent(
        <PresentationDialog 
          presentations={response.data}
          onSelect={handleSelectPresentation}
          onClose={() => setDialogContent(null)}
          onDelete={handleDeletePresentation}
        />
      );
    } catch (error) {
      console.error('Error deleting presentation:', error);
    }
  };

  const handleSelectPresentation = async (presentation) => {
    try {
      // Ambil data lengkap presentasi dari server
      const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations/${presentation.id}`);
      const fullPresentation = response.data;
      
      setSelectedItems(fullPresentation.selectedItems);
      setPlaylist(fullPresentation.playlist);
      setCurrentPlayingIndex(fullPresentation.currentPlayingIndex);
      setCurrentPresentation(fullPresentation);
      setDialogContent(null);
    } catch (error) {
      console.error('Error loading presentation:', error);
      alert('Gagal memuat presentasi: ' + error.message);
    }
  };

  const handleInvite = async (selectedUsers) => {
    try {
      if (!currentPresentation?.id) {
        throw new Error('Presentasi belum disimpan');
      }

      // Kirim ke endpoint baru untuk invite
      await axiosInstance.post(`${API_BASE_URL}/api/presentations/${currentPresentation.id}/invite`, {
        userIds: selectedUsers
      });
      
      setDialogContent(null);
    } catch (error) {
      console.error('Error inviting users:', error);
      alert('Gagal mengundang user: ' + error.message);
    }
  };

  const handleInviteClick = () => {
    if (!currentPresentation) {
      alert('Harap simpan presentasi terlebih dahulu sebelum mengundang user');
      return;
    }

    setDialogContent(
      <InviteModal
        isOpen={true}
        onClose={() => setDialogContent(null)}
        onInvite={handleInvite}
        isDarkMode={isDarkMode}
        currentPresentation={currentPresentation}
      />
    );
  };

  const handleBroadcastClick = () => {
    if (!currentPresentation) {
      alert('Harap simpan presentasi terlebih dahulu sebelum menjadwalkan broadcast');
      return;
    }
    setIsBroadcastModalOpen(true);
  };

  const handleBroadcastSubmit = async () => {
    // Refresh recent files setelah broadcast dijadwalkan
    const fetchRecentFiles = async () => {
      if (!activeProject?.id) return;
      
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations/recent`, {
          params: {
            projectId: activeProject.id,
            limit: 5
          }
        });
        
        const formattedFiles = response.data.map(pres => ({
          id: pres.id,
          name: `${pres.name}.pres`,
          date: pres.date
        }));
        
        setRecentFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching recent presentations:', error);
      }
    };
    await fetchRecentFiles();
  };

  const handleAddPlan = async (planData) => {
    try {
      // Validasi data sebelum dikirim
      if (!activeProject?.id) {
        throw new Error('Project ID tidak valid');
      }
      
      if (!planData || !planData.title || !planData.schedule) {
        throw new Error('Data broadcast tidak lengkap');
      }

      if (!currentPresentation?.id) {
        throw new Error('Presentasi belum dipilih');
      }

      // Ambil ID user yang sedang login
      const response = await axiosInstance.get('/api/auth/me');
      if (!response.data) {
        throw new Error('Pengguna saat ini tidak terdefinisi');
      }
      const organizerId = response.data.id;
      if (!organizerId) {
        throw new Error('ID pengguna tidak valid atau tidak ada');
      }

      const response2 = await axiosInstance.post(`${API_BASE_URL}/api/plans/broadcast`, {
        ...planData,
        projectId: activeProject.id,
        presentationId: currentPresentation.id,
        organizerId
      });
      
      setDialogContent(null);
      alert('Broadcast berhasil dijadwalkan');
    } catch (error) {
      console.error('Error creating plan:', error);
      alert(`Gagal membuat jadwal broadcast: ${error.message}`);
    }
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    
    // Cek apakah video sudah ada di selectedItems
    const isVideoSelected = selectedItems.some(item => item.id === video.id);
    
    if (!isVideoSelected) {
      setSelectedItems(prevItems => [...prevItems, video]);
    }
  };

  return (
    <div className="present-container">
      <div className="present-topbar">
        <div className="present-topbar-left">
          <div style={{ position: 'relative' }} ref={dropdownRef}>
            <button onClick={() => setIsFileDropdownOpen(!isFileDropdownOpen)}>
              <span>📁</span>
              File
            </button>
            
            {isFileDropdownOpen && (
              <div className="file-dropdown">
                <div className="file-dropdown-item" onClick={() => handleFileAction('open')}>
                  <span>📂</span>
                  Open File
                </div>
                <div className="file-dropdown-item" onClick={() => handleFileAction('save')}>
                  <span>💾</span>
                  Save File
                </div>
                
                <div className="file-dropdown-divider" />
                
                <div className="recent-files">
                  <div className="recent-files-title">Recent Files</div>
                  {recentFiles.map((file, index) => (
                    <div 
                      key={index}
                      className="file-dropdown-item"
                      onClick={() => handleFileAction(`open-recent-${file.name}`)}
                    >
                      <span>📄</span>
                      {file.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <button onClick={handleBroadcastClick}>
            <span>🔄</span>
            Broadcast
          </button>
        </div>
        <div className="present-topbar-right">
          <button onClick={handleInviteClick}>
            <span>👥</span>
            Invite
          </button>
        </div>
      </div>
      
      <div className="present-content">
        <div className="present-sidebar">
          <select 
            className="category-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="shot">Shot</option>
            <option value="sequence">Sequence</option>
            <option value="asset">Asset</option>
            <option value="task">Task</option>
          </select>

          {publishedVideos.length > 0 ? (
            <div className="library-grid">
              {publishedVideos.map((item, video) => (
                <div
                  key={item.id}
                  className="item-card"
                  onClick={() => handleVideoClick(item)}
                >
                  <div className="video-preview">
                    <img 
                      src={item.data?.thumbnailUrl} 
                      alt="Video preview"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/images/video-placeholder.png';
                      }}
                      
                    />
                    <div className="video-info-overlay">
                      <span className="version">v{item.version}</span>
                      {item.isApproved && (
                        <span className="approved-badge">Approved</span>
                      )}
                    </div>
                  </div>
                  <div className="video-info">
                    <h4>{item.name}</h4>
                    <p>Version {item.version}</p>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-videos-message">
              Tidak ada video yang dipublish untuk kategori ini
            </div>
          )}
        </div>
        <div className="present-main-content">
          {selectedItems.length > 0 && (
            <div>
              <div className="selected-videos-scroll">
                {selectedItems.map((item, index) => (
                  <div 
                    key={item.id}
                    className={`selected-video-card ${index === currentPlayingIndex ? 'playing' : ''}`}
                    onClick={() => handleVideoSelect(index)}
                  >
                    <div className="video-preview">
                      <span className={`category-badge ${
                        item.shotId ? 'shot' : 
                        item.sequenceId ? 'sequence' :
                        item.assetId ? 'asset' :
                        item.taskId ? 'task' : ''
                      }`}>
                        {item.shotId ? 'Shot' : 
                         item.sequenceId ? 'Sequence' :
                         item.assetId ? 'Asset' :
                         item.taskId ? 'Task' : ''}
                      </span>
                      <img 
                        src={item.data?.thumbnailUrl} 
                        alt="Video preview"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{item.data?.version}</span>
                        {item.data?.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-card-info">
                      <small>
                        {index + 1}. {item.shot?.shotName || 
                           item.sequence?.sequenceName || 
                           item.asset?.assetName || 
                           item.task?.taskName}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="playlist-controls">
                <button 
                  className="generate-playlist-button"
                  onClick={generatePlaylist}
                >
                  Generate Playlist ({selectedItems.length} items)
                </button>
              </div>
              
              {playlist.length > 0 && (
                <div className="playlist-container">
                  <div className="video-container">
                    <div className="video-player-wrapper">
                      <VideoPlayerPresent
                        key={playlist[currentPlayingIndex]}
                        filepath={playlist[currentPlayingIndex]}
                        watchMode={true}
                        initialAnnotations={[]}
                        ref={videoPlayerRef}
                        onEnded={handleVideoEnd}
                        showSidebar={true}
                        currentItem={selectedItems[currentPlayingIndex]}
                      />
                    </div>
                  </div>
                  <div className="playlist-controls">
                    <button 
                      onClick={() => setCurrentPlayingIndex(prev => Math.max(0, prev - 1))}
                      disabled={currentPlayingIndex === 0}
                    >
                      Previous
                    </button>
                    <button 
                      onClick={() => setCurrentPlayingIndex(prev => Math.min(playlist.length - 1, prev + 1))}
                      disabled={currentPlayingIndex === playlist.length - 1}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {dialogContent}
      
      <BroadcastModal
        isOpen={isBroadcastModalOpen}
        onClose={() => setIsBroadcastModalOpen(false)}
        onSubmit={handleBroadcastSubmit}
        isDarkMode={isDarkMode}
        currentPresentation={currentPresentation}
        currentUser={currentUser}
      />
    </div>
  );
};

export default PresentPanel;