import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  IconButton,
  useDisclosure,
  Input,
  Select,
  HStack,
  Text,
  Flex,
  Spacer,
  ButtonGroup,
  useToast,
  Spinner
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import IssueModal from './IssueModal';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';

const IssueList = ({ activeProject, userRole, isDarkMode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    status: '',
    priority: '',
    category: '',
    search: '',
    type: ''
  });
  const toast = useToast();
  const navigate = useNavigate();

  const fetchIssues = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'Please login to view issues',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
        navigate('/login');
        return;
      }

      const queryParams = new URLSearchParams();
      if (activeProject?.id) {
        queryParams.append('projectId', activeProject.id);
      }
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.priority) queryParams.append('priority', filters.priority);
      if (filters.category) queryParams.append('category', filters.category);
      if (filters.search) queryParams.append('search', filters.search);
      if (filters.type) queryParams.append('type', filters.type);

      console.log('Fetching issues with params:', queryParams.toString());
      console.log('Token:', token);

      const response = await axiosInstance.get(`/api/issues?${queryParams.toString()}`);
      
      if (!response.data) {
        throw new Error('No data received from server');
      }

      setIssues(response.data || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching issues:', error);
      let errorMessage = 'Failed to fetch issues';
      
      if (error.response) {
        if (error.response.status === 403) {
          errorMessage = 'Session expired. Please login again.';
          localStorage.removeItem('token');
          navigate('/login');
        } else if (error.response.data?.message) {
          errorMessage = error.response.data.message;
        }
      }
      
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeProject?.id) {
      fetchIssues();
    }
  }, [activeProject, filters]);

  const handleDeleteIssue = async (id) => {
    try {
      await axiosInstance.delete(`/api/issues/${id}`);
      setIssues(prevIssues => prevIssues.filter(issue => issue.id !== id));
      toast({
        title: 'Success',
        description: 'Issue deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      console.error('Error deleting issue:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete issue',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleEditIssue = (issue) => {
    // Transform the issue data to match the form structure
    const issueToEdit = {
      ...issue,
      assigneeId: issue.assignedTo || issue.assigneeId
    };
    setSelectedIssue(issueToEdit);
    onOpen();
  };

  const handleIssueUpdated = (updatedIssue) => {
    console.log('Updated issue:', updatedIssue);
    setIssues(issues.map(issue => 
      issue.id === updatedIssue.id ? {
        ...updatedIssue,
        assigneeId: updatedIssue.assignedTo,
        assignee: updatedIssue.assignee
      } : issue
    ));
    setSelectedIssue(null);
    toast({
      title: 'Success',
      description: 'Issue updated successfully',
      status: 'success',
      duration: 3000,
      isClosable: true
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box p={4} className={isDarkMode ? 'dark-mode' : ''}>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize="xl" fontWeight="bold">Issues</Text>
          <HStack spacing={4}>
            {userRole && userRole.permissions?.includes('manage_issues') && (
              <IconButton
                colorScheme="blue"
                aria-label="Add Issue"
                icon={<EditIcon />}
                onClick={() => {
                  setSelectedIssue(null);
                  onOpen();
                }}
              />
            )}
          </HStack>
        </Flex>

        <HStack spacing={4} mb={4}>
          <Input
            placeholder="Search issues..."
            value={filters.search}
            onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
          />
          <Select
            value={filters.status}
            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          >
            <option value="">All Status</option>
            <option value="OPEN">Open</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="RESOLVED">Resolved</option>
            <option value="CLOSED">Closed</option>
          </Select>
          <Select
            value={filters.priority}
            onChange={(e) => setFilters(prev => ({ ...prev, priority: e.target.value }))}
          >
            <option value="">All Priority</option>
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>
          </Select>
        </HStack>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>Status</Th>
              <Th>Priority</Th>
              <Th>Category</Th>
              <Th>Location</Th>
              <Th>Assigned To</Th>
              <Th>Created At</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {issues && issues.length > 0 ? (
              issues.map((issue) => (
                <Tr key={issue.id}>
                  <Td maxW="200px" isTruncated title={issue.title}>{issue.title}</Td>
                  <Td maxW="300px" isTruncated title={issue.description}>
                    {issue.description}
                  </Td>
                  <Td>
                    <Badge colorScheme={getStatusColor(issue.status)}>
                      {issue.status}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge colorScheme={getPriorityColor(issue.priority)}>
                      {issue.priority}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge colorScheme={getCategoryColor(issue.category)}>
                      {issue.category}
                    </Badge>
                  </Td>
                  <Td>{issue.location}</Td>
                  <Td>{issue.assignee ? issue.assignee.username : 'Unassigned'}</Td>
                  <Td>{format(new Date(issue.createdAt), 'dd/MM/yyyy HH:mm')}</Td>
                  <Td>
                    <ButtonGroup variant="ghost" spacing={2}>
                      <IconButton
                        icon={<EditIcon />}
                        aria-label="Edit issue"
                        onClick={() => handleEditIssue(issue)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete issue"
                        onClick={() => handleDeleteIssue(issue.id)}
                      />
                    </ButtonGroup>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={9} textAlign="center">No issues found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        {isOpen && (
          <IssueModal
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setSelectedIssue(null);
            }}
            issue={selectedIssue}
            onUpdate={handleIssueUpdated}
            isDarkMode={isDarkMode}
          />
        )}
      </Flex>
    </Box>
  );
};

const getStatusColor = (status) => {
  switch (status?.toUpperCase()) {
    case 'OPEN':
      return 'blue';
    case 'IN_PROGRESS':
      return 'yellow';
    case 'RESOLVED':
      return 'green';
    case 'CLOSED':
      return 'gray';
    default:
      return 'gray';
  }
};

const getPriorityColor = (priority) => {
  switch (priority?.toUpperCase()) {
    case 'HIGH':
      return 'red';
    case 'MEDIUM':
      return 'yellow';
    case 'LOW':
      return 'green';
    default:
      return 'gray';
  }
};

const getCategoryColor = (category) => {
  switch (category?.toUpperCase()) {
    case 'BUG':
      return 'red';
    case 'FEATURE':
      return 'green';
    case 'IMPROVEMENT':
      return 'blue';
    default:
      return 'gray';
  }
};

export default IssueList;
