import { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';

const useHashtags = () => {
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchHashtags = async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/hashtags`);
      setHashtags(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching hashtags:', error);
      setError('Gagal mengambil data hashtag');
      setLoading(false);
    }
  };

  const addHashtag = async (name) => {
    if (!name.startsWith('#')) {
      name = `#${name}`;
    }

    // Check if hashtag already exists
    const existingHashtag = hashtags.find(h => h.name === name);
    if (existingHashtag) {
      return existingHashtag;
    }

    try {
      const response = await axiosInstance.post(`${API_BASE_URL}/api/hashtags`, {
        name
      });
      
      // Update local state with new hashtag
      const newHashtag = response.data;
      setHashtags(prevHashtags => [...prevHashtags, newHashtag]);
      
      return newHashtag;
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data?.error === 'Hashtag sudah ada') {
        // If hashtag already exists in database but not in local state, fetch all hashtags
        await fetchHashtags();
        return hashtags.find(h => h.name === name);
      }
      console.error('Error adding hashtag:', error);
      throw new Error('Gagal menambahkan hashtag');
    }
  };

  useEffect(() => {
    fetchHashtags();
  }, []);

  return {
    hashtags,
    loading,
    error,
    fetchHashtags,
    addHashtag
  };
};

export default useHashtags;
