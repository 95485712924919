import React, { useState, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  HStack, 
  Text, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Progress, 
  Button, 
  useToast,
  TableContainer,
  Badge,
  Tooltip,
  IconButton
} from '@chakra-ui/react';
import { 
  FaFolder, 
  FaFile, 
  FaTrash, 
  FaDownload, 
  FaHdd, 
  FaImage, 
  FaVideo, 
  FaMusic, 
  FaFilePdf, 
  FaFileExcel, 
  FaFileWord,
  FaFileAlt 
} from 'react-icons/fa';
import axiosInstance from '../config/axios';

const StorageManagement = ({ isDarkMode }) => {
  const [storageData, setStorageData] = useState({
    total: 0,
    used: 0,
    files: [],
    categories: {}
  });
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchStorageData();
  }, []);

  const fetchStorageData = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      
      const response = await axiosInstance.get('/api/storage/management', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setStorageData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching video storage data:', {
        error: error.message,
        response: error.response?.data,
        status: error.response?.status
      });

      toast({
        title: 'Error Fetching Videos',
        description: error.response?.data?.message || 'Failed to fetch video data',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });

      setIsLoading(false);
    }
  };

  const handleDeleteFile = async (fileId, source) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/api/storage/file/${fileId}?source=${source}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      toast({
        title: 'File Deleted',
        description: 'File successfully removed from storage',
        status: 'success',
        duration: 2000,
        isClosable: true
      });

      fetchStorageData();
    } catch (error) {
      console.error('Error deleting file:', error);
      toast({
        title: 'Delete Failed',
        description: error.response?.data?.message || 'Could not delete file',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const sourceLabels = {
    project: 'Projects',
    upload: 'Uploads',
    presentation: 'Presentations', 
    render: 'Render Jobs',
    task: 'Tasks'
  };

  const renderStorageUsage = () => {
    const usedPercentage = (storageData.used / storageData.total) * 100;
    return (
      <VStack spacing={4} width="full" align="start">
        <Text fontSize="xl" fontWeight="bold">Storage Usage</Text>
        <Box width="full">
          <Progress 
            value={usedPercentage} 
            colorScheme={usedPercentage > 80 ? 'red' : 'blue'} 
            size="lg" 
            borderRadius="md"
          />
        </Box>
        <HStack width="full" justifyContent="space-between">
          <Text>Used: {(storageData.used / (1024 * 1024)).toFixed(2)} MB</Text>
          <Text>Total: {(storageData.total / (1024 * 1024)).toFixed(2)} MB</Text>
        </HStack>
      </VStack>
    );
  };

  const renderFileCategories = () => {
    return (
      <VStack spacing={4} width="full" align="start">
        <Text fontSize="xl" fontWeight="bold">Storage by Category</Text>
        {Object.entries(storageData.categories).map(([category, details]) => (
          <HStack key={category} width="full" justifyContent="space-between">
            <Text>{sourceLabels[category] || category}</Text>
            <Text>{(details.size / (1024 * 1024)).toFixed(2)} MB</Text>
          </HStack>
        ))}
      </VStack>
    );
  };

  const formatFileSize = (size) => {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  };

  const handleDownload = async (file) => {
    try {
      // Ensure filePath is properly encoded
      const encodedFilePath = encodeURIComponent(file.filePath);
      
      const response = await axiosInstance.get(`/api/storage/download`, {
        params: { filePath: file.filePath },
        responseType: 'blob' // Important for file downloads
      });

      // Determine filename with multiple fallbacks
      let filename = 'downloaded_video.mp4';
      
      // Priority 1: Use file.fileName
      if (file.fileName && file.fileName !== 'Unnamed Video') {
        filename = file.fileName;
      }
      
      // Priority 2: Extract from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }
      }

      // Ensure file extension
      if (!filename.includes('.')) {
        filename += '.mp4';
      }

      // Create a link element to trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Use the determined filename
      link.download = filename;
      
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      // Optional: Show success toast
      toast({
        title: "Download Started",
        description: `Downloading ${filename}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Download error:', error);
      
      // Error handling toast
      toast({
        title: "Download Failed",
        description: error.response?.data?.message || "Unable to download file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = (file) => {
    handleDeleteFile(file.id, file.source);
  };

  // File type icon mapping
  const fileTypeIcons = {
    image: <FaImage />,
    video: <FaVideo />,
    audio: <FaMusic />,
    document: <FaFileWord />,
    spreadsheet: <FaFileExcel />,
    pdf: <FaFilePdf />,
    other: <FaFile />
  };

  // File type color mapping
  const fileTypeColors = {
    image: 'green',
    video: 'purple',
    audio: 'blue',
    document: 'blue',
    spreadsheet: 'green',
    pdf: 'red',
    other: 'gray'
  };

  const renderFileList = () => {
    return (
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>File Name</Th>
              <Th>Size</Th>
              <Th>Source</Th>
              <Th>Associated Item</Th>
              <Th>Uploaded By</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {storageData.files.map((file) => (
              <Tr key={file.id}>
                <Td>
                  <Tooltip label={file.fileType}>
                    <Box color={fileTypeColors.video}>
                      {fileTypeIcons.video}
                    </Box>
                  </Tooltip>
                </Td>
                <Td>{file.fileName}</Td>
                <Td>{formatFileSize(file.fileSize || 0)}</Td>
                <Td>
                  <Badge colorScheme="purple">
                    {file.source}
                  </Badge>
                </Td>
                <Td>
                  <Tooltip label={file.associatedItem || 'No associated item'}>
                    <Text noOfLines={1}>
                      {file.associatedItem || 'N/A'}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>{file.uploadedBy}</Td>
                <Td>
                  <HStack>
                    <Tooltip label="Download">
                      <IconButton
                        icon={<FaDownload />}
                        size="sm"
                        onClick={() => handleDownload(file)}
                      />
                    </Tooltip>
                    <Tooltip label="Delete">
                      <IconButton
                        icon={<FaTrash />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(file)}
                      />
                    </Tooltip>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box p={4} bg={isDarkMode ? 'gray.800' : 'white'}>
      <VStack spacing={6} align="stretch">
        {renderStorageUsage()}
        {renderFileCategories()}
        <Text fontSize="xl" fontWeight="bold">Video Files</Text>
        {isLoading ? (
          <Text>Loading video files...</Text>
        ) : storageData.files.length === 0 ? (
          <Text>No video files found</Text>
        ) : (
          renderFileList()
        )}
      </VStack>
    </Box>
  );
};

export default StorageManagement;
