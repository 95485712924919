import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import VideoModal from './VideoModal';
import '../styles/globaloverview.css';
import TaskList from './TaskList';
import TaskCardView from './TaskCardView';
import GanttChart from './GanttChart';
import { API_BASE_URL } from '../config/config';
import OverviewHeader from './OverviewHeader';
import IssueForm from './IssueForm';

const AssetOverview = ({ assets, activeProject, currentUser, userRole, tasks, shots }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentAsset, setCurrentAsset] = useState(null);
  const [activeTab, setActiveTab] = useState('Asset Info');
  const [activities, setActivities] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [taskViewMode, setTaskViewMode] = useState('list');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isIssueFormVisible, setIsIssueFormVisible] = useState(false);

  const filteredAssets = assets.filter(asset => asset.projectId === activeProject?.id);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get(`/api/activities/assets/${id}`);
        // Urutkan activities berdasarkan timestamp terbaru
        const sortedActivities = response.data.sort((a, b) => 
          new Date(b.timestamp) - new Date(a.timestamp)
        );
        setActivities(sortedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    if (assets && id) {
      const asset = assets.find(s => s.id.toString() === id);
      if (asset) {
        setCurrentAsset(asset);
        // Fetch activities saat asset ditemukan
        fetchActivities();
      }
    }
  }, [assets, id]);

  const handlePrevious = () => {
    const currentIndex = filteredAssets.findIndex(s => s.id.toString() === id);
    if (currentIndex > 0) {
      navigate(`/asset-overview/${filteredAssets[currentIndex - 1].id}`);
    }
  };

  const handleNext = () => {
    const currentIndex = filteredAssets.findIndex(s => s.id.toString() === id);
    if (currentIndex < filteredAssets.length - 1) {
      navigate(`/asset-overview/${filteredAssets[currentIndex + 1].id}`);
    }
  };

  const handleAddComment = async (comment) => {
    try {
      const response = await axiosInstance.post('/api/activities', {
        assetId: id,
        action: 'add_comment',
        details: comment.text,
        user: currentUser,
        timestamp: new Date().toISOString()
      });
      
      if (response.data) {
        setActivities(prevActivities => [response.data, ...prevActivities]);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleEditComment = async (commentId, updatedComment) => {
    try {
      const response = await axiosInstance.put(`/api/activities/${commentId}`, {
        details: updatedComment.text
      });
      
      if (response.data) {
        setActivities(prevActivities =>
          prevActivities.map(activity =>
            activity.id === commentId ? response.data : activity
          )
        );
      }
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axiosInstance.delete(`/api/activities/${commentId}`);
      setActivities(prevActivities =>
        prevActivities.filter(activity => activity.id !== commentId)
      );
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handlePinComment = async (commentId) => {
    try {
      await axiosInstance.put(`/api/activities/${commentId}/pin`);
      setActivities(prevActivities =>
        prevActivities.map(activity =>
          activity.id === commentId
            ? { ...activity, isPinned: !activity.isPinned }
            : activity
        )
      );
    } catch (error) {
      console.error('Error pinning comment:', error);
    }
  };

  const handleApproveVideo = async (activityId, version) => {
    try {
      const response = await axiosInstance.post(`api/activities/${activityId}/approve`, {
        assetId: id,
        user: "Admin",
        version: version,
        timestamp: new Date().toISOString()
      });

      if (response.data) {
        setActivities(prevActivities =>
          prevActivities.map(activity =>
            activity.id === activityId ? response.data : activity
          )
        );
        setApprovalStatus(prev => ({
          ...prev,
          [activityId]: response.data.isApproved
        }));
      }
    } catch (error) {
      console.error('Error saat mengubah status approval video:', error);
      alert('Gagal mengubah status approval video');
    }
  };

  const getRelatedTasks = () => {
    if (!currentAsset || !tasks) return [];
    
    return tasks.filter(task => {
      // Cek apakah task terkait dengan asset ini langsung
      const isDirectlyRelated = currentAsset.selectedTasks?.includes(task.id);
      
      // Cek apakah task terkait melalui shot yang memiliki asset ini
      const isRelatedThroughShot = shots.some(shot => 
        shot.selectedAssets?.includes(currentAsset.id) && 
        shot.selectedTasks?.includes(task.id)
      );
      
      return isDirectlyRelated || isRelatedThroughShot;
    });
  };

  const getAssetThumbnail = (asset, tasks = [], shots = []) => {
    // Prioritaskan thumbnail asset itu sendiri
    if (asset.thumbnailUrl) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (asset.thumbnailUrl.startsWith('http')) return asset.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${asset.thumbnailUrl.startsWith('/') ? asset.thumbnailUrl : '/' + asset.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari task terkait
    const relatedTask = tasks.find(task => 
      asset.selectedTasks?.includes(task.id) && task.thumbnailUrl
    );
    
    if (relatedTask) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedTask.thumbnailUrl.startsWith('http')) return relatedTask.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedTask.thumbnailUrl.startsWith('/') ? relatedTask.thumbnailUrl : '/' + relatedTask.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari shot terkait
    const relatedShot = shots.find(shot => 
      asset.selectedShots?.includes(shot.id) && shot.thumbnailUrl
    );
    
    if (relatedShot) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedShot.thumbnailUrl.startsWith('http')) return relatedShot.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedShot.thumbnailUrl.startsWith('/') ? relatedShot.thumbnailUrl : '/' + relatedShot.thumbnailUrl}`;
    }
    
    // Jika tidak ada thumbnail, kembalikan placeholder
    return '/assets/images/asset-placeholder.png';
  };

  if (!currentAsset) {
    return (
      <div className="asset-not-found">
        <h2>Asset tidak ditemukan</h2>
        <button onClick={() => navigate('/assets')}>Kembali ke Daftar Asset</button>
      </div>
    );
  }

  const tabs = ['Asset Info', 'Activity', 'Related Tasks', 'Notes', 'Published', 'Client Approved'];

  return (
    <div className="asset-overview">
      <OverviewHeader
        item={currentAsset}
        type="Asset"
        thumbnailUrl={getAssetThumbnail(currentAsset, tasks, shots)}
        onPrevious={handlePrevious}
        onNext={handleNext}
        currentUser={currentUser}
      />

      <div className="asset-details">
        <div className="asset-info">
          <p><strong>Status:</strong> {currentAsset.status}</p>
          <p><strong>Deskripsi:</strong> {currentAsset.description}</p>
          <p><strong>Project:</strong> {activeProject?.name}</p>
        </div>
      </div>

      <div className="tab-container">
        {tabs.map(tab => (
          <button 
            key={tab} 
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === 'Asset Info' && (
          <div className="asset-info-content">
            <h3>Detail Asset</h3>
            <p>Nama: {currentAsset.assetName}</p>
            <p>Deskripsi: {currentAsset.description}</p>
            <p>Status: {currentAsset.status}</p>
          </div>
        )}
        {activeTab === 'Activity' && (
          <div className="activity-tab-content">
            <ActivityPanel
              item={currentAsset}
              type="asset"
              isOpen={true}
              onClose={() => {}}
              onAddComment={handleAddComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
              onPinComment={handlePinComment}
              currentUser={currentUser}
              activities={activities}
            />
          </div>
        )}
        {activeTab === 'Related Tasks' && (
          <div className="related-tasks-content">
            <div className="task-view-controls">
              <button 
                className={taskViewMode === 'list' ? 'active' : ''}
                onClick={() => setTaskViewMode('list')}
              >
                List View
              </button>
              <button 
                className={taskViewMode === 'card' ? 'active' : ''}
                onClick={() => setTaskViewMode('card')}
              >
                Card View
              </button>
              <button 
                className={taskViewMode === 'gantt' ? 'active' : ''}
                onClick={() => setTaskViewMode('gantt')}
              >
                Gantt Chart
              </button>
            </div>

            {taskViewMode === 'list' && (
              <TaskList 
                tasks={getRelatedTasks()} 
                currentUser={currentUser}
                isDarkMode={isDarkMode}
                shots={shots}
                assets={assets}
              />
            )}

            {taskViewMode === 'card' && (
              <TaskCardView 
                tasks={getRelatedTasks()}
                currentUser={currentUser}
                isDarkMode={isDarkMode}
                shots={shots}
                assets={assets}
              />
            )}

            {taskViewMode === 'gantt' && (
              <GanttChart 
                tasks={getRelatedTasks()}
                isDarkMode={isDarkMode}
                shots={shots}
                assets={assets}
              />
            )}
          </div>
        )}
        {activeTab === 'Published' && (
          <div className="published-content">
            <div className="library-grid">
              {activities
                .filter(activity => activity.action === 'publish_video')
                .map((activity, index) => (
                  <div key={index} className="library-item">
                    <div className="video-preview" onClick={() => setSelectedVideo(activity.data)}>
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        {activity.data.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>{activity.user}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.timestamp).toLocaleDateString()}</span>
                      </div>
                      {activity.action === 'publish_video' && (
                        <button 
                          className={`approve-button ${activity.data.isApproved ? 'cancel' : ''}`}
                          onClick={() => handleApproveVideo(activity.id, activity.data.version)}
                        >
                          {activity.data.isApproved ? 'Cancel Approve' : 'Client Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {selectedVideo && (
              <VideoModal
                isOpen={true}
                onClose={() => setSelectedVideo(null)}
                videoPath={selectedVideo.videoPath}
                annotations={selectedVideo.annotations || []}
              />
            )}
          </div>
        )}
        {activeTab === 'Client Approved' && (
          <div className="approved-content">
            <div className="library-grid">
              {activities
                .filter(activity => 
                  activity.action === 'publish_video' && 
                  activity.data.isApproved
                )
                .map((activity, index) => (
                  <div 
                    key={index} 
                    className="library-item"
                  >
                    <div 
                      className="video-preview"
                      onClick={() => setSelectedVideo(activity.data)}
                    >
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        <span className="approved-badge">Client Approved</span>
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>Approved by: {activity.data.approvedBy}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.data.approvedAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Container History */}
            <div className="approval-history-container">
              <h3>Approval History</h3>
              <div className="approval-history-list">
                {activities
                  .filter(activity => 
                    activity.action === 'approve_video' || 
                    activity.action === 'cancel_approve_video'
                  )
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((activity, index) => (
                    <div key={index} className="history-item">
                      <div className="history-icon">
                        {activity.action === 'approve_video' ? '✅' : '❌'}
                      </div>
                      <div className="history-content">
                        <p className="history-action">
                          {activity.action === 'approve_video' 
                            ? 'Video Approved' 
                            : 'Approval Cancelled'}
                        </p>
                        <p className="history-details">
                          Version: {activity.data.version} • 
                          By: {activity.user} • 
                          {new Date(activity.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <IssueForm
        isOpen={isIssueFormVisible}
        onClose={() => setIsIssueFormVisible(false)}
        itemId={currentAsset.id}
        itemType="Asset"
        currentUser={currentUser}
        location={`Asset: ${currentAsset.assetName}`}
      />
    </div>
  );
};

export default AssetOverview;
