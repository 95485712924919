import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import { FaEye, FaPlus } from 'react-icons/fa';
import './ReportManager.css';
import '../styles/global.css';
import { API_BASE_URL } from '../config/config';

const criticalStyles = `
  .task-name {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .critical-badge {
    background-color: #dc3545;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: bold;
  }

  .critical-days {
    color: #dc3545;
    font-weight: bold;
  }

  .critical-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .critical-filter {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .critical-count {
    color: #dc3545;
    font-weight: bold;
  }
`;

// Add styles to head
const styleSheet = document.createElement("style");
styleSheet.innerText = criticalStyles;
document.head.appendChild(styleSheet);

const TaskTrackingReport = ({ isDarkMode, selectedProject }) => {
  const [taskStats, setTaskStats] = useState({
    taskAnalytics: {
      totalTasks: 0,
      statusBreakdown: {},
      statusColors: {},
      topTasks: []
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    name: '',
    status: '',
    assignee: '',
    critical: false
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!selectedProject) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/stats/task-tracking/${selectedProject}`);
        if (!response.ok) {
          throw new Error('Failed to fetch task statistics');
        }
        const data = await response.json();
        if (isMounted) {
          setTaskStats(data);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching task stats:', err);
          setError('Gagal mengambil statistik task');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedProject]);

  // Fungsi untuk sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Fungsi untuk mendapatkan data yang sudah difilter dan disort
  const getFilteredAndSortedData = () => {
    let filteredData = [...(taskStats.taskAnalytics.topTasks || [])];

    // Filter data
    filteredData = filteredData.filter(task => {
      const matchesName = !filters.name || (task.name && task.name.toLowerCase().includes(filters.name.toLowerCase()));
      const matchesStatus = !filters.status || (task.status && task.status.toLowerCase().includes(filters.status.toLowerCase()));
      const matchesAssignee = !filters.assignee || (task.assignee && task.assignee.toLowerCase().includes(filters.assignee.toLowerCase()));
      const matchesCritical = !filters.critical || task.isCritical;
      
      return matchesName && matchesStatus && matchesAssignee && matchesCritical;
    });

    // Sort data
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  };

  const downloadExcel = () => {
    const filteredData = getFilteredAndSortedData();
    
    // Menyiapkan data untuk Excel
    const excelData = filteredData.map(task => ({
      'Task ID': task.id,
      'Name': task.name,
      'Description': task.description,
      'Status': task.status,
      'Assignee': task.assignee,
      'Due Date': task.dueDate,
      'Related Assets': Array.isArray(task.relatedAssets) && task.relatedAssets.length > 0 ? task.relatedAssets.map(asset => asset.assetName).join(', ') : '-',
      'Related Shots': Array.isArray(task.relatedShots) && task.relatedShots.length > 0 ? task.relatedShots.map(shot => shot.shotName).join(', ') : '-'
    }));

    // Membuat workbook dan worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Task Tracking Report');

    // Download file
    XLSX.writeFile(wb, 'task_tracking_report.xlsx');
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
    cutout: '60%'
  };

  if (loading) {
    return <div className="loading">Memuat data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className={`task-tracking-report ${isDarkMode ? 'dark' : ''}`}>
      <div className="report-header">
        <h2>Laporan Task Tracking</h2>
        <div className="summary-stats">
          <div className="stat-card">
            <h3>Total Tasks</h3>
            <p>{taskStats.taskAnalytics.totalTasks}</p>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <div className="chart-section">
          <h3>Status Task</h3>
          <div className="chart-wrapper">
            <Pie 
              data={{
                labels: Object.keys(taskStats.taskAnalytics.statusBreakdown),
                datasets: [{
                  backgroundColor: Object.keys(taskStats.taskAnalytics.statusBreakdown).map(status => {
                    const statusObj = taskStats.taskAnalytics.statusColors[status];
                    return statusObj ? `${statusObj.color}80` : '#CCCCCC80'; // 80 adalah 50% opacity dalam hex
                  }),
                  borderColor: Object.keys(taskStats.taskAnalytics.statusBreakdown).map(status => {
                    const statusObj = taskStats.taskAnalytics.statusColors[status];
                    return statusObj ? statusObj.color : '#CCCCCC';
                  }),
                  borderWidth: 1,
                  data: Object.values(taskStats.taskAnalytics.statusBreakdown)
                }]
              }}
              options={chartOptions}
            />
          </div>
        </div>
      </div>

      <div className="filters-section">
        <div className="filter-group">
          <label>Name:</label>
          <input
            type="text"
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            placeholder="Filter by name..."
          />
        </div>
        <div className="filter-group">
          <label>Status:</label>
          <input
            type="text"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            placeholder="Filter by status..."
          />
        </div>
        <div className="filter-group">
          <label>Assignee:</label>
          <input
            type="text"
            value={filters.assignee}
            onChange={(e) => setFilters({ ...filters, assignee: e.target.value })}
            placeholder="Filter by assignee..."
          />
        </div>
        <div className="filter-group critical-group">
          <div className="critical-filter">
            <label>Critical:</label>
            <input
              type="checkbox"
              checked={filters.critical}
              onChange={(e) => setFilters({ ...filters, critical: e.target.checked })}
            />
          </div>
          <div className="critical-count">
            Task Kritis: {taskStats.taskAnalytics.topTasks.filter(task => task.isCritical).length}
          </div>
        </div>
        <button onClick={downloadExcel} className="download-btn">
          Download Excel
        </button>
      </div>

      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th onClick={() => requestSort('id')}>Task ID</th>
              <th onClick={() => requestSort('name')}>Name</th>
              <th onClick={() => requestSort('description')}>Description</th>
              <th onClick={() => requestSort('status')}>Status</th>
              <th onClick={() => requestSort('assignee')}>Assignee</th>
              <th onClick={() => requestSort('dueDate')}>Due Date</th>
              <th onClick={() => requestSort('daysUntilDue')}>Days Until Due</th>
              <th onClick={() => requestSort('relatedAssets')}>Related Assets</th>
              <th onClick={() => requestSort('relatedShots')}>Related Shots</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredAndSortedData().map(task => (
              <tr key={task.id}>
                <td>{task.id}</td>
                <td>
                  <div className="task-name">
                    {task.name}
                    {task.isCritical && (
                      <span className="critical-badge" title="Deadline < 14 days">CRITICAL</span>
                    )}
                  </div>
                </td>
                <td>{task.description}</td>
                <td>
                  <span className={`status-badge ${task.status.toLowerCase()}`}>
                    {task.status}
                  </span>
                </td>
                <td>{task.assignee}</td>
                <td>{task.dueDate}</td>
                <td>
                  <span className={task.isCritical ? 'critical-days' : ''}>
                    {task.daysUntilDue > 0 ? `${task.daysUntilDue} days` : 'Overdue'}
                  </span>
                </td>
                <td>
                  {task.relatedAssets !== '-' ? (
                    <div className="related-badge">
                      <span className="icon"><FaPlus /></span>
                      {task.relatedAssets}
                    </div>
                  ) : '-'}
                </td>
                <td>
                  {task.relatedShots !== '-' ? (
                    <div className="related-badge">
                      <span className="icon"><FaEye /></span>
                      {task.relatedShots}
                    </div>
                  ) : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskTrackingReport;
