import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import TaskList from './TaskList';
import TaskForm from './TaskForm';
import TaskCardView from './TaskCardView';
import ActivityPanel from './ActivityPanel';
import { Routes, Route } from 'react-router-dom';
import GanttChart from './GanttChart';
import TaskOverview from './TaskOverview';
import { FaThLarge, FaList } from 'react-icons/fa';
import { API_BASE_URL } from '../config/config';
import '../styles/global.css';

const hasManagePermission = (userRole) => {
  const allowedPermissions = ['manage_tasks', 'create_project'];
  return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
};

const Task = ({ activeProject, userRole, tasks, setTasks, shots, assets, isDarkMode }) => {
  const [editingTask, setEditingTask] = useState(null);
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [selectedTask, setSelectedTask] = useState(null);
  const [isActivityPanelOpen, setIsActivityPanelOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    status: null,
    assignee: null,
    startDate: null,
    dueDate: null,
    progress: null
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'taskName',
    direction: 'asc'
  });
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('username'));

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  useEffect(() => {
    const newSocket = io(WS_BASE_URL);
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('taskUpdated', (updatedTask) => {
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === updatedTask.id ? updatedTask : task
          )
        );
      });

      socket.on('taskDeleted', (deletedTaskId) => {
        setTasks(prevTasks => 
          prevTasks.filter(task => task.id !== deletedTaskId)
        );
      });

      socket.on('taskCreated', (newTask) => {
        setTasks(prevTasks => [...prevTasks, newTask]);
      });
    }
  }, [socket, setTasks]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axiosInstance.get(`/api/tasks/${activeProject.id}`);
        const tasksWithProgress = response.data.map(task => ({
          ...task,
          progress: task.progress !== null && task.progress !== undefined ? 
            Number(task.progress) : 0
        }));
        setTasks(tasksWithProgress);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    if (activeProject?.id) {
      fetchTasks();
    }
  }, [activeProject?.id, setTasks]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error mengambil status:', error);
      }
    };
    fetchStatuses();
  }, []);

  const handleDeleteTask = async (id) => {
    try {
      const response = await axiosInstance.delete(`${API_BASE_URL}/api/tasks/${id}`);
      if (response.status === 200) {
        socket.emit('deleteTask', id);
        console.log('Task deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Gagal menghapus task');
    }
  };

  const handleEditTask = async (id, updatedFields) => {
    try {
      const oldTask = tasks.find(task => task.id === id);

      // Jika task tidak ditemukan di state, kita tetap lanjutkan update
      const taskToUpdate = oldTask || { id };

      // Tentukan status berdasarkan progress
      let status = updatedFields.status;
      const progress = updatedFields.progress || 0;
      
      if (progress === 100) {
        status = 'completed';
      } else if (progress > 0) {
        status = 'in-progress';
      } else {
        status = 'todo';
      }

      // Tambahkan logging untuk debug
      console.log('Task Update Request:', {
        ...updatedFields,
        taskName: updatedFields.taskName || oldTask?.taskName,
        status,
        projectId: activeProject.id
      });

      const response = await axiosInstance.put(`${API_BASE_URL}/api/tasks/${id}`, {
        ...updatedFields,
        taskName: updatedFields.taskName || oldTask?.taskName, // Pastikan taskName selalu ada
        status,
        projectId: activeProject.id
      }).catch(error => {
        console.error('Error updating task:', error.response || error);
        alert(`Failed to update task: ${error.response?.data?.message || error.message}`);
        throw error; // Re-throw error if you need further error handling
      });

      socket.emit('updateTask', response.data);

      // Create activity for task update only if we have the old task data
      if (oldTask) {
        await axiosInstance.post('/api/activities', {
          taskId: id,
          user: currentUser,
          action: 'edit_task',
          details: `Task "${updatedFields.taskName || oldTask.taskName}" telah diubah`,
          data: {
            before: oldTask,
            after: updatedFields
          },
          timestamp: new Date().toISOString()
        });
      }

      // Update related shots if we have the data
      if (oldTask?.selectedShots && updatedFields.selectedShots) {
        const oldShotIds = oldTask.selectedShots || [];
        const newShotIds = updatedFields.selectedShots || [];

        const shotsToAdd = newShotIds.filter(id => !oldShotIds.includes(id));
        const shotsToRemove = oldShotIds.filter(id => !newShotIds.includes(id));

        await Promise.all([
          ...shotsToAdd.map(shotId => 
            axiosInstance.put(`${API_BASE_URL}/api/shots/${shotId}`, {
              selectedTasks: [...(shots.find(s => s.id === shotId)?.selectedTasks || []), id]
            })
          ),
          ...shotsToRemove.map(shotId => {
            const shot = shots.find(s => s.id === shotId);
            return axiosInstance.put(`${API_BASE_URL}/api/shots/${shotId}`, {
              selectedTasks: (shot?.selectedTasks || []).filter(taskId => taskId !== id)
            });
          })
        ]);
      }

      // Update related assets if we have the data
      if (oldTask?.selectedAssets && updatedFields.selectedAssets) {
        const oldAssetIds = oldTask.selectedAssets || [];
        const newAssetIds = updatedFields.selectedAssets || [];

        const assetsToAdd = newAssetIds.filter(id => !oldAssetIds.includes(id));
        const assetsToRemove = oldAssetIds.filter(id => !newAssetIds.includes(id));

        await Promise.all([
          ...assetsToAdd.map(assetId => 
            axiosInstance.put(`${API_BASE_URL}/api/assets/${assetId}`, {
              selectedTasks: [...(assets.find(a => a.id === assetId)?.selectedTasks || []), id]
            })
          ),
          ...assetsToRemove.map(assetId => {
            const asset = assets.find(a => a.id === assetId);
            return axiosInstance.put(`${API_BASE_URL}/api/assets/${assetId}`, {
              selectedTasks: (asset?.selectedTasks || []).filter(taskId => taskId !== id)
            });
          })
        ]);
      }

      setEditingTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
      alert('Gagal mengupdate task');
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    setIsActivityPanelOpen(true);
  };

  return (
    <div className={`app-container ${isDarkMode ? 'dark' : ''} ${isActivityPanelOpen ? 'panel-open' : ''}`}>
      <div className="content-container">
        <div className="global-header">
          <h2>Task Manager</h2>
          <div className="view-toggle">
            <button 
              className={`toggle-btn ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => setViewMode('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'gantt' ? 'active' : ''}`}
              onClick={() => setViewMode('gantt')}
              title="Gantt View"
            >
              Gantt
            </button>
          </div>
        </div>
        <Routes>
          <Route path="/" element={
            <>
              {viewMode === 'list' ? (
                <TaskList
                  tasks={tasks}
                  shots={shots}
                  assets={assets}
                  onDeleteTask={handleDeleteTask}
                  onEditTask={handleEditTask}
                  userRole={userRole}
                  users={users}
                  statuses={statuses}
                  handleSort={handleSort}
                  handleFilterChange={handleFilterChange}
                  filters={filters}
                  onTaskSelect={handleTaskSelect}
                  selectedTaskId={selectedTask?.id}
                  isDarkMode={isDarkMode}
                  activeProject={activeProject}
                  socket={socket}
                  isActivityPanelOpen={isActivityPanelOpen}
                  setIsActivityPanelOpen={setIsActivityPanelOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  formatDate={formatDate}
                  hasManagePermission={hasManagePermission(userRole)}
                />
              ) : viewMode === 'card' ? (
                <TaskCardView
                tasks={tasks}
                shots={shots}
                assets={assets}
                onDeleteTask={handleDeleteTask}
                onEditTask={handleEditTask}
                userRole={userRole}
                users={users}
                statuses={statuses}
                handleSort={handleSort}
                handleFilterChange={handleFilterChange}
                filters={filters}
                onTaskSelect={handleTaskSelect}
                selectedTaskId={selectedTask?.id}
                isDarkMode={isDarkMode}
                activeProject={activeProject}
                socket={socket}
                isActivityPanelOpen={isActivityPanelOpen}
                setIsActivityPanelOpen={setIsActivityPanelOpen}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                formatDate={formatDate}
                hasManagePermission={hasManagePermission(userRole)}
                />
              ) : (
                <GanttChart 
                  tasks={tasks}
                  shots={shots}
                  assets={assets}
                  sequences={[]}
                  onDeleteTask={handleDeleteTask}
                  onEditTask={handleEditTask}
                  userRole={userRole}
                  isDarkMode={isDarkMode}
                  activeProject={activeProject}
                  socket={socket}
                />
              )}
            </>
          } />
          <Route path="/overview" element={<TaskOverview tasks={tasks} />} />
        </Routes>
      </div>
    </div>
  );
};

export default Task;
