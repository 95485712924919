import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import './ReportManager.css';
import '../styles/global.css';
import { API_BASE_URL } from '../config/config';

const AssetTrackingReport = ({ isDarkMode, selectedProject }) => {
  const [assetStats, setAssetStats] = useState({
    assetAnalytics: {
      totalAssets: 0,
      statusBreakdown: {},
      statusColors: {},
      topAssets: []
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    name: '',
    status: ''
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!selectedProject) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/stats/asset-tracking/${selectedProject}`);
        if (!response.ok) {
          throw new Error('Failed to fetch asset statistics');
        }
        const data = await response.json();
        if (isMounted) {
          setAssetStats(data);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching asset stats:', err);
          setError('Gagal mengambil statistik asset');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedProject]);

  // Fungsi untuk sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Fungsi untuk mendapatkan data yang sudah difilter dan disort
  const getFilteredAndSortedData = () => {
    let filteredData = [...(assetStats.assetAnalytics.topAssets || [])];

    // Filter data
    filteredData = filteredData.filter(asset => {
      const matchesName = !filters.name || (asset.name && asset.name.toLowerCase().includes(filters.name.toLowerCase()));
      const matchesStatus = !filters.status || (asset.status && asset.status.toLowerCase().includes(filters.status.toLowerCase()));
      
      return matchesName && matchesStatus;
    });

    // Sort data
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  };

  const downloadExcel = () => {
    const filteredData = getFilteredAndSortedData();
    
    // Menyiapkan data untuk Excel
    const excelData = filteredData.map(asset => ({
      'Asset ID': asset.id,
      'Name': asset.name,
      'Description': asset.description,
      'Status': asset.status
    }));

    // Membuat workbook dan worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Asset Tracking Report');

    // Download file
    XLSX.writeFile(wb, 'asset_tracking_report.xlsx');
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
    cutout: '60%'
  };

  if (loading) {
    return <div className="loading">Memuat data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className={`asset-tracking-report ${isDarkMode ? 'dark' : ''}`}>
      <div className="report-header">
        <h2>Laporan Asset Tracking</h2>
        <div className="summary-stats">
          <div className="stat-card">
            <h3>Total Assets</h3>
            <p>{assetStats.assetAnalytics.totalAssets}</p>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <div className="chart-section">
          <h3>Status Asset</h3>
          <div className="chart-wrapper">
            <Pie 
              data={{
                labels: Object.keys(assetStats.assetAnalytics.statusBreakdown),
                datasets: [{
                  backgroundColor: Object.keys(assetStats.assetAnalytics.statusBreakdown).map(status => {
                    const statusObj = assetStats.assetAnalytics.statusColors[status];
                    return statusObj ? `${statusObj.color}80` : '#CCCCCC80'; // 80 adalah 50% opacity dalam hex
                  }),
                  borderColor: Object.keys(assetStats.assetAnalytics.statusBreakdown).map(status => {
                    const statusObj = assetStats.assetAnalytics.statusColors[status];
                    return statusObj ? statusObj.color : '#CCCCCC';
                  }),
                  borderWidth: 1,
                  data: Object.values(assetStats.assetAnalytics.statusBreakdown)
                }]
              }}
              options={chartOptions}
            />
          </div>
        </div>
      </div>

      <div className="filters-section">
        <div className="filter-group">
          <label>Name:</label>
          <input
            type="text"
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            placeholder="Filter by name..."
          />
        </div>
        <div className="filter-group">
          <label>Status:</label>
          <input
            type="text"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            placeholder="Filter by status..."
          />
        </div>
        <button onClick={downloadExcel} className="download-btn">
          Download Excel
        </button>
      </div>

      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th onClick={() => requestSort('id')}>Asset ID</th>
              <th onClick={() => requestSort('name')}>Name</th>
              <th onClick={() => requestSort('description')}>Description</th>
              <th onClick={() => requestSort('status')}>Status</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredAndSortedData().map(asset => (
              <tr key={asset.id}>
                <td>{asset.id}</td>
                <td>{asset.name}</td>
                <td>{asset.description}</td>
                <td>
                  <span className={`status-badge ${asset.status.toLowerCase()}`}>
                    {asset.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetTrackingReport;
