import React, { useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/globalform.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import { FaEdit, FaTrash, FaEye, FaFilter, FaPlus, FaList, FaThLarge } from 'react-icons/fa';
import ShotForm from './ShotForm';

const hasManagePermission = (userRole) => {
  const allowedPermissions = ['manage_shots', 'create_project'];
  return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
};

const ShotList = ({ 
  shots, 
  sequences, 
  assets, 
  tasks,
  onDeleteShot, 
  onEditShot, 
  onAddShot,
  userRole, 
  isDarkMode, 
  activeProject, 
  socket,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  selectedShot,
  setSelectedShot,
  currentUser,
  setSequences,
  setShots,
  statuses = [],  // Add statuses prop
  users = []  // Add users prop
}) => {
  console.log('ShotList props:', { activeProject, currentUser, sequences });

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingShot, setEditingShot] = useState(null);
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    sequence: null,
    relatedAsset: null,
    relatedTask: null
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/shot-statuses`);
        const statuses = response.data;
        console.log(statuses);
      } catch (error) {
        console.error('Error mengambil status:', error);
      }
    };
    fetchStatuses();
  }, []);

  const handleFormSubmit = async (formData) => {
    console.log('Form data entries:');
    for (let [key, value] of formData.entries()) {
      console.log(`${key} : ${value instanceof File ? 'File' : value}`);
    }

    // Debug thumbnail
    const thumbnailFile = formData.get('thumbnail');
    console.log('Thumbnail Debug:', {
      thumbnailExists: !!thumbnailFile,
      thumbnailType: thumbnailFile instanceof File ? thumbnailFile.type : 'N/A',
      thumbnailSize: thumbnailFile instanceof File ? thumbnailFile.size : 'N/A',
      thumbnailName: thumbnailFile instanceof File ? thumbnailFile.name : 'N/A'
    });

    try {
      let response;
      const isEditing = editingShot !== null;
      
      // Log whether we're editing or creating
      console.log('Operation:', isEditing ? 'Editing shot' : 'Creating new shot');
      console.log('Editing shot data:', editingShot);

      if (isEditing) {
        // If editing, use PUT request
        response = await axiosInstance.put(
          `${API_BASE_URL}/api/shots/${editingShot.id}`, 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        console.log('Shot updated successfully:', response.data);
      } else {
        // If creating new, use POST request
        response = await axiosInstance.post(
          `${API_BASE_URL}/api/shots`, 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        console.log('Shot created successfully:', response.data);
      }

      // Debug thumbnail in response
      console.log('Response Thumbnail Details:', {
        thumbnailUrl: response.data.thumbnailUrl,
        thumbnailExists: !!response.data.thumbnailUrl
      });

      // Update shots list
      if (setShots && response.data) {
        setShots(prevShots => {
          if (isEditing) {
            // Update existing shot
            return prevShots.map(shot => 
              shot.id === response.data.id ? response.data : shot
            );
          } else {
            // Add new shot
            return [...prevShots, response.data];
          }
        });
      }

      // Update sequences
      if (setSequences && response.data) {
        const newSequenceId = response.data.sequenceId;
        const oldSequenceId = editingShot?.sequenceId;

        setSequences(prevSequences => 
          prevSequences.map(seq => {
            // If this is the new sequence for the shot
            if (seq.id === newSequenceId) {
              const existingShots = seq.shots || [];
              const shotIndex = existingShots.findIndex(s => s.id === response.data.id);
              
              if (shotIndex !== -1) {
                // Update existing shot in sequence
                return {
                  ...seq,
                  shots: existingShots.map(s => 
                    s.id === response.data.id ? response.data : s
                  )
                };
              } else {
                // Add shot to sequence
                return {
                  ...seq,
                  shots: [...existingShots, response.data]
                };
              }
            }
            
            // If this is the old sequence and we're moving the shot
            if (isEditing && oldSequenceId && seq.id === oldSequenceId && oldSequenceId !== newSequenceId) {
              return {
                ...seq,
                shots: (seq.shots || []).filter(s => s.id !== response.data.id)
              };
            }
            
            return seq;
          })
        );
      }

      return response.data;
    } catch (error) {
      console.error('Error submitting shot:', error);
      console.error('Error details:', error.response?.data);
      throw error;
    }
  };

  const handleEditClick = (shot) => {
    // Include sequence data in editFormData
    const shotWithSequence = {
      ...shot,
      sequence: sequences.find(seq => seq.id === shot.sequenceId)
    };
    setEditingShot(shotWithSequence);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingShot(null);
  };

  const handleShotClick = async (shot) => {
    let isMounted = true;
    
    try {
      const response = await axiosInstance.get(`/activities/shots/${shot.id}`);
      const activities = response.data;
      
      if (isMounted) {
        const updatedShot = {
          ...shot,
          activities: activities
        };
        
        setSelectedShot(updatedShot);
        setIsActivityPanelOpen(true);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      if (isMounted) {
        setSelectedShot(shot);
        setIsActivityPanelOpen(true);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const getStatusName = (statusId) => {
    const status = statuses.find(s => s.id === statusId);
    return status ? status.name : 'Unknown';
  };

  const formatDate = (date) => {
    if (!date) return '-';
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '-';
    return dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const navigateToSequence = (sequenceId) => {
    navigate(`/sequence-overview/${sequenceId}`);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: '',
      sequence: null,
      relatedAsset: null,
      relatedTask: null
    });
  };

  const filteredShots = shots.filter(shot => {
    if (filters.status && shot.statusId !== filters.status.value) return false;
    if (filters.name && !shot.shotName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    
    if (filters.sequence) {
      if (shot.sequenceId !== filters.sequence.value) return false;
    }
    
    if (filters.relatedAsset) {
      const hasRelatedAsset = shot.selectedAssets?.includes(filters.relatedAsset.value) ||
        assets.some(asset => 
          asset.id === filters.relatedAsset.value && 
          (
            asset.selectedShots?.includes(shot.id) ||
            asset.shotId === shot.id
          )
        );
      if (!hasRelatedAsset) return false;
    }
    
    if (filters.relatedTask) {
      const hasRelatedTask = shot.selectedTasks?.includes(filters.relatedTask.value) ||
        tasks.some(task => 
          task.id === filters.relatedTask.value && 
          (
            task.selectedShots?.includes(shot.id) ||
            task.shotId === shot.id
          )
        );
      if (!hasRelatedTask) return false;
    }
    
    if (searchTerm && !shot.shotName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  const getStatusBadge = (statusId) => {
    const status = statuses.find(s => s.id === statusId);
    if (!status) return (
      <span className="status-badge unknown">Unknown</span>
    );

    // Convert status name to a CSS-friendly class name
    const badgeClass = status.name.toLowerCase().replace(/\s+/g, '-');
    
    return (
      <span className={`status-badge ${badgeClass}`}>
        {status.name}
      </span>
    );
  };

  const getShotThumbnail = (shot, assets = [], tasks = []) => {
    // Prioritaskan thumbnail shot itu sendiri
    if (shot.thumbnailUrl) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (shot.thumbnailUrl.startsWith('http')) return shot.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${shot.thumbnailUrl.startsWith('/') ? shot.thumbnailUrl : '/' + shot.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari aset terkait
    const relatedAsset = assets.find(asset => 
      shot.selectedAssets?.includes(asset.id) && asset.thumbnailUrl
    );
    
    if (relatedAsset) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedAsset.thumbnailUrl.startsWith('http')) return relatedAsset.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedAsset.thumbnailUrl.startsWith('/') ? relatedAsset.thumbnailUrl : '/' + relatedAsset.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari task terkait
    const relatedTask = tasks.find(task => 
      shot.selectedTasks?.includes(task.id) && task.thumbnailUrl
    );
    
    if (relatedTask) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedTask.thumbnailUrl.startsWith('http')) return relatedTask.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedTask.thumbnailUrl.startsWith('/') ? relatedTask.thumbnailUrl : '/' + relatedTask.thumbnailUrl}`;
    }
    
    // Jika tidak ada thumbnail, kembalikan placeholder
    return '/assets/images/shot-placeholder.png';
  };

  const getAssignedUserName = (userId) => {
    const user = users.find(u => u.id === userId);
    return user ? (user.username || user.email || 'Unknown User') : 'Unassigned';
  };

  const handleOverviewClick = (shotId) => {
    navigate(`/shot-overview/${shotId}`);
  };

  return (
    <div className={`content-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>List View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search shots..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={() => {
                setEditingShot(null);
                setIsFormVisible(true);
              }}
            >
              <FaPlus /> Add Shot
            </button>
          )}
          <div className="view-toggle">
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <div className="filter-group">
              <label>Sequence</label>
              <Select
                value={filters.sequence}
                onChange={(value) => handleFilterChange('sequence', value)}
                options={sequences.map(sequence => ({
                  value: sequence.id,
                  label: sequence.sequenceName
                }))}
                isClearable
                placeholder="Filter by sequence"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Asset</label>
              <Select
                value={filters.relatedAsset}
                onChange={(value) => handleFilterChange('relatedAsset', value)}
                options={assets.map(asset => ({
                  value: asset.id,
                  label: asset.assetName
                }))}
                isClearable
                placeholder="Filter by related asset"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Task</label>
              <Select
                value={filters.relatedTask}
                onChange={(value) => handleFilterChange('relatedTask', value)}
                options={tasks.map(task => ({
                  value: task.id,
                  label: task.taskName
                }))}
                isClearable
                placeholder="Filter by related task"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      {isFormVisible && (
        <div className="modal-overlay">
          <div className="modal-form">
            <ShotForm
              onSubmit={handleFormSubmit}
              onClose={handleFormClose}
              sequences={sequences}
              assets={assets}
              tasks={tasks}
              isEditing={!!editingShot}
              initialShot={editingShot}
              isDarkMode={isDarkMode}
              currentUser={currentUser}
              activeProject={activeProject}
              setSequences={setSequences}
              setShots={setShots}
            />
          </div>
        </div>
      )}

      <div className="card mt-4">
        <div className="table-container">
          <table className="shot-table">
            <thead>
              <tr>
                <th>Thumbnail</th>
                <th>Shot Name</th>
                <th>Status</th>
                <th>Sequence</th>
                <th>Related Asset</th>
                <th>Related Tasks</th>
                <th>Start Date</th>
                <th>Due Date</th>
                <th>In Frame</th>
                <th>Out Frame</th>
                <th>Total Frame</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredShots.map((shot) => (
                <tr 
                  key={shot.id} 
                  className={`shot-item ${selectedShot?.id === shot.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
                  onClick={() => handleShotClick(shot)}
                >
                  <td>
                    <img 
                      src={getShotThumbnail(shot, assets, tasks)} 
                      alt="Shot Thumbnail" 
                      className="asset-thumbnail" 
                    />
                  </td>
                  <td>{shot.shotName}</td>
                  <td>{getStatusBadge(shot.statusId)}</td>
                  <td>
                    <div className="related-sequences-container">
                      {sequences.find(seq => seq.id === shot.sequenceId) && (
                        <div className="sequence-badge-container">
                          <span 
                            className={`sequence-badge ${shot.sequenceId ? `status-${shot.sequenceId}` : 'status-pending'}`}
                            onClick={() => navigateToSequence(shot.sequenceId)}
                          >
                            <div className="sequence-badge-content">
                              <span className="sequence-name">
                                {sequences.find(seq => seq.id === shot.sequenceId)?.sequenceName}
                              </span>
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="related-assets-container">
                      {assets.filter(asset => shot.selectedAssets?.includes(asset.id)).map(asset => (
                        <div key={asset.id} className="asset-badge-container">
                          <span className={`asset-badge ${asset.statusId ? `status-${asset.statusId}` : 'status-pending'}`}>
                            <div className="asset-badge-content">
                              <span className="asset-name">{asset.assetName}</span>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="related-tasks-container">
                      {tasks.filter(task => shot.selectedTasks?.includes(task.id)).map(task => (
                        <div key={task.id} className="task-badge-container">
                          <span className={`task-badge ${task.statusId ? `status-${task.statusId}` : 'status-pending'}`}>
                            <div className="task-badge-content">
                              <span className="task-name">{task.taskName}</span>
                            </div>
                            {task.assigneeId && (
                              <div className="task-stats">
                                <span 
                                  className="assignee-count" 
                                  title={`Assigned to: ${getAssignedUserName(task.assigneeId)}`}
                                >
                                  {getAssignedUserName(task.assigneeId).charAt(0).toUpperCase()}
                                </span>
                              </div>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>{formatDate(shot.start_date)}</td>
                  <td>{formatDate(shot.dueDate)}</td>
                  <td>{shot.in_frame || '-'}</td>
                  <td>{shot.out_frame || '-'}</td>
                  <td>{shot.total_frame || '-'}</td>
                  <td className="action-buttons">
                    {hasManagePermission(userRole) && (
                      <>
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(shot);
                          }}
                          title="Edit Shot"
                        >
                          <FaEdit />
                        </button>
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteShot(shot.id);
                          }}
                          title="Delete Shot"
                        >
                          <FaTrash />
                        </button>
                      </>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOverviewClick(shot.id);
                      }}
                      title="Shot Overview"
                    >
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedShot?.id && isActivityPanelOpen && (
        <ActivityPanel
          item={selectedShot}
          type="shot"
          isOpen={isActivityPanelOpen}
          onClose={() => {
            setSelectedShot(null);
            setIsActivityPanelOpen(false);
          }}
          currentUser={currentUser}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default ShotList;
