import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../styles/globalform.css';

const EpicForm = ({ onClose, onSubmit, editData = null, isDarkMode = false }) => {
  const [formData, setFormData] = useState({
    title: editData?.title || '',
    description: editData?.description || '',
    startDate: editData?.startDate || '',
    endDate: editData?.endDate || '',
    assignee: editData?.assignee || '',
    priority: editData?.priority || 'medium',
    status: editData?.status || 'todo',
    progress: editData?.progress || 0
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      if (!formData.title || !formData.startDate || !formData.endDate) {
        alert('Mohon isi semua field yang diperlukan');
        return;
      }

      // Format data
      const formattedData = {
        ...formData,
        progress: Number(formData.progress)
      };

      console.log('Submitting form data:', formattedData);
      await onSubmit(formattedData);
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Terjadi kesalahan saat menyimpan epic');
    }
  };

  return (
    <div className="epic-form-overlay">
      <div className={`epic-form ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="form-header">
          <h3>{editData ? 'Edit Epic' : 'Buat Epic Baru'}</h3>
          <button type="button" className="close-btn" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Judul<span className="required">*</span></label>
            <input
              id="title"
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Deskripsi</label>
            <textarea
              id="description"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              rows={4}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="startDate">Tanggal Mulai<span className="required">*</span></label>
              <input
                id="startDate"
                type="date"
                value={formData.startDate}
                onChange={(e) => setFormData({...formData, startDate: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">Tanggal Selesai<span className="required">*</span></label>
              <input
                id="endDate"
                type="date"
                value={formData.endDate}
                onChange={(e) => setFormData({...formData, endDate: e.target.value})}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="priority">Prioritas</label>
              <select
                id="priority"
                value={formData.priority}
                onChange={(e) => setFormData({...formData, priority: e.target.value})}
              >
                <option value="low">Rendah</option>
                <option value="medium">Sedang</option>
                <option value="high">Tinggi</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                value={formData.status}
                onChange={(e) => setFormData({...formData, status: e.target.value})}
              >
                <option value="todo">To Do</option>
                <option value="in-progress">In Progress</option>
                <option value="done">Done</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="progress">Progress (%)</label>
              <input
                id="progress"
                type="number"
                min="0"
                max="100"
                value={formData.progress}
                onChange={(e) => setFormData({...formData, progress: Math.min(100, Math.max(0, parseInt(e.target.value) || 0))})}
              />
            </div>
            <div className="form-group">
              <label htmlFor="assignee">Assignee</label>
              <input
                id="assignee"
                type="text"
                value={formData.assignee}
                onChange={(e) => setFormData({...formData, assignee: e.target.value})}
              />
            </div>
          </div>

          <div className="form-actions">
            <button type="button" className="cancel-btn" onClick={onClose}>
              Batal
            </button>
            <button type="submit" className="submit-btn">
              {editData ? 'Update' : 'Simpan'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EpicForm;