import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import VideoModal from './VideoModal';
import '../styles/globaloverview.css';
import TaskList from './TaskList';
import TaskCardView from './TaskCardView';
import GanttChart from './GanttChart';
import { API_BASE_URL } from '../config/config';
import OverviewHeader from './OverviewHeader';
import IssueForm from './IssueForm';

const ShotOverview = ({ shots, activeProject, currentUser, userRole, tasks = [], assets, isDarkMode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentShot, setCurrentShot] = useState(null);
  const [activeTab, setActiveTab] = useState('Shot Info');
  const [activities, setActivities] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [taskViewMode, setTaskViewMode] = useState('list');
  const [isIssueFormVisible, setIsIssueFormVisible] = useState(false);

  const filteredShots = shots.filter(shot => shot.projectId === activeProject?.id);

  useEffect(() => {
    if (shots && id) {
      const shot = shots.find(s => s.id.toString() === id);
      if (shot) {
        setCurrentShot(shot);
        // Fetch activities saat shot ditemukan
        fetchActivities(shot.id);
      }
    }
  }, [shots, id]);

  useEffect(() => {
    console.log('Tasks in ShotOverview:', tasks);
    console.log('Related tasks:', getRelatedTasks());
  }, [tasks]);

  const fetchActivities = async (shotId) => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/activities/shots/${shotId}`);
      // Urutkan activities berdasarkan timestamp terbaru
      const sortedActivities = response.data.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivities(sortedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handlePrevious = () => {
    const currentIndex = filteredShots.findIndex(s => s.id.toString() === id);
    if (currentIndex > 0) {
      navigate(`/shot-overview/${filteredShots[currentIndex - 1].id}`);
    }
  };

  const handleNext = () => {
    const currentIndex = filteredShots.findIndex(s => s.id.toString() === id);
    if (currentIndex < filteredShots.length - 1) {
      navigate(`/shot-overview/${filteredShots[currentIndex + 1].id}`);
    }
  };

  const handleAddComment = async (shotId, comment) => {
    try {
      const response = await axiosInstance.post('/api/activities', {
        shotId,
        action: 'add_comment',
        details: comment.text,
        user: currentUser,
        timestamp: new Date().toISOString()
      });
      
      if (response.data) {
        fetchActivities(shotId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleEditComment = async (shotId, commentId, updatedComment) => {
    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}`, {
        details: updatedComment.text
      });
      
      if (response.data) {
        fetchActivities(shotId);
      }
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (shotId, commentId) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/activities/${commentId}`);
      fetchActivities(shotId);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handlePinComment = async (shotId, commentId) => {
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}/pin`);
      fetchActivities(shotId);
    } catch (error) {
      console.error('Error pinning comment:', error);
    }
  };

  const handleApproveVideo = async (activityId, version) => {
    try {
      const response = await axiosInstance.post(`${API_BASE_URL}/api/activities/${activityId}/approve`, {
        shotId: currentShot.id,
        user: "Admin",
        version: version,
        timestamp: new Date().toISOString()
      });

      if (response.data) {
        await fetchActivities(currentShot.id);
        setApprovalStatus(prev => ({
          ...prev,
          [activityId]: response.data.isApproved
        }));
      }
    } catch (error) {
      console.error('Error saat mengubah status approval video:', error);
      alert('Gagal mengubah status approval video');
    }
  };

  const getRelatedTasks = () => {
    if (!currentShot || !tasks) return [];
    
    return tasks.filter(task => {
      // Cari semua shot yang terkait dengan task ini
      const relatedShots = shots.filter(shot => 
        shot.selectedTasks?.includes(task.id)
      );
      
      // Cek apakah ada shot yang namanya match dengan current shot
      const hasMatchingShot = relatedShots.some(shot => 
        shot.shotName === currentShot.shotName
      );
      
      // Cek juga direct relationship
      const hasDirectRelationship = 
        task.shotId === currentShot.id || 
        task.selectedShots?.includes(currentShot.id);
      
      return hasMatchingShot || hasDirectRelationship;
    });
  };

  const getShotThumbnail = (shot, assets = [], tasks = []) => {
    // Prioritaskan thumbnail shot itu sendiri
    if (shot.thumbnailUrl) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (shot.thumbnailUrl.startsWith('http')) return shot.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${shot.thumbnailUrl.startsWith('/') ? shot.thumbnailUrl : '/' + shot.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari aset terkait
    const relatedAsset = assets.find(asset => 
      shot.selectedAssets?.includes(asset.id) && asset.thumbnailUrl
    );
    
    if (relatedAsset) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedAsset.thumbnailUrl.startsWith('http')) return relatedAsset.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedAsset.thumbnailUrl.startsWith('/') ? relatedAsset.thumbnailUrl : '/' + relatedAsset.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari task terkait
    const relatedTask = tasks.find(task => 
      shot.selectedTasks?.includes(task.id) && task.thumbnailUrl
    );
    
    if (relatedTask) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedTask.thumbnailUrl.startsWith('http')) return relatedTask.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedTask.thumbnailUrl.startsWith('/') ? relatedTask.thumbnailUrl : '/' + relatedTask.thumbnailUrl}`;
    }
    
    // Jika tidak ada thumbnail, kembalikan placeholder
    return '/assets/images/shot-placeholder.png';
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  if (!currentShot) {
    return (
      <div className="shot-not-found">
        <h2>Shot tidak ditemukan</h2>
        <button onClick={() => navigate('/shots')}>Kembali ke Daftar Shot</button>
      </div>
    );
  }

  const tabs = ['Shot Info', 'Activity', 'Related Tasks', 'Notes', 'Published', 'Client Approved'];

  return (
    <div className="shot-overview">
      <OverviewHeader
        item={currentShot}
        type="Shot"
        thumbnailUrl={getShotThumbnail(currentShot, assets, tasks)}
        onPrevious={handlePrevious}
        onNext={handleNext}
        currentUser={currentUser}
      />

      <div className="shot-details">
        <div className="shot-info">
          <p><strong>Status:</strong> {currentShot.status}</p>
          <p><strong>Deskripsi:</strong> {currentShot.description}</p>
          <p><strong>Project:</strong> {activeProject?.name}</p>
        </div>
      </div>

      <div className="tab-container">
        {tabs.map(tab => (
          <button 
            key={tab} 
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === 'Shot Info' && currentShot && (
          <div className="shot-info-container">
            <div className="shot-info-section">
              <h3>Informasi Dasar</h3>
              <div className="info-row">
                <span className="info-label">Nama Shot:</span>
                <span className="info-value">{currentShot.shotName}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Deskripsi:</span>
                <span className="info-value">{currentShot.description || '-'}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Sequence:</span>
                <span className="info-value">{currentShot.sequence?.sequenceName || '-'}</span>
              </div>
            </div>

            <div className="shot-info-section">
              <h3>Tanggal</h3>
              <div className="info-row">
                <span className="info-label">Tanggal Mulai:</span>
                <span className="info-value">{formatDate(currentShot.startDate)}</span>
              </div>
              <div className="info-row">
                <span className="info-label">Tanggal Selesai:</span>
                <span className="info-value">{formatDate(currentShot.dueDate)}</span>
              </div>
            </div>

            <div className="shot-info-section">
              <h3>Aset Terkait</h3>
              {assets.filter(asset => currentShot.selectedAssets?.includes(asset.id)).map(asset => (
                <div key={asset.id} className="info-row">
                  <span className="info-label">Aset:</span>
                  <span className="info-value">{asset.assetName}</span>
                </div>
              ))}
            </div>

            <div className="shot-info-section">
              <h3>Task Terkait</h3>
              {getRelatedTasks().map(task => (
                <div key={task.id} className="info-row">
                  <span className="info-label">Task:</span>
                  <span className="info-value">{task.taskName}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === 'Activity' && (
          <div className="activity-tab-content">
            <ActivityPanel
              item={currentShot}
              type="shot"
              isOpen={true}
              onClose={() => {}}
              onAddComment={handleAddComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
              onPinComment={handlePinComment}
              currentUser={currentUser}
              activities={activities}
            />
          </div>
        )}
        {activeTab === 'Related Tasks' && (
          <div className="related-tasks-section">
            <div className="view-controls">
              <button 
                className={`view-button ${taskViewMode === 'list' ? 'active' : ''}`}
                onClick={() => setTaskViewMode('list')}
              >
                List View
              </button>
              <button 
                className={`view-button ${taskViewMode === 'card' ? 'active' : ''}`}
                onClick={() => setTaskViewMode('card')}
              >
                Card View
              </button>
              <button 
                className={`view-button ${taskViewMode === 'gantt' ? 'active' : ''}`}
                onClick={() => setTaskViewMode('gantt')}
              >
                Gantt Chart
              </button>
            </div>

            {taskViewMode === 'list' && (
              <TaskList
                tasks={getRelatedTasks()}
                shots={shots}
                assets={assets}
                onDeleteTask={() => {}}
                onEditTask={() => {}}
                userRole={userRole}
                isDarkMode={isDarkMode}
                activeProject={activeProject}
                isActivityPanelOpen={false}
                setIsActivityPanelOpen={() => {}}
                selectedTask={null}
                setSelectedTask={() => {}}
              />
            )}

            {taskViewMode === 'card' && (
              <TaskCardView
                tasks={getRelatedTasks()}
                shots={shots}
                assets={assets}
                onDeleteTask={() => {}}
                onEditTask={() => {}}
                userRole={userRole}
                isDarkMode={isDarkMode}
                activeProject={activeProject}
                isActivityPanelOpen={false}
                setIsActivityPanelOpen={() => {}}
                selectedTask={null}
                setSelectedTask={() => {}}
              />
            )}

            {taskViewMode === 'gantt' && (
              <GanttChart
                tasks={getRelatedTasks()}
                shots={shots}
                assets={assets}
                sequences={[]}
                onDeleteTask={() => {}}
                onEditTask={() => {}}
                userRole={userRole}
                isDarkMode={isDarkMode}
                activeProject={activeProject}
                socket={null}
              />
            )}
          </div>
        )}
        {activeTab === 'Notes' && (
          <div className="notes-content">
            {/* Implementasi untuk menampilkan catatan */}
          </div>
        )}
        {activeTab === 'Published' && (
          <div className="published-content">
            <div className="library-grid">
              {activities
                .filter(activity => activity.action === 'publish_video')
                .map((activity, index) => (
                  <div key={index} className="library-item">
                    <div className="video-preview" onClick={() => setSelectedVideo(activity.data)}>
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        {activity.data.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>{activity.user}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.timestamp).toLocaleDateString()}</span>
                      </div>
                      {activity.action === 'publish_video' && (
                        <button 
                          className={`approve-button ${activity.data.isApproved ? 'cancel' : ''}`}
                          onClick={() => handleApproveVideo(activity.id, activity.data.version)}
                        >
                          {activity.data.isApproved ? 'Cancel Approve' : 'Client Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {selectedVideo && (
              <VideoModal
                isOpen={true}
                onClose={() => setSelectedVideo(null)}
                videoPath={selectedVideo.videoPath}
                annotations={selectedVideo.annotations || []}
              />
            )}
          </div>
        )}
        {activeTab === 'Client Approved' && (
          <div className="approved-content">
            <div className="library-grid">
              {activities
                .filter(activity => 
                  activity.action === 'publish_video' && 
                  activity.data.isApproved
                )
                .map((activity, index) => (
                  <div 
                    key={index} 
                    className="library-item"
                  >
                    <div 
                      className="video-preview"
                      onClick={() => setSelectedVideo(activity.data)}
                    >
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        <span className="approved-badge">Client Approved</span>
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>Approved by: {activity.data.approvedBy}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.data.approvedAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Container History */}
            <div className="approval-history-container">
              <h3>Approval History</h3>
              <div className="approval-history-list">
                {activities
                  .filter(activity => 
                    activity.action === 'approve_video' || 
                    activity.action === 'cancel_approve_video'
                  )
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((activity, index) => (
                    <div key={index} className="history-item">
                      <div className="history-icon">
                        {activity.action === 'approve_video' ? '✅' : '❌'}
                      </div>
                      <div className="history-content">
                        <p className="history-action">
                          {activity.action === 'approve_video' 
                            ? 'Video Approved' 
                            : 'Approval Cancelled'}
                        </p>
                        <p className="history-details">
                          Version: {activity.data.version} • 
                          By: {activity.user} • 
                          {new Date(activity.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <IssueForm
        isOpen={isIssueFormVisible}
        onClose={() => setIsIssueFormVisible(false)}
        itemId={currentShot.id}
        itemType="Shot"
        currentUser={currentUser}
        location={`Shot: ${currentShot.shotName}`}
      />
    </div>
  );
};

export default ShotOverview;
