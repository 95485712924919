import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';

const LevelList = () => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    try {
      const response = await axiosInstance.get('/api/levels');
      setLevels(response.data);
    } catch (error) {
      console.error('Error mengambil data level:', error);
    }
  };

  return (
    <div className="level-list-container">
      <table className="level-table">
        <thead>
          <tr>
            <th>Nama Level</th>
            <th>Deskripsi</th>
            <th>Ranking</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {levels.map(level => (
            <tr key={level.id}>
              <td>{level.name}</td>
              <td>{level.description}</td>
              <td>{level.rank}</td>
              <td>
                <span className={`status-badge ${level.isActive ? 'active' : 'inactive'}`}>
                  {level.isActive ? 'Aktif' : 'Tidak Aktif'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LevelList; 