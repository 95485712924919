import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import AssetList from './AssetList';
import AssetCardView from './AssetCardView';
import { FaThLarge, FaList } from 'react-icons/fa';
import ActivityPanel from './ActivityPanel';
import '../styles/global.css';

const Asset = ({ 
  activeProject, 
  userRole, 
  assets, 
  setAssets, 
  shots, 
  tasks, 
  isDarkMode, 
  setSelectedAsset, 
  setShots 
}) => {
  const [editingAsset, setEditingAsset] = useState(null);
  const socketRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [selectedAssetState, setSelectedAssetState] = useState(null);
  const [isActivityPanelOpen, setIsActivityPanelOpen] = useState(false);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState(null);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    search: '',
    status: null,
    assignee: null,
    startDate: null,
    dueDate: null,
    progress: null
  });

  const setupSocket = () => {
    console.group('Asset Socket Connection Setup');
    console.log('Attempting to establish asset socket connection');

    // Disconnect existing socket if it exists
    if (socketRef.current) {
      try {
        socketRef.current.disconnect();
        console.log('Previous asset socket disconnected');
      } catch (disconnectError) {
        console.error('Error disconnecting previous asset socket:', disconnectError);
      }
    }

    // Retrieve and validate authentication token
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. Cannot establish socket connection.');
      navigate('/login');
      return null;
    }

    // Create new socket connection with enhanced authentication
    const newSocket = io(WS_BASE_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      randomizationFactor: 0.5,
      timeout: 10000,
      forceNew: true,
      auth: {
        token: token
      },
      query: {
        token: token
      }
    });

    // Detailed socket event logging
    newSocket.on('connect', () => {
      console.log('Asset socket connected successfully');
      console.log('Asset Socket ID:', newSocket.id);
    });

    newSocket.on('connect_error', (error) => {
      console.group('Asset Socket Connection Error');
      console.error('Connection error details:', error);
      console.log('Error name:', error.name);
      console.log('Error message:', error.message);
      console.log('Authentication token present:', !!token);
      
      // Detailed authentication error handling
      if (error.message.includes('Authentication')) {
        console.error('Socket authentication failed. Logging out user.');
        // Perform logout and redirect to login
        localStorage.removeItem('token');
        navigate('/login');
      }
      
      console.groupEnd();
    });

    newSocket.on('disconnect', (reason) => {
      console.group('Asset Socket Disconnected');
      console.log('Disconnect reason:', reason);
      console.log('Was socket connected before disconnect:', newSocket.connected);
      console.groupEnd();

      // Attempt reconnection if not an intentional disconnect
      if (reason !== 'io client disconnect') {
        newSocket.connect();
      }
    });

    newSocket.on('reconnect', (attemptNumber) => {
      console.group('Asset Socket Reconnected');
      console.log('Reconnected after', attemptNumber, 'attempts');
      console.groupEnd();
    });

    console.groupEnd();
    return newSocket;
  };

  useEffect(() => {
    // Setup socket connection
    const newSocket = setupSocket();
    socketRef.current = newSocket;

    // Socket listeners
    if (newSocket) {
      newSocket.on('assetCreated', (newAsset) => {
        console.log('New asset received via socket:', newAsset);
        setAssets(prevAssets => {
          const exists = prevAssets.some(asset => asset.id === newAsset.id);
          return exists 
            ? prevAssets.map(asset => asset.id === newAsset.id ? newAsset : asset)
            : [...prevAssets, newAsset];
        });
      });

      newSocket.on('assetUpdated', (updatedAsset) => {
        console.log('Asset updated via socket:', updatedAsset);
        setAssets(prevAssets => 
          prevAssets.map(asset => 
            asset.id === updatedAsset.id ? updatedAsset : asset
          )
        );
      });

      newSocket.on('assetDeleted', (deletedAssetId) => {
        console.log('Asset deleted via socket:', deletedAssetId);
        setAssets(prevAssets => 
          prevAssets.filter(asset => asset.id !== deletedAssetId)
        );
      });
    }

    // Cleanup
    return () => {
      if (socketRef.current) {
        socketRef.current.off('assetCreated');
        socketRef.current.off('assetUpdated');
        socketRef.current.off('assetDeleted');
        socketRef.current.disconnect();
      }
    };
  }, [activeProject?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusesRes, usersRes] = await Promise.all([
          axiosInstance.get('/api/task-statuses'),
          axiosInstance.get('/api/users')
        ]);

        setStatuses(statusesRes.data);
        setUsers(usersRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAssets = async () => {
      if (activeProject) {
        try {
          const response = await axiosInstance.get(`/api/assets/project/${activeProject.id}`);
          if (response.data) {
            setAssets(response.data);
          }
        } catch (error) {
          console.error('Error fetching assets:', error);
        }
      }
    };

    fetchAssets();
  }, [activeProject, setAssets]);

  useEffect(() => {
    const fetchShots = async () => {
      if (activeProject) {
        try {
          const response = await axiosInstance.get(`/api/shots/${activeProject.id}`);
          if (response.data) {
            if (typeof setShots === 'function') {
              setShots(response.data);
            }
          }
        } catch (error) {
          console.error('Error fetching shots:', error);
        }
      }
    };

    fetchShots();
  }, [activeProject, setShots]);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_assets', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleAssetSelect = (asset) => {
    setSelectedAssetState(asset);
    setIsActivityPanelOpen(true);
  };

  const handleDeleteAsset = async (id) => {
    if (window.confirm('Are you sure you want to delete this asset?')) {
      try {
        const assetId = id?.id || id; // Handle both object and primitive id
        const response = await axiosInstance.delete(`api/assets/${assetId}`);
        if (response.status === 200) {
          socketRef.current.emit('deleteAsset', assetId);
          console.log('Asset deleted successfully');
        }
      } catch (error) {
        console.error('Error deleting asset:', error);
      }
    }
  };

  const handleEditAsset = async (id, updatedData) => {
    try {
      if (!userRole) {
        throw new Error('User role tidak tersedia');
      }

      const oldAsset = assets.find(a => a.id === id);
      if (!oldAsset) {
        throw new Error('Asset tidak ditemukan');
      }

      let assetData;
      if (updatedData instanceof FormData) {
        assetData = {
          assetName: updatedData.get('assetName'),
          description: updatedData.get('description'),
          statusId: updatedData.get('statusId'),
          dueDate: updatedData.get('dueDate'),
          selectedShots: JSON.parse(updatedData.get('selectedShots') || '[]'),
          selectedTasks: JSON.parse(updatedData.get('selectedTasks') || '[]')
        };
      } else {
        assetData = updatedData;
      }

      const dataToSend = {
        assetName: assetData.assetName || oldAsset.assetName,
        description: assetData.description || oldAsset.description,
        statusId: assetData.statusId || oldAsset.statusId,
        dueDate: assetData.dueDate !== undefined ? assetData.dueDate : oldAsset.dueDate,
        selectedShots: assetData.selectedShots || oldAsset.selectedShots || [],
        selectedTasks: assetData.selectedTasks || oldAsset.selectedTasks || []
      };

      const response = await axiosInstance.put(`/api/assets/${id}`, dataToSend);

      if (response.data) {
        socketRef.current.emit('updateAsset', response.data);

        // Ambil username dari localStorage atau currentUser
        const username = localStorage.getItem('username') || userRole.username || 'unknown';

        await axiosInstance.post('/api/activities', {
          assetId: id,
          user: username,
          action: 'edit_asset',
          details: `Asset "${dataToSend.assetName}" telah diubah`,
          data: {
            before: {
              assetName: oldAsset.assetName,
              description: oldAsset.description,
              statusId: oldAsset.statusId,
              selectedShots: oldAsset.selectedShots || [],
              selectedTasks: oldAsset.selectedTasks || []
            },
            after: dataToSend
          },
          timestamp: new Date().toISOString()
        });
      }

      return response.data;
    } catch (error) {
      console.error('Error editing asset:', error);
      alert('Gagal mengubah asset. Silakan coba lagi.');
      return null;
    }
  };

  const fetchAssetDetails = async (assetId) => {
    try {
      const response = await axiosInstance.get(`/assets/detail/${assetId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching asset details:', error);
      return null;
    }
  };

  const handleShowAssetDetails = async (assetId) => {
    const details = await fetchAssetDetails(assetId);
    setSelectedAssetDetails(details);
    // Tambahkan logika untuk membuka modal atau panel detail asset
  };

  const filteredAssets = activeProject
    ? assets.filter(asset => asset.projectId === activeProject.id)
    : [];

  return (
    <div className={`app-container ${isDarkMode ? 'dark' : ''} ${isActivityPanelOpen ? 'panel-open' : ''}`}>
      <div className="content-container">
        <div className="global-header">
          <h2>Asset Manager</h2>
          <div className="view-toggle">
            <button 
              className={`toggle-btn ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => setViewMode('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>

        {viewMode === 'list' ? (
          <AssetList
            assets={filteredAssets}
            shots={shots}
            tasks={tasks}
            onDeleteAsset={handleDeleteAsset}
            onEditAsset={handleEditAsset}
            userRole={userRole}
            isDarkMode={isDarkMode}
            activeProject={activeProject}
            socket={socketRef.current}
            isActivityPanelOpen={isActivityPanelOpen}
            setIsActivityPanelOpen={setIsActivityPanelOpen}
            selectedAsset={selectedAssetState}
            setSelectedAsset={setSelectedAssetState}
          />
        ) : (
          <AssetCardView
            assets={filteredAssets}
            setAssets={setAssets}
            shots={shots}
            tasks={tasks}
            onDeleteAsset={handleDeleteAsset}
            handleEditClick={handleEditAsset}
            userRole={userRole}
            isDarkMode={isDarkMode}
            activeProject={activeProject}
            isActivityPanelOpen={isActivityPanelOpen}
            setIsActivityPanelOpen={setIsActivityPanelOpen}
            selectedAsset={selectedAssetState}
            setSelectedAsset={setSelectedAssetState}
            onAssetSelect={handleAssetSelect}
            hasManagePermission={hasManagePermission(userRole)}
            statuses={statuses}
            users={users}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
