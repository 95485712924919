import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { SketchPicker } from 'react-color';
import './TrackingSettings.css';

const AssetStatusManager = () => {
  const [statuses, setStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [newAbbreviation, setNewAbbreviation] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [progressType, setProgressType] = useState('+');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [editingStatus, setEditingStatus] = useState(null);
  const [showEditColorPicker, setShowEditColorPicker] = useState(false);

  useEffect(() => {
    fetchStatuses();
  }, []);

  const fetchStatuses = async () => {
    try {
      const response = await axiosInstance.get('/api/asset-statuses');
      setStatuses(response.data);
    } catch (error) {
      console.error('Error mengambil status:', error);
    }
  };

  const handleAddStatus = async () => {
    if (!newStatus.trim() || !newAbbreviation.trim()) {
      alert('Nama status dan singkatan harus diisi');
      return;
    }

    try {
      const response = await axiosInstance.post('/api/asset-statuses', {
        name: newStatus,
        abbreviation: newAbbreviation,
        progressType: progressType,
        color: selectedColor,
        isDefault: false
      });
      
      setStatuses([...statuses, response.data]);
      setNewStatus('');
      setNewAbbreviation('');
      setSelectedColor('#000000');
      setProgressType('+');
    } catch (error) {
      console.error('Error menambah status:', error);
    }
  };

  const handleColorPickerClick = (e, isEdit = false) => {
    e.stopPropagation();
    if (isEdit) {
      setShowEditColorPicker(!showEditColorPicker);
      setShowColorPicker(false);
    } else {
      setShowColorPicker(!showColorPicker);
      setShowEditColorPicker(false);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const handleClickOutside = () => {
    setShowColorPicker(false);
    setShowEditColorPicker(false);
  };

  const handleEditClick = (status) => {
    setEditingStatus(status);
    setNewStatus(status.name);
    setNewAbbreviation(status.abbreviation);
    setSelectedColor(status.color);
    setProgressType(status.progressType);
  };

  const handleCancelEdit = () => {
    setEditingStatus(null);
    setNewStatus('');
    setNewAbbreviation('');
    setSelectedColor('#000000');
    setProgressType('+');
    setShowColorPicker(false);
    setShowEditColorPicker(false);
  };

  const handleEditStatus = async (status) => {
    try {
      if (!newStatus.trim() || !newAbbreviation.trim()) {
        alert('Nama status dan singkatan harus diisi');
        return;
      }

      const updatedStatus = {
        name: newStatus,
        abbreviation: newAbbreviation,
        color: selectedColor,
        progressType: progressType
      };

      const response = await axiosInstance.put(`/api/asset-statuses/${status.id}`, updatedStatus);
      
      setStatuses(statuses.map(s => 
        s.id === status.id ? response.data : s
      ));
      
      // Reset form
      setEditingStatus(null);
      setNewStatus('');
      setNewAbbreviation('');
      setSelectedColor('#000000');
      setProgressType('+');
      setShowColorPicker(false);
      setShowEditColorPicker(false);
    } catch (error) {
      console.error('Error mengupdate status:', error);
      alert('Gagal mengupdate status');
    }
  };

  const handleDeleteStatus = async (id) => {
    try {
      const statusToDelete = statuses.find(s => s.id === id);
      if (statusToDelete.isDefault) {
        alert('Status default tidak dapat dihapus');
        return;
      }

      await axiosInstance.delete(`/api/asset-statuses/${id}`);
      setStatuses(statuses.filter(status => status.id !== id));
    } catch (error) {
      console.error('Error menghapus status:', error);
    }
  };

  return (
    <div className="status-manager">
      <div className="add-status-form">
        <h3>Tambah Status Baru</h3>
        <input
          type="text"
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          placeholder="Nama status"
        />
        <input
          type="text"
          value={newAbbreviation}
          onChange={(e) => setNewAbbreviation(e.target.value)}
          placeholder="Singkatan"
          maxLength={10}
        />
        <select
          value={progressType}
          onChange={(e) => setProgressType(e.target.value)}
        >
          <option value="+">Progress (+)</option>
          <option value="-">Progress (-)</option>
        </select>
        <div className="color-selector">
          <div
            className="color-preview"
            style={{ backgroundColor: selectedColor }}
            onClick={(e) => handleColorPickerClick(e, false)}
          />
          {showColorPicker && (
            <div className="color-picker-popover">
              <div className="color-picker-cover" onClick={handleClickOutside} />
              <SketchPicker color={selectedColor} onChange={handleColorChange} />
            </div>
          )}
        </div>
        <button onClick={handleAddStatus}>Tambah Status</button>
      </div>

      <div className="status-list">
        {statuses.map(status => (
          <div key={status.id} className="status-item">
            {editingStatus?.id === status.id ? (
              <div className="status-edit-form">
                <input
                  type="text"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  placeholder="Nama status"
                />
                <input
                  type="text"
                  value={newAbbreviation}
                  onChange={(e) => setNewAbbreviation(e.target.value)}
                  placeholder="Singkatan"
                  maxLength={10}
                />
                <select
                  value={progressType}
                  onChange={(e) => setProgressType(e.target.value)}
                >
                  <option value="+">Progress (+)</option>
                  <option value="-">Progress (-)</option>
                </select>
                <div className="color-selector">
                  <div
                    className="color-preview"
                    style={{ backgroundColor: selectedColor }}
                    onClick={(e) => handleColorPickerClick(e, true)}
                  />
                  {showEditColorPicker && (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={handleClickOutside} />
                      <SketchPicker color={selectedColor} onChange={handleColorChange} />
                    </div>
                  )}
                </div>
                <button onClick={() => handleEditStatus(status)}>Simpan</button>
                <button onClick={handleCancelEdit}>Batal</button>
              </div>
            ) : (
              <>
                <div className="status-info">
                  <span className="status-name">{status.name}</span>
                  <span className="status-abbr">[{status.abbreviation || '-'}]</span>
                  <span className={`progress-type ${status.progressType === '+' ? 'positive' : 'negative'}`}>
                    {status.progressType}
                  </span>
                </div>
                <div className="status-controls">
                  <div
                    className="color-preview"
                    style={{ backgroundColor: status.color }}
                  />
                  {!status.isDefault && (
                    <>
                      <button onClick={() => handleEditClick(status)}>Edit</button>
                      <button onClick={() => handleDeleteStatus(status.id)}>Hapus</button>
                    </>
                  )}
                  {status.isDefault && (
                    <span className="default-badge">Default</span>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetStatusManager; 