import React, { useState } from 'react';
import { FaEdit, FaTrash, FaEye, FaTasks, FaImages, FaUser, FaCalendarAlt, FaList, FaThLarge, FaPlus, FaFilter } from 'react-icons/fa';
import Select from 'react-select';
import '../styles/CardView.css';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import SequenceForm from './SequenceForm'; // Import SequenceForm
import axiosInstance from '../config/axios';
const SequenceCardView = ({ 
  sequences,
  tasks,
  shots,
  onDeleteSequence,
  handleEditClick: propHandleEditClick, // Rename prop untuk menghindari konflik
  userRole, 
  isDarkMode,
  activeProject,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  onSequenceSelect,
  selectedSequence,
  setSelectedSequence,
  statuses,
  users,
  hasManagePermission,
  onViewModeChange,
  viewMode,
  socket,
  onEditSequence // Tambahkan prop untuk edit sequence
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: null,
    name: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);

  const handleSequenceClick = (sequence) => {
    if (setSelectedSequence) {
      setSelectedSequence(sequence);
    }
    if (onSequenceSelect) {
      onSequenceSelect(sequence);
    }
    if (setIsActivityPanelOpen) {
      setIsActivityPanelOpen(true);
    }
  };

  const navigateToSequenceOverview = (sequenceId) => {
    navigate(`/sequence-overview/${sequenceId}`);
  };

  const getStatusBadge = (statusId) => {
    if (!statusId) return 'pending';
    const status = statuses?.find(s => s.id === parseInt(statusId));
    return status ? status.name.toLowerCase().replace(/\s+/g, '-') : 'pending';
  };

  const getStatusName = (statusId) => {
    if (!statusId) return 'Pending';
    const status = statuses?.find(s => s.id === parseInt(statusId));
    return status ? status.name : 'Pending';
  };

  const getAssigneeName = (userId) => {
    if (!userId || !users) return '';
    const user = users.find(u => u.id === userId);
    return user ? user.name : '';
  };

  const calculateProgress = (sequenceShots) => {
    if (!sequenceShots.length) return 0;
    const completedShots = sequenceShots.filter(shot => 
      shot.statusId && statuses?.find(s => s.id === parseInt(shot.statusId))?.name === 'Completed'
    ).length;
    return Math.round((completedShots / sequenceShots.length) * 100);
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: ''
    });
  };

  const filteredSequences = sequences.filter(sequence => {
    if (filters.status && sequence.statusId !== filters.status.value) return false;
    if (filters.name && !sequence.sequenceName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    if (searchTerm && !sequence.sequenceName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  const handleEditClick = (sequence) => {
    console.log('Editing sequence:', sequence);
    setEditingId(sequence.id);
    setEditFormData(sequence);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      console.log('Submitting sequence form:', formData);
      if (editingId) {
        await onEditSequence(editingId, formData);
      }
      handleFormClose();
    } catch (error) {
      console.error('Error updating sequence:', error);
    }
  };
  const handleAddSequence = async (formData) => {
    try {
      console.log('Adding new sequence:', formData);
      const response = await axiosInstance.post('/api/sequences', {
        ...formData,
        projectId: activeProject.id
      });

      if (response.data) {
        console.log('New sequence created:', response.data);
        socket.emit('createSequence', response.data);
        setIsFormVisible(false);

        const username = typeof userRole === 'object' ? 
          userRole.username || 'unknown' : 
          userRole || 'unknown';

        await axiosInstance.post('/api/activities', {
          sequenceId: response.data.id,
          user: username,
          action: 'create_sequence',
          details: `Sequence "${formData.sequenceName}" telah dibuat`,
          data: {
            sequenceName: formData.sequenceName,
            description: formData.description,
            statusId: formData.statusId,
            selectedShots: formData.selectedShots
          },
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error creating sequence:', error);
      alert('Gagal membuat sequence. Silakan coba lagi.');
    }
  };
  return (
    <div className={`card-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>Card View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search sequences..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
            {hasManagePermission && (
              <button
                className="btn btn-primary"
                onClick={() => setIsFormVisible(true)}
              >
                <FaPlus /> Add Sequence
              </button>
            )}
          </div>
          {isFormVisible && (
        <div className="modal-overlay">
          <div className="modal-form">
            <SequenceForm
              onSubmit={editingId ? handleFormSubmit : handleAddSequence}
              onClose={handleFormClose}
              shots={shots}
              isEditing={!!editingId}
              initialSequence={editFormData}
              currentUser={userRole?.username}
              isDarkMode={isDarkMode}
            />
          </div>
        </div>
      )}


          <div className="view-toggle">
            <button
              className={`toggle-button ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => onViewModeChange('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => onViewModeChange('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      <div className={`card-grid ${isDarkMode ? 'dark-mode' : ''}`}>
        {filteredSequences.map((sequence) => {
          const sequenceShots = shots.filter(shot => shot.sequenceId === sequence.id);
          const sequenceTasks = tasks.filter(task => 
            sequenceShots.some(shot => shot.selectedTasks?.includes(task.id))
          );
          const progress = calculateProgress(sequenceShots);
          const completedShots = sequenceShots.filter(shot => 
            shot.statusId && statuses?.find(s => s.id === parseInt(shot.statusId))?.name === 'Completed'
          ).length;
          const totalShots = sequenceShots.length;

          return (
            <div 
              key={sequence.id}
              className={`sequence-item ${selectedSequence?.id === sequence.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
              onClick={() => handleSequenceClick(sequence)}
            >
              {sequence.thumbnail && (
                <div className="thumbnail-container">
                  <img 
                    src={sequence.thumbnail} 
                    alt={sequence.sequenceName} 
                    className="thumbnail" 
                  />
                </div>
              )}
              
              <div className="card-header">
                <h3 className="card-title">{sequence.sequenceName}</h3>
                <span className={`status-badge status-${getStatusBadge(sequence.statusId)}`}>
                  {getStatusName(sequence.statusId)}
                </span>
              </div>

              <div className="card-description">
                {sequence.description || 'No description'}
              </div>

              <div className="card-content">
                {/* Progress bar removed */}
                <div className="stats-container">
                  <div className="stat-item">
                    <div className="stat-label">
                      <FaImages /> Shots
                    </div>
                    <div className="stat-value">{sequenceShots.length}</div>
                  </div>
                  <div className="stat-item">
                    <div className="stat-label">
                      <FaTasks /> Tasks
                    </div>
                    <div className="stat-value">{sequenceTasks.length}</div>
                  </div>
                  <div className="stat-item">
                    <div className="stat-label">
                      <FaCalendarAlt /> Due
                    </div>
                    <div className="stat-value">{formatDate(sequence.dueDate)}</div>
                  </div>
                </div>

                <div className="related-items-container">
                  {sequence.assignedTo && (
                    <span className="item-badge">
                      <FaUser />
                      {getAssigneeName(sequence.assignedTo)}
                    </span>
                  )}
                  {sequence.priority && (
                    <span className={`priority-badge priority-${sequence.priority.toLowerCase()}`}>
                      {sequence.priority}
                    </span>
                  )}
                  {sequence.tags?.map(tag => (
                    <span key={tag} className="item-badge">
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>

              <div className="card-footer">
                <div className="card-actions">
                  {hasManagePermission && (
                    <>
                      <button
                        className="action-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(sequence);
                        }}
                        title="Edit Sequence"
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="action-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteSequence(sequence.id);
                        }}
                        title="Delete Sequence"
                      >
                        <FaTrash />
                      </button>
                    </>
                  )}
                  <button
                    className="action-button"
                    onClick={() => navigateToSequenceOverview(sequence.id)}
                    title="View Details"
                  >
                    <FaEye />
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        {selectedSequence?.id && isActivityPanelOpen && (
          <ActivityPanel
            item={selectedSequence}
            type="sequence"
            isOpen={isActivityPanelOpen}
            onClose={() => {
              setSelectedSequence(null);
              setIsActivityPanelOpen(false);
            }}
            onAddComment={() => {
              if (activeProject?.id) {
                // fetchSequences();
              }
            }}
            onEditComment={() => {
              if (activeProject?.id) {
                // fetchSequences();
              }
            }}
            onDeleteComment={() => {
              if (activeProject?.id) {
                // fetchSequences();
              }
            }}
            onPinComment={() => {
              if (activeProject?.id) {
                // fetchSequences();
              }
            }}
            currentUser={userRole?.username}
            isDarkMode={isDarkMode}
          />
        )}
      </div>


    </div>
  );
};

export default SequenceCardView;
