import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import { 
  FaCalendarAlt, 
  FaUsers, 
  FaClock, 
  FaMapMarkerAlt, 
  FaPlus, 
  FaEdit, 
  FaTrash, 
  FaLink, 
  FaList, 
  FaChevronLeft, 
  FaChevronRight,
  FaUser
} from 'react-icons/fa';
import '../styles/global.css';
import { 
  Box, 
  Button, 
  Flex, 
  Grid, 
  HStack, 
  IconButton, 
  Text, 
  VStack, 
  useToast 
} from '@chakra-ui/react';
import PlanForm from './PlanForm';

const Plans = ({ activeProject, userRole, isDarkMode, currentUser }) => {
  const [plans, setPlans] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingPlan, setEditingPlan] = useState(null);
  const [view, setView] = useState('calendar');
  const [filter, setFilter] = useState('all');
  const [users, setUsers] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const toast = useToast();
  const [socket, setSocket] = useState(null);

  const fetchPlans = async () => {
    try {
      console.log('Fetching plans for project:', activeProject?.id);
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/plans/${activeProject.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Plans response:', response.data);
      setPlans(response.data);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  useEffect(() => {
    if (activeProject?.id) {
      fetchPlans();
    }
  }, [activeProject]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found');
      return;
    }

    const socket = io(WS_BASE_URL, {
      auth: { token },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });

    socket.on('connect', () => {
      console.log('Socket connected successfully');
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket && activeProject?.id) {
      socket.on('newPlan', (plan) => {
        if (plan.projectId === activeProject.id) {
          setPlans(prevPlans => [...prevPlans, plan]);
        }
      });

      socket.on('updatePlan', (updatedPlan) => {
        if (updatedPlan.projectId === activeProject.id) {
          setPlans(prevPlans => 
            prevPlans.map(p => p.id === updatedPlan.id ? updatedPlan : p)
          );
        }
      });

      socket.on('deletePlan', (planId) => {
        setPlans(prevPlans => prevPlans.filter(p => p.id !== planId));
      });

      return () => {
        socket.off('newPlan');
        socket.off('updatePlan');
        socket.off('deletePlan');
      };
    }
  }, [socket, activeProject]);

  useEffect(() => {
    if (activeProject) {
      console.log('Anda sedang berada di project:', activeProject.name, 'dengan ID:', activeProject.id);
    } else {
      console.log('Tidak ada project yang aktif.');
    }
  }, [activeProject]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddPlan = async (planData) => {
    try {
      if (!activeProject) {
        throw new Error('Project tidak tersedia');
      }

      const projectResponse = await axiosInstance.get(`/api/projects/${activeProject.id}`);
      if (!projectResponse.data) {
        throw new Error('Project tidak ditemukan');
      }

      const payload = {
        ...planData,
        projectId: activeProject.id,
        status: 'upcoming',
        organizerId: planData.organizerId || currentUser?.id
      };

      console.log('Adding plan with payload:', payload);

      await axiosInstance.post('/api/plans', payload);
      await fetchPlans();
      setIsFormVisible(false);
      toast({
        title: 'Success',
        description: 'Plan created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding plan:', error);
      console.log('Error detail:', error.response?.data);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to create plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditPlan = async (id, planData) => {
    try {
      const payload = {
        ...planData,
        projectId: activeProject.id,
        organizerId: planData.organizerId || currentUser?.id
      };

      console.log('Updating plan with payload:', payload);

      const response = await axiosInstance.put(`/api/plans/${id}`, payload);
      setPlans(plans.map(plan => plan.id === id ? response.data : plan));
      setEditingPlan(null);
      setIsFormVisible(false);
      toast({
        title: 'Success',
        description: 'Plan updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating plan:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeletePlan = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this plan?')) {
        await axiosInstance.delete(`/api/plans/${id}`);
        await fetchPlans();
      }
    } catch (error) {
      console.error('Error deleting plan:', error);
      alert('Failed to delete plan');
    }
  };

  const filterPlans = () => {
    const now = new Date();
    switch (filter) {
      case 'upcoming':
        return plans.filter(plan => new Date(plan.date) > now);
      case 'past':
        return plans.filter(plan => new Date(plan.date) < now);
      default:
        return plans;
    }
  };

  const renderCalendarView = () => {
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    const daysInMonth = [];
    for (let i = 0; i < firstDay.getDay(); i++) {
      daysInMonth.push(null);
    }
    for (let i = 1; i <= lastDay.getDate(); i++) {
      daysInMonth.push(new Date(currentYear, currentMonth, i));
    }

    const goToPreviousMonth = () => {
      setCurrentDate(new Date(currentYear, currentMonth - 1, 1));
    };

    const goToNextMonth = () => {
      setCurrentDate(new Date(currentYear, currentMonth + 1, 1));
    };

    const goToToday = () => {
      setCurrentDate(new Date());
    };

    return (
      <Box p={4} bg={isDarkMode ? "gray.800" : "white"} borderRadius="lg" shadow="base">
        <Flex direction="column" gap={4}>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={2}>
              <IconButton
                icon={<FaChevronLeft />}
                onClick={goToPreviousMonth}
                variant="ghost"
                aria-label="Previous month"
              />
              <Text fontSize="xl" fontWeight="bold">
                {currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
              </Text>
              <IconButton
                icon={<FaChevronRight />}
                onClick={goToNextMonth}
                variant="ghost"
                aria-label="Next month"
              />
            </Flex>
            <Button onClick={goToToday} size="sm">Today</Button>
          </Flex>

          <Grid templateColumns="repeat(7, 1fr)" gap={2}>
            {weekDays.map(day => (
              <Box 
                key={day} 
                p={2} 
                textAlign="center"
                fontWeight="semibold"
                color={isDarkMode ? "gray.400" : "gray.600"}
              >
                {day.slice(0, 3)}
              </Box>
            ))}
            
            {daysInMonth.map((date, index) => {
              if (!date) {
                return (
                  <Box 
                    key={`empty-${index}`} 
                    bg={isDarkMode ? "gray.700" : "gray.50"} 
                    borderRadius="md" 
                    minH="120px"
                  />
                );
              }

              const dayPlans = plans.filter(plan => 
                new Date(plan.date).toDateString() === date.toDateString()
              );

              const isToday = date.toDateString() === new Date().toDateString();

              return (
                <Box
                  key={date.getTime()}
                  p={2}
                  bg={isDarkMode ? "gray.700" : "gray.50"}
                  borderRadius="md"
                  borderWidth={isToday ? "2px" : "1px"}
                  borderColor={isToday ? "blue.500" : isDarkMode ? "gray.600" : "gray.200"}
                  minH="120px"
                >
                  <Text 
                    fontWeight="semibold" 
                    mb={2}
                    color={isDarkMode ? "white" : "gray.800"}
                  >
                    {date.getDate()}
                  </Text>
                  <VStack spacing={1} align="stretch">
                    {dayPlans.map(plan => (
                      <Box
                        key={plan.id}
                        p={2}
                        bg={getPlanColor(plan.type)}
                        color="white"
                        borderRadius="md"
                        fontSize="sm"
                        cursor="pointer"
                        position="relative"
                        _hover={{
                          '& > .tooltip': {
                            display: 'block',
                          }
                        }}
                      >
                        <Flex gap={2} align="center">
                          <Text fontSize="xs" fontWeight="bold">
                            {plan.time}
                          </Text>
                          <Text noOfLines={1}>
                            {plan.title}
                          </Text>
                        </Flex>
                        
                        <Box
                          className="tooltip"
                          display="none"
                          position="absolute"
                          top="100%"
                          left="0"
                          zIndex={1000}
                          minW="250px"
                          p={3}
                          bg={isDarkMode ? "#1A202C" : "#FFFFFF"}
                          borderWidth="1px"
                          borderColor={isDarkMode ? "gray.600" : "gray.200"}
                          borderRadius="md"
                          shadow="lg"
                          color={isDarkMode ? "white" : "gray.800"}
                          _hover={{ display: "block" }}
                          mt={2}
                          backdropFilter="none"
                        >
                          <VStack align="stretch" spacing={2} bg="inherit">
                            <Text fontWeight="bold" fontSize="md">{plan.title}</Text>
                            <Text fontSize="sm">{plan.description}</Text>
                            <HStack spacing={2}>
                              <Box color={isDarkMode ? "blue.300" : "blue.500"}>
                                <FaClock />
                              </Box>
                              <Text fontSize="sm">{plan.time} ({plan.duration} minutes)</Text>
                            </HStack>
                            <HStack spacing={2}>
                              <Box color={isDarkMode ? "green.300" : "green.500"}>
                                <FaMapMarkerAlt />
                              </Box>
                              <Text fontSize="sm">{plan.location}</Text>
                            </HStack>
                            <HStack spacing={2}>
                              <Box color={isDarkMode ? "purple.300" : "purple.500"}>
                                <FaUsers />
                              </Box>
                              <Text fontSize="sm">
                                {plan.attendeeUsers?.map(user => user.username).join(', ') || 'No attendees'}
                              </Text>
                            </HStack>
                            <HStack spacing={2}>
                              <Box color={isDarkMode ? "orange.300" : "orange.500"}>
                                <FaUser />
                              </Box>
                              <Text fontSize="sm">
                                {plan.organizer?.username || 'Unknown'}
                              </Text>
                            </HStack>
                          </VStack>
                        </Box>
                      </Box>
                    ))}
                  </VStack>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      </Box>
    );
  };

  const renderPlanCards = (plans) => {
    return (
      <div className='plan-cards-container'>
        {plans.map((plan) => (
          <div key={plan.id} className={`plan-card ${isDarkMode ? 'dark' : 'light'}`}>
            <div className='plan-card-header'>
              <h3>{plan.title}</h3>
            </div>
            <div className='plan-card-body'>
              <p><FaCalendarAlt /> {new Date(plan.date).toLocaleDateString()}</p>
              <p><FaClock /> {plan.time}</p>
              <p><FaMapMarkerAlt /> {plan.location || 'No location specified'}</p>
              <p><FaUser /> {plan.organizer?.username || 'Unknown'}</p>
              <p><FaUsers /> {plan.attendeeUsers?.map(user => user.username).join(', ') || 'No attendees'}</p>
              <p>Status: {plan.status}</p>
              {plan.description && <p>📝 {plan.description}</p>}
            </div>
            <div className='plan-card-actions'>
              <button onClick={() => handleEditPlan(plan.id)}>
                <FaEdit /> Edit
              </button>
              <button onClick={() => handleDeletePlan(plan.id)}>
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderListView = () => {
    return (
      <div className="grid gap-4">
        {renderPlanCards(filterPlans())}
      </div>
    );
  };

  const renderPlanCard = (plan) => {
    return (
      <div key={plan.id} className="plan-card">
        <div className="card-header">
          <h4 className="card-title">{plan.title}</h4>
          <div className="flex gap-2">
            <span className="badge" style={{ backgroundColor: getPlanColor(plan.type) }}>
              {plan.type}
            </span>
            <button 
              onClick={() => handleDeletePlan(plan.id)}
              className="btn btn-icon btn-danger"
            >
              <FaTrash />
            </button>
          </div>
        </div>
        
        <div className="card-content">
          <div className="info-item">
            <FaCalendarAlt />
            <span>{new Date(plan.date).toLocaleDateString()}</span>
          </div>
          <div className="info-item">
            <FaClock />
            <span>{plan.time} ({plan.duration} minutes)</span>
          </div>
          <div className="info-item">
            <FaMapMarkerAlt />
            <span>{plan.location}</span>
          </div>
          <div className="info-item">
            <FaUsers />
            <span>{plan.attendeeUsers?.length || 0} attendees</span>
          </div>
          {plan.presentationLink && (
            <div className="info-item">
              <FaLink />
              <a href={plan.presentationLink} target="_blank" rel="noopener noreferrer" className="link">
                Presentation Link
              </a>
            </div>
          )}
        </div>
        
        {plan.attendeeUsers && plan.attendeeUsers.length > 0 && (
          <div className="card-footer">
            <div className="attendee-list">
              {plan.attendeeUsers.map(user => (
                <span key={user.id} className="attendee-badge">
                  {user.username}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const getPlanColor = (type) => {
    const colors = {
      review: '#4285f4',
      meeting: '#0f9d58',
      deadline: '#db4437',
      default: '#f4b400'
    };
    return colors[type] || colors.default;
  };

  return (
    <Box className={`content-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <Text fontSize="2xl" fontWeight="bold">
          {view === 'calendar' ? 'Calendar View' : 'List View'}
        </Text>
        <Text fontSize="md" color="gray.500">
          {activeProject?.projectName || ''}
        </Text>
        <HStack spacing={4} mt={4}>
          <Button
            size="sm"
            colorScheme={view === 'calendar' ? 'blue' : 'gray'}
            onClick={() => setView('calendar')}
            leftIcon={<FaCalendarAlt />}
          >
            Calendar
          </Button>
          <Button
            size="sm"
            colorScheme={view === 'list' ? 'blue' : 'gray'}
            onClick={() => setView('list')}
            leftIcon={<FaList />}
          >
            List
          </Button>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => {
              if (!activeProject) {
                toast({
                  title: 'Error',
                  description: 'Please select a project first',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
                return;
              }
              setEditingPlan(null);
              setIsFormVisible(true);
            }}
            leftIcon={<FaPlus />}
          >
            Add Plan
          </Button>
        </HStack>
      </div>

      <Box className="main-content" p={4}>
        {view === 'calendar' ? renderCalendarView() : renderListView()}
      </Box>

      {isFormVisible && (
        <PlanForm
          isOpen={isFormVisible}
          onClose={() => {
            setIsFormVisible(false);
            setEditingPlan(null);
          }}
          onSubmit={editingPlan ? 
            (data) => handleEditPlan(editingPlan.id, data) : 
            handleAddPlan}
          editingPlan={editingPlan}
          users={users}
          isDarkMode={isDarkMode}
          activeProject={activeProject}
        />
      )}
    </Box>
  );
};

export default Plans;