import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API_BASE_URL } from '../config/config';
import './Login.css';

function Login({ setIsLoggedIn, setUserRole, setCurrentUser }) {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const verifyToken = async (token) => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/auth/verify-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Token tidak valid');
        }

        const data = await response.json();
        if (data.valid && isMounted) {
          setIsLoggedIn(true);
          setUserRole(data.role);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        if (isMounted) {
          localStorage.removeItem('token');
          localStorage.removeItem('userRole');
        }
      }
    };

    const token = localStorage.getItem('token');
    if (token) {
      verifyToken(token);
    }

    return () => {
      isMounted = false;
    };
  }, [setIsLoggedIn, setUserRole, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      console.log('🔑 Mencoba login dengan:', formData);
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Gagal melakukan login');
      }

      const data = await response.json();
      console.log('✅ Login berhasil:', data);
      
      const { token, user } = data;

      localStorage.setItem('token', token);
      localStorage.setItem('userRole', JSON.stringify({
        id: user.role.id,
        name: user.role.name,
        permissions: user.role.permissions
      }));
      localStorage.setItem('username', user.username);
      localStorage.setItem('userId', user.id);

      setIsLoggedIn(true);
      setUserRole({
        id: user.role.id,
        name: user.role.name,
        permissions: user.role.permissions
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(error.response.data.message || 'Terjadi kesalahan saat login');
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('Tidak dapat terhubung ke server');
      } else {
        console.error('Error setup:', error.message);
        setError('Terjadi kesalahan saat mengirim permintaan');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="/assets/images/smood-logo.png" alt="Smood Logo" className="login-logo" />
        <h2>Login</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Masukkan username Anda"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Masukkan password Anda"
              required
            />
          </div>
          <button type="submit" disabled={isLoading} className="login-button">
            {isLoading ? 'Loading...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
