import React, { useState, useEffect, useRef, useMemo } from 'react';
import './ActivityPanel.css';
import { getFilePath } from '../utils/fileHandler';
import VideoPlayer from './VideoPlayer';
import styled from '@emotion/styled';
import { API_BASE_URL } from '../config/config';
import UploadModal from './UploadModal';
import axiosInstance from '../config/axios';
import VideoModal from './VideoModal';
import { Box, Button, HStack, IconButton, useDisclosure, Textarea } from '@chakra-ui/react';
import { AttachmentIcon, WarningIcon, DeleteIcon, StarIcon, EditIcon } from '@chakra-ui/icons';
import IssueForm from './IssueForm';
import useHashtags from '../hooks/useHashtags';

const ActivityItem = styled('div')`
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;

  &.pinned {
    background: rgba(255, 193, 7, 0.15);  // Background kuning untuk yang di-pin
  }

  &.comment {
    background: rgba(144, 238, 144, 0.15);  // Background hijau muda untuk comment
  }

  &.pinned.comment {
    background: linear-gradient(
      135deg, 
      rgba(255, 193, 7, 0.15) 0%, 
      rgba(144, 238, 144, 0.15) 100%
    );  // Gradient untuk kombinasi pin dan comment
  }

  &.video-activity {
    background: rgba(25, 118, 210, 0.1);  // Background biru untuk activity video
  }

  &.pinned.video-activity {
    background: linear-gradient(
      135deg, 
      rgba(255, 193, 7, 0.15) 0%, 
      rgba(25, 118, 210, 0.1) 100%
    );  // Gradient untuk kombinasi pin dan video
  }

  &.has-annotations {
    border: 2px solid #2196f3;
  }

  .preview-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    margin: 10px 0;
    overflow: hidden;
    border-radius: 4px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .annotation-info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    color: #666;
    font-size: 14px;
  }

  .activity-content {
    margin-top: 10px;
  }

  .hashtags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }

  .hashtag {
    background: rgba(25, 118, 210, 0.1);
    color: #1976d2;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: rgba(25, 118, 210, 0.2);
    }
  }
`;

const ActivityItemComponent = ({ item, currentUser, onDelete, onEdit, onPin, isDarkMode, renderStatus }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [hasSavedAnnotations, setHasSavedAnnotations] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState('');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        let endpoint = '/api/sequence-statuses';
        
        // Sesuaikan endpoint berdasarkan tipe
        if (item.type === 'asset') {
          endpoint = '/api/asset-statuses';
        } else if (item.type === 'shot') {
          endpoint = '/api/shot-statuses';
        } else if (item.type === 'task') {
          endpoint = '/api/task-statuses'; 
        }

        const response = await axiosInstance.get(endpoint);
        console.log('[ActivityItemComponent] Statuses fetched:', response.data);
        setStatuses(response.data);
      } catch (error) {
        console.error('[ActivityItemComponent] Error fetching statuses:', error);
      }
    };
    fetchStatuses();

    const fetchUserProfile = async () => {
      try {
        if (!item.user) {
          console.log('No user ID provided');
          setUserProfile({
            username: 'Unknown User',
            avatar: getDefaultAvatar('Unknown User')
          });
          return;
        }

        const response = await axiosInstance.get(`/api/users/profile/${item.user}`);
        if (response.data) {
          setUserProfile({
            ...response.data,
            profileImage: response.data.profileImage ? 
              `${API_BASE_URL}${response.data.profileImage}` : 
              getDefaultAvatar(response.data.username)
          });
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setUserProfile({
          username: 'Unknown User',
          avatar: getDefaultAvatar('Unknown User')
        });
      }
    };

    if (item.user) {
      fetchUserProfile();
    }

    // Check for saved annotations if this is a video activity
    const checkSavedAnnotations = async () => {
      if (item.action?.includes('video') && item.data?.videoPath) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/activities?type=video_annotation`);
          if (response.ok) {
            const annotations = await response.json();
            const videoAnnotations = annotations.filter(annotation => 
              annotation.data?.videoPath === item.data.videoPath
            );
            setHasSavedAnnotations(videoAnnotations.length > 0);
          }
        } catch (error) {
          console.error('Error checking saved annotations:', error);
        }
      }
    };

    checkSavedAnnotations();
  }, [item]);

  const getStatusDetails = (statusId) => {
    const status = statuses.find(s => s.id === parseInt(statusId));
    return status ? {
      name: status.name,
      abbreviation: status.abbreviation,
      color: status.color
    } : {
      name: 'Unknown',
      abbreviation: '?',
      color: '#808080'
    };
  };

  const renderHashtags = (text) => {
    const hashtagRegex = /#[\w-]+/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = hashtagRegex.exec(text)) !== null) {
      // Add text before hashtag
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }
      // Add hashtag as badge
      parts.push(
        <span key={match.index} className="hashtag-badge">
          {match[0]}
        </span>
      );
      lastIndex = match.index + match[0].length;
    }
    // Add remaining text
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
    return parts;
  };

  const getUserInitials = (username) => {
    return username
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const getDefaultAvatar = (username) => {
    return `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random`;
  };

  const renderUserAvatar = () => {
    if (!userProfile) {
      return null;
    }

    return (
      <div className="user-avatar">
        {userProfile.profileImage ? (
          <img 
            src={userProfile.profileImage} 
            alt={`${item.user}'s profile`}
            className="user-avatar-image"
          />
        ) : (
          <div className="user-avatar-placeholder">
            {getUserInitials(userProfile.username)}
          </div>
        )}
      </div>
    );
  };

  const renderThumbnail = () => {
    // Untuk video (publish atau review)
    if (item.data?.videoPath && item.data?.thumbnailUrl) {
      return (
        <div className="activity-thumbnail" onClick={() => setShowVideo(true)}>
          <img 
            src={item.data.thumbnailUrl} 
            alt="Video thumbnail"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/assets/images/video-placeholder.png';
            }}
          />
        </div>
      );
    }// Untuk gambar pada review
    if (item.type === 'review' && item.data?.filePath && !item.data?.videoPath) {
      const fileExtension = item.data.filePath.split('.').pop().toLowerCase();
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
      
      if (imageExtensions.includes(fileExtension)) {
        return (
          <div className="activity-thumbnail">
            <img 
              src={item.data.filePath}
              alt="Review image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/assets/images/image-placeholder.png';
              }}
            />
          </div>
        );
      }
      // Untuk file non-gambar dan non-video
      return (
        <div className="activity-attachment">
          <a 
            href={item.data.filePath}
            download={item.data.fileName}
            className="download-link"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '8px',
              background: isDarkMode ? '#2D3748' : '#EDF2F7',
              borderRadius: '4px',
              textDecoration: 'none',
              color: isDarkMode ? '#E2E8F0' : '#2D3748'
            }}
          >
            <AttachmentIcon />
            Download {item.data.fileName}
          </a>
        </div>
      );
    }
   
    
    // For edit activities showing status changes
    if (item.action?.includes('edit_') && item.data?.after?.statusId) {
      const status = getStatusDetails(item.data.after.statusId);
      return (
        <div 
          className="activity-thumbnail"
          style={{ 
            background: `linear-gradient(135deg, ${status.color}40, ${status.color}80)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px'
          }}
        >
          <span style={{ color: status.color }}>{status.abbreviation}</span>
        </div>
      );
    }
    
    return null;
  };

  const renderStatusBadge = () => {
    let statusId = null;
    
    // For edit activities, show the new status
    if (item.action?.includes('edit_') && item.data?.after?.statusId) {
      statusId = item.data.after.statusId;
    }
    // For other activities that might have status info
    else if (item.statusId) {
      statusId = item.statusId;
    }
    
    return (
      <>
        {statusId && (
          <span 
            className="status-badge"
            style={{ 
              backgroundColor: `${getStatusDetails(statusId).color}20`,
              color: getStatusDetails(statusId).color,
              border: `1px solid ${getStatusDetails(statusId).color}40`
            }}
          >
            <span className="abbreviation" style={{ backgroundColor: `${getStatusDetails(statusId).color}40` }}>
              {getStatusDetails(statusId).abbreviation}
            </span>
            {getStatusDetails(statusId).name}
          </span>
        )}
        {renderStatus && (
          <span 
            className="render-status-badge"
            style={{ 
              backgroundColor: 'rgba(33, 150, 243, 0.1)',
              color: '#2196f3',
              border: '1px solid rgba(33, 150, 243, 0.2)',
              marginLeft: '8px',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '0.9em',
              display: 'inline-flex',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: '8px' }}>
              Render: {renderStatus.status}
            </span>
            {renderStatus.progress >= 0 && (
              <span style={{ 
                backgroundColor: 'rgba(33, 150, 243, 0.2)',
                padding: '2px 6px',
                borderRadius: '3px'
              }}>
                {renderStatus.progress}%
              </span>
            )}
          </span>
        )}
      </>
    );
  };

  const handleDelete = async () => {
    onDelete && onDelete(item.id);
  };

  const renderActivityContent = () => {
    if (item.action === 'ISSUE_CREATED' || item.action === 'ISSUE_UPDATED') {
      const issueDetails = item.data?.issueDetails;
      const getStatusBackground = (status) => {
        switch (status) {
          case 'OPEN':
            return 'rgba(254, 178, 178, 0.15)'; // Lighter version of red
          case 'IN_PROGRESS':
            return 'rgba(250, 240, 137, 0.15)'; // Lighter version of yellow
          case 'RESOLVED':
            return 'rgba(154, 230, 180, 0.15)'; // Lighter version of green
          case 'CLOSED':
            return 'rgba(203, 213, 224, 0.15)'; // Lighter version of gray
          case 'REOPENED':
            return 'rgba(254, 178, 178, 0.15)'; // Lighter version of red
          default:
            return 'transparent';
        }
      };

      return (
        <div 
          className="activity-content"
          style={{ 
            background: getStatusBackground(issueDetails?.status),
            padding: '12px',
            borderRadius: '8px',
            transition: 'background-color 0.2s'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
            <WarningIcon color="red.500" />
            <span style={{ fontWeight: 'bold' }}>{item.details}</span>
          </div>
          {issueDetails && (
            <>
              <div style={{ 
                marginBottom: '12px',
                color: isDarkMode ? '#A0AEC0' : '#4A5568',
                fontSize: '0.95em',
                lineHeight: '1.5'
              }}>
                {issueDetails.description}
              </div>
              <div style={{ 
                display: 'flex', 
                gap: '8px', 
                fontSize: '0.9em',
                color: isDarkMode ? '#718096' : '#4A5568',
                flexWrap: 'wrap'
              }}>
                <span style={{ 
                  padding: '2px 8px', 
                  borderRadius: '4px', 
                  backgroundColor: issueDetails.status === 'OPEN' ? '#FEB2B2' : '#9AE6B4',
                  color: '#2D3748'
                }}>
                  {issueDetails.status}
                </span>
                <span style={{ 
                  padding: '2px 8px', 
                  borderRadius: '4px', 
                  backgroundColor: '#BEE3F8',
                  color: '#2D3748'
                }}>
                  {issueDetails.priority}
                </span>
                <span style={{ 
                  padding: '2px 8px', 
                  borderRadius: '4px', 
                  backgroundColor: '#E9D8FD',
                  color: '#2D3748'
                }}>
                  {issueDetails.category}
                </span>
                {issueDetails.assignedTo && (
                  <span style={{ 
                    padding: '2px 8px', 
                    borderRadius: '4px', 
                    backgroundColor: '#CBD5E0',
                    color: '#2D3748',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <span style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: '#4A5568',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.8em'
                    }}>
                      {getUserInitials(issueDetails.assignedTo)}
                    </span>
                    {issueDetails.assignedTo}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      );
    }
    
    if (isEditing && item.type === 'comment') {
      return (
        <div className="activity-content">
          <Textarea
            value={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
            rows={3}
            mb={2}
            resize="vertical"
          />
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button onClick={handleSaveEdit} colorScheme="blue" size="sm">
              Save
            </Button>
            <Button onClick={() => setIsEditing(false)} colorScheme="gray" size="sm">
              Cancel
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="activity-content">
        {renderHashtags(item.details)}
      </div>
    );
  };

  const handleEditClick = () => {
    setEditedComment(item.details);
    setIsEditing(true);
  };

  const handlePin = async () => {
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/activities/${item.id}/pin`, {
        isPinned: !item.isPinned
      });
      
      // Update item locally
      item.isPinned = !item.isPinned;
      onPin && onPin(item.id);
    } catch (error) {
      console.error('Error pinning activity:', error);
      alert('Failed to pin activity');
    }
  };

  const handleSaveEdit = async () => {
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/activities/${item.id}`, {
        details: editedComment
      });
      
      // Update item locally
      item.details = editedComment;
      onEdit && onEdit(item.id);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating comment:', error);
      alert('Failed to update comment');
    }
  };

  return (
    <div className={`activity-item ${item.isPinned ? 'pinned' : ''} ${item.action?.includes('video') ? 'video-activity' : ''} ${item.type === 'comment' ? 'comment' : ''} ${hasSavedAnnotations ? 'has-annotations' : ''}`}>
      {renderThumbnail()}
      <div className="activity-content">
        <div className="activity-header">
          <div className="header-left">
            {item.isPinned && <span className="pin-indicator">📌</span>}
            {item.action?.includes('video') && hasSavedAnnotations && (
              <span className="annotation-indicator" title="Has saved annotations">
                ✏️
              </span>
            )}
            <div className="user-info">
              {renderUserAvatar()}
              <div className="user-details">
                <div className="user-name-and-status">
                  <span className="user-name">{item.user}</span>
                  {renderStatusBadge()}
                </div>
                <div className="timestamp">
                  {item.timestamp && new Date(item.timestamp).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className="header-right">
            {item.type === 'comment' && (
              <IconButton
                icon={<EditIcon />}
                onClick={handleEditClick}
                colorScheme="blue"
                variant="ghost"
                size="sm"
                aria-label="Edit activity"
                title="Edit activity"
                mr={1}
              />
            )}
            <IconButton
              icon={<DeleteIcon />}
              onClick={handleDelete}
              colorScheme="red"
              variant="ghost"
              size="sm"
              aria-label="Delete activity"
              title="Delete activity"
              mr={1}
            />
            <IconButton
              icon={<StarIcon />}
              onClick={handlePin}
              colorScheme={item.isPinned ? 'yellow' : 'gray'}
              variant="ghost"
              size="sm"
              aria-label={item.isPinned ? 'Unpin activity' : 'Pin activity'}
              title={item.isPinned ? 'Unpin activity' : 'Pin activity'}
            />
          </div>
        </div>
        {renderActivityContent()}
      </div>
      {showVideo && item.data?.videoPath && (
        <VideoModal
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
          videoPath={item.data.videoPath}
        />
      )}
    </div>
  );
};

const ActivityPanel = ({ 
  item = {},
  type = '',
  isOpen = false,
  onClose,
  onAddComment,
  onEditComment,
  onDeleteComment,
  onPinComment,
  currentUser,
  isDarkMode,
  socket
}) => {
  const [activities, setActivities] = useState([]);
  const [comment, setComment] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
  const [hashtagQuery, setHashtagQuery] = useState('');
  const commentInputRef = useRef(null);
  const { hashtags, addHashtag } = useHashtags();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [renderStatus, setRenderStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [currentChunk, setCurrentChunk] = useState(0);
  const [uploadData, setUploadData] = useState(null);
  const { 
    isOpen: isIssueOpen, 
    onOpen: onIssueOpen, 
    onClose: onIssueClose 
  } = useDisclosure();
  const panelRef = useRef(null);

  console.log('ActivityPanel - type:', type);
  console.log('ActivityPanel - item:', item);

  // Fetch render status periodically
  useEffect(() => {
    let intervalId;
    
    const getStatusText = (stat, job) => {
      // Tambahkan parameter job untuk debug
      console.log('Raw job status details:', {
        Stat: stat,
        QueuedChunks: job.QueuedChunks,
        RenderingChunks: job.RenderingChunks,
        CompletedChunks: job.CompletedChunks,
        FailedChunks: job.FailedChunks
      });

      switch (stat) {
        case 0: return 'Unknown';
        case 1: 
          // Cek detail status tambahan
          if (job.QueuedChunks > 0 && job.RenderingChunks === 0) {
            return 'Pending';
          }
          if (job.RenderingChunks > 0) {
            return 'Active';
          }
          if (job.CompletedChunks > 0) {
            return 'Completed';
          }
          return 'Active';
        case 2: return 'Suspended';
        case 3: return 'Completed';
        case 4: return 'Failed';
        case 5: return 'Pending';
        default: return 'Unknown';
      }
    };

    const parseProgress = (job) => {
      // Hitung progress berdasarkan chunk
      const totalChunks = 
        job.QueuedChunks + 
        job.RenderingChunks + 
        job.CompletedChunks + 
        job.FailedChunks;
      
      const completedChunks = job.CompletedChunks;
      
      console.log('Progress calculation:', {
        totalChunks,
        completedChunks,
        QueuedChunks: job.QueuedChunks,
        RenderingChunks: job.RenderingChunks,
        CompletedChunks: job.CompletedChunks,
        FailedChunks: job.FailedChunks,
        singleTaskProgress: job.SnglTskPrg
      });
      
      if (totalChunks > 0) {
        const calculatedProgress = Math.round((completedChunks / totalChunks) * 100);
        console.log('Calculated progress:', calculatedProgress);
        return calculatedProgress;
      }
      
      // Fallback ke parsing progress string
      const progressStr = job.SnglTskPrg || '0%';
      const parsedProgress = parseFloat(progressStr.replace('%', '').trim());
      
      console.log('Parsed progress:', parsedProgress);
      return parsedProgress;
    };
    
    const fetchRenderStatus = async () => {
      try {
        const taskName = item?.taskName;
        console.log('Task name for render check:', taskName);
        
        if (taskName) {
          console.log('Checking render status for task:', taskName);
          
          const token = localStorage.getItem('token');
          if (!token) {
            console.error('No auth token found');
            return;
          }
          
          const response = await axiosInstance.get(`${API_BASE_URL}/api/renders`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const renderJobs = response.data;
          console.log('All render jobs:', renderJobs);
          
          // Find matching render job by name
          const matchingJob = renderJobs.find(job => {
            const jobName = (job.Props?.Name || job.name || '').toLowerCase().trim();
            const currentTaskName = taskName.toLowerCase().trim();
            
            console.log('Comparing names:', {
              jobName,
              taskName: currentTaskName,
              stat: job.Stat,
              progress: job.SnglTskPrg
            });
            
            return jobName === currentTaskName;
          });
          
          console.log('Matching job found:', matchingJob);
          
          if (matchingJob) {
            const status = getStatusText(matchingJob.Stat, matchingJob);
            const progress = parseProgress(matchingJob);
            
            console.log('Setting render status:', { 
              status, 
              progress,
              jobDetails: {
                Stat: matchingJob.Stat,
                QueuedChunks: matchingJob.QueuedChunks,
                RenderingChunks: matchingJob.RenderingChunks,
                CompletedChunks: matchingJob.CompletedChunks,
                FailedChunks: matchingJob.FailedChunks
              }
            });
            
            setRenderStatus({
              status: status,
              progress: progress
            });
          } else {
            console.log('No matching job found, setting render status to null');
            setRenderStatus(null);
          }
        } else {
          console.log('No task name found');
        }
      } catch (error) {
        console.error('Error fetching render status:', error);
        if (error.response?.status === 401) {
          console.error('Authentication error - please check your token');
        }
      }
    };

    // Always try to fetch render status if we have an item
    if (item) {
      console.log('Starting render status fetch for item:', item);
      // Initial fetch
      fetchRenderStatus();
      
      // Set up interval for periodic updates
      intervalId = setInterval(fetchRenderStatus, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [item]); // Only depend on item changes

  useEffect(() => {
    // Socket listener untuk update activity
    if (socket) {
      socket.on('activityUpdate', (updatedActivity) => {
        setActivities(prevActivities => 
          prevActivities.map(activity => 
            activity.id === updatedActivity.id
              ? { ...activity, ...updatedActivity }
              : activity
          )
        );
      });

      return () => {
        socket.off('activityUpdate');
      };
    }
  }, [socket]);

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (item?.id && type) {
        try {
          setLoading(true);
          // Fetch status details
          const endpoint = type === 'sequence' ? 'sequence-statuses' : 
                          type === 'shot' ? 'shot-statuses' : 
                          type === 'asset' ? 'asset-statuses' :
                          type === 'task' ? 'task-statuses' : '';
          
          const statusResponse = await axiosInstance.get(`${API_BASE_URL}/api/${endpoint}`);
          const statusList = statusResponse.data;
          const currentStatus = statusList.find(s => s.id === item.statusId);
          setStatus(currentStatus);

          await fetchActivities();
        } catch (error) {
          console.error('[ActivityPanel] Error fetching details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchItemDetails();
  }, [item?.id, type]);

  const handleIssueCreated = async (issue) => {
    // Fetch latest activities after issue creation
    await fetchActivities();
  };

  const getHashtagSuggestions = useMemo(() => {
    if (!hashtagQuery) return [];
    return hashtags
      .filter(tag => tag.name.toLowerCase().includes(hashtagQuery.toLowerCase()))
      .slice(0, 5);
  }, [hashtags, hashtagQuery]);

  const handleCommentChange = (e) => {
    const value = e.target.value;
    setComment(value);
    
    // Get cursor position
    const cursorPos = e.target.selectionStart;
    setCursorPosition(cursorPos);

    // Check if we're typing a hashtag
    const textBeforeCursor = value.slice(0, cursorPos);
    const hashtagMatch = textBeforeCursor.match(/#[\w-]*$/);

    if (hashtagMatch) {
      setHashtagQuery(hashtagMatch[0].slice(1)); // Remove # from query
      setShowHashtagSuggestions(true);
    } else {
      setShowHashtagSuggestions(false);
    }
  };

  const insertHashtag = async (hashtag) => {
    const beforeHashtag = comment.slice(0, cursorPosition).replace(/#[\w-]*$/, '');
    const afterHashtag = comment.slice(cursorPosition);
    const newComment = beforeHashtag + hashtag.name + ' ' + afterHashtag;
    
    setComment(newComment);
    setShowHashtagSuggestions(false);

    // If this is a new hashtag, add it to the HashtagManager
    if (!hashtags.find(h => h.name === hashtag.name)) {
      try {
        await addHashtag(hashtag.name);
      } catch (error) {
        console.error('Error adding new hashtag:', error);
      }
    }

    // Focus back on input and move cursor after inserted hashtag
    if (commentInputRef.current) {
      commentInputRef.current.focus();
      const newCursorPos = beforeHashtag.length + hashtag.name.length + 1;
      commentInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
    }
  };

  const extractHashtags = (text) => {
    const hashtagRegex = /#[\w-]+/g;
    return text.match(hashtagRegex) || [];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    try {
      // Extract hashtags from comment
      const newHashtags = extractHashtags(comment);
      
      // Add new hashtags to HashtagManager
      for (const hashtag of newHashtags) {
        if (!hashtags.find(h => h.name === hashtag)) {
          try {
            await addHashtag(hashtag);
          } catch (error) {
            console.error('Error adding new hashtag:', error);
          }
        }
      }
      const response = await axiosInstance.post(`${API_BASE_URL}/api/activities`, {
        [`${type.toLowerCase()}Id`]: item.id,
        action: 'COMMENT',
        details: comment,
        
        user: localStorage.getItem('username') || 'Anonymous',
        type: 'comment'
      });

      // Add new activity to state
      setActivities(prev => [...prev, response.data]);
      
      // Clear comment
      setComment('');
      
      // Notify parent
      onAddComment && onAddComment(response.data);
    } catch (error) {
      console.error('[ActivityPanel] Error adding comment:', error);
      alert('Failed to add comment');
    }
  };

  const getItemName = () => {
    if (!item) return '';
    switch(type) {
      case 'sequence':
        return item.sequenceName || '';
      case 'shot':
        return item.shotName || '';
      case 'task':
        return item.taskName || '';
        case 'asset':
          return item.assetName || '';
      default:
        return '';
    }
  };

  const getItemThumbnail = () => {
    let thumbnailUrl;

    // Check different possible locations for thumbnail
    if (item?.thumbnailUrl) {
      thumbnailUrl = item.thumbnailUrl;
    } else if (item?.data?.thumbnailUrl) {
      thumbnailUrl = item.data.thumbnailUrl;
    }

    // If no thumbnail found, use default
    if (!thumbnailUrl) {
      const defaultThumbnails = {
        sequence: '/assets/images/sequence-placeholder.png',
        shot: '/assets/images/shot-placeholder.png',
        asset: '/assets/images/asset-placeholder.png',
        task: '/assets/images/task-placeholder.png'
      };
      return defaultThumbnails[type] || '/assets/images/default-placeholder.png';
    }

    // Ensure full URL is used
    return thumbnailUrl.startsWith('http')
      ? thumbnailUrl
      : `${API_BASE_URL}${thumbnailUrl}`;
  };

  const handleDeleteActivity = async (activityId) => {
    try {
      if (!activityId) {
        console.error('Activity ID is undefined');
        return;
      }

      // Get activity data first
      const activity = activities.find(a => a.id === activityId);
      if (!activity) {
        console.error('Activity not found');
        return;
      }

      // If it's a video activity, delete the video file first
      if (activity.data?.videoPath) {
        const filename = activity.data.videoPath.split('/').pop();
        try {
          await axiosInstance.delete(`${API_BASE_URL}/api/uploads/${filename}`);
        } catch (videoError) {
          console.error('Error deleting video file:', videoError);
          // Continue with activity deletion even if video deletion fails
        }
      }

      // Delete activity from server
      await axiosInstance.delete(`${API_BASE_URL}/api/activities/${activityId}`);
      
      // Update local state immediately
      setActivities(prevActivities => 
        prevActivities.filter(activity => activity.id !== activityId)
      );

      // Notify parent component
      onDeleteComment && onDeleteComment(activityId);
    } catch (error) {
      console.error('[ActivityPanel] Error deleting activity:', error);
      // Only show alert if the activity wasn't deleted
      if (activities.some(a => a.id === activityId)) {
        alert('Failed to delete activity');
      }
    }
  };

  const handleUpload = async (uploadData) => {
    setIsUploading(true);
    setUploadProgress(0);
    setUploadData(uploadData);
    
    try {
      // Untuk file kecil (<10MB), upload langsung
      if (uploadData.file.size < 10 * 1024 * 1024) {
        const formData = new FormData();
        formData.append('file', uploadData.file);
        formData.append('type', uploadData.type || 'attachment');

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
          timeout: 900000
        };

        return await uploadFile(formData, config, uploadData);
      }
      
      // Untuk file besar, gunakan chunked upload
      return await chunkedUpload(uploadData);
    } catch (error) {
      console.error('Upload error:', error);
      setIsUploading(false);
      setUploadProgress(0);
      throw error;
    } finally {
      if (!isPaused) {
        setIsUploading(false);
      }
    }
  };

  const uploadFile = async (formData, config, data) => {
    const response = await axiosInstance.post(`${API_BASE_URL}/api/uploads`, formData, config);
    return await handleUploadResponse(response.data, data);
  };

  const chunkedUpload = async (data) => {
    setUploadData(data);
    const CHUNK_SIZE = 5 * 1024 * 1024;
    const file = data.file;
    const chunks = Math.ceil(file.size / CHUNK_SIZE);
    let startChunk = uploadQueue.length > 0 ? uploadQueue[uploadQueue.length - 1].index + 1 : 0;
    let sanitizedFileName = null;
    const maxRetries = 3;
    
    for (let i = startChunk; i < chunks; i++) {
      if (isPaused) {
        setUploadQueue(prev => [...prev, { index: i, file: data.file.name }]);
        return;
      }
      const start = i * CHUNK_SIZE;
      const end = Math.min(file.size, start + CHUNK_SIZE);
      const chunk = file.slice(start, end);
      const formData = new FormData();
      formData.append('file', chunk, file.name);
      formData.append('type', data.type || 'attachment');
      formData.append('chunkIndex', i);
      formData.append('totalChunks', chunks);
      formData.append('originalSize', file.size);
      
      let attempts = 0;
      let success = false;
      while (attempts < maxRetries && !success) {
        try {
          const response = await axiosInstance.post(`${API_BASE_URL}/api/uploads/chunk`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              const overallProgress = Math.round((i * CHUNK_SIZE + progressEvent.loaded) * 100 / file.size);
              setUploadProgress(overallProgress);
            },
            timeout: 300000
          });
          if (i === startChunk) {
            sanitizedFileName = response.data.sanitizedFileName;
          }
          success = true;
          setCurrentChunk(i + 1);
        } catch (error) {
          attempts++;
          if (error.code === 'ECONNABORTED' || attempts >= maxRetries) {
            setUploadQueue(prev => [...prev, { index: i, file: data.file.name }]);
            throw new Error(`Failed to upload chunk ${i} after ${attempts} attempts: ${error.message}`);
          }
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
        }
      }
    }
    
    await finalizeUpload(data, chunks, file, sanitizedFileName);
  };
  
  const finalizeUpload = async (data, chunks, file, sanitizedFileName) => {
    const response = await axiosInstance.post(`${API_BASE_URL}/api/uploads/finalize`, {
      fileName: sanitizedFileName || file.name, // Use sanitized name if available
      type: data.type,
      totalChunks: chunks,
      originalSize: file.size
    });
    return await handleUploadResponse(response.data, data);
  };


  const handleReview = async (file) => await handleUpload({ file, type: 'review' });
  const handlePublish = async (data) => await handleUpload({ file: data.file, type: 'publish', version: data.version, publishText: data.publishText });
  const handleAttachment = async (file) => await handleUpload({ file, type: 'attachment' });

  const handleUploadResponse = async (uploadResponse, uploadData) => {
    const activityData = {
      user: localStorage.getItem('username') || 'Anonymous',
      type: uploadData.type,
      action: uploadData.type === 'publish' ? 'publish_video' : 
              uploadData.type === 'review' ? 'upload_review' : 'upload_attachment',
      details: uploadData.type === 'publish' ? `Published version ${uploadData.version}` : 
               uploadData.type === 'review' ? 'Uploaded for review' : 'Added attachment',
      data: { 
        type: uploadData.type, 
        filePath: uploadResponse.filePath, 
        fileName: uploadData.file.name 
      },
      timestamp: new Date()
    };

    if ((uploadData.type === 'publish' || uploadData.type === 'review') && uploadResponse.videoPath) {
      activityData.data.videoPath = uploadResponse.videoPath;
      activityData.data.thumbnailUrl = uploadResponse.thumbnailUrl;
      if (uploadData.type === 'publish') {
        activityData.data.version = uploadData.version;
        activityData.data.description = uploadData.publishText;
      }
    }

    if (type === 'sequence') activityData.sequenceId = item.id;
    else if (type === 'shot') activityData.shotId = item.id;
    else if (type === 'asset') activityData.assetId = item.id;
    else if (type === 'task') activityData.taskId = item.id;

    const response = await axiosInstance.post(`${API_BASE_URL}/api/activities`, activityData);
    await fetchActivities();
    return response.data;
  };

  const fetchActivities = async () => {
    if (item?.id && type) {
      try {
        setLoading(true);
        const activitiesEndpoint = `${type.toLowerCase()}s`;
        const activitiesResponse = await axiosInstance.get(`${API_BASE_URL}/api/activities/${activitiesEndpoint}/${item.id}`);
        if (activitiesResponse.data) {
          setActivities(activitiesResponse.data);
        }
      } catch (error) {
        console.error('[ActivityPanel] Error fetching activities:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const sortedActivities = useMemo(() => {
    return activities?.slice().sort((a, b) => {
      if (a.isPinned === b.isPinned) return 0;
      return a.isPinned ? -1 : 1;
    });
  }, [activities]);

  return (
    <div className={`activity-panel ${isOpen ? 'open' : ''} ${isDarkMode ? 'dark' : ''}`} ref={panelRef}>
      <button 
        className={`toggle-button ${isDarkMode ? 'dark' : ''}`}
        onClick={onClose}
        aria-label="Close Activity Panel"
      >
        <span className="vertical-text">CLOSE</span>
      </button>

      <div className="panel-header">
        <div className="item-details">
          <div className="item-thumbnail">
            <img 
              src={getItemThumbnail()}
              alt={`${type} thumbnail`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/assets/images/default-placeholder.png';
              }}
            />
          </div>
          <div className="item-info">
            <div className="item-title">
              <span>{getItemName()}</span>
            
            </div>
            <div className="item-title">
              <span className={`item-type-badge type-${type}`}>
              {type}
            </span>
            </div>
            <div className="item-meta">
              {status && (
                <div 
                  className="item-status"
                  style={{
                    backgroundColor: `${status.color}20`,
                    color: status.color,
                    border: `1px solid ${status.color}40`
                  }}
                >
                  <span 
                    className="status-dot"
                    style={{ backgroundColor: status.color }}
                  />
                  {status.name} [{status.abbreviation}]
                </div>
              )}
              {renderStatus && (
                <span 
                  className="render-status-badge"
                  style={{ 
                    backgroundColor: 'rgba(33, 150, 243, 0.1)',
                    color: '#2196f3',
                    border: '1px solid rgba(33, 150, 243, 0.2)',
                    marginLeft: '8px',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '0.9em',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ marginRight: '8px' }}>
                    Render: {renderStatus.status}
                  </span>
                  {renderStatus.progress >= 0 && (
                    <span style={{ 
                      backgroundColor: 'rgba(33, 150, 243, 0.2)',
                      padding: '2px 6px',
                      borderRadius: '3px'
                    }}>
                      {renderStatus.progress}%
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        
      </div>
      <div className="panel-content">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {[...sortedActivities].map(activity => (
              <ActivityItemComponent
                key={activity.id}
                item={activity}
                currentUser={currentUser}
                onDelete={handleDeleteActivity}
                onEdit={onEditComment}
                onPin={onPinComment}
                isDarkMode={isDarkMode}
                renderStatus={renderStatus}
              />
            ))}
          </>
        )}
      </div>
      <div className="comment-input-container">
        <form onSubmit={handleSubmit} className="comment-input">
          <div className="comment-form">
            <textarea
              ref={commentInputRef}
              value={comment}
              onChange={handleCommentChange}
              placeholder="Add a comment... (use # for hashtags)"
            />
            {showHashtagSuggestions && getHashtagSuggestions.length > 0 && (
              <div className="hashtag-suggestions">
                {getHashtagSuggestions.map((tag) => (
                  <div
                    key={tag.id}
                    className="hashtag-suggestion-item"
                    onClick={() => insertHashtag(tag)}
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="action-buttons">
            <button type="submit">Post Comment</button>
            <button
              className="upload-video"
              onClick={() => setShowUploadModal(true)}
              style={{ marginRight: '10px' }}
            >
              Upload Video
            </button>
            <button
              className="report-issue"
              onClick={onIssueOpen}
              style={{ 
                backgroundColor: '#E53E3E',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <WarningIcon />
              Report Issue
            </button>
          </div>
        </form>
      </div>
      {isUploading && (
        <div className="upload-progress-container">
          <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
          <div className="progress-text">{uploadProgress}%</div>
          
          <div className="upload-controls">
            {!isPaused ? (
              <button 
                className="btn-pause"
                onClick={() => setIsPaused(true)}
              >
                Pause
              </button>
            ) : (
              <button 
                className="btn-resume"
                onClick={() => {
                  setIsPaused(false);
                  chunkedUpload(uploadData);
                }}
              >
                Resume
              </button>
            )}
            <button 
              className="btn-cancel"
              onClick={() => {
                setIsUploading(false);
                setIsPaused(false);
                setUploadQueue([]);
                setUploadProgress(0);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showUploadModal && (
        <UploadModal
          isOpen={showUploadModal}
          onClose={() => setShowUploadModal(false)}
          onPublish={handlePublish}
          onReview={handleReview}
          onAttachment={handleAttachment}
        />
      )}
      <IssueForm
        isOpen={isIssueOpen}
        onClose={onIssueClose}
        itemType={type}
        itemId={item.id}
        itemName={getItemName()}
        isDarkMode={isDarkMode}
        onIssueCreated={handleIssueCreated}
      />
    </div>
  );
};

export default ActivityPanel;
