import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import './DccFilePanel.css';

const DCC_TYPES = [
  { 
    name: 'Nuke', 
    extension: '.nk',
    icon: '🎬'
  },
  { 
    name: 'Houdini', 
    extension: '.hip',
    icon: '🔮'
  },
  { 
    name: 'Substance Designer', 
    extension: '.sbs',
    icon: '🎨'
  }
];

const DccFilePanel = ({ task, activeProject }) => {
  const [files, setFiles] = useState([]);
  const [nextVersion, setNextVersion] = useState(1);

  useEffect(() => {
    if (task?.id) {
      fetchFiles();
    }
  }, [task?.id]);

  const fetchFiles = async () => {
    try {
      const response = await axiosInstance.get(`/api/files/task/${task.id}`);
      setFiles(response.data);
      
      // Calculate next version
      const maxVersion = Math.max(...response.data.map(f => f.version || 0), 0);
      setNextVersion(maxVersion + 1);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleCreateFile = async (dccType) => {
    try {
      const versionPadded = String(nextVersion).padStart(4, '0');
      const fileName = `${activeProject.name}_${task.sequence || 'Sequence01'}_${task.shot || 'Shot01'}_${task.taskName}_v${versionPadded}${dccType.extension}`;
      
      const response = await axiosInstance.post('/api/files', {
        taskId: task.id,
        fileName,
        dccType: dccType.name,
        version: nextVersion,
        projectId: activeProject.id
      });

      setFiles([...files, response.data]);
      setNextVersion(nextVersion + 1);
    } catch (error) {
      console.error('Error creating file:', error);
      alert('Failed to create file');
    }
  };

  const handleOpenFile = async (file) => {
    try {
      await axiosInstance.post(`/api/files/${file.id}/open`);
      // Untuk sementara hanya tampilkan alert
      alert(`File akan dibuka: ${file.filePath}`);
    } catch (error) {
      console.error('Error opening file:', error);
      alert('Failed to open file');
    }
  };

  if (!task) {
    return (
      <div className="dcc-file-panel">
        <div className="dcc-empty-state">
          Select a task to manage DCC files
        </div>
      </div>
    );
  }

  return (
    <div className="dcc-file-panel">
      <div className="dcc-header">
        <h3>DCC Files</h3>
        <div className="dcc-buttons">
          {DCC_TYPES.map(dcc => (
            <button
              key={dcc.name}
              className="dcc-create-btn"
              onClick={() => handleCreateFile(dcc)}
            >
              {dcc.icon} New {dcc.name}
            </button>
          ))}
        </div>
      </div>

      <div className="dcc-files-list">
        {files.length === 0 ? (
          <div className="dcc-empty-state">
            No files yet. Create one using the buttons above.
          </div>
        ) : (
          files.map(file => (
            <div key={file.id} className="dcc-file-item">
              <div className="dcc-file-info">
                <span className="dcc-file-name">{file.fileName}</span>
                <span className="dcc-file-version">v{String(file.version).padStart(4, '0')}</span>
              </div>
              <button 
                className="dcc-open-btn"
                onClick={() => handleOpenFile(file)}
              >
                Open
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DccFilePanel;
