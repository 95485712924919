import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/globalform.css';
import { API_BASE_URL } from '../config/config';
import axiosInstance from '../config/axios';

const priorityOptions = [
  { value: 'LOW', label: 'Low' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'HIGH', label: 'High' },
];

const TaskForm = ({ onAddTask, onClose, users = [], isEditing = false, initialTask = null, currentUser, activeProject }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    // Check if date is valid
    if (isNaN(date.getTime())) return '';
    
    // Format to yyyy-MM-dd
    return date.toISOString().split('T')[0];
  };

  const [taskData, setTaskData] = useState({
    taskName: initialTask?.taskName || '',
    description: initialTask?.description || '',
    statusId: initialTask?.statusId || '',
    startDate: initialTask?.startDate ? formatDate(initialTask.startDate) : '',
    dueDate: initialTask?.dueDate ? formatDate(initialTask.dueDate) : '',
    progress: initialTask?.progress || 0,
    userId: initialTask?.userId || currentUser?.id || '',
    priority: initialTask?.priority || 'MEDIUM'
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(initialTask?.thumbnailUrl || null);
  const [usersList, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
        if (!taskData.statusId && response.data.length > 0) {
          const defaultStatus = response.data.find(s => s.isDefault) || response.data[0];
          setTaskData(prev => ({ ...prev, statusId: defaultStatus.id }));
        }
      } catch (error) {
        console.error('Error mengambil status:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error mengambil users:', error);
      }
    };

    fetchStatuses();
    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!taskData.taskName || !taskData.startDate || !taskData.dueDate || !taskData.userId || !taskData.statusId) {
        alert('Please fill in all required fields');
        return;
      }

      const formData = new FormData();
      formData.append('taskName', taskData.taskName);
      formData.append('description', taskData.description || '');
      formData.append('startDate', taskData.startDate);
      formData.append('dueDate', taskData.dueDate);
      formData.append('progress', taskData.progress.toString());
      formData.append('statusId', taskData.statusId.toString());
      formData.append('userId', taskData.userId.toString());
      formData.append('projectId', activeProject?.id || '1');
      formData.append('priority', taskData.priority);
      
      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      if (isEditing && initialTask && initialTask.id) {
        // Update existing task
        await axiosInstance.put(`${API_BASE_URL}/api/tasks/${initialTask.id}`, formData);
      } else {
        // Create new task
        await onAddTask(formData);
      }

      onClose();
    } catch (error) {
      console.error('Error submitting task:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        alert(error.response.data.error || 'Failed to submit task. Please try again.');
      } else {
        alert('Failed to submit task. Please try again.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData(prev => ({ ...prev, [name]: value }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-header">
        <h2>{isEditing ? 'Edit Task' : 'Add New Task'}</h2>
        <button type="button" className="close-button" onClick={onClose}>×</button>
      </div>

      <div className="form-body">
        <div className="form-group">
          <label>Task Name *</label>
          <input
            type="text"
            name="taskName"
            value={taskData.taskName}
            onChange={handleInputChange}
            required
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            value={taskData.description}
            onChange={handleInputChange}
            className="form-control"
            rows="3"
          />
        </div>

        <div className="form-group">
          <label>Status *</label>
          <select
            name="statusId"
            value={taskData.statusId}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            <option value="">Select Status</option>
            {statuses.map(status => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Priority *</label>
          <select
            name="priority"
            value={taskData.priority}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            {priorityOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Start Date *</label>
            <input
              type="date"
              name="startDate"
              value={taskData.startDate}
              onChange={handleInputChange}
              required
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Due Date *</label>
            <input
              type="date"
              name="dueDate"
              value={taskData.dueDate}
              onChange={handleInputChange}
              required
              className="form-control"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Progress</label>
          <input
            type="number"
            name="progress"
            value={taskData.progress}
            onChange={handleInputChange}
            min="0"
            max="100"
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Assignee *</label>
          <select
            name="userId"
            value={taskData.userId}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            <option value="">Select Assignee</option>
            {usersList.map(user => (
              <option key={user.id} value={user.id}>
                {user.username || user.email}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Thumbnail</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleThumbnailChange}
            className="form-control"
          />
          {thumbnailPreview && (
            <div className="thumbnail-preview">
              <img src={thumbnailPreview} alt="Thumbnail preview" />
            </div>
          )}
        </div>
      </div>

      <div className="form-footer">
        <button type="button" onClick={onClose} className="btn btn-secondary">
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          {isEditing ? 'Update Task' : 'Create Task'}
        </button>
      </div>
    </form>
  );
};

export default TaskForm;