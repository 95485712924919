import React, { useState } from 'react';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';

const ProjectForm = ({ onAddProject, onClose }) => {
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    responsiblePerson: '',
  });
  const [thumbnail, setThumbnail] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedData = {
        ...projectData,
        startDate: new Date(projectData.startDate).toISOString(),
        endDate: new Date(projectData.endDate).toISOString()
      };

      const formData = new FormData();
      Object.keys(formattedData).forEach(key => {
        formData.append(key, formattedData[key]);
      });
      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      const response = await axiosInstance.post('/api/projects', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      onAddProject(response.data);
      onClose();
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleThumbnailChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setThumbnail(e.target.files[0]);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-form">
      <form onSubmit={handleSubmit} className="form-container">
        <h2>Tambah Proyek Baru</h2>
        <div className="form-group">
          <label htmlFor="name">Nama Proyek</label>
          <input
            type="text"
            id="name"
            name="name"
            value={projectData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Deskripsi</label>
          <textarea
            id="description"
            name="description"
            value={projectData.description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="startDate">Tanggal Mulai</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={projectData.startDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate">Tanggal Selesai</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={projectData.endDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="responsiblePerson">Penanggung Jawab</label>
          <input
            type="text"
            id="responsiblePerson"
            name="responsiblePerson"
            value={projectData.responsiblePerson}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="thumbnail">Thumbnail</label>
          <input
            type="file"
            id="thumbnail"
            onChange={handleThumbnailChange}
            accept="image/*"
          />
        </div>
        <div className="form-buttons">
          <button type="submit" className="submit-button">Tambah</button>
          <button type="button" onClick={onClose} className="close-button">Batal</button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default ProjectForm;
