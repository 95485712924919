import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './GanttChart.css';
import { FaSearchPlus, FaSearchMinus, FaExpand, FaCompress, FaFilePdf, FaFileImage, FaFileExcel, FaFilter, FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import TaskForm from './TaskForm';
import '../styles/global.css';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';

const hasManagePermission = (userRole) => {
  const allowedPermissions = ['manage_tasks', 'create_project'];
  return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
};

const GanttChart = ({ 
  tasks, 
  isDarkMode, 
  userRole,
  onEditTask,
  onDeleteTask,
  shots = [],
  assets = [],
  sequences = [],
  socket,
  activeProject
}) => {
  const ganttContainer = useRef(null);
  const [currentZoom, setCurrentZoom] = useState('Days');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    priority: null,
    progress: { min: 0, max: 100 },
    assignee: null,
    relatedShot: null,
    relatedAsset: null,
    startDate: null,
    dueDate: null
  });
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const columnOptions = [
    { value: 'text', label: 'Task Name' },
    { value: 'status', label: 'Status' },
    { value: 'start_date', label: 'Start Date' },
    { value: 'end_date', label: 'Due Date' },
    { value: 'progress', label: 'Progress' },
    { value: 'assignee', label: 'Assignee' },
    { value: 'priority', label: 'Priority' },
    { value: 'relatedShot', label: 'Related Shot' },
    { value: 'relatedAsset', label: 'Related Asset' }
  ];

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchStatuses();
    fetchUsers();
  }, []);

  const getStatusName = (statusId) => {
    if (!statusId) return 'Pending';
    const status = statuses.find(s => s.id === parseInt(statusId));
    return status ? status.name : 'Unknown';
  };

  const handleAddTask = () => {
    setEditFormData(null);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      const response = await axiosInstance.post('/api/tasks', formData);
      if (socket) {
        socket.emit('createTask', response.data);
      }
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  // Fungsi untuk mendapatkan warna berdasarkan progress
  const getProgressColor = (progress) => {
    if (progress >= 0.5) return '#4CAF50'; // Hijau untuk progress >= 50%
    if (progress >= 0.25) return '#FFC107'; // Kuning untuk progress >= 25%
    return '#F44336'; // Merah untuk progress < 25%
  };

  // Format tasks untuk Gantt Chart
  const formatTasks = useCallback((tasksToFormat) => {
    const formattedTasks = [];

    // Pertama, buat parent untuk setiap shot yang memiliki task terkait
    shots.forEach(shot => {
      // Tambahkan shot sebagai parent task
      formattedTasks.push({
        id: `shot-${shot.id}`,
        text: shot.shotName || shot.name,
        type: 'project',
        start_date: shot.start_date ? new Date(shot.start_date) : null,
        end_date: shot.dueDate ? new Date(shot.dueDate) : null,
        progress: 0 // Progress akan dihitung nanti
      });
    });

    // Kemudian tambahkan task sebagai child dari shot yang sesuai
    tasksToFormat.forEach(task => {
      const startDate = task.startDate ? new Date(task.startDate) : new Date();
      const endDate = task.dueDate ? new Date(task.dueDate) : new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
      
      // Validasi tanggal
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        console.error(`Invalid date for task ${task.id}:`, { startDate: task.startDate, endDate: task.dueDate });
        return null;
      }

      const validEndDate = endDate < startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : endDate;

      // Temukan shot dan asset yang terkait dengan task ini
      const relatedShots = shots.filter(shot => 
        shot.selectedTasks?.includes(task.id) || 
        task.selectedShots?.includes(shot.id)
      );

      const relatedAssets = assets.filter(asset => 
        asset.selectedTasks?.includes(task.id) || 
        task.selectedAssets?.includes(asset.id)
      );

      // Jika task terkait dengan multiple shots, buat task untuk setiap shot
      relatedShots.forEach(relatedShot => {
        formattedTasks.push({
          id: `${task.id}-${relatedShot.id}`, // ID unik untuk setiap task-shot pair
          text: task.taskName || 'Untitled Task',
          start_date: startDate,
          end_date: validEndDate,
          progress: typeof task.progress === 'number' ? task.progress / 100 : 0,
          statusId: task.statusId,
          priority: task.priority || 'medium',
          assignee: task.assignedUser?.username || 'Unassigned',
          duration: Math.max(1, Math.ceil((validEndDate - startDate) / (24 * 60 * 60 * 1000))),
          parent: `shot-${relatedShot.id}`,
          type: 'task',
          relatedShot: relatedShot.shotName || relatedShot.name,
          relatedAsset: relatedAssets.length > 0 ? relatedAssets[0].assetName : '',
          description: task.description
        });
      });
    });

    // Hitung progress untuk setiap shot berdasarkan task anaknya
    formattedTasks.forEach(task => {
      if (task.type === 'project') {
        const childTasks = formattedTasks.filter(t => t.parent === task.id);
        if (childTasks.length > 0) {
          const totalProgress = childTasks.reduce((sum, t) => sum + (t.progress || 0), 0);
          task.progress = totalProgress / childTasks.length;
        }
      }
    });

    return formattedTasks.filter(Boolean);
  }, [filters, searchTerm, shots, assets, users, statuses]);

  const getTaskColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'completed':
        return '#4CAF50';
      case 'in progress':
        return '#FF9800';
      case 'todo':
        return '#2196F3';
      case 'pending':
        return '#F44336';
      default:
        return '#9E9E9E';
    }
  };

  useEffect(() => {
    if (!ganttContainer.current) return;

    // Initialize plugins
    gantt.plugins({
      marker: true,
      fullscreen: true,
      tooltip: true,
      critical_path: true,
      auto_scheduling: true,
      zoom: true
    });

    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    gantt.config.order_branch = true;
    gantt.config.order_branch_free = true;
    gantt.config.show_tasks_outside_timescale = true;
    gantt.config.grid_resize = true;
    gantt.config.min_column_width = 20;
    gantt.config.row_height = 35;
    gantt.config.grid_width = 380;
    gantt.config.task_height = 20;
    gantt.config.resize_column = true;
    gantt.config.resize_grid = true;
    gantt.config.grid_resizer = {
      width: 8,
      touch_area: 16,
      css_class: 'gantt_grid_resizer'
    };

    // Enable task tree view dan expand/collapse
    gantt.config.show_tasks_outside_timescale = true;
    gantt.config.open_tree_initially = true;
    gantt.config.branch_loading = true;
    gantt.config.branch_auto_close = false;

    // Configure zoom levels
    gantt.ext.zoom.init({
      levels: [
        {
          name: "Hours",
          scale_height: 60,
          min_column_width: 30,
          scales: [
            {unit: "day", step: 1, format: "%d %M"},
            {unit: "hour", step: 1, format: "%H:%i"}
          ]
        },
        {
          name: "Days",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: "week", step: 1, format: "Week #%W"},
            {unit: "day", step: 1, format: "%d %M"}
          ]
        },
        {
          name: "Months",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: "month", step: 1, format: "%F"},
            {unit: "week", step: 1, format: "#%W"}
          ]
        }
      ]
    });

    // Konfigurasi template untuk warna task berdasarkan status dan progress
    gantt.templates.task_class = function(start, end, task) {
      let classes = [];
      
      // Add progress class
      if (task.progress >= 0.5) {
        classes.push('high-progress');
      } else if (task.progress >= 0.25) {
        classes.push('medium-progress');
      } else {
        classes.push('low-progress');
      }

      // Add status class
      switch (task.statusId) {
        case 1:
          classes.push('completed');
          break;
        case 2:
        case 3:
          classes.push('in-progress');
          break;
        case 4:
        case 5:
        default:
          classes.push('todo');
          break;
      }

      return classes.join(' ');
    };

    // Konfigurasi template untuk progress bar
    gantt.templates.progress_text = function(start, end, task) {
      return ""; // Mengembalikan string kosong untuk menghilangkan teks persentase
    };

    // Konfigurasi kolom grid dengan tree support yang selalu ada
    const defaultColumns = [
      { 
        name: "text", 
        label: "Task Name", 
        tree: true, 
        width: 200, 
        resize: true,
        template: function(task) {
          // Pastikan kolom text selalu ada untuk expand/collapse
          return task.text;
        }
      },
      { 
        name: "status", 
        label: "Status", 
        align: "center", 
        width: 100,
        template: function(task) {
          const statusName = getStatusName(task.statusId);
          const statusClass = statusName.toLowerCase().replace(/\s+/g, '-');
          return `<div class="gantt-status ${statusClass}">${statusName}</div>`;
        }
      },
      { name: "start_date", label: "Start Date", align: "center", width: 100, resize: true },
      { name: "end_date", label: "Due Date", align: "center", width: 100, resize: true },
      { 
        name: "progress", 
        label: "Progress",
        align: "center",
        width: 80,
        template: function(task) {
          return Math.round(task.progress * 100) + '%';
        }
      },
      { name: "assignee", label: "Assignee", align: "center", width: 100, resize: true },
      { name: "priority", label: "Priority", align: "center", width: 80, resize: true },
      { name: "relatedShot", label: "Related Shot", align: "center", width: 100, resize: true },
      { name: "relatedAsset", label: "Related Asset", align: "center", width: 100, resize: true }
    ];

    // Filter kolom berdasarkan hiddenColumns
    const visibleColumns = defaultColumns.filter(column => 
      !hiddenColumns.some(hidden => {
        const matchMap = {
          'progress': 'progress',
          'Task Name': 'text',
          'Status': 'status',
          'Start Date': 'start_date',
          'Due Date': 'end_date',
          'Assignee': 'assignee',
          'Priority': 'priority',
          'Related Shot': 'relatedShot',
          'Related Asset': 'relatedAsset'
        };
        return matchMap[hidden.label] === column.name || hidden.value === column.name;
      })
    );

    // Pastikan kolom "text" selalu ada untuk tree view
    if (!visibleColumns.some(col => col.name === 'text')) {
      visibleColumns.unshift(defaultColumns.find(col => col.name === 'text'));
    }

    // Atur kolom yang akan ditampilkan
    gantt.config.columns = visibleColumns;

    // Hitung ulang lebar grid
    gantt.config.grid_width = calculateGridWidth(visibleColumns);

    // Configure task template
    gantt.templates.task_text = function(start, end, task) {
      return task.text + (task.progress ? ' (' + Math.round(task.progress * 100) + '%)' : '');
    };

    // Configure tooltip
    gantt.templates.tooltip_text = function(start, end, task) {
      const startDate = gantt.date.date_to_str(gantt.config.task_date)(start);
      const endDate = gantt.date.date_to_str(gantt.config.task_date)(end);
      return `<div class="tooltip-header">${task.text}</div>
              <div class="tooltip-content">
                <div class="tooltip-row">
                  <span class="tooltip-label">Start:</span>
                  <span>${startDate}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Due:</span>
                  <span>${endDate}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Progress:</span>
                  <span>${Math.round(task.progress * 100)}%</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Status:</span>
                  <span>${task.statusId}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Assignee:</span>
                  <span>${task.assignee}</span>
                </div>
                ${task.description ? `
                <div class="tooltip-row">
                  <span class="tooltip-label">Description:</span>
                  <span>${task.description}</span>
                </div>` : ''}
              </div>`;
    };

    // Enable task editing
    gantt.config.drag_progress = true;
    gantt.config.drag_resize = true;
    gantt.config.drag_move = true;
    gantt.config.drag_links = true;
    gantt.config.details_on_dblclick = true;
    gantt.config.click_drag = true;

    // Enable inline editing
    gantt.config.readonly = false;
    gantt.config.editable_property = "text";

    // Configure task editing
    gantt.config.editor_types.text = {
      show: function(id, column, config, placeholder) {
        var task = gantt.getTask(id);
        var html = "<div class='gantt-edit-content'><input type='text' value='" + task.text + "'/></div>";
        return html;
      },
      hide: function() {
        var input = document.querySelector(".gantt-edit-content input");
        if (input) {
          return input.value;
        }
        return "";
      },
      set_value: function(value, id, column, node) {
        var input = node.querySelector("input");
        if (input) {
          input.value = value;
        }
      }
    };

    // Enable progress drag with animation
    gantt.config.drag_progress = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Enable task resizing with animation
    gantt.config.drag_resize = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Enable task moving with animation
    gantt.config.drag_move = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Konfigurasi scroll
    gantt.config.scroll_size = 20;
    gantt.config.scroll_step = 50;
    gantt.config.scroll_sensitivity = 30;
    gantt.config.autoscroll = true;
    gantt.config.autoscroll_speed = 20;

    // Configure gantt events
    const extractEntityInfo = (id) => {
      const idString = id.toString();
      const isShot = idString.startsWith('shot-');
      const cleanId = isShot ? idString.replace('shot-', '') : idString.split('-')[0];
      return { isShot, id: parseInt(cleanId) };
    };

    gantt.attachEvent("onAfterTaskUpdate", async (id, task) => {
      try {
        const { isShot, id: entityId } = extractEntityInfo(id);

        if (isShot) {
          // Ambil shot yang ada untuk mendapatkan data lainnya
          const existingShot = shots.find(s => s.id === entityId);
          if (!existingShot) {
            throw new Error('Shot tidak ditemukan');
          }

          // Debug logging
          console.log('Existing Shot:', existingShot);
          console.log('Task Update Details:', {
            start_date: task.start_date,
            end_date: task.end_date
          });

          // Update shot hanya dengan start date dan due date
          const updatedShot = {
            id: entityId,
            shotName: existingShot.shotName,
            start_date: task.start_date.toISOString(),
            dueDate: task.end_date.toISOString(),  // Pastikan menggunakan dueDate
            description: existingShot.description,
            statusId: existingShot.statusId,
            sequenceId: existingShot.sequenceId,
            selectedAssets: existingShot.selectedAssets || [],
            selectedTasks: existingShot.selectedTasks || []
          };

          // Debug logging
          console.log('Prepared Shot Update:', updatedShot);

          // Gunakan FormData untuk mengirim data
          const formData = new FormData();
          Object.keys(updatedShot).forEach(key => {
            if (updatedShot[key] !== null && updatedShot[key] !== undefined) {
              // Konversi array ke JSON string
              if (Array.isArray(updatedShot[key])) {
                formData.append(key, JSON.stringify(updatedShot[key]));
              } else {
                formData.append(key, updatedShot[key]);
              }
            }
          });

          const response = await axiosInstance.put(`api/shots/${entityId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          // Debug logging
          console.log('Server Response:', response.data);

          // Emit socket event jika tersedia
          if (socket && typeof socket.emit === 'function') {
            socket.emit('updateShot', response.data);
          }

          console.log('Shot updated successfully', response.data);
          return response.data;
        } else {
          // Logika update task tetap sama
          const oldTask = tasks.find(t => t.id === entityId);
          if (!oldTask) {
            throw new Error('Task not found');
          }

          const updatedTask = {
            id: entityId,
            taskName: task.text || oldTask.taskName || 'Unnamed Task',
            startDate: task.start_date.toISOString(),
            dueDate: task.end_date.toISOString(),
            progress: Math.round(task.progress * 100),
            statusId: task.statusId || 1,
            priority: task.priority || 'medium',
            description: task.description || oldTask.description,
            parentId: task.parent ? extractEntityInfo(task.parent).id : null,
            selectedShots: oldTask.selectedShots || [],
            selectedAssets: oldTask.selectedAssets || []
          };

          await onEditTask(entityId, updatedTask);

          // Emit socket event
          if (socket) {
            socket.emit('taskUpdated', updatedTask);
          }
        }
      } catch (error) {
        console.error('Error updating task/shot:', error);
        gantt.message({ type: "error", text: "Gagal memperbarui" });
        gantt.refreshTask(id);
      }
    });

    gantt.attachEvent("onAfterTaskDrag", async (id, mode, e) => {
      try {
        const task = gantt.getTask(id);
        const { isShot, id: entityId } = extractEntityInfo(id);

        if (isShot) {
          // Ambil shot yang ada untuk mendapatkan data lainnya
          const existingShot = shots.find(s => s.id === entityId);
          if (!existingShot) {
            throw new Error('Shot tidak ditemukan');
          }

          // Update shot hanya dengan start date dan due date
          const updatedShot = {
            id: entityId,
            shotName: existingShot.shotName,
            start_date: task.start_date.toISOString(),
            dueDate: task.end_date.toISOString(),  // Pastikan menggunakan dueDate
            description: existingShot.description,
            statusId: existingShot.statusId,
            sequenceId: existingShot.sequenceId,
            selectedAssets: existingShot.selectedAssets || [],
            selectedTasks: existingShot.selectedTasks || []
          };

          // Gunakan FormData untuk mengirim data
          const formData = new FormData();
          Object.keys(updatedShot).forEach(key => {
            if (updatedShot[key] !== null && updatedShot[key] !== undefined) {
              // Konversi array ke JSON string
              if (Array.isArray(updatedShot[key])) {
                formData.append(key, JSON.stringify(updatedShot[key]));
              } else {
                formData.append(key, updatedShot[key]);
              }
            }
          });

          const response = await axiosInstance.put(`api/shots/${entityId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          // Emit socket event
          if (socket && typeof socket.emit === 'function') {
            socket.emit('updateShot', response.data);
          }

          console.log('Shot updated successfully');
        } else {
          // Update task
          const oldTask = tasks.find(t => t.id === entityId);
          if (!oldTask) {
            throw new Error('Task not found');
          }

          const updatedTask = {
            id: entityId,
            startDate: task.start_date.toISOString(),
            dueDate: task.end_date.toISOString(),
            parentId: task.parent ? extractEntityInfo(task.parent).id : null,
            // Pertahankan data lain dari task asli
            taskName: oldTask.taskName,
            progress: Math.round(task.progress * 100),
            statusId: oldTask.statusId,
            priority: oldTask.priority
          };

          await onEditTask(entityId, updatedTask);

          // Emit socket event
          if (socket) {
            socket.emit('taskUpdated', updatedTask);
          }
        }
      } catch (error) {
        console.error('Error updating task/shot after drag:', error);
        gantt.message({ type: "error", text: "Gagal memperbarui posisi" });
        gantt.refreshTask(id);
      }
    });

    gantt.attachEvent("onAfterTaskAdd", async (id, task) => {
      try {
        // Convert gantt task format to our API format
        const newTask = {
          taskName: task.text,
          startDate: task.start_date.toISOString(),
          dueDate: task.end_date.toISOString(),
          progress: Math.round(task.progress * 100),
          statusId: task.statusId,
          priority: task.priority || 'medium',
          description: task.description || '',
          parentId: task.parent
        };

        // Call the API to create task
        const response = await axiosInstance.post(`/api/tasks`, {
          ...newTask,
          projectId: activeProject.id
        });

        // Update gantt with the new task ID from server
        gantt.changeTaskId(id, response.data.id);

        // Emit socket event
        if (socket) {
          socket.emit('createTask', response.data);
        }

      } catch (error) {
        console.error('Error creating task:', error);
        gantt.message({ type: "error", text: "Failed to create task" });
        // Remove task on error
        gantt.deleteTask(id);
      }
    });

    gantt.attachEvent("onAfterTaskDelete", async (id) => {
      try {
        const { isShot, id: entityId } = extractEntityInfo(id);

        if (isShot) {
          // Panggil API untuk delete shot
          const response = await axiosInstance.delete(`api/shots/${entityId}`);

          // Emit socket event jika tersedia
          if (socket && typeof socket.emit === 'function') {
            socket.emit('deleteShot', entityId);
          }

          console.log('Shot deleted successfully');
          return response.data;
        } else {
          // Panggil callback onDeleteTask dengan ID asli
          await onDeleteTask(entityId);

          // Emit socket event
          if (socket) {
            socket.emit('taskDeleted', { id: entityId });
          }

        }
      } catch (error) {
        console.error('Error deleting task:', error);
        gantt.message({ type: "error", text: `Gagal menghapus tugas: ${error.message}` });
      }
    });

    gantt.attachEvent("onTaskDblClick", (id, e) => {
      if (e.target.className.includes('gantt_task_progress_drag')) {
        const task = gantt.getTask(id);
        const { id: entityId } = extractEntityInfo(id);
        
        onEditTask(entityId, {
          ...task,
          progress: Math.round(task.progress * 100)
        });
      }
    });

    gantt.attachEvent("onGridResize", function(new_width){
      console.log("New grid width:", new_width);
      // Lakukan sesuatu dengan new_width
    });

    // Initialize Gantt
    gantt.init(ganttContainer.current);

    // Format and load tasks
    const formattedTasks = formatTasks(tasks);
    const ganttData = {
      data: formattedTasks,
      links: [] // Jika ada dependencies antar task, bisa ditambahkan di sini
    };
    gantt.parse(ganttData);

    // Add today marker after initialization
    const today = new Date();
    const marker = gantt.addMarker({
      start_date: today,
      css: "today",
      text: "Today",
      title: today.toLocaleDateString()
    });

    // Handle window resize
    const handleResize = () => {
      if (ganttContainer.current) {
        gantt.render();
      }
    };

    window.addEventListener('resize', handleResize);

    const gridCell = document.querySelector('.gantt_layout_cell');
    const gridContent = document.querySelector('.gantt_layout_content');
    
    if (!gridCell || !gridContent) return;

    let isResizing = false;

    const handleMouseDown = (e) => {
      isResizing = true;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
      if (!isResizing) return;
      const newWidth = e.clientX - gridContent.getBoundingClientRect().left;
      gridContent.style.width = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      isResizing = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    gridCell.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (marker) {
        gantt.deleteMarker(marker);
      }
      gantt.clearAll();
      gantt.detachAllEvents();
      gridCell.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [tasks, formatTasks, onEditTask, onDeleteTask, socket, activeProject?.id]);

  // Zoom controls
  const handleZoomChange = (direction) => {
    const zoomLevels = ['Hours', 'Days', 'Months'];
    const currentIndex = zoomLevels.indexOf(currentZoom);

    if (direction === 'in' && currentIndex < zoomLevels.length - 1) {
      const newZoom = zoomLevels[currentIndex + 1];
      setCurrentZoom(newZoom);
      gantt.ext.zoom.setLevel(newZoom);
    } else if (direction === 'out' && currentIndex > 0) {
      const newZoom = zoomLevels[currentIndex - 1];
      setCurrentZoom(newZoom);
      gantt.ext.zoom.setLevel(newZoom);
    }
  };

  // Toggle fullscreen
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (ganttContainer.current.requestFullscreen) {
        ganttContainer.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  // Di dalam komponen GanttChart, tambahkan useEffect untuk mengatur kolom yang tersembunyi
  useEffect(() => {
    if (!ganttContainer.current) return;

    // Konfigurasi kolom grid dengan tree support yang selalu ada
    const defaultColumns = [
      { 
        name: "text", 
        label: "Task Name", 
        tree: true, 
        width: 200, 
        resize: true,
        template: function(task) {
          // Pastikan kolom text selalu ada untuk expand/collapse
          return task.text;
        }
      },
      { 
        name: "status", 
        label: "Status", 
        align: "center", 
        width: 100,
        template: function(task) {
          const statusName = getStatusName(task.statusId);
          const statusClass = statusName.toLowerCase().replace(/\s+/g, '-');
          return `<div class="gantt-status ${statusClass}">${statusName}</div>`;
        }
      },
      { name: "start_date", label: "Start Date", align: "center", width: 100, resize: true },
      { name: "end_date", label: "Due Date", align: "center", width: 100, resize: true },
      { 
        name: "progress", 
        label: "Progress",
        align: "center",
        width: 80,
        template: function(task) {
          return Math.round(task.progress * 100) + '%';
        }
      },
      { name: "assignee", label: "Assignee", align: "center", width: 100, resize: true },
      { name: "priority", label: "Priority", align: "center", width: 80, resize: true },
      { name: "relatedShot", label: "Related Shot", align: "center", width: 100, resize: true },
      { name: "relatedAsset", label: "Related Asset", align: "center", width: 100, resize: true }
    ];

    // Filter kolom berdasarkan hiddenColumns
    const visibleColumns = defaultColumns.filter(column => 
      !hiddenColumns.some(hidden => {
        const matchMap = {
          'progress': 'progress',
          'Task Name': 'text',
          'Status': 'status',
          'Start Date': 'start_date',
          'Due Date': 'end_date',
          'Assignee': 'assignee',
          'Priority': 'priority',
          'Related Shot': 'relatedShot',
          'Related Asset': 'relatedAsset'
        };
        return matchMap[hidden.label] === column.name || hidden.value === column.name;
      })
    );

    // Pastikan kolom "text" selalu ada untuk tree view
    if (!visibleColumns.some(col => col.name === 'text')) {
      visibleColumns.unshift(defaultColumns.find(col => col.name === 'text'));
    }

    // Atur kolom yang akan ditampilkan
    gantt.config.columns = visibleColumns;

    // Hitung ulang lebar grid
    gantt.config.grid_width = calculateGridWidth(visibleColumns);

    // Render ulang Gantt Chart
    gantt.render();
  }, [hiddenColumns]);

  const calculateGridWidth = (columns) => {
    return columns.reduce((total, col) => total + (col.width || 100), 0);
  };

  const calculateShotProgress = (shotId, tasks) => {
    const shotTasks = tasks.filter(task => task.parent === `shot-${shotId}`);
    if (shotTasks.length === 0) return 0;
    
    const totalProgress = shotTasks.reduce((sum, task) => sum + (task.progress || 0), 0);
    return totalProgress / shotTasks.length;
  };

  return (
    <div className={`list-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>Gantt View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={handleAddTask}
            >
              <FaPlus /> Add Task
            </button>
          )}
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="form-group">
            <label>Status:</label>
            <Select
              value={filters.status}
              onChange={(value) => setFilters({ ...filters, status: value })}
              isClearable
              placeholder="Filter by status..."
              options={statuses.map(status => ({
                value: status.id,
                label: status.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Priority:</label>
            <Select
              value={filters.priority}
              onChange={(value) => setFilters({ ...filters, priority: value })}
              isClearable
              placeholder="Filter by priority..."
              options={[
                { value: 'high', label: 'High' },
                { value: 'medium', label: 'Medium' },
                { value: 'low', label: 'Low' }
              ]}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Start Date:</label>
            <input
              type="date"
              value={filters.startDate || ''}
              onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Due Date:</label>
            <input
              type="date"
              value={filters.dueDate || ''}
              onChange={(e) => setFilters({ ...filters, dueDate: e.target.value })}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Progress:</label>
            <div className="progress-range">
              <input
                type="number"
                min="0"
                max="100"
                value={filters.progress.min}
                onChange={(e) => setFilters({
                  ...filters,
                  progress: { ...filters.progress, min: parseInt(e.target.value) }
                })}
                className="form-control"
              />
              <span>to</span>
              <input
                type="number"
                min="0"
                max="100"
                value={filters.progress.max}
                onChange={(e) => setFilters({
                  ...filters,
                  progress: { ...filters.progress, max: parseInt(e.target.value) }
                })}
                className="form-control"
              />
              <span>%</span>
            </div>
          </div>
          <div className="form-group">
            <label>Assignee:</label>
            <Select
              value={filters.assignee}
              onChange={(value) => setFilters({ ...filters, assignee: value })}
              isClearable
              placeholder="Filter by assignee..."
              options={users.map(user => ({
                value: user.id,
                label: user.username || user.email
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Related Shot:</label>
            <Select
              value={filters.relatedShot}
              onChange={(value) => setFilters({ ...filters, relatedShot: value })}
              isClearable
              placeholder="Filter by related shot..."
              options={shots.map(shot => ({
                value: shot.id,
                label: shot.shotName || shot.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Related Asset:</label>
            <Select
              value={filters.relatedAsset}
              onChange={(value) => setFilters({ ...filters, relatedAsset: value })}
              isClearable
              placeholder="Filter by related asset..."
              options={assets.map(asset => ({
                value: asset.id,
                label: asset.assetName || asset.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
        </div>
      )}

      <div className="gantt-wrapper">
        <div className="gantt-controls">
          <div className="zoom-buttons">
            <button onClick={() => handleZoomChange('in')} title="Zoom In">
              <FaSearchPlus />
            </button>
            <button onClick={() => handleZoomChange('out')} title="Zoom Out">
              <FaSearchMinus />
            </button>
          </div>
          
          <div className="fullscreen-button">
            <button onClick={toggleFullscreen} title="Toggle Fullscreen">
              {isFullscreen ? <FaCompress /> : <FaExpand />}
            </button>
          </div>

          <div className="export-buttons">
            <button onClick={() => gantt.exportToPDF()} title="Export to PDF">
              <FaFilePdf />
            </button>
            <button onClick={() => gantt.exportToPNG()} title="Export to Image">
              <FaFileImage />
            </button>
            <button onClick={() => gantt.exportToExcel()} title="Export to Excel">
              <FaFileExcel />
            </button>
          </div>
        </div>

        <div className="column-controls">
          <Select
            isMulti
            value={hiddenColumns}
            onChange={setHiddenColumns}
            options={columnOptions}
            placeholder="Hide columns..."
            className="select-container"
            classNamePrefix="select"
          />
        </div>

        <div className="gantt-container">
          <div ref={ganttContainer} style={{ width: '100%', height: '100%' }}></div>
        </div>
      </div>

      {isFormVisible && (
        <TaskForm
          onClose={handleFormClose}
          onSubmit={handleFormSubmit}
          initialData={editFormData}
          sequences={sequences}
          shots={shots}
          assets={assets}
        />
      )}
    </div>
  );
};

export default GanttChart;
