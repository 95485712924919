import React from 'react';
import ReactDOM from 'react-dom';
import VideoPlayer from './VideoPlayer';
import './VideoModal.css';

const VideoModal = ({ isOpen, onClose, videoPath, annotations = [] }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="video-modal-overlay" onClick={onClose}>
      <div className="video-modal-container" onClick={e => e.stopPropagation()}>
        <VideoPlayer
          filepath={videoPath}
          watchMode={true}
          onClose={onClose}
          initialAnnotations={annotations}
          isModal={true}
        />
      </div>
    </div>,
    document.body
  );
};

export default VideoModal;