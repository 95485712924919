import React, { useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/globalform.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaEye, FaPlus, FaFilter, FaList, FaThLarge, FaImage } from 'react-icons/fa';
import ActivityPanel from './ActivityPanel';
import TaskForm from './TaskForm';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';

const TaskList = ({ 
  tasks = [], 
  sequences = [],
  assets = [],
  shots = [], 
  onDeleteTask,
  onEditTask,
  userRole,
  isDarkMode,
  activeProject,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  selectedTask,
  setSelectedTask,
  onTaskSelect,
  socket,
  newProp
}) => {
  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    priority: null,
    progress: { min: 0, max: 100 },
    assignee: null,
    relatedShot: null,
    relatedAsset: null
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchStatuses();
    fetchUsers();
  }, []);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_tasks', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleEditClick = (task) => {
    setEditingId(task.id);
    setEditFormData(task);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      if (editingId) {
        // Update existing task
        await onEditTask(editingId, formData);
      } else {
        // Create new task
        await handleAddTask(formData);
      }
      handleFormClose();
    } catch (error) {
      console.error('Error handling task:', error);
    }
  };

  const handleAddTask = async (formData) => {
    try {
      const response = await axiosInstance.post('/api/tasks', formData);
      if (socket) {
        socket.emit('createTask', response.data);
      }
      handleFormClose();
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  };

  const handleTaskClick = (task) => {
    if (setSelectedTask) {
      setSelectedTask(task);
    }
    if (onTaskSelect) {
      onTaskSelect(task);
    }
  };

  const handleOverviewClick = (taskId) => {
    navigate(`/task-overview/${taskId}`);
  };

  const getStatusBadge = (statusId) => {
    if (!statusId) return (
      <span className="status-badge pending">
        Pending
      </span>
    );
    
    const status = statuses.find(s => s.id === parseInt(statusId));
    if (!status) return (
      <span className="status-badge unknown">
        Unknown
      </span>
    );

    const statusClass = status.name.toLowerCase().replace(/\s+/g, '-');
    return (
      <span className={`status-badge ${statusClass}`}>
        {status.name}
      </span>
    );
  };

  const getProgressColor = (progress) => {
    progress = Number(progress) || 0;
    if (progress < 30) return '#dc3545';  // Red
    if (progress < 70) return '#ffc107';  // Yellow
    return '#28a745';  // Green
  };

  const getPriorityColor = (priority) => {
    switch (priority?.toLowerCase()) {
      case 'high':
        return '#dc3545';  // Red
      case 'medium':
        return '#ffc107';  // Yellow
      case 'low':
        return '#28a745';  // Green
      default:
        return '#6c757d';  // Gray
    }
  };

  const getAssignedUserName = (userId) => {
    const user = users.find(u => u.id === userId);
    return user ? (user.username || user.email || 'Unknown User') : 'Unassigned';
  };

  const getRelatedShots = (taskId) => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) return [];

    // Cari shots yang memiliki task ini di selectedTasks
    const shotsWithTask = shots.filter(shot => 
      shot.selectedTasks?.includes(taskId)
    );

    // Cari shots yang ada di selectedShots task
    const taskSelectedShots = shots.filter(shot => 
      task.selectedShots?.includes(shot.id)
    );

    // Gabungkan dan hapus duplikat
    const allRelatedShots = [...shotsWithTask, ...taskSelectedShots];
    const uniqueShots = Array.from(new Set(allRelatedShots.map(shot => shot.id)))
      .map(id => allRelatedShots.find(shot => shot.id === id));

    return uniqueShots;
  };

  const getRelatedAsset = (taskId) => {
    return assets?.find(asset => asset.selectedTasks?.includes(taskId));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: '',
      priority: null,
      progress: { min: 0, max: 100 },
      assignee: null,
      relatedShot: null,
      relatedAsset: null
    });
  };

  const getTaskThumbnail = (task, assets, shots) => {
    // Gunakan thumbnail task jika ada
    if (task.thumbnailUrl) {
      return task.thumbnailUrl.startsWith('http')
        ? task.thumbnailUrl
        : `${API_BASE_URL}${task.thumbnailUrl}`;
    }

    // Jika tidak ada thumbnail, kembalikan placeholder
    return '/assets/images/task-placeholder.png';
  };

  const filteredTasks = tasks.filter(task => {
    if (filters.status && task.statusId !== filters.status.value) return false;
    if (filters.name && !task.taskName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    if (filters.priority && task.priority?.toLowerCase() !== filters.priority.value.toLowerCase()) return false;
    if (task.progress < filters.progress.min || task.progress > filters.progress.max) return false;
    if (filters.assignee && task.assignedUser?.id !== filters.assignee.value) return false;
    
    if (filters.relatedShot) {
      const hasRelatedShot = shots.some(shot => 
        shot.id === filters.relatedShot.value && 
        shot.selectedTasks?.includes(task.id)
      );
      if (!hasRelatedShot) return false;
    }
    
    if (filters.relatedAsset) {
      const hasRelatedAsset = assets.some(asset => 
        asset.id === filters.relatedAsset.value && 
        asset.selectedTasks?.includes(task.id)
      );
      if (!hasRelatedAsset) return false;
    }
    
    if (searchTerm && !task.taskName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  return (
    <div className={`list-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>List View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={() => {
                setEditFormData(null);
                setIsFormVisible(true);
              }}
              title="Add new task"
            >
              <FaPlus /> Add Task
            </button>
          )}
          <div className="view-toggle">
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <div className="filter-group">
              <label>Priority</label>
              <Select
                value={filters.priority}
                onChange={(value) => handleFilterChange('priority', value)}
                options={[
                  { value: 'high', label: 'High' },
                  { value: 'medium', label: 'Medium' },
                  { value: 'low', label: 'Low' }
                ]}
                isClearable
                placeholder="Filter by priority"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Progress Range</label>
              <div className="progress-range">
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={filters.progress.min}
                  onChange={(e) => handleFilterChange('progress', { 
                    ...filters.progress, 
                    min: parseInt(e.target.value) 
                  })}
                  className="form-control"
                />
                <span>to</span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={filters.progress.max}
                  onChange={(e) => handleFilterChange('progress', { 
                    ...filters.progress, 
                    max: parseInt(e.target.value) 
                  })}
                  className="form-control"
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Assignee</label>
              <Select
                value={filters.assignee}
                onChange={(value) => handleFilterChange('assignee', value)}
                options={users.map(user => ({
                  value: user.id,
                  label: user.username || user.email
                }))}
                isClearable
                placeholder="Filter by assignee"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Shot</label>
              <Select
                value={filters.relatedShot}
                onChange={(value) => handleFilterChange('relatedShot', value)}
                options={shots.map(shot => ({
                  value: shot.id,
                  label: shot.shotName
                }))}
                isClearable
                placeholder="Filter by related shot"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Asset</label>
              <Select
                value={filters.relatedAsset}
                onChange={(value) => handleFilterChange('relatedAsset', value)}
                options={assets.map(asset => ({
                  value: asset.id,
                  label: asset.assetName
                }))}
                isClearable
                placeholder="Filter by related asset"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      {isFormVisible && (
        <div className="modal-overlay">
          <div className="modal-form">
            <TaskForm
              onAddTask={handleFormSubmit}
              onClose={handleFormClose}
              isEditing={!!editFormData}
              initialTask={editFormData}
              sequences={sequences}
              assets={assets}
              shots={shots}
              users={users}
              isDarkMode={isDarkMode}
              activeProject={activeProject}
              currentUser={userRole.username}
            />
          </div>
        </div>
      )}

      <div className="card mt-4">
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Thumbnail</th>
              <th>Name</th>
              <th>Description</th>
              <th>Priority</th>
              <th>Progress</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>Due Date</th>
              <th>Assignee</th>
              <th>Related Shots</th>
              <th>Related Asset</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map(task => (
              <tr 
                key={task.id}
                className={`task-row ${selectedTask?.id === task.id ? 'selected-task' : ''} ${selectedTask?.id === task.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
                onClick={() => handleTaskClick(task)}
              >
                <td>
                  <img 
                    src={getTaskThumbnail(task, assets, shots)} 
                    alt="Task Thumbnail" 
                    className="asset-thumbnail" 
                  />
                </td>
                <td>{task.taskName}</td>
                <td>{task.description}</td>
                <td>
                  <span className={`priority-badge ${task.priority?.toLowerCase() || 'normal'}`} style={{ backgroundColor: getPriorityColor(task.priority) }}>
                    {task.priority || 'Normal'}
                  </span>
                </td>
                <td>
                  <div className="progress-bar" style={{ backgroundColor: getProgressColor(task.progress) }}>
                    <div 
                      className="progress-fill" 
                      style={{ 
                        width: `${task.progress || 0}%`,
                        backgroundColor: getProgressColor(task.progress)
                      }}
                    />
                    <span className="progress-text">{task.progress || 0}%</span>
                  </div>
                </td>
                <td>{getStatusBadge(task.statusId)}</td>
                <td>{task.startDate ? formatDate(task.startDate) : '-'}</td>
                <td>{formatDate(task.dueDate)}</td>
                <td>
                  {task.assignedUser ? (
                    <div className="assignee-badge">
                      {task.assignedUser.username || task.assignedUser.email}
                    </div>
                  ) : '-'}
                </td>
                <td>
                  <div className="related-shots-container">
                    {getRelatedShots(task.id).map(shot => (
                      <div key={shot.id} className="shot-badge-container">
                        <span 
                          className={`shot-badge ${shot.statusId ? `status-${shot.statusId}` : 'status-pending'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/shot-overview/${shot.id}`);
                          }}
                        >
                          <div className="shot-badge-content">
                            <FaImage />
                            <span className="shot-name">{shot.shotName}</span>
                          </div>
                          {shot.sequence && (
                            <div className="shot-stats">
                              <span className="sequence-name" title={`Sequence: ${shot.sequence.sequenceName}`}>
                                {shot.sequence.sequenceName}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  {getRelatedAsset(task.id) ? (
                    <div className="related-badge">
                      <span className="icon"><FaPlus /></span>
                      {getRelatedAsset(task.id).assetName}
                    </div>
                  ) : '-'}
                </td>
                <td>
                  <div className="flex gap-2">
                    {userRole && (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(task);
                          }}
                          className="btn btn-icon btn-sm"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteTask(task.id);
                          }}
                          className="btn btn-icon btn-sm btn-danger"
                        >
                          <FaTrash />
                        </button>
                      </>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOverviewClick(task.id);
                      }}
                      className="btn btn-icon btn-sm"
                    >
                      <FaEye />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      {selectedTask?.id && isActivityPanelOpen && (
        <ActivityPanel
          item={selectedTask}
          type="task"
          isOpen={isActivityPanelOpen}
          onClose={() => {
            setSelectedTask(null);
            setIsActivityPanelOpen(false);
          }}
          onAddComment={() => {
            // Refresh tasks after adding comment
            if (activeProject?.id) {
              // fetchTasks();
            }
          }}
          onEditComment={() => {
            // Refresh tasks after editing comment
            if (activeProject?.id) {
              // fetchTasks();
            }
          }}
          onDeleteComment={() => {
            // Refresh tasks after deleting comment
            if (activeProject?.id) {
              // fetchTasks();
            }
          }}
          onPinComment={() => {
            // Refresh tasks after pinning comment
            if (activeProject?.id) {
              // fetchTasks();
            }
          }}
          currentUser={userRole?.username}
          isDarkMode={isDarkMode}
          socket={socket}
        />
      )}
      <style>
        {`
          .priority-badge {
            display: inline-block;
            padding: 4px 8px;
            border-radius: 4px;
            color: white;
            font-size: 12px;
            font-weight: 500;
          }

          .progress-bar {
            width: 100%;
            height: 20px;
            background-color: #e9ecef;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
          }

          .progress-fill {
            height: 100%;
            transition: width 0.3s ease;
          }

          .progress-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 12px;
            font-weight: 500;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
          }

          .assignee-badge,
          .related-badge {
            display: inline-flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            gap: 4px;
          }

          .assignee-badge {
            background-color: #bee3f8;
            color: #2c5282;
          }

          .related-badge {
            background-color: #e9d8fd;
            color: #553c9a;
          }

          .related-badge .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #805ad5;
            color: white;
            font-size: 10px;
            margin-right: 4px;
          }
        `}
      </style>
    </div>
  );
};

export default TaskList;
