import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import './ReportManager.css';
import { API_BASE_URL } from '../config/config';

// Helper function to get week number
const getWeekNumber = (date) => {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
};

const WeeklyReport = ({ isDarkMode, selectedProject }) => {
  const [weeklyStats, setWeeklyStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Dapatkan minggu saat ini
  const currentWeek = getWeekNumber(new Date());
  const currentYear = new Date().getFullYear();

  const [filters, setFilters] = useState({
    startWeek: currentWeek || 1,
    endWeek: currentWeek || 1,
    year: currentYear
  });


  useEffect(() => {
    const fetchWeeklyStats = async () => {
      try {
        setLoading(true);
        console.log('Fetching stats with params:', {
          project: selectedProject,
          startWeek: filters.startWeek,
          endWeek: filters.endWeek,
          year: filters.year
        });

        const response = await fetch(`${API_BASE_URL}/api/stats/weekly/${selectedProject}?startWeek=${filters.startWeek}&endWeek=${filters.endWeek}&year=${filters.year}`);
        if (!response.ok) {
          throw new Error('Failed to fetch weekly statistics');
        }
        const data = await response.json();
        console.log('Weekly stats received:', data);
        console.log('Time distribution:', data.timeDistribution);
        setWeeklyStats(data);
      } catch (err) {
        console.error('Error details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWeeklyStats();
  }, [selectedProject, filters]);

  const downloadExcel = () => {
    if (!weeklyStats) return;

    const workbook = XLSX.utils.book_new();
    
    // Tasks worksheet
    const tasksData = [
      ['Weekly Report'],
      ['Week', filters.week],
      ['Year', filters.year],
      [],
      ['Overview'],
      ['Total Tasks Completed', weeklyStats.overview.totalTasks.completed],
      ['Total Tasks Created', weeklyStats.overview.totalTasks.created],
      ['Tasks In Progress', weeklyStats.overview.totalTasks.inProgress],
      ['Blocked Tasks', weeklyStats.overview.totalTasks.blocked],
      ['Productivity Trend', weeklyStats.overview.productivity.trend],
      [],
      ['Time Tracking'],
      ['Total Hours', weeklyStats.overview.timeTracking.totalHours],
      ['Billable Hours', weeklyStats.overview.timeTracking.billableHours],
      ['Utilization Rate', weeklyStats.overview.timeTracking.utilization + '%']
    ];

    const tasksSheet = XLSX.utils.aoa_to_sheet(tasksData);
    XLSX.utils.book_append_sheet(workbook, tasksSheet, 'Weekly Overview');

    // Top Performers worksheet
    const performersData = [
      ['Top Performers'],
      ['Name', 'Role', 'Tasks Completed', 'Efficiency', 'Quality Score'],
      ...weeklyStats.topPerformers.map(performer => [
        performer.name,
        performer.role,
        performer.metrics.tasksCompleted,
        performer.metrics.efficiency + '%',
        performer.metrics.qualityScore
      ])
    ];

    const performersSheet = XLSX.utils.aoa_to_sheet(performersData);
    XLSX.utils.book_append_sheet(workbook, performersSheet, 'Top Performers');

    // Download file
    XLSX.writeFile(workbook, `weekly_report_w${filters.week}_${filters.year}.xlsx`);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: isDarkMode ? '#ffffff' : '#333333'
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        }
      },
      x: {
        ticks: {
          color: isDarkMode ? '#ffffff' : '#333333'
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        }
      }
    }
  };

  if (loading) {
    return <div className="loading">Memuat data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className={`weekly-report ${isDarkMode ? 'dark' : ''}`}>
      <div className="report-header">
        <h2>Laporan Mingguan</h2>
        <div className="filters-section">
          <div className="filter-group">
            <label>Dari Minggu:</label>
            <input
              type="number"
              min="1"
              max="52"
              value={filters.startWeek}
              onChange={(e) => {
                const startWeek = parseInt(e.target.value) || 1;
                if (startWeek >= 1 && startWeek <= 52) {
                  setFilters(prev => ({
                    ...prev,
                    startWeek,
                    endWeek: Math.max(startWeek, prev.endWeek)
                  }));
                }
              }}
            />
          </div>
          <div className="filter-group">
            <label>Sampai Minggu:</label>
            <input
              type="number"
              min="1"
              max="52"
              value={filters.endWeek}
              onChange={(e) => {
                const endWeek = parseInt(e.target.value) || 1;
                if (endWeek >= 1 && endWeek <= 52) {
                  setFilters(prev => ({
                    ...prev,
                    endWeek,
                    startWeek: Math.min(endWeek, prev.startWeek)
                  }));
                }
              }}
            />
          </div>
          <div className="filter-group">
            <label>Tahun:</label>
            <input
              type="number"
              min="2000"
              max="2100"
              value={filters.year}
              onChange={(e) => setFilters({ ...filters, year: parseInt(e.target.value) })}
            />
          </div>
          <button onClick={downloadExcel} className="download-btn">
            Download Excel
          </button>
        </div>
      </div>

      <div className="summary-stats">
        <div className="stat-card">
          <h3>Total Tasks</h3>
          <div className="stat-details">
            <div className="stat-row">
              <span className="stat-label">Completed:</span>
              <span className="stat-value completed">{weeklyStats.overview.totalTasks.completed}</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Created:</span>
              <span className="stat-value">{weeklyStats.overview.totalTasks.created}</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">In Progress:</span>
              <span className="stat-value in-progress">{weeklyStats.overview.totalTasks.inProgress}</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Blocked:</span>
              <span className="stat-value blocked">{weeklyStats.overview.totalTasks.blocked}</span>
            </div>
          </div>
        </div>
        <div className="stat-card">
          <h3>Productivity</h3>
          <div className="stat-details">
            <div className="stat-row">
              <span className="stat-label">This Week:</span>
              <span className="stat-value">{weeklyStats.overview.productivity.thisWeek}%</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Last Week:</span>
              <span className="stat-value">{weeklyStats.overview.productivity.lastWeek}%</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Trend:</span>
              <span className={`stat-value trend-${weeklyStats.overview.productivity.trend.toLowerCase()}`}>
                {weeklyStats.overview.productivity.trend}
              </span>
            </div>
          </div>
        </div>
        <div className="stat-card">
          <h3>Time Tracking</h3>
          <div className="stat-details">
            <div className="stat-row">
              <span className="stat-label">Total Hours:</span>
              <span className="stat-value">{weeklyStats.overview.timeTracking.totalHours}</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Billable Hours:</span>
              <span className="stat-value">{weeklyStats.overview.timeTracking.billableHours}</span>
            </div>
            <div className="stat-row">
              <span className="stat-label">Utilization:</span>
              <span className="stat-value">{weeklyStats.overview.timeTracking.utilization}%</span>
            </div>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <div className="chart-section">
          <h3>Department Task Distribution</h3>
          <div className="chart-wrapper" style={{ height: '400px' }}>
            <Bar
              data={{
                labels: weeklyStats.timeDistribution.labels,
                datasets: [{
                  label: 'Number of Tasks',
                  data: weeklyStats.timeDistribution.datasets[0].data,
                  backgroundColor: weeklyStats.timeDistribution.datasets[0].backgroundColor
                }]
              }}
              options={{
                ...chartOptions,
                indexAxis: 'y',
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: isDarkMode ? '#ffffff' : '#333333',
                      font: {
                        size: 12
                      }
                    }
                  },
                  x: {
                    beginAtZero: true,
                    grid: {
                      color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                    },
                    ticks: {
                      stepSize: 1,
                      color: isDarkMode ? '#ffffff' : '#333333'
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        return `${context.parsed.x} tasks`;
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="table-section">
        <h3>Top Performers</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Tasks Completed</th>
              <th>Efficiency</th>
              <th>Quality Score</th>
              <th>Highlights</th>
            </tr>
          </thead>
          <tbody>
            {weeklyStats.topPerformers.map(performer => (
              <tr key={performer.id}>
                <td>{performer.username}</td>
                <td>Role ID: {performer.roleId}</td>
                <td>{performer.metrics.tasksCompleted}</td>
                <td>{performer.metrics.efficiency}%</td>
                <td>{performer.metrics.qualityScore}</td>
                <td>
                  <ul>
                    {performer.highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-section">
        <h3>Key Milestones</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Due Date</th>
              <th>Progress</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {weeklyStats.keyMilestones.map(milestone => (
              <tr key={milestone.id}>
                <td>{milestone.name}</td>
                <td>{milestone.dueDate}</td>
                <td>{milestone.progress}%</td>
                <td>
                  <span className={`status-badge ${milestone.status.toLowerCase().replace(' ', '-')}`}>
                    {milestone.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeeklyReport;
