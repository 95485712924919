import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import './TrackingSettings.css';

const AssetTypeManager = () => {
  const [types, setTypes] = useState([]);
  const [newType, setNewType] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [editingType, setEditingType] = useState(null);

  useEffect(() => {
    fetchTypes();
  }, []);

  const fetchTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/asset-types');
      setTypes(response.data);
    } catch (error) {
      console.error('Error mengambil tipe asset:', error);
    }
  };

  const handleAddType = async () => {
    if (!newType.trim()) {
      alert('Nama tipe asset harus diisi');
      return;
    }

    try {
      const response = await axiosInstance.post('/api/asset-types', {
        name: newType,
        description: newDescription
      });
      
      setTypes([...types, response.data]);
      setNewType('');
      setNewDescription('');
    } catch (error) {
      console.error('Error menambah tipe asset:', error);
    }
  };

  const handleEditType = async (id, updatedData) => {
    try {
      const response = await axiosInstance.put(`/api/asset-types/${id}`, updatedData);
      setTypes(types.map(type => 
        type.id === id ? response.data : type
      ));
      setEditingType(null);
    } catch (error) {
      console.error('Error mengupdate tipe asset:', error);
    }
  };

  const handleDeleteType = async (id) => {
    if (window.confirm('Yakin ingin menghapus tipe asset ini?')) {
      try {
        await axiosInstance.delete(`/api/asset-types/${id}`);
        setTypes(types.filter(type => type.id !== id));
      } catch (error) {
        console.error('Error menghapus tipe asset:', error);
      }
    }
  };

  return (
    <div className="type-manager">
      <div className="add-type-form">
        <h3>Tambah Tipe Asset Baru</h3>
        <input
          type="text"
          value={newType}
          onChange={(e) => setNewType(e.target.value)}
          placeholder="Nama tipe asset"
        />
        <textarea
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          placeholder="Deskripsi (opsional)"
        />
        <button onClick={handleAddType}>Tambah Tipe</button>
      </div>

      <div className="type-list">
        <h3>Daftar Tipe Asset</h3>
        <table>
          <thead>
            <tr>
              <th>Nama</th>
              <th>Deskripsi</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {types.map(type => (
              <tr key={type.id}>
                {editingType?.id === type.id ? (
                  <>
                    <td>
                      <input
                        value={editingType.name}
                        onChange={e => setEditingType({
                          ...editingType,
                          name: e.target.value
                        })}
                      />
                    </td>
                    <td>
                      <textarea
                        value={editingType.description}
                        onChange={e => setEditingType({
                          ...editingType,
                          description: e.target.value
                        })}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleEditType(type.id, editingType)}>
                        Simpan
                      </button>
                      <button onClick={() => setEditingType(null)}>
                        Batal
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{type.name}</td>
                    <td>{type.description}</td>
                    <td>
                      <button onClick={() => setEditingType(type)}>
                        Edit
                      </button>
                      <button onClick={() => handleDeleteType(type.id)}>
                        Hapus
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetTypeManager; 