import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';

const TreeNode = ({ node, assets = [], tasks = [], expandedShots, toggleShot, expandedAssets, toggleAsset, sequenceId, onActivityClick, onOverviewClick }) => {
  const isShotExpanded = expandedShots[node.id];
  const hasTasks = node.selectedTasks?.length > 0;
  const hasAssets = node.selectedAssets?.length > 0;

  const renderActionButtons = (id, type) => (
    <td>
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onActivityClick(id, type);
        }}
        style={{ marginRight: '8px', padding: '4px 8px', cursor: 'pointer' }}
      >
        Activity
      </button>
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onOverviewClick(id, type);
        }}
        style={{ padding: '4px 8px', cursor: 'pointer' }}
      >
        Overview
      </button>
    </td>
  );

  return (
    <>
      <tr style={{ backgroundColor: 'rgba(0, 255, 0, 0.3)' }}>
        <td style={{ paddingLeft: '40px' }}>
          <span 
            onClick={() => toggleShot(node.id)} 
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            {(hasTasks || hasAssets) && (
              <span style={{ marginRight: '8px' }}>
                {isShotExpanded ? '▼' : '▶'}
              </span>
            )}
            {node.shotName}
          </span>
        </td>
        <td>{node.status ? node.status : 'No Status'}</td>
        <td>{node.dueDate ? new Date(node.dueDate).toLocaleDateString() : 'No Due Date'}</td>
        {renderActionButtons(node.id, 'shot')}
      </tr>
      
      {isShotExpanded && (
        <>
          {node.selectedTasks?.map(taskId => {
            const task = tasks.find(t => t.id === taskId);
            return (
              <tr key={taskId} style={{ backgroundColor: 'rgba(255, 255, 0, 0.3)' }}>
                <td style={{ paddingLeft: '60px' }}>{task ? task.taskName : `Task ID: ${taskId}`}</td>
                <td>{task ? task.taskStatus.name : 'No Status'}</td>
                <td>{task ? (task.dueDate ? new Date(task.dueDate).toLocaleDateString() : 'No Due Date') : 'No Due Date'}</td>
                {renderActionButtons(taskId, 'task')}
              </tr>
            );
          })}

          {node.selectedAssets?.map(assetId => {
            const asset = assets.find(a => a.id === assetId);
            const isAssetExpanded = expandedAssets[sequenceId]?.[node.id]?.[assetId];
            const hasAssetTasks = asset?.selectedTasks?.length > 0;

            return (
              <React.Fragment key={`${sequenceId}-${node.id}-${assetId}`}>
                <tr style={{ backgroundColor: 'rgba(0, 0, 255, 0.3)' }}>
                  <td style={{ paddingLeft: '60px' }}>
                    <span 
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleAsset(sequenceId, node.id, assetId);
                      }} 
                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    >
                      {hasAssetTasks && (
                        <span style={{ marginRight: '8px' }}>
                          {isAssetExpanded ? '▼' : '▶'}
                        </span>
                      )}
                      {asset ? asset.assetName : `Asset ID: ${assetId}`}
                    </span>
                  </td>
                  <td>{asset ? asset.assetStatus.name : 'No Status'}</td>
                  <td>{asset ? (asset.dueDate ? new Date(asset.dueDate).toLocaleDateString() : 'No Due Date') : 'No Due Date'}</td>
                  {renderActionButtons(assetId, 'asset')}
                </tr>
                
                {isAssetExpanded && asset?.selectedTasks?.map(taskId => {
                  const task = tasks.find(t => t.id === taskId);
                  return (
                    <tr key={`${sequenceId}-${node.id}-${assetId}-${taskId}`} style={{ backgroundColor: 'rgba(255, 255, 0, 0.3)' }}>
                      <td style={{ paddingLeft: '80px' }}>{task ? task.taskName : `Task ID: ${taskId}`}</td>
                      <td>{task ? task.taskStatus.name : 'No Status'}</td>
                      <td>{task ? (task.dueDate ? new Date(task.dueDate).toLocaleDateString() : 'No Due Date') : 'No Due Date'}</td>
                      {renderActionButtons(taskId, 'task')}
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

const ShotTreeView = ({ shots, assets, tasks, currentUser }) => {
  const [expandedSequences, setExpandedSequences] = useState({});
  const [expandedShots, setExpandedShots] = useState({});
  const [expandedAssets, setExpandedAssets] = useState({});
  const [isActivityPanelOpen, setIsActivityPanelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemType, setItemType] = useState(null);
  const navigate = useNavigate();

  const toggleSequence = (id) => {
    setExpandedSequences((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleShot = (id) => {
    setExpandedShots((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleAsset = (sequenceId, shotId, assetId) => {
    setExpandedAssets(prev => ({
      ...prev,
      [sequenceId]: {
        ...prev[sequenceId],
        [shotId]: {
          ...prev[sequenceId]?.[shotId],
          [assetId]: !prev[sequenceId]?.[shotId]?.[assetId]
        }
      }
    }));
  };

  const handleActivityClick = (id, type) => {
    let item;
    switch(type) {
      case 'shot':
        item = shots.find(shot => shot.id === id);
        break;
      case 'task':
        item = tasks.find(task => task.id === id);
        break;
      case 'asset':
        item = assets.find(asset => asset.id === id);
        break;
      default:
        console.error('Invalid type for activity:', type);
        return;
    }

    if (item) {
      setSelectedItem(item);
      setItemType(type);
      setIsActivityPanelOpen(true);
    } else {
      console.error(`Item with id ${id} and type ${type} not found`);
    }
  };

  const handleCloseActivityPanel = () => {
    setIsActivityPanelOpen(false);
    setSelectedItem(null);
    setItemType(null);
  };

  const handleOverviewClick = (id, type) => {
    switch(type) {
      case 'shot':
        navigate(`/shot-overview/${id}`);
        break;
      case 'task':
        navigate(`/task-overview/${id}`);
        break;
      case 'asset':
        navigate(`/asset-overview/${id}`);
        break;
      default:
        console.error('Invalid type for overview:', type);
    }
  };

  console.log('Shots data:', shots);
  console.log('Assets data:', assets);
  console.log('Tasks data:', tasks);

  // Mengelompokkan shots berdasarkan sequenceId
  const groupedShots = shots.reduce((acc, shot) => {
    const sequenceId = shot.sequenceId || 'Ungrouped';
    if (!acc[sequenceId]) {
      acc[sequenceId] = [];
    }
    acc[sequenceId].push({
      ...shot,
      selectedAssets: shot.selectedAssets || [],
      selectedTasks: shot.selectedTasks || [],
      sequenceName: shot.sequence ? shot.sequence.sequenceName : 'No Sequence'
    });
    return acc;
  }, {});

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flex: 1, overflow: 'auto', marginRight: isActivityPanelOpen ? '20px' : '0' }}>
      <div className="global-header">
        <h2>Shot Tree View</h2>
        </div>
        <div className="card mt-4">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Due Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedShots).map(sequenceId => (
              <React.Fragment key={sequenceId}>
                <tr style={{ backgroundColor: 'rgba(255, 0, 0, 0.3)' }}>
                  <td colSpan="4">
                    <span onClick={() => toggleSequence(sequenceId)}>
                      <strong>Sequence: {groupedShots[sequenceId][0].sequenceName}</strong>
                    </span>
                  </td>
                </tr>
                {expandedSequences[sequenceId] && groupedShots[sequenceId].map(shot => (
                  <TreeNode 
                    key={shot.id} 
                    node={shot} 
                    assets={assets} 
                    tasks={tasks} 
                    expandedShots={expandedShots} 
                    toggleShot={toggleShot} 
                    expandedAssets={expandedAssets} 
                    toggleAsset={toggleAsset} 
                    sequenceId={sequenceId}
                    onActivityClick={handleActivityClick}
                    onOverviewClick={handleOverviewClick}
                  />
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      </div>
      {isActivityPanelOpen && selectedItem && (
        <div style={{ width: '400px', height: '100%', overflow: 'auto' }}>
          <ActivityPanel
            item={selectedItem}
            type={itemType}
            isOpen={isActivityPanelOpen}
            onClose={handleCloseActivityPanel}
            currentUser={currentUser}
          />
        </div>
      )}
    </div>
  );
};

export default ShotTreeView;