import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';

const getFullThumbnailUrl = (thumbnailUrl) => {
  if (!thumbnailUrl) return '/assets/images/shot-placeholder.png';
  if (thumbnailUrl.startsWith('http')) return thumbnailUrl;
  if (thumbnailUrl.startsWith('data:')) return thumbnailUrl;
  const cleanUrl = thumbnailUrl.replace(/^\/+/, '');
  return `${API_BASE_URL}/${cleanUrl}`;
};

const ShotForm = ({ 
  onSubmit, 
  onClose, 
  sequences = [], 
  assets = [], 
  tasks = [], 
  isEditing = false, 
  initialShot = null, 
  currentUser,
  isDarkMode,
  activeProject,
  setSequences,
  setShots
}) => {
  console.log('ShotForm props:', { currentUser, activeProject, sequences });

  const [shotName, setShotName] = useState(initialShot?.shotName || '');
  const [description, setDescription] = useState(initialShot?.description || '');
  const [statusId, setStatusId] = useState(initialShot?.statusId || '');
  const [dueDate, setDueDate] = useState(initialShot?.dueDate ? new Date(initialShot.dueDate).toISOString().split('T')[0] : '');
  const [start_date, setStartDate] = useState(initialShot?.start_date ? new Date(initialShot.start_date).toISOString().split('T')[0] : '');
  const [statuses, setStatuses] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(initialShot?.thumbnailUrl ? getFullThumbnailUrl(initialShot.thumbnailUrl) : null);
  
  const [selectedSequence, setSelectedSequence] = useState(
    initialShot?.sequence ? {
      value: initialShot.sequence.id,
      label: initialShot.sequence.sequenceName
    } : null
  );

  const [selectedAssets, setSelectedAssets] = useState(
    initialShot?.selectedAssets?.map(assetId => {
      const asset = assets.find(a => a.id === assetId);
      return asset ? { value: asset.id, label: asset.assetName } : null;
    }).filter(Boolean) || []
  );

  const [selectedTasks, setSelectedTasks] = useState(
    initialShot?.selectedTasks?.map(taskId => {
      const task = tasks.find(t => t.id === taskId);
      return task ? { value: task.id, label: task.taskName } : null;
    }).filter(Boolean) || []
  );

  const [inFrame, setInFrame] = useState(initialShot?.in_frame || null);
  const [outFrame, setOutFrame] = useState(initialShot?.out_frame || null);

  const totalFrame = outFrame && inFrame ? outFrame - inFrame + 1 : null;

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/shot-statuses');
        setStatuses(response.data);
        if (!initialShot && response.data.length > 0) {
          const defaultStatus = response.data.find(s => s.isDefault) || response.data[0];
          setStatusId(defaultStatus.id);
        }
      } catch (error) {
        console.error('Error mengambil status:', error);
      }
    };
    fetchStatuses();
  }, [initialShot]);

  const prepareThumbnailForUpload = (thumbnailFile) => {
    // Validasi ukuran file
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

    if (!thumbnailFile) {
      console.log('Tidak ada file thumbnail yang dipilih');
      return null;
    }

    // Cek tipe file
    if (!ALLOWED_TYPES.includes(thumbnailFile.type)) {
      console.error('Tipe file tidak diizinkan. Hanya JPEG, PNG, dan GIF yang diperbolehkan.');
      alert('Hanya file gambar JPEG, PNG, dan GIF yang diperbolehkan.');
      return null;
    }

    // Cek ukuran file
    if (thumbnailFile.size > MAX_FILE_SIZE) {
      console.error('Ukuran file terlalu besar. Maksimal 10MB.');
      alert('Ukuran file terlalu besar. Maksimal 10MB.');
      return null;
    }

    return thumbnailFile;
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveThumbnail = () => {
    setThumbnail(null);
    setThumbnailPreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shotName.trim()) {
      alert('Shot name is required');
      return;
    }

    if (!statusId) {
      alert('Status is required');
      return;
    }

    if (!activeProject || !activeProject.id) {
      console.error('No active project selected', activeProject);
      alert('Please select an active project before creating a shot');
      return;
    }

    const formData = new FormData();
    
    formData.append('shotName', String(shotName));
    formData.append('description', String(description || ''));
    formData.append('statusId', String(statusId));
    formData.append('projectId', String(activeProject.id));
    
    if (selectedSequence) {
      formData.append('sequenceId', String(selectedSequence.value));
    }
    
    if (dueDate) {
      formData.append('dueDate', String(dueDate));
    }

    if (start_date) {
      formData.append('start_date', String(start_date));
    }

    if (inFrame !== null) {
      formData.append('in_frame', String(inFrame));
    }
    if (outFrame !== null) {
      formData.append('out_frame', String(outFrame));
    }

    // Proses thumbnail dengan validasi
    const validatedThumbnail = prepareThumbnailForUpload(thumbnail);
    if (validatedThumbnail) {
      formData.append('thumbnail', validatedThumbnail);
      console.log('Thumbnail siap diunggah:', {
        name: validatedThumbnail.name,
        type: validatedThumbnail.type,
        size: validatedThumbnail.size
      });
    } else if (initialShot?.thumbnailUrl) {
      // Jika tidak ada thumbnail baru, tetapi ada thumbnail lama
      console.log('Menggunakan thumbnail lama:', initialShot.thumbnailUrl);
    }

    if (selectedAssets && selectedAssets.length > 0) {
      formData.append('selectedAssets', JSON.stringify(selectedAssets.map(asset => asset.value)));
    } else {
      formData.append('selectedAssets', JSON.stringify([]));
    }

    if (selectedTasks && selectedTasks.length > 0) {
      formData.append('selectedTasks', JSON.stringify(selectedTasks.map(task => task.value)));
    } else {
      formData.append('selectedTasks', JSON.stringify([]));
    }

    try {
      const result = await onSubmit(formData);
      console.log('Shot submit result:', result);
      
      if (setShots && result) {
        setShots(prevShots => {
          const updatedShots = isEditing
            ? prevShots.map(shot => shot.id === result.id ? result : shot)
            : [...prevShots, result];
          
          console.log('Updated shots:', updatedShots);
          return updatedShots;
        });
      }

      if (setSequences && result) {
        const newSequenceId = selectedSequence?.value;
        const oldSequenceId = initialShot?.sequenceId;
        
        setSequences(prevSequences => {
          const updatedSequences = prevSequences.map(seq => {
            if (seq.id === newSequenceId) {
              const existingShots = seq.shots || [];
              const shotExists = existingShots.some(s => s.id === result.id);
              
              return {
                ...seq,
                shots: shotExists
                  ? existingShots.map(s => s.id === result.id ? result : s)
                  : [...existingShots, result]
              };
            }
            
            if (isEditing && oldSequenceId && seq.id === oldSequenceId && oldSequenceId !== newSequenceId) {
              return {
                ...seq,
                shots: (seq.shots || []).filter(s => s.id !== result.id)
              };
            }
            
            return seq;
          });
          
          console.log('Updated sequences:', updatedSequences);
          return updatedSequences;
        });
      }
      
      onClose();
    } catch (error) {
      console.error('Error submitting shot:', error);
      console.error('Error details:', error.response?.data);
      alert('Failed to save shot. Please try again.');
    }
  };

  const sequenceOptions = sequences.map(sequence => ({
    value: sequence.id,
    label: sequence.sequenceName
  }));

  const assetOptions = assets.map(asset => ({
    value: asset.id,
    label: asset.assetName
  }));

  const taskOptions = tasks.map(task => ({
    value: task.id,
    label: task.taskName
  }));

  return (
    <form onSubmit={handleSubmit} className={`form-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="form-header">
        <h2>{isEditing ? 'Edit Shot' : 'Add New Shot'}</h2>
        <button type="button" onClick={onClose} className="close-button">&times;</button>
      </div>

      <div className="form-body">
        <div className="form-group">
          <label htmlFor="shotName">Shot Name</label>
          <input
            type="text"
            id="shotName"
            value={shotName}
            onChange={(e) => setShotName(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="sequence">Sequence</label>
          <Select
            id="sequence"
            value={selectedSequence}
            onChange={setSelectedSequence}
            options={sequenceOptions}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>

        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            value={statusId}
            onChange={(e) => setStatusId(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select Status</option>
            {statuses.map(status => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>In Frame</label>
          <input
            type="number"
            className="form-control"
            value={inFrame || ''}
            onChange={(e) => setInFrame(Number(e.target.value))}
          />
        </div>

        <div className="form-group">
          <label>Out Frame</label>
          <input
            type="number"
            className="form-control"
            value={outFrame || ''}
            onChange={(e) => setOutFrame(Number(e.target.value))}
          />
        </div>

        <div className="form-group">
          <label>Total Frame</label>
          <input
            type="number"
            className="form-control"
            value={totalFrame || ''}
            readOnly
          />
        </div>

        <div className="form-group">
          <label htmlFor="dueDate">Due Date</label>
          <input
            type="date"
            id="dueDate"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="start_date">Start Date</label>
          <input
            type="date"
            id="start_date"
            value={start_date}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Thumbnail</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleThumbnailChange}
            className="form-control"
          />
          {thumbnailPreview && (
            <div className="thumbnail-preview-container">
              <img 
                src={getFullThumbnailUrl(thumbnailPreview)} 
                alt="Shot Thumbnail Preview" 
                className="thumbnail-preview" 
              />
              <button 
                type="button" 
                className="remove-thumbnail-btn" 
                onClick={handleRemoveThumbnail}
              >
                Remove Thumbnail
              </button>
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="assets">Assets</label>
          <Select
            id="assets"
            isMulti
            value={selectedAssets}
            onChange={setSelectedAssets}
            options={assetOptions}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>

        <div className="form-group">
          <label htmlFor="tasks">Tasks</label>
          <Select
            id="tasks"
            isMulti
            value={selectedTasks}
            onChange={setSelectedTasks}
            options={taskOptions}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>

      <div className="form-footer">
        <button type="submit" className="btn btn-primary">
          {isEditing ? 'Update Shot' : 'Create Shot'}
        </button>
        <button type="button" onClick={onClose} className="btn btn-secondary">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ShotForm;