import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import '../styles/globalform.css';
import { WarningIcon } from '@chakra-ui/icons';

const IssueForm = ({ 
  isOpen, 
  onClose, 
  itemType, 
  itemId,
  itemName,
  onIssueCreated,
  isDarkMode,
  editMode = false,
  issueData = null
}) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'MEDIUM',
    category: 'BUG',
    status: 'OPEN'
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editMode && issueData) {
      setFormData({
        title: issueData.title || '',
        description: issueData.description || '',
        priority: issueData.priority || 'MEDIUM',
        category: issueData.category || 'BUG',
        status: issueData.status || 'OPEN'
      });
    }
  }, [editMode, issueData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title || !formData.description) {
      alert('Please fill in all required fields');
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      let relatedData = {};
      switch (itemType?.toUpperCase()) {
        case 'TASK':
          relatedData = { relatedTaskId: itemId };
          break;
        case 'SHOT':
          relatedData = { relatedShotId: itemId };
          break;
        case 'SEQUENCE':
          relatedData = { relatedSequenceId: itemId };
          break;
        case 'ASSET':
          relatedData = { relatedAssetId: itemId };
          break;
        default:
          break;
      }

      if (editMode && issueData) {
        const response = await axiosInstance.put(`/api/issues/${issueData.id}`, {
          ...formData
        });

        if (response.data.success) {
          onIssueCreated && onIssueCreated(response.data.data);
          onClose();
        } else {
          throw new Error(response.data.message || 'Failed to update issue');
        }
      } else {
        const response = await axiosInstance.post('/api/issues', {
          ...formData,
          ...relatedData,
          type: itemType?.toUpperCase()
        });

        if (response.data.success) {
          const activityResponse = await axiosInstance.post('/api/activities', {
            [`${itemType?.toLowerCase()}Id`]: itemId,
            action: 'ISSUE_CREATED',
            details: `Reported issue: ${formData.title}`,
            user: localStorage.getItem('username') || 'Anonymous',
            data: {
              issueId: response.data.data.id,
              issueDetails: {
                status: response.data.data.status,
                priority: response.data.data.priority,
                category: response.data.data.category
              },
              itemType,
              itemId,
              itemName
            }
          });

          onIssueCreated && onIssueCreated(response.data.data, activityResponse.data);
          onClose();
        } else {
          throw new Error(response.data.message || 'Failed to create issue');
        }
      }

      setFormData({
        title: '',
        description: '',
        priority: 'MEDIUM',
        category: 'BUG',
        status: 'OPEN'
      });
    } catch (error) {
      console.error(editMode ? 'Error updating issue:' : 'Error creating issue:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal-form ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="form-header">
          <div className="form-title">
            <WarningIcon color="red.500" />
            <h2>{editMode ? 'Edit Issue' : 'Report Issue'}</h2>
          </div>
          {itemType && itemName && (
            <div className="form-subtitle">
              {itemType}: {itemName}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title" className="required">Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
              placeholder="Enter issue title"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description" className="required">Description</label>
            <textarea
              id="description"
              className="form-control"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
              placeholder="Describe the issue in detail"
              rows={4}
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="priority">Priority</label>
              <select
                id="priority"
                className="form-control"
                value={formData.priority}
                onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
              >
                <option value="LOW">Low</option>
                <option value="MEDIUM">Medium</option>
                <option value="HIGH">High</option>
                <option value="CRITICAL">Critical</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="category">Category</label>
              <select
                id="category"
                className="form-control"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              >
                <option value="BUG">Bug</option>
                <option value="FEATURE">Feature</option>
                <option value="IMPROVEMENT">Improvement</option>
                <option value="TECHNICAL_DEBT">Technical Debt</option>
              </select>
            </div>
          </div>

          {editMode && (
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                className="form-control"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              >
                <option value="OPEN">Open</option>
                <option value="IN_PROGRESS">In Progress</option>
                <option value="RESOLVED">Resolved</option>
                <option value="CLOSED">Closed</option>
              </select>
            </div>
          )}

          <div className="form-actions">
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? 'Loading...' : (editMode ? 'Update Issue' : 'Create Issue')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IssueForm;
