import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  NodeResizer,
  ConnectionLineType,
  MarkerType,
  Handle,
  Position
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { 
  FaPlus, 
  FaEllipsisH, 
  FaShare, 
  FaEdit, 
  FaSearch,
  FaRegClone,
  FaRegCommentDots,
  FaTable,
  FaChartBar,
  FaRegFileAlt,
  FaCog,
  FaSave,
  FaTimes,
  FaTrash,
  FaFilter,
  FaDownload,
  FaUpload,
  FaTimesCircle,
  FaClipboardList,
  FaListAlt,
  FaColumns,
  FaChartPie,
  FaCalendarAlt,
  FaProjectDiagram,
  FaCode,
  FaTextWidth,
  FaFolder,
  FaHistory,
  FaChevronDown
} from 'react-icons/fa';
import { useDarkMode } from '../../App';
import './Board.css';

// Utility function for auto-layout
const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 250;
  const nodeHeight = 150;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return {
    nodes: nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        },
      };
    }),
    edges,
  };
};

// Custom Node Component
const TaskNode = ({ id, data, isConnectable, onEdit, isEditingMode, selected }) => {
  const [isInlineEditing, setIsInlineEditing] = useState(false);
  const [editedLabel, setEditedLabel] = useState(data.label || '');
  const [editedDescription, setEditedDescription] = useState(data.description || '');

  const handleDoubleClick = () => {
    if (isEditingMode) {
      setIsInlineEditing(true);
      // Reset edited values to current data
      setEditedLabel(data.label || '');
      setEditedDescription(data.description || '');
    }
  };

  const handleSave = () => {
    console.log('Saving TaskNode:', {
      id,
      originalData: data,
      editedLabel,
      editedDescription
    });

    const updatedData = {
      id,  // Gunakan id yang diterima sebagai prop
      data: {
        ...data,
        label: editedLabel || data.label,
        description: editedDescription || data.description
      }
    };

    onEdit(updatedData);
    setIsInlineEditing(false);
  };

  const handleCancel = () => {
    setEditedLabel(data.label || '');
    setEditedDescription(data.description || '');
    setIsInlineEditing(false);
  };

  // Tambahkan useEffect untuk memperbarui state jika data berubah
  useEffect(() => {
    setEditedLabel(data.label || '');
    setEditedDescription(data.description || '');
  }, [data.label, data.description]);

  return (
    <div 
      className={`task-node ${data.type} ${selected ? 'selected' : ''} ${isEditingMode ? 'editing-mode' : ''}`}
      data-id={id}
      onDoubleClick={handleDoubleClick}
    >
      <NodeResizer isVisible={isEditingMode} />
      {isInlineEditing ? (
        <div className="inline-edit-container">
          <input 
            type="text" 
            value={editedLabel}
            onChange={(e) => setEditedLabel(e.target.value)}
            placeholder="Node Title"
            className="inline-edit-input"
          />
          <textarea 
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            placeholder="Node Description"
            className="inline-edit-textarea"
          />
          <div className="inline-edit-actions">
            <button onClick={handleSave} className="save-btn">
              <FaSave /> Save
            </button>
            <button onClick={handleCancel} className="cancel-btn">
              <FaTimes /> Cancel
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="task-node-header">
            <span>{data.label}</span>
            {isEditingMode && (
              <div className="node-actions">
                <button 
                  className="node-edit-btn" 
                  onClick={() => onEdit(data)}
                >
                  <FaEdit />
                </button>
                <button className="node-menu-btn">
                  <FaEllipsisH />
                </button>
              </div>
            )}
          </div>
          <div className="task-node-content">
            {data.description}
          </div>
          <div className="task-node-footer">
            {data.assignee && (
              <div className="task-assignee">
                {data.assignee}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const TextNode = ({ id, data, isConnectable, isEditingMode, selected, onEdit }) => {
  const [isInlineEditing, setIsInlineEditing] = useState(false);
  const [editedLabel, setEditedLabel] = useState(data.label || '');

  const handleDoubleClick = () => {
    if (isEditingMode) {
      setIsInlineEditing(true);
      // Reset edited value to current data
      setEditedLabel(data.label || '');
    }
  };

  const handleSave = () => {
    console.log('Saving TextNode:', {
      id,
      originalData: data,
      editedLabel: editedLabel
    });

    // Pastikan ada label yang akan disave
    const updatedData = {
      id,  // Gunakan id yang diterima sebagai prop
      data: {
        ...data,
        label: editedLabel || data.label
      }
    };

    onEdit(updatedData);
    setIsInlineEditing(false);
  };

  const handleCancel = () => {
    setEditedLabel(data.label || '');
    setIsInlineEditing(false);
  };

  // Tambahkan useEffect untuk memperbarui state jika data berubah
  useEffect(() => {
    setEditedLabel(data.label || '');
  }, [data.label]);

  return (
    <div 
      className={`custom-node text-node ${selected ? 'selected' : ''} ${isEditingMode ? 'editing-mode' : ''}`}
      data-id={id}
      onDoubleClick={handleDoubleClick}
    >
      <Handle type="target" position={Position.Left} />
      {isInlineEditing ? (
        <div className="inline-edit-container">
          <input 
            type="text" 
            value={editedLabel}
            onChange={(e) => setEditedLabel(e.target.value)}
            placeholder="Text Node Content"
            className="inline-edit-input"
            onKeyDown={(e) => {
              // Tambahkan save dengan Enter
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
          />
          <div className="inline-edit-actions">
            <button onClick={handleSave} className="save-btn">
              <FaSave /> Save
            </button>
            <button onClick={handleCancel} className="cancel-btn">
              <FaTimes /> Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="node-content">
          {data.label || 'Text Node'}
        </div>
      )}
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

const CodeNode = ({ id, data, isConnectable, isEditingMode, selected, onEdit }) => {
  const [isInlineEditing, setIsInlineEditing] = useState(false);
  const [editedCode, setEditedCode] = useState(data.code || '');

  const handleDoubleClick = () => {
    if (isEditingMode) {
      setIsInlineEditing(true);
      // Reset edited value to current data
      setEditedCode(data.code || '');
    }
  };

  const handleSave = () => {
    console.log('Saving CodeNode:', {
      id,
      originalData: data,
      editedCode: editedCode
    });

    const updatedData = {
      id,  // Gunakan id yang diterima sebagai prop
      data: {
        ...data,
        code: editedCode || data.code
      }
    };

    onEdit(updatedData);
    setIsInlineEditing(false);
  };

  const handleCancel = () => {
    setEditedCode(data.code || '');
    setIsInlineEditing(false);
  };

  // Tambahkan useEffect untuk memperbarui state jika data berubah
  useEffect(() => {
    setEditedCode(data.code || '');
  }, [data.code]);

  return (
    <div 
      className={`custom-node code-node ${selected ? 'selected' : ''} ${isEditingMode ? 'editing-mode' : ''}`}
      data-id={id}
      onDoubleClick={handleDoubleClick}
    >
      <Handle type="target" position={Position.Left} />
      {isInlineEditing ? (
        <div className="inline-edit-container">
          <textarea 
            value={editedCode}
            onChange={(e) => setEditedCode(e.target.value)}
            placeholder="Code Node Content"
            className="inline-edit-textarea code-edit"
          />
          <div className="inline-edit-actions">
            <button onClick={handleSave} className="save-btn">
              <FaSave /> Save
            </button>
            <button onClick={handleCancel} className="cancel-btn">
              <FaTimes /> Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="node-content">
          <pre>{data.code || '// Code Node'}</pre>
        </div>
      )}
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

const BoardActionDropdown = ({ 
  isOpen, 
  onClose, 
  onSave, 
  onOpen, 
  onRecentBoards, 
  position 
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div 
      ref={dropdownRef} 
      className="board-action-dropdown"
      style={{
        position: 'absolute',
        top: position.y + 40,
        left: position.x,
        zIndex: 1000
      }}
    >
      <div className="dropdown-item" onClick={onSave}>
        <FaSave /> Save Board
      </div>
      <div className="dropdown-item" onClick={onOpen}>
        <FaFolder /> Open Board
      </div>
      <div className="dropdown-item" onClick={onRecentBoards}>
        <FaHistory /> Recent Boards
      </div>
    </div>
  );
};

const Board = () => {
  const { isDarkMode } = useDarkMode();
  const reactFlowWrapper = useRef(null);
  const fileInputRef = useRef(null);
  const searchInputRef = useRef(null);
  const plusButtonRef = useRef(null);
  const sidebarPlusButtonRef = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(() => {
    const savedNodes = localStorage.getItem('boardNodes');
    return savedNodes ? JSON.parse(savedNodes) : [
      {
        id: '1',
        type: 'task',
        position: { x: 0, y: 0 },
        data: { 
          label: 'Initial Task', 
          description: 'First task in the board',
          type: 'todo',
          assignee: 'John Doe'
        }
      }
    ];
  });

  const [edges, setEdges, onEdgesChange] = useEdgesState(() => {
    const savedEdges = localStorage.getItem('boardEdges');
    return savedEdges ? JSON.parse(savedEdges) : [];
  });

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [editingNode, setEditingNode] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPlusMenuOpen, setIsPlusMenuOpen] = useState(false);
  const [plusMenuPosition, setPlusMenuPosition] = useState({ x: 0, y: 0 });
  const [isSidebarPlusMenuOpen, setIsSidebarPlusMenuOpen] = useState(false);
  const [sidebarPlusMenuPosition, setSidebarPlusMenuPosition] = useState({ x: 0, y: 0 });

  // Board Action Dropdown State
  const [isBoardActionDropdownOpen, setIsBoardActionDropdownOpen] = useState(false);
  const [boardActionDropdownPosition, setBoardActionDropdownPosition] = useState({ x: 0, y: 0 });

  // State untuk menyimpan daftar board
  const [savedBoards, setSavedBoards] = useState([]);
  const [isOpenBoardModalOpen, setIsOpenBoardModalOpen] = useState(false);
  const [isRecentBoardsModalOpen, setIsRecentBoardsModalOpen] = useState(false);

  const handleEditNode = useCallback((updatedNode) => {
    console.log('Editing Node:', {
      updatedNode,
      type: typeof updatedNode
    });

    setNodes((nds) => 
      nds.map(node => {
        // Cari node yang sesuai
        if (node.id === updatedNode.id) {
          console.log('Matched Node:', node);
          
          // Jika updatedNode adalah objek dengan properti data
          if (updatedNode.data) {
            return {
              ...node,
              data: {
                ...node.data,
                ...updatedNode.data
              }
            };
          }
          
          // Jika updatedNode adalah objek data langsung
          return {
            ...node,
            data: {
              ...node.data,
              ...updatedNode
            }
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const nodeTypes = useMemo(() => ({
    task: (props) => (
      <TaskNode 
        {...props} 
        onEdit={handleEditNode}
        isEditingMode={isEditingMode}
      />
    ),
    text: (props) => (
      <TextNode 
        {...props}
        onEdit={handleEditNode}
        isEditingMode={isEditingMode}
      />
    ),
    code: (props) => (
      <CodeNode 
        {...props}
        onEdit={handleEditNode}
        isEditingMode={isEditingMode}
      />
    )
  }), [isEditingMode, handleEditNode]);

  // Save nodes and edges to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('boardNodes', JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    localStorage.setItem('boardEdges', JSON.stringify(edges));
  }, [edges]);

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge({
      ...params, 
      type: ConnectionLineType.Bezier,
      markerEnd: { type: MarkerType.ArrowClosed }
    }, eds));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: `node_${Date.now()}`,
        type,
        position,
        data: { 
          label: 'New Task', 
          description: 'Task description',
          type: 'todo',
          assignee: ''
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const addNode = () => {
    const newNode = {
      id: `node_${Date.now()}`,
      type: 'task',
      position: { 
        x: Math.random() * 500, 
        y: Math.random() * 500 
      },
      data: { 
        label: 'New Task', 
        description: 'Task description',
        type: 'todo',
        assignee: ''
      },
    };

    setNodes((nds) => nds.concat(newNode));
  };

  const handleDeleteNode = useCallback((nodeId) => {
    if (!isEditingMode) return;

    setNodes((nds) => {
      const updatedNodes = nds.filter(node => node.id !== nodeId);
      
      localStorage.setItem('boardNodes', JSON.stringify(updatedNodes));
      
      return updatedNodes;
    });
    
    setEdges((eds) => {
      const updatedEdges = eds.filter(
        edge => edge.source !== nodeId && edge.target !== nodeId
      );
      
      localStorage.setItem('boardEdges', JSON.stringify(updatedEdges));
      
      return updatedEdges;
    });

    if (nodes.length <= 1) {
      setIsEditingMode(false);
    }
  }, [nodes, isEditingMode]);

  const updateNode = useCallback(() => {
    if (editingNode) {
      setNodes((nds) => 
        nds.map((node) => 
          node.id === editingNode.id 
            ? { ...node, data: editingNode } 
            : node
        )
      );
      setEditingNode(null);
    }
  }, [editingNode]);

  const handleAutoLayout = useCallback(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [nodes, edges]);

  const handleExportBoard = useCallback(() => {
    const boardData = {
      nodes,
      edges
    };
    const dataStr = JSON.stringify(boardData, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    
    const exportFileDefaultName = 'board_export.json';
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }, [nodes, edges]);

  const handleImportBoard = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const boardData = JSON.parse(e.target.result);
          setNodes(boardData.nodes);
          setEdges(boardData.edges);
        } catch (error) {
          console.error('Error importing board:', error);
          alert('Invalid board file');
        }
      };
      reader.readAsText(file);
    }
  }, []);

  const handleSearchNodes = useCallback(() => {
    setIsSearchActive(true);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    setIsSearchActive(false);
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, []);

  const filteredNodes = useMemo(() => {
    let result = nodes;

    if (filterStatus) {
      result = result.filter(node => node.data.type === filterStatus);
    }

    if (isSearchActive && searchQuery) {
      const lowercaseQuery = searchQuery.toLowerCase();
      result = result.filter(node => 
        node.data.label.toLowerCase().includes(lowercaseQuery) ||
        node.data.description.toLowerCase().includes(lowercaseQuery) ||
        node.data.assignee?.toLowerCase().includes(lowercaseQuery)
      );
    }

    return result;
  }, [nodes, filterStatus, searchQuery, isSearchActive]);

  const handleToggleEditingMode = useCallback(() => {
    setIsEditingMode(prev => !prev);
  }, []);

  const handleShareBoard = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const handleCopyBoardLink = useCallback(() => {
    const boardLink = window.location.href;
    navigator.clipboard.writeText(boardLink).then(() => {
      alert('Board link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }, []);

  const handleAddTask = useCallback(() => {
    const newNode = {
      id: `node_${Date.now()}`,
      type: 'task',
      position: { 
        x: Math.random() * 500, 
        y: Math.random() * 500 
      },
      data: { 
        label: 'New Task', 
        description: 'Task description',
        type: 'todo',
        assignee: ''
      },
    };

    setNodes((nds) => nds.concat(newNode));
  }, []);

  const handleAddTaskFromSidebar = useCallback((event) => {
    event.dataTransfer.setData('application/reactflow', 'task');
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const handlePlusButtonClick = useCallback((event) => {
    if (plusButtonRef.current) {
      const rect = plusButtonRef.current.getBoundingClientRect();
      setPlusMenuPosition({
        x: rect.left,
        y: rect.bottom
      });
      setIsPlusMenuOpen(prev => !prev);
    }
  }, []);

  const closePlusMenu = useCallback((event) => {
    if (event.target.closest('.plus-menu') === null && 
        event.target !== plusButtonRef.current) {
      setIsPlusMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closePlusMenu);
    return () => {
      document.removeEventListener('click', closePlusMenu);
    };
  }, [closePlusMenu]);

  const handleAddTaskType = useCallback((type) => {
    const newNode = {
      id: `node_${Date.now()}`,
      type: 'task',
      position: { 
        x: Math.random() * 500, 
        y: Math.random() * 500 
      },
      data: { 
        label: `New ${type} Task`, 
        description: `${type} task description`,
        type: type,
        assignee: ''
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setIsPlusMenuOpen(false);
  }, []);

  const handleSidebarPlusButtonClick = useCallback((event) => {
    if (sidebarPlusButtonRef.current) {
      const rect = sidebarPlusButtonRef.current.getBoundingClientRect();
      setSidebarPlusMenuPosition({
        x: rect.right,
        y: rect.top
      });
      setIsSidebarPlusMenuOpen(prev => !prev);
    }
  }, []);

  const closeSidebarPlusMenu = useCallback((event) => {
    if (event.target.closest('.sidebar-plus-menu') === null && 
        event.target !== sidebarPlusButtonRef.current) {
      setIsSidebarPlusMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeSidebarPlusMenu);
    return () => {
      document.removeEventListener('click', closeSidebarPlusMenu);
    };
  }, [closeSidebarPlusMenu]);

  const handleAddElement = useCallback((type) => {
    const newNode = {
      id: `node_${Date.now()}`,
      type: type,
      position: { 
        x: Math.random() * 500, 
        y: Math.random() * 500 
      },
      data: type === 'task' ? { 
        label: 'New Task', 
        description: 'Task description',
        type: 'todo',
        assignee: ''
      } : type === 'text' ? {
        label: 'New Text Node'
      } : type === 'code' ? {
        code: '// New Code Node'
      } : {},
    };

    if (type === 'edge') {
      const newEdge = {
        id: `edge_${Date.now()}`,
        source: nodes[0].id, 
        target: nodes[nodes.length - 1].id,
        type: ConnectionLineType.Bezier,
        markerEnd: { type: MarkerType.ArrowClosed }
      };
      setEdges((eds) => eds.concat(newEdge));
    } else {
      setNodes((nds) => nds.concat(newNode));
    }

    setIsSidebarPlusMenuOpen(false);
  }, [nodes]);

  useEffect(() => {
    const handleKeyDelete = (event) => {
      // Cek apakah sedang ada input aktif
      const activeElement = document.activeElement;
      const isInputFocused = 
        activeElement?.tagName === 'INPUT' || 
        activeElement?.tagName === 'TEXTAREA';

      // Jangan proses delete jika sedang mengedit input
      if (isInputFocused) return;

      // Pastikan mode editing aktif
      if (!isEditingMode) return;

      if (event.key === 'Delete' || event.key === 'Backspace') {
        const selectedNodes = nodes.filter(node => node.selected);
        
        if (selectedNodes.length > 0) {
          selectedNodes.forEach(node => {
            handleDeleteNode(node.id);
          });
        }
      }
    };

    window.addEventListener('keydown', handleKeyDelete);

    return () => {
      window.removeEventListener('keydown', handleKeyDelete);
    };
  }, [nodes, isEditingMode, handleDeleteNode]);

  const handleBoardActionClick = (event) => {
    if (plusButtonRef.current) {
      const rect = plusButtonRef.current.getBoundingClientRect();
      setBoardActionDropdownPosition({ 
        x: rect.left, 
        y: rect.bottom 
      });
      setIsBoardActionDropdownOpen(!isBoardActionDropdownOpen);
    }
  };

  // Fungsi untuk menyimpan board
  const handleSaveBoard = () => {
    // Generate unique board name/ID
    const boardName = `Board-${new Date().toISOString()}`;
    
    // Simpan board ke localStorage
    const boardData = {
      id: boardName,
      nodes: nodes,
      edges: edges,
      createdAt: new Date().toISOString()
    };

    // Ambil daftar board yang sudah tersimpan
    const existingBoards = JSON.parse(localStorage.getItem('savedBoards') || '[]');
    
    // Tambahkan board baru
    const updatedBoards = [boardData, ...existingBoards];
    
    // Simpan ke localStorage
    localStorage.setItem('savedBoards', JSON.stringify(updatedBoards));
    localStorage.setItem('boardNodes', JSON.stringify(nodes));
    localStorage.setItem('boardEdges', JSON.stringify(edges));
    
    // Update state saved boards
    setSavedBoards(updatedBoards);
    
    // Tutup dropdown
    setIsBoardActionDropdownOpen(false);
    
    // Notifikasi (bisa diganti dengan toast)
    alert(`Board "${boardName}" saved successfully!`);
  };

  // Fungsi untuk membuka board
  const handleOpenBoard = () => {
    // Buka modal pilih board
    setIsOpenBoardModalOpen(true);
    setIsBoardActionDropdownOpen(false);
  };

  // Fungsi untuk memuat board yang dipilih
  const loadSelectedBoard = (boardData) => {
    // Set nodes dan edges dari board yang dipilih
    setNodes(boardData.nodes);
    setEdges(boardData.edges);
    
    // Tutup modal
    setIsOpenBoardModalOpen(false);
  };

  // Fungsi untuk menampilkan board terakhir
  const handleRecentBoards = () => {
    // Buka modal recent boards
    setIsRecentBoardsModalOpen(true);
    setIsBoardActionDropdownOpen(false);
  };

  // Render modal untuk open board
  const OpenBoardModal = () => {
    const boards = JSON.parse(localStorage.getItem('savedBoards') || '[]');

    if (!isOpenBoardModalOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Open Board</h2>
          {boards.length === 0 ? (
            <p>No saved boards found.</p>
          ) : (
            <div className="boards-list">
              {boards.map((board) => (
                <div 
                  key={board.id} 
                  className="board-item"
                  onClick={() => loadSelectedBoard(board)}
                >
                  <span>{board.id}</span>
                  <small>{new Date(board.createdAt).toLocaleString()}</small>
                </div>
              ))}
            </div>
          )}
          <div className="modal-actions">
            <button onClick={() => setIsOpenBoardModalOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render modal untuk recent boards
  const RecentBoardsModal = () => {
    const boards = JSON.parse(localStorage.getItem('savedBoards') || '[]')
      .slice(0, 5); // Ambil 5 board terakhir

    if (!isRecentBoardsModalOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Recent Boards</h2>
          {boards.length === 0 ? (
            <p>No recent boards found.</p>
          ) : (
            <div className="boards-list">
              {boards.map((board) => (
                <div 
                  key={board.id} 
                  className="board-item"
                  onClick={() => loadSelectedBoard(board)}
                >
                  <span>{board.id}</span>
                  <small>{new Date(board.createdAt).toLocaleString()}</small>
                </div>
              ))}
            </div>
          )}
          <div className="modal-actions">
            <button onClick={() => setIsRecentBoardsModalOpen(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`board-wrapper ${isDarkMode ? 'darkmode' : ''}`}>
      {/* Plus Menu Dropdown */}
      {isPlusMenuOpen && (
        <div 
          className="plus-menu" 
          style={{
            position: 'absolute', 
            top: `${plusMenuPosition.y}px`, 
            left: `${plusMenuPosition.x}px`
          }}
        >
          <div className="plus-menu-content">
            <button 
              onClick={() => handleAddTaskType('todo')}
              className="plus-menu-item"
            >
              <FaListAlt /> Todo Task
            </button>
            <button 
              onClick={() => handleAddTaskType('in-progress')}
              className="plus-menu-item"
            >
              <FaColumns /> In Progress Task
            </button>
            <button 
              onClick={() => handleAddTaskType('done')}
              className="plus-menu-item"
            >
              <FaChartPie /> Completed Task
            </button>
            <button 
              onClick={() => handleAddTaskType('milestone')}
              className="plus-menu-item"
            >
              <FaCalendarAlt /> Milestone
            </button>
          </div>
        </div>
      )}

      {/* Sidebar Plus Menu Dropdown */}
      {isSidebarPlusMenuOpen && (
        <div 
          className="sidebar-plus-menu" 
          style={{
            position: 'absolute', 
            top: `${sidebarPlusMenuPosition.y}px`, 
            left: `${sidebarPlusMenuPosition.x}px`
          }}
        >
          <div className="plus-menu-content">
            <button 
              onClick={() => handleAddElement('task')}
              className="plus-menu-item"
            >
              <FaListAlt /> Task Node
            </button>
            <button 
              onClick={() => handleAddElement('text')}
              className="plus-menu-item"
            >
              <FaTextWidth /> Text Node
            </button>
            <button 
              onClick={() => handleAddElement('code')}
              className="plus-menu-item"
            >
              <FaCode /> Code Node
            </button>
            <button 
              onClick={() => handleAddElement('edge')}
              className="plus-menu-item"
            >
              <FaProjectDiagram /> Connection
            </button>
          </div>
        </div>
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <div className="share-modal">
          <div className="share-modal-content">
            <h3>Share Board</h3>
            <div className="share-options">
              <button onClick={handleCopyBoardLink}>
                <FaClipboardList /> Copy Board Link
              </button>
              {/* Tambahkan opsi share lainnya */}
            </div>
            <button 
              className="close-share-modal" 
              onClick={() => setIsShareModalOpen(false)}
            >
              <FaTimes /> Close
            </button>
          </div>
        </div>
      )}

      {/* Hidden file input for import */}
      <input 
        type="file" 
        ref={fileInputRef} 
        style={{ display: 'none' }}
        accept=".json"
        onChange={handleImportBoard}
      />

      {/* Edit Node Modal */}
      {editingNode && (
        <div className="node-edit-modal">
          <div className="node-edit-content">
            <h3>Edit Task</h3>
            <div className="edit-form-group">
              <label>Title</label>
              <input 
                type="text" 
                value={editingNode.label} 
                onChange={(e) => setEditingNode({...editingNode, label: e.target.value})}
              />
            </div>
            <div className="edit-form-group">
              <label>Description</label>
              <textarea 
                value={editingNode.description} 
                onChange={(e) => setEditingNode({...editingNode, description: e.target.value})}
              />
            </div>
            <div className="edit-form-group">
              <label>Status</label>
              <select 
                value={editingNode.type} 
                onChange={(e) => setEditingNode({...editingNode, type: e.target.value})}
              >
                <option value="todo">To Do</option>
                <option value="in-progress">In Progress</option>
                <option value="done">Done</option>
              </select>
            </div>
            <div className="edit-form-group">
              <label>Assignee</label>
              <input 
                type="text" 
                value={editingNode.assignee || ''} 
                onChange={(e) => setEditingNode({...editingNode, assignee: e.target.value})}
              />
            </div>
            <div className="modal-actions">
              <button onClick={updateNode}><FaSave /> Save</button>
              <button onClick={() => setEditingNode(null)}><FaTimes /> Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Board Action Dropdown */}
      <BoardActionDropdown 
        isOpen={isBoardActionDropdownOpen}
        onClose={() => setIsBoardActionDropdownOpen(false)}
        onSave={handleSaveBoard}
        onOpen={handleOpenBoard}
        onRecentBoards={handleRecentBoards}
        position={boardActionDropdownPosition}
      />

      {/* Modal untuk Open Board */}
      <OpenBoardModal />
      
      {/* Modal untuk Recent Boards */}
      <RecentBoardsModal />

      {/* Sidebar */}
      <div className={`board-sidebar ${isDarkMode ? 'darkmode' : ''}`}>
        <div className="sidebar-tools">
          <button 
            ref={sidebarPlusButtonRef}
            className="tool-button" 
            onClick={handleSidebarPlusButtonClick}
          >
            <FaPlus /> 
          </button>
          <button 
            className="tool-button" 
            draggable
            onDragStart={handleAddTaskFromSidebar}
          >
            <FaPlus /> 
          </button>
          <button 
            className="tool-button" 
            onClick={() => fileInputRef.current.click()}
          >
            <FaUpload />
          </button>
          <button 
            className="tool-button" 
            onClick={handleExportBoard}
          >
            <FaDownload />
          </button>
          <button 
            className="tool-button"
            onClick={handleAutoLayout}
          >
            <FaTable />
          </button>
          <div className="filter-dropdown">
            <button 
              className={`tool-button ${filterStatus ? 'active' : ''}`}
              onClick={() => setFilterStatus(prev => 
                prev === null ? 'todo' : 
                prev === 'todo' ? 'in-progress' : 
                prev === 'in-progress' ? 'done' : 
                null
              )}
            >
              <FaFilter />
            </button>
            {filterStatus && (
              <div className="filter-status">
                {filterStatus.replace('-', ' ')}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="board-main">
        {/* Top Toolbar */}
        <div className={`board-toolbar ${isDarkMode ? 'darkmode' : ''}`}>
          <div className="toolbar-left">
            <button className="back-button">←</button>
            <h2>Board</h2>
            <button className="board-type-button">Flow</button>
          </div>
          <div className="toolbar-right">
            <div className="search-container">
              <input 
                ref={searchInputRef}
                type="text" 
                placeholder="Search nodes..." 
                className="search-input"
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={handleSearchNodes}
              />
              {isSearchActive && (
                <button 
                  className="clear-search-btn" 
                  onClick={clearSearch}
                >
                  <FaTimesCircle />
                </button>
              )}
            </div>
            <button 
              className={`toolbar-button ${isEditingMode ? 'active' : ''}`} 
              onClick={handleToggleEditingMode}
            >
              <FaEdit /> {isEditingMode ? 'Editing On' : 'Editing'}
            </button>
            <button 
              className="toolbar-button" 
              onClick={handleShareBoard}
            >
              <FaShare /> Share
            </button>
            <button 
              ref={plusButtonRef}
              className="toolbar-button" 
              onClick={handleBoardActionClick}
            >
              Board Actions <FaChevronDown />
            </button>
            <button className="toolbar-button"><FaEllipsisH /></button>
          </div>
        </div>

        {/* Board Content */}
        <div className={`board-container ${isDarkMode ? 'darkmode' : ''} ${isEditingMode ? 'editing-mode' : ''}`}>
          <ReactFlowProvider>
            <div 
              className="reactflow-wrapper" 
              ref={reactFlowWrapper}
              style={{ height: '100%', width: '100%' }}
            >
              <ReactFlow
                nodes={filteredNodes}
                edges={edges}
                onNodesChange={isEditingMode ? onNodesChange : undefined}
                onEdgesChange={isEditingMode ? onEdgesChange : undefined}
                onConnect={isEditingMode ? onConnect : undefined}
                nodeTypes={nodeTypes}
                onInit={setReactFlowInstance}
                onDrop={isEditingMode ? onDrop : undefined}
                onDragOver={isEditingMode ? onDragOver : undefined}
                connectionLineType={ConnectionLineType.Bezier}
                fitView
                nodesDraggable={isEditingMode}
                nodesConnectable={isEditingMode}
                elementsSelectable={isEditingMode}
              >
                <Controls />
                <MiniMap />
                <Background variant="dots" gap={12} size={1} />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default Board;
