import React, { useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from 'react';
import { createPortal } from 'react-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css';

// TextInput Component
const TextInputComponent = ({ position, onSubmit, onCancel }) => {
  const [text, setText] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(text);
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: `${position.x}%`,
        top: `${position.y}%`,
        transform: 'translate(-50%, -50%)',
        zIndex: 4
      }}
    >
      <textarea
        autoFocus
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={() => onSubmit(text)}
        style={{
          background: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
          padding: '4px 8px',
          borderRadius: '4px',
          border: 'none',
          resize: 'both',
          minWidth: '100px',
          minHeight: '30px',
          fontSize: '14px'
        }}
        placeholder="Type and press Enter..."
      />
    </div>
  );
};

const VideoPlayer = forwardRef(({ 
  filepath, 
  watchMode = false, 
  onSaveAnnotation, 
  initialAnnotations = [], 
  onClose, 
  isModal = false, 
  onEnded 
}, ref) => {
  // Essential refs
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);
  const cleanupFunctionsRef = useRef([]);
  const timelineRef = useRef(null);

  // Essential state
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [annotations, setAnnotations] = useState(initialAnnotations);
  const [activeTab, setActiveTab] = useState('info');
  const [selectedTool, setSelectedTool] = useState(null);
  const [showTextForm, setShowTextForm] = useState(false);
  const [annotationText, setAnnotationText] = useState('');
  const [drawColor, setDrawColor] = useState('#FF0000');
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });
  const FPS = 30; // Assuming 30fps

  // State untuk menyimpan drawing paths per frame
  const [drawingPaths, setDrawingPaths] = useState({});
  const [currentPath, setCurrentPath] = useState([]);

  // State untuk text annotation
  const [textAnnotations, setTextAnnotations] = useState([]);
  const [isAddingText, setIsAddingText] = useState(false);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // State untuk save annotation
  const [savedAnnotations, setSavedAnnotations] = useState([]);

  // State untuk mengontrol tampilan list
  const [showCurrentList, setShowCurrentList] = useState(false);
  const [showSavedList, setShowSavedList] = useState(false);

  // Memoized video options
  const videoJsOptions = useMemo(() => ({
    controls: false,
    responsive: true,
    fluid: true,
    sources: [{
      src: filepath,
      type: 'video/mp4'
    }]
  }), [filepath]);

  // Player control functions
  const handlePlayPause = useCallback(() => {
    if (!playerRef.current || !isPlayerReady) return;
    
    if (playerRef.current.paused()) {
      playerRef.current.play().catch(console.error);
      setIsPlaying(true);
    } else {
      playerRef.current.pause();
      setIsPlaying(false);
    }
  }, [isPlayerReady]);

  const handleStop = useCallback(() => {
    if (!playerRef.current || !isPlayerReady) return;
    
    playerRef.current.pause();
    playerRef.current.currentTime(0);
    setIsPlaying(false);
    setCurrentTime(0);
  }, [isPlayerReady]);

  const handleFrameStep = useCallback((forward = true) => {
    if (!playerRef.current || !isPlayerReady) return;
    
    const frameTime = 1/30;
    const currentTime = playerRef.current.currentTime();
    const newTime = forward ? 
      Math.min(currentTime + frameTime, duration) : 
      Math.max(currentTime - frameTime, 0);
    
    playerRef.current.currentTime(newTime);
    setCurrentTime(newTime);
  }, [isPlayerReady, duration]);

  // Drawing functions
  const getMousePos = useCallback((e) => {
    if (!canvasRef.current) return { x: 0, y: 0 };
    
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    
    // Jika canvas belum diinisialisasi dengan benar, gunakan nilai default
    if (!canvas.width || !canvas.height || !rect.width || !rect.height) {
      console.log('Canvas belum diinisialisasi dengan benar');
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
    }
    
    // Dapatkan ukuran asli video
    const videoElement = videoRef.current?.querySelector('video');
    const videoWidth = videoElement?.videoWidth;
    const videoHeight = videoElement?.videoHeight;
    
    // Jika kita memiliki ukuran asli video, gunakan itu untuk scaling
    if (videoWidth && videoHeight) {
      // Hitung scaling factor berdasarkan rasio ukuran asli video dan ukuran tampilan
      const scaleX = videoWidth / rect.width;
      const scaleY = videoHeight / rect.height;
      
      // Hitung posisi mouse yang sudah discaling
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      
      console.log(`Mouse position: (${x}, ${y}), Video size: ${videoWidth}x${videoHeight}, Canvas size: ${canvas.width}x${canvas.height}, Rect size: ${rect.width}x${rect.height}, Scale: ${scaleX}x${scaleY}`);
      
      return { x, y };
    } else {
      // Fallback ke scaling berdasarkan ukuran canvas jika ukuran video tidak tersedia
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      
      // Hitung posisi mouse yang sudah discaling
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      
      console.log(`Mouse position (fallback): (${x}, ${y}), Canvas size: ${canvas.width}x${canvas.height}, Rect size: ${rect.width}x${rect.height}, Scale: ${scaleX}x${scaleY}`);
      
      return { x, y };
    }
  }, [videoRef]);

  const startDrawing = useCallback((e) => {
    if (selectedTool !== 'draw' || !canvasRef.current) {
      console.log('Tidak bisa mulai menggambar: tool tidak aktif atau canvas tidak tersedia');
      return;
    }
    
    e.preventDefault();
    e.stopPropagation();
    
    const pos = getMousePos(e);
    setIsDrawing(true);
    setLastPos(pos);
    setCurrentPath([{ x: pos.x, y: pos.y }]);
    
    // Start new path
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);
    ctx.strokeStyle = drawColor;
    ctx.lineWidth = 3; // Meningkatkan ketebalan garis agar lebih terlihat
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    console.log('Drawing started at', pos.x, pos.y);
  }, [selectedTool, getMousePos, drawColor]);

  const draw = useCallback((e) => {
    if (!isDrawing || selectedTool !== 'draw' || !canvasRef.current) return;
    
    e.preventDefault();
    e.stopPropagation();
    
    const pos = getMousePos(e);
    const ctx = canvasRef.current.getContext('2d');
    
    ctx.strokeStyle = drawColor;
    ctx.lineWidth = 3; // Meningkatkan ketebalan garis agar lebih terlihat
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    
    ctx.beginPath();
    ctx.moveTo(lastPos.x, lastPos.y);
    ctx.lineTo(pos.x, pos.y);
    ctx.stroke();
    
    setLastPos(pos);
    setCurrentPath(prev => [...prev, { x: pos.x, y: pos.y }]);
    console.log('Drawing continued to', pos.x, pos.y);
  }, [isDrawing, selectedTool, drawColor, lastPos, getMousePos]);

  const stopDrawing = useCallback((e) => {
    if (!isDrawing || !canvasRef.current) return;
    
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setIsDrawing(false);
    
    // Save the drawing path for current frame
    if (currentPath.length > 0) {
      const frameTime = playerRef.current?.currentTime() || 0;
      setDrawingPaths(prev => ({
        ...prev,
        [frameTime]: [...(prev[frameTime] || []), {
          color: drawColor,
          points: currentPath
        }]
      }));
      
      // Add to annotations
      const newAnnotation = {
        type: 'draw',
        timestamp: frameTime,
        content: 'Drawing annotation',
        drawingData: {
          color: drawColor,
          points: currentPath
        }
      };
      setAnnotations(prev => [...prev, newAnnotation]);
      setCurrentPath([]);
      console.log('Drawing selesai dan disimpan sebagai anotasi');
    }
  }, [isDrawing, drawColor, currentPath, playerRef]);

  // Annotation functions
  const handleSaveAnnotation = useCallback(async () => {
    if (!annotations.length) {
      alert('No annotations to save');
      return;
    }

    try {
      const response = await fetch('/api/activities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'video_annotation',
          action: 'saved_annotation',
          content: 'Video Annotation',
          data: {
            annotations,
            videoPath: filepath,
            timestamp: new Date().toISOString()
          }
        })
      });

      if (response.ok) {
        const savedAnnotation = await response.json();
        setSavedAnnotations(prev => [...prev, savedAnnotation]);
        alert('Annotation saved successfully!');
      } else {
        const error = await response.json();
        throw new Error(error.message || 'Failed to save annotation');
      }
    } catch (error) {
      console.error('Error saving annotation:', error);
      alert('Failed to save annotation: ' + error.message);
    }
  }, [annotations, filepath]);

  const handleSaveTextAnnotation = useCallback(() => {
    if (!annotationText.trim()) return;
    
    const newAnnotation = {
      type: 'text',
      content: annotationText,
      timestamp: currentTime
    };
    
    setAnnotations(prev => [...prev, newAnnotation]);
    setAnnotationText('');
    setShowTextForm(false);
  }, [annotationText, currentTime]);

  const handleDeleteAnnotation = useCallback((index) => {
    setAnnotations(prev => {
      const newAnnotations = [...prev];
      newAnnotations.splice(index, 1);
      return newAnnotations;
    });
  }, []);

  const handleJumpToAnnotation = useCallback((timestamp) => {
    if (!playerRef.current) return;
    playerRef.current.currentTime(timestamp);
  }, []);

  const handleToolSelect = useCallback((tool) => {
    if (selectedTool === tool) {
      setSelectedTool(null);
      setShowTextForm(false);
    } else {
      setSelectedTool(tool);
      if (tool === 'text') {
        setShowTextForm(true);
      }
    }
  }, [selectedTool]);

  // Format time helper
  const formatTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  // Add frame timeline functions
  const timeToFrame = useCallback((time) => {
    return Math.floor(time * FPS);
  }, []);

  const frameToTime = useCallback((frame) => {
    return frame / FPS;
  }, []);

  const handleTimelineClick = useCallback((e) => {
    if (!playerRef.current || !isPlayerReady) return;
    
    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const percentage = clickX / rect.width;
    const frame = Math.floor(percentage * totalFrames);
    const time = frameToTime(frame);
    
    playerRef.current.currentTime(time);
    setCurrentTime(time);
    setCurrentFrame(frame);
  }, [isPlayerReady, totalFrames, frameToTime]);

  const renderTimeline = useCallback(() => {
    const frameMarkers = [];
    const annotationMarkers = [];
    
    // Add frame markers
    for (let i = 0; i <= totalFrames; i += Math.max(1, Math.floor(totalFrames / 100))) {
      const position = (i / totalFrames) * 100;
      frameMarkers.push(
        <div
          key={`frame-${i}`}
          className="timeline-frame-marker"
          style={{ left: `${position}%` }}
        />
      );
    }

    // Add annotation markers
    annotations.forEach((annotation, index) => {
      const position = (timeToFrame(annotation.timestamp) / totalFrames) * 100;
      annotationMarkers.push(
        <div
          key={`annotation-${index}`}
          className="timeline-marker"
          style={{ left: `${position}%` }}
          title={`Annotation at ${annotation.timestamp.toFixed(2)}s`}
        />
      );
    });

    return (
      <div 
        className="timeline" 
        onClick={handleTimelineClick}
        ref={timelineRef}
      >
        <div 
          className="timeline-progress" 
          style={{ width: `${(currentFrame / totalFrames) * 100}%` }} 
        />
        {frameMarkers}
        {annotationMarkers}
      </div>
    );
  }, [totalFrames, currentFrame, annotations, timeToFrame, handleTimelineClick]);

  // Frame markers component
  const FrameTimeline = useCallback(() => {
    const markerCount = Math.min(totalFrames, 300); // Limit markers for performance
    const interval = Math.ceil(totalFrames / markerCount);
    const markers = [];

    for (let i = 0; i <= totalFrames; i += interval) {
      const position = (i / totalFrames) * 100;
      const isMajor = i % (interval * 5) === 0;
      
      markers.push(
        <div
          key={i}
          className={`frame-marker ${isMajor ? 'major' : 'minor'}`}
          style={{ left: `${position}%` }}
        >
          {isMajor && <span className="frame-number">{i}</span>}
        </div>
      );
    }

    // Add annotation markers
    const annotationMarkers = annotations.map((ann, index) => {
      const frame = timeToFrame(ann.timestamp);
      const position = (frame / totalFrames) * 100;
      
      return (
        <div
          key={`ann-${index}`}
          className="annotation-marker"
          style={{ left: `${position}%` }}
          title={`Frame ${frame}: ${ann.type}`}
        />
      );
    });

    return (
      <div className="frame-timeline" onClick={handleTimelineClick}>
        <div className="frame-markers">
          {markers}
          {annotationMarkers}
        </div>
        <div 
          className="timeline-progress" 
          style={{ width: `${(currentFrame / totalFrames) * 100}%` }}
        >
          <div className="timeline-handle">
            <span className="current-frame">Frame {currentFrame}</span>
          </div>
        </div>
      </div>
    );
  }, [totalFrames, currentFrame, annotations, timeToFrame, handleTimelineClick]);

  // Effect untuk menggambar ulang annotations saat frame berubah
  useEffect(() => {
    if (!canvasRef.current || !playerRef.current) return;
    
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    
    const currentTime = playerRef.current.currentTime();
    const currentAnnotations = annotations.filter(
      ann => ann.type === 'draw' && Math.abs(ann.timestamp - currentTime) < 0.1
    );
    
    currentAnnotations.forEach(annotation => {
      const { drawingData } = annotation;
      if (!drawingData || !drawingData.points || drawingData.points.length < 2) return;
      
      ctx.beginPath();
      ctx.strokeStyle = drawingData.color;
      ctx.lineWidth = 3; // Menggunakan ketebalan yang sama dengan saat menggambar (3px)
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      
      // Pastikan kita menggunakan koordinat yang tepat
      const canvas = canvasRef.current;
      const videoElement = videoRef.current.querySelector('video');
      
      // Dapatkan ukuran asli video dan ukuran canvas
      const videoWidth = videoElement?.videoWidth || canvas.width;
      const videoHeight = videoElement?.videoHeight || canvas.height;
      
      // Gambar dengan koordinat yang sudah disesuaikan
      const firstPoint = drawingData.points[0];
      
      // Jika canvas dan video memiliki ukuran yang sama, gunakan koordinat asli
      // Jika tidak, sesuaikan koordinat berdasarkan rasio ukuran
      if (canvas.width === videoWidth && canvas.height === videoHeight) {
        ctx.moveTo(firstPoint.x, firstPoint.y);
        
        drawingData.points.slice(1).forEach(point => {
          ctx.lineTo(point.x, point.y);
        });
      } else {
        // Hitung faktor skala antara ukuran canvas saat ini dan ukuran canvas saat drawing dibuat
        const scaleX = canvas.width / videoWidth;
        const scaleY = canvas.height / videoHeight;
        
        // Log untuk debugging
        console.log(`Rendering drawing annotation. Canvas: ${canvas.width}x${canvas.height}, Video: ${videoWidth}x${videoHeight}, Scale: ${scaleX}x${scaleY}`);
        
        // Terapkan skala pada koordinat
        ctx.moveTo(firstPoint.x * scaleX, firstPoint.y * scaleY);
        
        drawingData.points.slice(1).forEach(point => {
          ctx.lineTo(point.x * scaleX, point.y * scaleY);
        });
      }
      
      ctx.stroke();
    });
  }, [currentFrame, annotations]);

  // Initialize video player
  useEffect(() => {
    // Pastikan elemen video sudah ada di DOM sebelum menginisialisasi videojs
    if (!videoRef.current) return;
    
    // Gunakan setTimeout untuk memastikan DOM sudah dirender sepenuhnya
    const initTimeout = setTimeout(() => {
      try {
        const player = videojs(videoRef.current, videoJsOptions, () => {
          console.log('Player is ready');
          setIsPlayerReady(true);
          
          player.on('loadedmetadata', () => {
            const dur = player.duration();
            setDuration(dur);
            setTotalFrames(Math.floor(dur * FPS));
          });

          player.on('timeupdate', () => {
            const time = player.currentTime();
            setCurrentTime(time);
            setCurrentFrame(timeToFrame(time));
          });
          
          player.on('ended', () => {
            setIsPlaying(false);
            if (onEnded) onEnded();
          });
        });
        
        playerRef.current = player;
      } catch (error) {
        console.error('Error initializing video player:', error);
      }
    }, 100); // Delay kecil untuk memastikan DOM sudah dirender

    return () => {
      clearTimeout(initTimeout);
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      cleanupFunctionsRef.current.forEach(cleanup => cleanup());
      cleanupFunctionsRef.current = [];
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [videoJsOptions, onEnded, timeToFrame]);

  // Handle annotations update
  useEffect(() => {
    setAnnotations(initialAnnotations);
  }, [initialAnnotations]);

  // Load saved annotations
  useEffect(() => {
    const loadSavedAnnotations = async () => {
      try {
        const response = await fetch(`/api/activities?type=video_annotation`);
        if (response.ok) {
          const data = await response.json();
          // Filter annotations for current video
          const videoAnnotations = data.filter(item => 
            item.data?.videoPath === filepath
          );
          setSavedAnnotations(videoAnnotations);
        }
      } catch (error) {
        console.error('Error loading saved annotations:', error);
      }
    };

    if (filepath) {
      loadSavedAnnotations();
    }
  }, [filepath]);

  const renderSavedAnnotations = useCallback(() => {
    if (!savedAnnotations?.length) return null;

    return (
      <div className="saved-annotations">
        <h3>Saved Annotations</h3>
        <div className="saved-annotations-list">
          {savedAnnotations.map((saved, index) => {
            if (!saved?.data?.annotations) return null;
            
            return (
              <div
                key={saved.id || index}
                className="saved-annotation-item"
                onClick={() => {
                  setAnnotations(saved.data.annotations);
                  if (playerRef.current) {
                    playerRef.current.currentTime(0);
                  }
                }}
              >
                <span>Annotation {index + 1}</span>
                <span>{new Date(saved.data.timestamp).toLocaleString()}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [savedAnnotations, setAnnotations, playerRef]);

  const renderAnnotationTools = useCallback(() => {
    return (
      <div className="annotation-tools">
        <div className="annotation-header">
          <h3>Annotation Tools</h3>
          <div className="tool-buttons">
            <button
              className={`tool-button ${selectedTool === 'draw' ? 'active' : ''}`}
              onClick={() => handleToolSelect('draw')}
            >
              Draw
            </button>
            <button
              className={`tool-button ${selectedTool === 'text' ? 'active' : ''}`}
              onClick={() => handleToolSelect('text')}
            >
              Text
            </button>
            <button
              className="tool-button"
              onClick={handleSaveAnnotation}
            >
              Save
            </button>
            <button
              className={`tool-button ${showCurrentList ? 'active' : ''}`}
              onClick={() => setShowCurrentList(!showCurrentList)}
            >
              List
            </button>
            <button
              className={`tool-button ${showSavedList ? 'active' : ''}`}
              onClick={() => setShowSavedList(!showSavedList)}
            >
              Load
            </button>
          </div>
        </div>

        {showTextForm && (
          <div className="text-annotation-form">
            <textarea
              value={annotationText}
              onChange={(e) => setAnnotationText(e.target.value)}
              placeholder="Enter annotation text..."
            />
            <div className="form-buttons">
              <button onClick={handleSaveTextAnnotation} className="save-button">
                Save
              </button>
              <button onClick={() => setShowTextForm(false)} className="cancel-button">
                Cancel
              </button>
            </div>
          </div>
        )}

        {showCurrentList && (
          <div className="annotations-section">
            <div className="section-header">
              <h3>Current Annotations ({annotations.length})</h3>
              <button 
                className="collapse-button"
                onClick={() => setShowCurrentList(false)}
              >
                ×
              </button>
            </div>
            <div className="annotations-list">
              {annotations.map((annotation, index) => (
                <div 
                  key={index} 
                  className={`annotation-item ${timeToFrame(annotation.timestamp) === currentFrame ? 'active' : ''}`}
                  onClick={() => handleJumpToAnnotation(annotation.timestamp)}
                >
                  <div className="annotation-info">
                    <div className="annotation-time">
                      <span className="time">{formatTime(annotation.timestamp)}</span>
                      <span className="frame">Frame {timeToFrame(annotation.timestamp)}</span>
                    </div>
                    <div className="annotation-content">
                      {annotation.type === 'text' ? (
                        <span className="text-content">{annotation.content}</span>
                      ) : (
                        <span className="draw-content">Drawing</span>
                      )}
                    </div>
                  </div>
                  <button 
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteAnnotation(index);
                    }}
                    title="Delete annotation"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {showSavedList && savedAnnotations?.length > 0 && (
          <div className="saved-annotations">
            <div className="section-header">
              <h3>Saved Annotations</h3>
              <button 
                className="collapse-button"
                onClick={() => setShowSavedList(false)}
              >
                ×
              </button>
            </div>
            <div className="saved-annotations-list">
              {savedAnnotations.map((saved, index) => {
                if (!saved?.data?.annotations) return null;
                
                return (
                  <div
                    key={saved.id || index}
                    className="saved-annotation-item"
                    onClick={() => {
                      setAnnotations(saved.data.annotations);
                      if (playerRef.current) {
                        playerRef.current.currentTime(0);
                      }
                      setShowSavedList(false);
                    }}
                  >
                    <span>Annotation {index + 1}</span>
                    <span>{new Date(saved.data.timestamp).toLocaleString()}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }, [
    selectedTool, 
    handleToolSelect, 
    handleSaveAnnotation,
    showTextForm,
    annotationText,
    handleSaveTextAnnotation,
    annotations,
    currentFrame,
    handleJumpToAnnotation,
    handleDeleteAnnotation,
    savedAnnotations,
    showCurrentList,
    showSavedList,
    playerRef
  ]);

  const getVideoFileName = useMemo(() => {
    if (!filepath) return '';
    const parts = filepath.split('/');
    return parts[parts.length - 1];
  }, [filepath]);

  // Mengatur ukuran canvas berdasarkan ukuran video
  useEffect(() => {
    if (!canvasRef.current || !videoRef.current || !isPlayerReady) return;
    
    const canvas = canvasRef.current;
    const video = videoRef.current;
    
    // Set canvas size to match video dimensions
    const updateCanvasSize = () => {
      const videoElement = video.querySelector('video');
      if (videoElement) {
        // Dapatkan ukuran video yang sebenarnya (ukuran asli video)
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        
        if (videoWidth && videoHeight) {
          // Set ukuran canvas sesuai dengan ukuran asli video
          canvas.width = videoWidth;
          canvas.height = videoHeight;
          
          // Clear and redraw annotations when size changes
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          
          // Inisialisasi canvas context
          ctx.lineCap = 'round';
          ctx.lineJoin = 'round';
          ctx.strokeStyle = drawColor;
          ctx.lineWidth = 3;
          
          console.log(`Canvas size updated to ${canvas.width}x${canvas.height} (ukuran asli video)`);
        } else {
          // Jika ukuran asli video tidak tersedia, gunakan ukuran tampilan
          const rect = videoElement.getBoundingClientRect();
          canvas.width = rect.width;
          canvas.height = rect.height;
          
          // Clear and redraw annotations when size changes
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          
          // Inisialisasi canvas context
          ctx.lineCap = 'round';
          ctx.lineJoin = 'round';
          ctx.strokeStyle = drawColor;
          ctx.lineWidth = 3;
          
          console.log(`Canvas size updated to ${canvas.width}x${canvas.height} (ukuran tampilan video)`);
        }
      }
    };
    
    // Update canvas size initially
    updateCanvasSize();
    
    // Gunakan ResizeObserver untuk memantau perubahan ukuran video
    const resizeObserver = new ResizeObserver(updateCanvasSize);
    resizeObserver.observe(video);
    
    // Tambahkan event listener untuk resize window
    window.addEventListener('resize', updateCanvasSize);
    
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [canvasRef, videoRef, isPlayerReady, drawColor]);
  
  // Inisialisasi canvas saat komponen dimuat
  useEffect(() => {
    if (!canvasRef.current) return;
    
    // Set ukuran awal canvas
    const canvasContainer = canvasRef.current.parentElement;
    if (canvasContainer) {
      const width = canvasContainer.clientWidth;
      const height = canvasContainer.clientHeight;
      
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      console.log(`Canvas diinisialisasi dengan ukuran ${width}x${height}`);
      
      // Inisialisasi canvas context
      const ctx = canvasRef.current.getContext('2d');
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.strokeStyle = drawColor;
      ctx.lineWidth = 3;
    }
  }, [canvasRef, drawColor]);
  
  // Menambahkan event listener untuk drawing
  useEffect(() => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    console.log('Menambahkan event listener untuk drawing');
    
    // Fungsi handler untuk event mouse
    const handleMouseDown = (e) => {
      console.log('Mouse down pada canvas, selectedTool:', selectedTool);
      if (selectedTool === 'draw') {
        e.preventDefault();
        e.stopPropagation();
        
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const pos = {
          x: (e.clientX - rect.left) * scaleX,
          y: (e.clientY - rect.top) * scaleY
        };
        
        setIsDrawing(true);
        setLastPos(pos);
        setCurrentPath([{ x: pos.x, y: pos.y }]);
        
        // Start new path
        const ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.strokeStyle = drawColor;
        ctx.lineWidth = 3;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        console.log('Drawing started at', pos.x, pos.y);
      }
    };
    
    const handleMouseMove = (e) => {
      if (selectedTool === 'draw' && isDrawing) {
        e.preventDefault();
        e.stopPropagation();
        
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const pos = {
          x: (e.clientX - rect.left) * scaleX,
          y: (e.clientY - rect.top) * scaleY
        };
        
        const ctx = canvas.getContext('2d');
        
        ctx.strokeStyle = drawColor;
        ctx.lineWidth = 3;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        
        ctx.beginPath();
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(pos.x, pos.y);
        ctx.stroke();
        
        setLastPos(pos);
        setCurrentPath(prev => [...prev, { x: pos.x, y: pos.y }]);
      }
    };
    
    const handleMouseUp = (e) => {
      if (selectedTool === 'draw' && isDrawing) {
        e.preventDefault();
        e.stopPropagation();
        
        setIsDrawing(false);
        
        // Save the drawing path for current frame
        if (currentPath.length > 0) {
          const frameTime = playerRef.current?.currentTime() || 0;
          setDrawingPaths(prev => ({
            ...prev,
            [frameTime]: [...(prev[frameTime] || []), {
              color: drawColor,
              points: currentPath
            }]
          }));
          
          // Add to annotations
          const newAnnotation = {
            type: 'draw',
            timestamp: frameTime,
            content: 'Drawing annotation',
            drawingData: {
              color: drawColor,
              points: currentPath
            }
          };
          setAnnotations(prev => [...prev, newAnnotation]);
          setCurrentPath([]);
          console.log('Drawing selesai dan disimpan sebagai anotasi');
        }
      }
    };
    
    // Tambahkan event listener
    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);
    canvas.addEventListener('mouseleave', handleMouseUp);
    
    // Cleanup
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
      canvas.removeEventListener('mouseleave', handleMouseUp);
    };
  }, [canvasRef, selectedTool, isDrawing, drawColor, lastPos, currentPath, playerRef]);

  const handleVideoClick = useCallback((e) => {
    // Jika tool drawing aktif, jangan lakukan apa-apa saat klik video
    if (selectedTool === 'draw') {
      return;
    }
    
    if (selectedTool !== 'text') {
      setSelectedAnnotation(null);
      return;
    }

    const rect = videoRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    
    setTextPosition({ x, y });
    setIsAddingText(true);
  }, [selectedTool]);

  const handleTextSubmit = useCallback((text) => {
    if (!text.trim()) {
      setIsAddingText(false);
      return;
    }

    const newAnnotation = {
      type: 'text',
      timestamp: playerRef.current?.currentTime() || 0,
      content: text,
      position: textPosition,
      fontSize: 14 // Default font size
    };

    setAnnotations(prev => [...prev, newAnnotation]);
    setIsAddingText(false);
  }, [textPosition]);

  const handleAnnotationClick = useCallback((e, annotation, index) => {
    e.stopPropagation();
    setSelectedAnnotation({ ...annotation, index });
  }, []);

  const handleAnnotationDragStart = useCallback((e, annotation) => {
    e.stopPropagation();
    setIsDragging(true);
    const rect = videoRef.current.getBoundingClientRect();
    setDragStart({
      x: e.clientX - (rect.width * annotation.position.x / 100),
      y: e.clientY - (rect.height * annotation.position.y / 100)
    });
  }, []);

  const handleAnnotationDrag = useCallback((e) => {
    if (!isDragging || !selectedAnnotation) return;

    const rect = videoRef.current.getBoundingClientRect();
    const x = ((e.clientX - dragStart.x) / rect.width) * 100;
    const y = ((e.clientY - dragStart.y) / rect.height) * 100;

    // Keep annotation within video bounds
    const boundedX = Math.max(0, Math.min(100, x));
    const boundedY = Math.max(0, Math.min(100, y));

    // Update the annotation at its original index
    setAnnotations(prev => prev.map((ann, i) => 
      i === selectedAnnotation.index
        ? { ...ann, position: { x: boundedX, y: boundedY } }
        : ann
    ));
  }, [isDragging, selectedAnnotation, dragStart]);

  const handleAnnotationDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleFontSizeChange = useCallback((increase) => {
    if (!selectedAnnotation) return;

    setAnnotations(prev => prev.map((ann, i) => {
      if (i === selectedAnnotation.index) {
        const currentSize = ann.fontSize || 14;
        const newSize = increase ? currentSize + 2 : Math.max(8, currentSize - 2);
        return { ...ann, fontSize: newSize };
      }
      return ann;
    }));
  }, [selectedAnnotation]);

  const renderTextAnnotations = useCallback(() => {
    if (!playerRef.current) return null;

    const currentTime = playerRef.current.currentTime();
    // Hanya tampilkan text annotation untuk frame yang sedang aktif
    const currentTextAnnotations = annotations.filter(
      ann => ann.type === 'text' && Math.abs(ann.timestamp - currentTime) < 0.1
    );

    return currentTextAnnotations.map((annotation, index) => {
      // Cari index asli annotation dalam array annotations
      const originalIndex = annotations.findIndex(
        ann => ann.type === 'text' && 
        ann.timestamp === annotation.timestamp && 
        ann.content === annotation.content
      );

      return (
        <div
          key={`text-${originalIndex}`}
          className="text-annotation"
          style={{
            position: 'absolute',
            left: `${annotation.position.x}%`,
            top: `${annotation.position.y}%`,
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: `${annotation.fontSize || 14}px`,
            maxWidth: '200px',
            wordBreak: 'break-word',
            zIndex: 3,
            cursor: 'move',
            userSelect: 'none',
            border: selectedAnnotation?.index === originalIndex ? '2px solid #4299e1' : 'none'
          }}
          onClick={(e) => handleAnnotationClick(e, annotation, originalIndex)}
          onMouseDown={(e) => handleAnnotationDragStart(e, annotation)}
          onMouseMove={handleAnnotationDrag}
          onMouseUp={handleAnnotationDragEnd}
          onMouseLeave={handleAnnotationDragEnd}
        >
          {annotation.content}
          {selectedAnnotation?.index === originalIndex && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '8px',
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '4px',
                borderRadius: '4px',
                marginTop: '4px',
                zIndex: 4
              }}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleFontSizeChange(false);
                }}
                style={{
                  background: '#4299e1',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  lineHeight: '1'
                }}
              >
                -
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleFontSizeChange(true);
                }}
                style={{
                  background: '#4299e1',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  lineHeight: '1'
                }}
              >
                +
              </button>
            </div>
          )}
        </div>
      );
    });
  }, [annotations, selectedAnnotation, handleAnnotationClick, handleAnnotationDragStart, handleAnnotationDrag, handleAnnotationDragEnd, handleFontSizeChange]);

  const renderModal = () => {
    return createPortal(
      <div className="video-player-modal-overlay">
        <div className="video-player-modal">
          <div className="modal-content">
            {renderVideoPlayer()}
          </div>
        </div>
      </div>,
      document.body
    );
  };

  const renderVideoPlayer = () => {
    return (
      <div className={`video-player-container ${isModal ? 'modal-mode' : ''}`}>
        <div className="video-container">
          <div className="video-main">
            <div className="top-bar">
              <div className="video-title">{getVideoFileName}</div>
              {isModal && (
                <button className="close-button" onClick={onClose}>×</button>
              )}
            </div>
            
            <div className="video-wrapper" onClick={handleVideoClick}>
              <div data-vjs-player>
                <video ref={videoRef} className="video-js" />
              </div>
              
              {watchMode && (
                <>
                  <canvas
                    ref={canvasRef}
                    className={`drawing-canvas ${selectedTool === 'draw' ? 'active' : ''}`}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      pointerEvents: selectedTool === 'draw' ? 'auto' : 'none',
                      zIndex: 10
                    }}
                  />
                  
                  <div className="video-controls">
                    <div className="control-buttons">
                      <button onClick={handlePlayPause} className="control-button">
                        {isPlaying ? '⏸' : '▶'}
                      </button>
                      <button onClick={handleStop} className="control-button">⏹</button>
                      <button onClick={() => handleFrameStep(false)} className="control-button">⏪</button>
                      <button onClick={() => handleFrameStep(true)} className="control-button">⏩</button>
                    </div>
                    <div className="time-display">
                      <span>{formatTime(currentTime)} / {formatTime(duration)}</span>
                      <span className="frame-display">Frame: {currentFrame}/{totalFrames}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            
            {watchMode && renderTimeline()}
            {renderTextAnnotations()}
            {isAddingText && (
              <TextInputComponent
                position={textPosition}
                onSubmit={handleTextSubmit}
                onCancel={() => setIsAddingText(false)}
              />
            )}
          </div>

          {watchMode && (
            <div className="sidebar">
              <div className="sidebar-tabs">
                <button 
                  className={`tab-button ${activeTab === 'info' ? 'active' : ''}`} 
                  onClick={() => setActiveTab('info')}
                >
                  Info
                </button>
                <button 
                  className={`tab-button ${activeTab === 'annotations' ? 'active' : ''}`} 
                  onClick={() => setActiveTab('annotations')}
                >
                  Annotations
                </button>
              </div>

              <div className="tab-content">
                {activeTab === 'info' ? (
                  <div className="info-panel">
                    <h3>Video Information</h3>
                    <div className="info-item">
                      <label>Filename:</label>
                      <span>{getVideoFileName}</span>
                    </div>
                    <div className="info-item">
                      <label>Duration:</label>
                      <span>{formatTime(duration)}</span>
                    </div>
                    <div className="info-item">
                      <label>Total Frames:</label>
                      <span>{totalFrames}</span>
                    </div>
                    <div className="info-item">
                      <label>Current Frame:</label>
                      <span>{currentFrame}</span>
                    </div>
                    <div className="info-item">
                      <label>FPS:</label>
                      <span>{FPS}</span>
                    </div>
                  </div>
                ) : (
                  <div className="annotations-panel">
                    {renderAnnotationTools()}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return isModal ? renderModal() : renderVideoPlayer();
});

export default VideoPlayer;