import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Box,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import axiosInstance from '../config/axios';
import '../styles/global.css';
import '../styles/globalform.css';

const IssueModal = ({ isOpen, onClose, issue, onUpdate, isDarkMode }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    status: 'OPEN',
    priority: 'MEDIUM',
    category: 'BUG',
    assigneeId: '',
    type: issue?.type || '',
    location: issue?.location || ''
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data || []);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch users',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchUsers();
  }, [toast]);

  useEffect(() => {
    if (issue) {
      setFormData({
        title: issue.title || '',
        description: issue.description || '',
        status: issue.status || 'OPEN',
        priority: issue.priority || 'MEDIUM',
        category: issue.category || 'BUG',
        assigneeId: issue.assignedTo || issue.assigneeId || '',
        type: issue.type || '',
        location: issue.location || ''
      });
    }
  }, [issue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title || !formData.description) {
      toast({
        title: 'Error',
        description: 'Title and description are required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      let response;
      const payload = {
        ...formData,
        assigneeId: formData.assigneeId || null,
        type: issue?.type || formData.type,
        location: issue?.location || formData.location
      };

      console.log('Submitting issue with payload:', payload);

      if (issue) {
        response = await axiosInstance.put(`/api/issues/${issue.id}`, payload);
      } else {
        response = await axiosInstance.post('/api/issues', payload);
      }

      if (response.data) {
        toast({
          title: issue ? 'Issue Updated' : 'Issue Created',
          description: issue ? 'Issue has been updated successfully' : 'Issue has been created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        if (onUpdate) {
          onUpdate(response.data.data);
        }
        onClose();
        
        setFormData({
          title: '',
          description: '',
          status: 'OPEN',
          priority: 'MEDIUM',
          category: 'BUG',
          assigneeId: '',
          type: '',
          location: ''
        });
      } else {
        throw new Error('No response data received');
      }
    } catch (error) {
      console.error('Error saving issue:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || error.message || 'Failed to save issue',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay style={{ background: 'rgba(0, 0, 0, 0.4)' }} />
      <ModalContent 
        className={`modal-form ${isDarkMode ? 'dark-mode' : ''}`}
        style={{
          position: 'relative',
          zIndex: 1400,
          margin: '3.75rem auto'
        }}
      >
        <form onSubmit={handleSubmit} className="form-container">
          <ModalHeader className="header-container">
            <Text fontSize="2xl" fontWeight="bold" className="header-title">
              {issue ? 'Edit Issue' : 'Create Issue'}
            </Text>
            {issue?.location && (
              <Text fontSize="md" color="gray.500" className="header-subtitle">
                Location: {issue.location}
              </Text>
            )}
          </ModalHeader>

          <ModalBody className="form-body">
            <div className="form-group">
              <FormLabel className="form-label">Title</FormLabel>
              <Input
                className="form-control"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                placeholder="Enter issue title"
                required
              />
            </div>

            <div className="form-group">
              <FormLabel className="form-label">Description</FormLabel>
              <Textarea
                className="form-control"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Describe the issue"
                rows={4}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <FormLabel className="form-label">Status</FormLabel>
                <Select
                  className="form-control"
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                >
                  <option value="OPEN">Open</option>
                  <option value="IN_PROGRESS">In Progress</option>
                  <option value="RESOLVED">Resolved</option>
                  <option value="CLOSED">Closed</option>
                </Select>
              </div>

              <div className="form-group">
                <FormLabel className="form-label">Priority</FormLabel>
                <Select
                  className="form-control"
                  value={formData.priority}
                  onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                >
                  <option value="LOW">Low</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HIGH">High</option>
                  <option value="CRITICAL">Critical</option>
                </Select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <FormLabel className="form-label">Category</FormLabel>
                <Select
                  className="form-control"
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                >
                  <option value="BUG">Bug</option>
                  <option value="FEATURE">Feature</option>
                  <option value="IMPROVEMENT">Improvement</option>
                  <option value="TECHNICAL_DEBT">Technical Debt</option>
                </Select>
              </div>

              <div className="form-group">
                <FormLabel className="form-label">Assignee</FormLabel>
                <Select
                  className="form-control"
                  value={formData.assigneeId}
                  onChange={(e) => setFormData({ ...formData, assigneeId: e.target.value })}
                >
                  <option value="">Unassigned</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.username || user.email}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="form-group">
              <FormLabel className="form-label">Location</FormLabel>
              <Input
                className="form-control"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                placeholder="Enter issue location"
              />
            </div>
          </ModalBody>

          <ModalFooter className="form-footer">
            <Button 
              className="btn btn-secondary" 
              mr={3} 
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              type="submit"
              isLoading={loading}
            >
              {issue ? 'Update Issue' : 'Create Issue'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default IssueModal;
