import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import ProjectForm from './ProjectForm';
import './ProjectList.css';
import '../styles/global.css';
import '../styles/globalform.css';
import { useDarkMode } from '../../App';
import { WS_BASE_URL } from '../config/config';
import { API_BASE_URL } from '../config/config';

const ProjectList = ({ onSelectProject, onAddProject, isProjectFormVisible, handleAddProject, handleCloseProjectForm, activeProject }) => {
  const { isDarkMode } = useDarkMode();
  const [projects, setProjects] = useState([]);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const navigate = useNavigate();

  const setupSocket = () => {
    console.group('Project List Socket Connection Setup');
    console.log('Attempting to establish project list socket connection');

    // Disconnect existing socket if it exists
    if (socketRef.current) {
      try {
        socketRef.current.disconnect();
        console.log('Previous project list socket disconnected');
      } catch (disconnectError) {
        console.error('Error disconnecting previous project list socket:', disconnectError);
      }
    }

    // Retrieve authentication token
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. Cannot establish socket connection.');
      return null;
    }

    // Create new socket connection with enhanced authentication
    const newSocket = io(WS_BASE_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      randomizationFactor: 0.5,
      timeout: 10000,
      forceNew: true,
      auth: {
        token: token
      },
      query: {
        token: token
      }
    });

    // Detailed socket event logging
    newSocket.on('connect', () => {
      console.log('Project list socket connected successfully');
      console.log('Project list Socket ID:', newSocket.id);
    });

    newSocket.on('connect_error', (error) => {
      console.group('Project List Socket Connection Error');
      console.error('Connection error details:', error);
      console.log('Error name:', error.name);
      console.log('Error message:', error.message);
      console.log('Authentication token present:', !!token);
      
      // Detailed authentication error handling
      if (error.message.includes('Authentication')) {
        console.error('Socket authentication failed. Logging out user.');
        // Perform logout and redirect to login
        localStorage.removeItem('token');
        navigate('/login');
      }
      
      console.groupEnd();
    });

    newSocket.on('disconnect', (reason) => {
      console.group('Project List Socket Disconnected');
      console.log('Disconnect reason:', reason);
      console.log('Was socket connected before disconnect:', newSocket.connected);
      console.groupEnd();

      // Attempt reconnection if not an intentional disconnect
      if (reason !== 'io client disconnect') {
        newSocket.connect();
      }
    });

    newSocket.on('reconnect', (attemptNumber) => {
      console.group('Project List Socket Reconnected');
      console.log('Reconnected after', attemptNumber, 'attempts');
      console.groupEnd();
    });

    console.groupEnd();
    return newSocket;
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/api/projects');
        setProjects(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Gagal mengambil data proyek');
        setLoading(false);
      }
    };

    fetchProjects();

    // Setup socket connection
    const newSocket = setupSocket();
    socketRef.current = newSocket;

    // Socket listeners
    if (newSocket) {
      newSocket.on('newProject', (project) => {
        setProjects(prevProjects => {
          // Avoid duplicates
          const exists = prevProjects.some(p => p.id === project.id);
          return exists 
            ? prevProjects.map(p => p.id === project.id ? project : p)
            : [...prevProjects, project];
        });
      });

      newSocket.on('removeProject', (projectId) => {
        setProjects(prevProjects => 
          prevProjects.filter(p => p.id !== projectId)
        );
      });
    }

    // Cleanup
    return () => {
      if (socketRef.current) {
        socketRef.current.off('newProject');
        socketRef.current.off('removeProject');
        socketRef.current.disconnect();
      }
    };
  }, []);

  const handleDeleteProject = async (project) => {
    try {
      await axiosInstance.delete(`/api/projects/${project.id}`);
      socketRef.current.emit('projectDeleted', project.id);
    } catch (error) {
      setError('Gagal menghapus proyek');
    }
  };

  const handleAddProjectWithUpdate = async (newProject) => {
    try {
      const response = await axiosInstance.post('/api/projects', newProject);
      socketRef.current.emit('projectCreated', response.data);
      handleCloseProjectForm();
    } catch (error) {
      setError('Gagal menambah proyek');
    }
  };

  const confirmDeleteProject = () => {
    const updatedProjects = projects.filter(p => p.id !== projectToDelete.id);
    setProjects(updatedProjects);
    setProjectToDelete(null);
  };

  return (
    <div className={`list-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>Daftar Proyek</h2>
        <div className="actions">


       
        <button className="btn btn-primary" onClick={onAddProject} title="Tambah Proyek">Tambah Proyek</button>
        </div>
      </div>
      
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div 
              key={`${project.id}-${index}`} 
              className={`project-card ${activeProject?.id === project.id ? 'active' : ''}`}
              onClick={() => onSelectProject(project)}
            >
              <div className="project-thumbnail-container">
                {project.thumbnail ? (
                  <img src={project.thumbnail} alt={project.name} className="project-thumbnail" />
                ) : (
                  <div className="project-thumbnail-placeholder">
                    <span>{project.name[0]}</span>
                  </div>
                )}
            
              </div>
              <div className="project-info">
                <h3>{project.name}</h3>
                <p className="project-description">{project.description}</p>
              </div>
              <div className="project-actions">
                <Link to={`/projects/${project.id}`} className="detail-button">Detail</Link>
                <button onClick={() => handleDeleteProject(project)} className="delete-project-button">Hapus</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isProjectFormVisible && (
        <ProjectForm
          onAddProject={handleAddProjectWithUpdate}
          onClose={handleCloseProjectForm}
        />
      )}
      {projectToDelete && (
        <div className="delete-confirmation-popup">
          <p>Apakah Anda yakin ingin menghapus proyek "{projectToDelete.name}"?</p>
          <button onClick={confirmDeleteProject} className="confirm-delete-button">Ya, Hapus</button>
          <button onClick={() => setProjectToDelete(null)} className="cancel-delete-button">Batal</button>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
