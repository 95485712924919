import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import './ReportManager.css';
import { API_BASE_URL } from '../config/config';
import '../styles/global.css';

const HashtagReport = ({ isDarkMode, activeProject }) => {
  const [hashtagData, setHashtagData] = useState({
    trendingHashtags: [],
    hashtagsByType: {},
    hashtagTimeline: [],
    totalActivities: 0,
    hashtagTasks: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    project: '',
    status: '',
    hashtag: ''
  });

  // Fungsi untuk sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Fungsi untuk mendapatkan data yang sudah difilter dan disort
  const downloadExcel = () => {
    const filteredData = getFilteredAndSortedData();
    
    // Menyiapkan data untuk Excel
    const excelData = filteredData.map(task => ({
      'Project': task.projectName,
      'Task': task.taskName,
      'Assignee': task.assigneeName,
      'Start Date': task.startDate ? new Date(task.startDate).toLocaleDateString() : '-',
      'Due Date': task.dueDate ? new Date(task.dueDate).toLocaleDateString() : '-',
      'Status': task.status,
      'Hashtag': task.hashtag,
      'Progress': `${task.progress}%`
    }));

    // Membuat workbook dan worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hashtag Tasks');

    // Download file
    XLSX.writeFile(wb, 'hashtag_tasks_report.xlsx');
  };

  const getFilteredAndSortedData = () => {
    if (!Array.isArray(hashtagData.hashtagTasks)) return [];

    let filteredData = hashtagData.hashtagTasks.filter(task => {
      return (
        task.projectName?.toLowerCase().includes(filters.project.toLowerCase()) &&
        task.status?.toLowerCase().includes(filters.status.toLowerCase()) &&
        task.hashtag?.toLowerCase().includes(filters.hashtag.toLowerCase())
      );
    });

    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  };

  useEffect(() => {
    fetchHashtagData();
  }, []);

  const fetchHashtagData = async () => {
    try {
      setLoading(true);
      const url = `${API_BASE_URL}/api/activities/hashtag-report${
        activeProject ? `?projectId=${activeProject}` : ''
      }`;
      const response = await fetch(url);
      const data = await response.json();
      console.log('Client received data:', data);
      console.log('Client received hashtagTasks:', data.hashtagTasks);
      setHashtagData(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching hashtag data:', err);
      setError('Gagal mengambil data hashtag');
    } finally {
      setLoading(false);
    }
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: isDarkMode ? '#E2E8F0' : '#2D3748'
        }
      },
      title: {
        display: true,
        color: isDarkMode ? '#E2E8F0' : '#2D3748'
      }
    },
    scales: {
      y: {
        ticks: {
          color: isDarkMode ? '#E2E8F0' : '#2D3748'
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        }
      },
      x: {
        ticks: {
          color: isDarkMode ? '#E2E8F0' : '#2D3748'
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        }
      }
    }
  };

  const trendingHashtagsData = {
    labels: hashtagData.trendingHashtags.map(h => h.name),
    datasets: [{
      label: 'Jumlah Penggunaan',
      data: hashtagData.trendingHashtags.map(h => h.count),
      backgroundColor: [
        'rgba(54, 162, 235, 0.8)',
        'rgba(255, 99, 132, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        'rgba(255, 206, 86, 0.8)',
        'rgba(153, 102, 255, 0.8)'
      ],
      borderWidth: 1
    }]
  };

  const hashtagTimelineData = {
    labels: hashtagData.hashtagTimeline.map(h => h.date),
    datasets: [{
      label: 'Aktivitas Hashtag per Hari',
      data: hashtagData.hashtagTimeline.map(h => h.count),
      fill: true,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      tension: 0.4
    }]
  };

  if (loading) {
    return <div className="loading">Memuat data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className={`hashtag-report ${isDarkMode ? 'dark' : ''}`}>
      <div className="report-header">
        <h2>Laporan Aktivitas Hashtag</h2>
        <div className="summary-stats">
          <div className="stat-card">
            <h3>Total Aktivitas</h3>
            <p>{hashtagData.totalActivities}</p>
          </div>
          <div className="stat-card">
            <h3>Hashtag Unik</h3>
            <p>{hashtagData.trendingHashtags.length}</p>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <div className="chart-section">
          <h3>Hashtag Terpopuler</h3>
          <div className="chart-wrapper">
            <Pie 
              data={trendingHashtagsData} 
              options={{
                ...chartOptions,
                plugins: {
                  ...chartOptions.plugins,
                  title: {
                    ...chartOptions.plugins.title,
                    text: 'Distribusi Penggunaan Hashtag'
                  }
                }
              }}
            />
          </div>
        </div>

        <div className="chart-section">
          <h3>Timeline Aktivitas Hashtag</h3>
          <div className="chart-wrapper">
            <Bar 
              data={hashtagTimelineData}
              options={{
                ...chartOptions,
                plugins: {
                  ...chartOptions.plugins,
                  title: {
                    ...chartOptions.plugins.title,
                    text: 'Aktivitas Hashtag per Hari'
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="hashtag-list">
        <h3>Daftar Hashtag Terpopuler</h3>
        <div className="hashtag-grid">
          {hashtagData.trendingHashtags.map((hashtag, index) => (
            <div key={index} className="hashtag-card">
              <span className="hashtag-name">{hashtag.name}</span>
              <span className="hashtag-count">{hashtag.count} penggunaan</span>
              {hashtag.trend && (
                <span className={`trend-indicator ${hashtag.trend}`}>
                  {hashtag.trend === 'up' ? '↑' : '↓'} {hashtag.change}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="hashtag-tasks">
        <h3>Task dengan Hashtag</h3>
        
        {/* Filter Controls */}
        <div className="filter-controls">
          <button
            onClick={downloadExcel}
            className="btn-primary"
            style={{ marginRight: '10px' }}
          >
            Download Excel
          </button>
          <input
            type="text"
            placeholder="Filter by Project"
            value={filters.project}
            onChange={(e) => setFilters({ ...filters, project: e.target.value })}
            className="filter-input"
          />
          <select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            className="filter-select"
          >
            <option value="">All Status</option>
            <option value="pending">Pending</option>
            <option value="in_progress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="on_hold">On Hold</option>
          </select>
          <input
            type="text"
            placeholder="Filter by Hashtag"
            value={filters.hashtag}
            onChange={(e) => setFilters({ ...filters, hashtag: e.target.value })}
            className="filter-input"
          />
        </div>

        <div className="table-responsive">
          <table className="report-table">
            <thead>
              <tr>
                <th onClick={() => requestSort('projectName')} className="sortable-header">
                  Project {sortConfig.key === 'projectName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('taskName')} className="sortable-header">
                  Task {sortConfig.key === 'taskName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('assigneeName')} className="sortable-header">
                  Assignee {sortConfig.key === 'assigneeName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('startDate')} className="sortable-header">
                  Start Date {sortConfig.key === 'startDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('dueDate')} className="sortable-header">
                  Due Date {sortConfig.key === 'dueDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('status')} className="sortable-header">
                  Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('hashtag')} className="sortable-header">
                  Hashtag {sortConfig.key === 'hashtag' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('progress')} className="sortable-header">
                  Progress {sortConfig.key === 'progress' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
              </tr>
            </thead>
            <tbody>
              {getFilteredAndSortedData().map((task, index) => (
                <tr key={index}>
                  <td>{task.projectName}</td>
                  <td>{task.taskName}</td>
                  <td>{task.assigneeName}</td>
                  <td>{task.startDate ? new Date(task.startDate).toLocaleDateString() : '-'}</td>
                  <td>{task.dueDate ? new Date(task.dueDate).toLocaleDateString() : '-'}</td>
                  <td>
                    <span className={`status-badge ${task.status.toLowerCase()}`}>
                      {task.status}
                    </span>
                  </td>
                  <td>{task.hashtag}</td>
                  <td>{task.progress}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HashtagReport;
