import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import './RoleManager.css';

const RoleManager = () => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState({
    name: '',
    description: '',
    permissions: []
  });
  const [editingRole, setEditingRole] = useState(null);

  const availablePermissions = [
    'create_project',
    'edit_project',
    'delete_project',
    'manage_users',
    'manage_tasks',
    'manage_shots',
    'manage_assets',
    'manage_sequences',
    'view_reports',
    'manage_statuses',
    'manage_departments',
    'manage_roles'
  ];

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get('/api/roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Error mengambil data role:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingRole) {
        await axiosInstance.put(`${API_BASE_URL}/api/roles/${editingRole.id}`, newRole);
      } else {
        await axiosInstance.post('/api/roles', newRole);
      }
      setNewRole({ name: '', description: '', permissions: [] });
      setEditingRole(null);
      fetchRoles();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Yakin ingin menghapus role ini?')) {
      try {
        await axiosInstance.delete(`${API_BASE_URL}/api/roles/${id}`);
        fetchRoles();
      } catch (error) {
        console.error('Error menghapus role:', error);
      }
    }
  };

  const handlePermissionChange = (permission) => {
    setNewRole(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission]
    }));
  };

  return (
    <div className="role-manager">
      <form onSubmit={handleSubmit} className="role-form">
        <input
          type="text"
          placeholder="Nama Role"
          value={newRole.name}
          onChange={(e) => setNewRole({...newRole, name: e.target.value})}
          required
        />
        <textarea
          placeholder="Deskripsi"
          value={newRole.description}
          onChange={(e) => setNewRole({...newRole, description: e.target.value})}
        />
        <div className="permissions-container">
          <h3>Permissions:</h3>
          <div className="permissions-grid">
            {availablePermissions.map(permission => (
              <label key={permission} className="permission-item">
                <input
                  type="checkbox"
                  checked={newRole.permissions.includes(permission)}
                  onChange={() => handlePermissionChange(permission)}
                />
                {permission.split('_').map(word => 
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </label>
            ))}
          </div>
        </div>
        <button type="submit">
          {editingRole ? 'Update Role' : 'Tambah Role'}
        </button>
      </form>

      <div className="role-list">
        {roles.map(role => (
          <div key={role.id} className="role-item">
            <div className="role-info">
              <h3>{role.name}</h3>
              <p>{role.description}</p>
              <div className="role-permissions">
                {role.permissions.map(permission => (
                  <span key={permission} className="permission-tag">
                    {permission}
                  </span>
                ))}
              </div>
            </div>
            <div className="role-actions">
              <button onClick={() => {
                setEditingRole(role);
                setNewRole(role);
              }}>Edit</button>
              <button onClick={() => handleDelete(role.id)}>Hapus</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoleManager;