const blobUrls = new Map();

const handleFileInBrowser = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('Tidak ada file yang dipilih'));
      return;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    const supportedExtensions = ['mp4', 'mov', 'webm', 'ogg'];
    
    if (!supportedExtensions.includes(extension)) {
      reject(new Error('Format file tidak didukung. Gunakan MP4, MOV, WEBM, atau OGG.'));
      return;
    }

    const fileUrl = URL.createObjectURL(file);
    blobUrls.set(file.name, fileUrl);
    resolve(fileUrl);
  });
};

export const getFilePath = async (file) => {
  if (window.electron) {
    return window.electron.getFilePath(file);
  }
  return handleFileInBrowser(file);
};

export const cleanupBlobUrl = (filename) => {
  if (blobUrls.has(filename)) {
    URL.revokeObjectURL(blobUrls.get(filename));
    blobUrls.delete(filename);
  }
}; 