import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = () => {
  const token = localStorage.getItem('token');
  const userRole = JSON.parse(localStorage.getItem('userRole'));

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('userRole');
      alert('Sesi Anda telah habis. Silakan login kembali.');
      return <Navigate to="/login" replace />;
    }
  } catch (error) {
    console.error('Invalid token:', error);
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    alert('Token tidak valid. Silakan login kembali.');
    return <Navigate to="/login" replace />;
  }

  return <Outlet context={{ userRole }} />;
};

export default PrivateRoute;