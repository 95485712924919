import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaEye, FaClock, FaUser, FaCalendarAlt, FaImage, FaBox, FaFilter, FaPlus, FaList, FaThLarge } from 'react-icons/fa';
import '../styles/CardView.css';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import Select from 'react-select';
import { API_BASE_URL } from '../config/config';
import TaskForm from './TaskForm';
import axiosInstance from '../config/axios';


const getTaskThumbnail = (task, shots = [], assets = []) => {
  // Prioritaskan thumbnail task itu sendiri
  if (task.thumbnailUrl) {
    // Jika sudah URL lengkap, kembalikan sebagaimana adanya
    if (task.thumbnailUrl.startsWith('http')) return task.thumbnailUrl;
    
    // Jika adalah path relatif, tambahkan base URL
    return `${API_BASE_URL}${task.thumbnailUrl.startsWith('/') ? task.thumbnailUrl : '/' + task.thumbnailUrl}`;
  }
  
  // Cari thumbnail dari shot terkait
  const relatedShot = shots.find(shot => 
    task.selectedShots?.includes(shot.id) && shot.thumbnailUrl
  );
  
  if (relatedShot) {
    // Jika sudah URL lengkap, kembalikan sebagaimana adanya
    if (relatedShot.thumbnailUrl.startsWith('http')) return relatedShot.thumbnailUrl;
    
    // Jika adalah path relatif, tambahkan base URL
    return `${API_BASE_URL}${relatedShot.thumbnailUrl.startsWith('/') ? relatedShot.thumbnailUrl : '/' + relatedShot.thumbnailUrl}`;
  }
  
  // Cari thumbnail dari asset terkait
  const relatedAsset = assets.find(asset => 
    task.selectedAssets?.includes(asset.id) && asset.thumbnailUrl
  );
  
  if (relatedAsset) {
    // Jika sudah URL lengkap, kembalikan sebagaimana adanya
    if (relatedAsset.thumbnailUrl.startsWith('http')) return relatedAsset.thumbnailUrl;
    
    // Jika adalah path relatif, tambahkan base URL
    return `${API_BASE_URL}${relatedAsset.thumbnailUrl.startsWith('/') ? relatedAsset.thumbnailUrl : '/' + relatedAsset.thumbnailUrl}`;
  }
  
  // Jika tidak ada thumbnail, kembalikan placeholder
  return '/assets/images/task-placeholder.png';
};

const TaskCardView = ({ 
  tasks,
  shots,
  assets,
  onDeleteTask,
  activeProject,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  userRole,
  isDarkMode,
  selectedTask,
  setSelectedTask,
  onEditTask,
  socket,
  onTaskSelect
}) => {
  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    priority: null,
    progress: { min: 0, max: 100 },
    assignee: null,
    relatedShot: null,
    relatedAsset: null
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchStatuses();
    fetchUsers();
  }, []);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_tasks', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleEditClick = (task) => {
    setEditingId(task.id);
    setEditFormData(task);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      await onEditTask(editingId, formData);
      handleFormClose();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleAddTask = async (formData) => {
    try {
      const response = await axiosInstance.post('/api/tasks', formData);
      if (socket) {
        socket.emit('createTask', response.data);
      }
      handleFormClose();
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  };

  const handleTaskClick = (task) => {
    if (setSelectedTask) {
      setSelectedTask(task);
    }
    if (onTaskSelect) {
      onTaskSelect(task);
    }
  };

  const handleOverviewClick = (taskId) => {
    navigate(`/task-overview/${taskId}`);
  };

  const getStatusBadge = (statusId) => {
    if (!statusId) return (
      <span className="status-badge pending">
        Pending
      </span>
    );
    
    const status = statuses.find(s => s.id === parseInt(statusId));
    if (!status) return (
      <span className="status-badge unknown">
        Unknown
      </span>
    );

    const statusClass = status.name.toLowerCase().replace(/\s+/g, '-');
    return (
      <span className={`status-badge ${statusClass}`}>
        {status.name}
      </span>
    );
  };

  const getProgressColor = (progress) => {
    progress = Number(progress) || 0;
    if (progress < 30) return '#dc3545';  // Red
    if (progress < 70) return '#ffc107';  // Yellow
    return '#28a745';  // Green
  };

  const getPriorityColor = (priority) => {
    switch (priority?.toLowerCase()) {
      case 'high':
        return '#dc3545';  // Red
      case 'medium':
        return '#ffc107';  // Yellow
      case 'low':
        return '#28a745';  // Green
      default:
        return '#6c757d';  // Gray
    }
  };

  const getAssignedUserName = (userId) => {
    const user = users.find(u => u.id === userId);
    return user ? (user.username || user.email || 'Unknown User') : 'Unassigned';
  };

  const getRelatedShots = (taskId) => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) return [];

    // Cari shots yang memiliki task ini di selectedTasks
    const shotsWithTask = shots.filter(shot => 
      shot.selectedTasks?.includes(taskId)
    );

    // Cari shots yang ada di selectedShots task
    const taskSelectedShots = shots.filter(shot => 
      task.selectedShots?.includes(shot.id)
    );

    // Gabungkan dan hapus duplikat
    const allRelatedShots = [...shotsWithTask, ...taskSelectedShots];
    const uniqueShots = Array.from(new Set(allRelatedShots.map(shot => shot.id)))
      .map(id => allRelatedShots.find(shot => shot.id === id));

    return uniqueShots;
  };

  const getRelatedAsset = (taskId) => {
    return assets?.find(asset => asset.selectedTasks?.includes(taskId));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: '',
      priority: null,
      progress: { min: 0, max: 100 },
      assignee: null,
      relatedShot: null,
      relatedAsset: null
    });
  };

  const getTaskThumbnail = (task, assets, shots) => {
    // Cari aset yang memiliki task ini
    const relatedAsset = assets?.find(asset => 
      asset.selectedTasks && asset.selectedTasks.includes(task.id)
    );

    // Cari shot yang memiliki task ini
    const relatedShot = shots?.find(shot => 
      shot.selectedTasks && shot.selectedTasks.includes(task.id)
    );

    // Prioritaskan thumbnail dari aset, kemudian dari shot
    const thumbnailUrl = relatedAsset?.thumbnailUrl || relatedShot?.thumbnailUrl;

    // Jika tidak ada thumbnail, kembalikan placeholder
    if (!thumbnailUrl) return '/assets/images/task-placeholder.png';

    // Tambahkan base URL jika diperlukan
    return thumbnailUrl.startsWith('http') 
      ? thumbnailUrl 
      : `${API_BASE_URL}/${thumbnailUrl.replace(/^\/+/, '')}`;
  };

  const filteredTasks = tasks.filter(task => {
    if (filters.status && task.statusId !== filters.status.value) return false;
    if (filters.name && !task.taskName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    if (filters.priority && task.priority?.toLowerCase() !== filters.priority.value.toLowerCase()) return false;
    if (task.progress < filters.progress.min || task.progress > filters.progress.max) return false;
    if (filters.assignee && task.assignedUser?.id !== filters.assignee.value) return false;
    
    if (filters.relatedShot) {
      const hasRelatedShot = shots.some(shot => 
        shot.id === filters.relatedShot.value && 
        shot.selectedTasks?.includes(task.id)
      );
      if (!hasRelatedShot) return false;
    }
    
    if (filters.relatedAsset) {
      const hasRelatedAsset = assets.some(asset => 
        asset.id === filters.relatedAsset.value && 
        asset.selectedTasks?.includes(task.id)
      );
      if (!hasRelatedAsset) return false;
    }
    
    if (searchTerm && !task.taskName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });
  return (
    <div className={`card-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>Card View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission && (
            <button 
              className="btn btn-primary"
              onClick={handleEditClick}
            >
              <FaPlus /> Add Task
            </button>
          )}
          <div className="view-toggle">
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <div className="filter-group">
              <label>Priority</label>
              <Select
                value={filters.priority}
                onChange={(value) => handleFilterChange('priority', value)}
                options={[
                  { value: 'high', label: 'High' },
                  { value: 'medium', label: 'Medium' },
                  { value: 'low', label: 'Low' }
                ]}
                isClearable
                placeholder="Filter by priority"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Progress Range</label>
              <div className="progress-range">
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={filters.progress.min}
                  onChange={(e) => handleFilterChange('progress', { 
                    ...filters.progress, 
                    min: parseInt(e.target.value) 
                  })}
                  className="form-control"
                />
                <span>to</span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={filters.progress.max}
                  onChange={(e) => handleFilterChange('progress', { 
                    ...filters.progress, 
                    max: parseInt(e.target.value) 
                  })}
                  className="form-control"
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Assignee</label>
              <Select
                value={filters.assignee}
                onChange={(value) => handleFilterChange('assignee', value)}
                options={users.map(user => ({
                  value: user.id,
                  label: user.username || user.email
                }))}
                isClearable
                placeholder="Filter by assignee"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Shot</label>
              <Select
                value={filters.relatedShot}
                onChange={(value) => handleFilterChange('relatedShot', value)}
                options={shots.map(shot => ({
                  value: shot.id,
                  label: shot.shotName
                }))}
                isClearable
                placeholder="Filter by related shot"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Asset</label>
              <Select
                value={filters.relatedAsset}
                onChange={(value) => handleFilterChange('relatedAsset', value)}
                options={assets.map(asset => ({
                  value: asset.id,
                  label: asset.assetName
                }))}
                isClearable
                placeholder="Filter by related asset"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      <div className={`card-grid ${isDarkMode ? 'dark-mode' : ''}`}>
        {filteredTasks.map((task) => {
          const relatedShots = getRelatedShots(task.id);
          const relatedAsset = getRelatedAsset(task.id);

          return (
            <div 
              key={task.id}
              className={`task-card ${selectedTask?.id === task.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
              onClick={() => handleTaskClick(task)}
            >
              <div className="card-thumbnail">
                <img 
                  src={getTaskThumbnail(task, shots, assets)} 
                  alt={`Thumbnail for ${task.taskName}`} 
                  className="thumbnail-image" 
                />
              </div>
              
              <div className="card-content">
                <div className="card-header">
                  <h3 className="card-title">{task.taskName}</h3>
                  <span className={`status-badge status-${getStatusBadge(task.statusId)}`}>
                    {getStatusBadge(task.statusId)}
                  </span>
                </div>

                <div className="card-description">
                  {task.description || 'No description'}
                </div>

                <div className="card-content">
                  <div className="progress-container">
                    <div className="progress-bar">
                      <div 
                        className={`progress-fill progress-${task.progress < 30 ? 'low' : task.progress < 70 ? 'medium' : 'high'}`}
                        style={{ width: `${task.progress || 0}%` }}
                      >
                        {task.progress || 0}%
                      </div>
                    </div>
                  </div>

                  <div className="stats-container">
                    <div className="stat-item">
                      <div className="stat-label">
                        <FaClock /> Hours
                      </div>
                      <div className="stat-value">{task.timeSpent || 0}</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-label">
                        <FaCalendarAlt /> Due
                      </div>
                      <div className="stat-value">{formatDate(task.dueDate)}</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-label">
                        <FaUser /> Assigned
                      </div>
                      <div className="stat-value">{task.assignedUser?.username || task.assignedUser?.email || 'Unassigned'}</div>
                    </div>
                  </div>

                  <div className="related-items-container">
                    <div className="related-shots-container">
                      {relatedShots.map(shot => (
                        <div key={shot.id} className="shot-badge-container">
                          <span 
                            className={`shot-badge ${shot.statusId ? `status-${shot.statusId}` : 'status-pending'}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/shot-overview/${shot.id}`);
                            }}
                          >
                            <div className="shot-badge-content">
                              <FaImage />
                              <span className="shot-name">{shot.shotName}</span>
                            </div>
                            {shot.sequence && (
                              <div className="shot-stats">
                                <span className="sequence-name" title={`Sequence: ${shot.sequence.sequenceName}`}>
                                  {shot.sequence.sequenceName}
                                </span>
                              </div>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="related-assets-container">
                      {assets.filter(asset => 
                        // Tampilkan asset yang memilih task ini
                        asset.selectedTasks?.includes(task.id) ||
                        // Atau asset dari shot yang memiliki task ini
                        shots.some(shot => 
                          shot.selectedTasks?.includes(task.id) && 
                          shot.selectedAssets?.includes(asset.id)
                        )
                      ).map(asset => (
                        <div key={asset.id} className="asset-badge-container">
                          <span 
                            className={`asset-badge ${asset.statusId ? `status-${asset.statusId}` : 'status-pending'}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/asset-overview/${asset.id}`);
                            }}
                          >
                            <div className="asset-badge-content">
                              <FaBox />
                              <span className="asset-name">{asset.assetName}</span>
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                    {task.priority && (
                      <span className={`priority-badge priority-${task.priority.toLowerCase()}`}>
                        {task.priority}
                      </span>
                    )}
                    {task.tags?.map(tag => (
                      <span key={tag} className="item-badge">
                        #{tag}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="card-footer">
                  <div className="card-actions">
                    {hasManagePermission && (
                      <>
                        <button
                          className="action-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(task);
                          }}
                          title="Edit"
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="action-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteTask(task.id);
                          }}
                          title="Delete"
                        >
                          <FaTrash />
                        </button>
                      </>
                    )}
                    <button
                      className="action-button"
                      onClick={() => handleOverviewClick(task.id)}
                      title="View Details"
                    >
                      <FaEye />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {selectedTask?.id && isActivityPanelOpen && (
          <ActivityPanel
            item={selectedTask}
            type="task"
            isOpen={isActivityPanelOpen}
            onClose={() => {
              setSelectedTask(null);
              setIsActivityPanelOpen(false);
            }}
            onAddComment={() => {
              // Refresh tasks after adding comment
              if (activeProject?.id) {
                // fetchTasks();
              }
            }}
            onEditComment={() => {
              // Refresh tasks after editing comment
              if (activeProject?.id) {
                // fetchTasks();
              }
            }}
            onDeleteComment={() => {
              // Refresh tasks after deleting comment
              if (activeProject?.id) {
                // fetchTasks();
              }
            }}
            onPinComment={() => {
              // Refresh tasks after pinning comment
              if (activeProject?.id) {
                // fetchTasks();
              }
            }}
            currentUser={userRole?.username}
            isDarkMode={isDarkMode}
          />
        )}
      </div>
    </div>
  );
};

export default TaskCardView;
