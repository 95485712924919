import axios from 'axios';
import { API_BASE_URL } from '../config/config';

// Create axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

export const epicService = {
  getAllEpics: async () => {
    try {
      const response = await api.get('/api/epics');
      return response.data;
    } catch (error) {
      console.error('Error fetching epics:', error.response?.data || error.message);
      throw error;
    }
  },

  createEpic: async (epicData) => {
    try {
      console.log('Sending epic data:', epicData);
      const formattedData = {
        ...epicData,
        progress: Number(epicData.progress)
      };
      const response = await api.post('/api/epics', formattedData);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  updateEpic: async (id, epicData) => {
    try {
      const formattedData = {
        ...epicData,
        progress: Number(epicData.progress)
      };
      const response = await api.put(`/api/epics/${id}`, formattedData);
      return response.data;
    } catch (error) {
      console.error('Error updating epic:', error.response?.data || error.message);
      throw error;
    }
  },

  deleteEpic: async (id) => {
    try {
      const response = await api.delete(`/api/epics/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting epic:', error.response?.data || error.message);
      throw error;
    }
  }
};
