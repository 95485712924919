import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';

const DepartmentManager = () => {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState({
    name: '',
    description: '',
    isActive: true
  });

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axiosInstance.get('/api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Error mengambil data department:', error);
    }
  };

  const handleAddDepartment = async () => {
    try {
      await axiosInstance.post('/api/departments', newDepartment);
      setNewDepartment({ name: '', description: '', isActive: true });
      fetchDepartments();
    } catch (error) {
      console.error('Error menambah department:', error);
    }
  };

  return (
    <div className="department-manager">
      <div className="add-department-form">
        <input
          type="text"
          placeholder="Nama Department"
          value={newDepartment.name}
          onChange={(e) => setNewDepartment({...newDepartment, name: e.target.value})}
        />
        <textarea
          placeholder="Deskripsi"
          value={newDepartment.description}
          onChange={(e) => setNewDepartment({...newDepartment, description: e.target.value})}
        />
        <button onClick={handleAddDepartment}>Tambah Department</button>
      </div>
      
      <div className="department-list">
        {departments.map(dept => (
          <div key={dept.id} className="department-item">
            <h3>{dept.name}</h3>
            <p>{dept.description}</p>
            <div className="department-controls">
              <button>Edit</button>
              <button>Hapus</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepartmentManager;