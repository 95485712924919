import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import '../styles/globalform.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Text
} from '@chakra-ui/react';

const PlanForm = ({ 
  isOpen,
  onSubmit, 
  onClose, 
  editingPlan, 
  users, 
  activeProject, 
  currentUser, 
  isDarkMode,
  isBroadcast = false,
  presentationLink = ''
}) => {
  const [planData, setPlanData] = useState({
    title: editingPlan?.title || '',
    description: editingPlan?.description || '',
    date: editingPlan?.date ? new Date(editingPlan.date).toISOString().split('T')[0] : '',
    time: editingPlan?.time || '',
    duration: editingPlan?.duration || 60,
    location: editingPlan?.location || '',
    type: isBroadcast ? 'review' : (editingPlan?.type || 'meeting'),
    attendees: editingPlan?.attendees || [],
    organizerId: editingPlan?.organizerId || currentUser?.id || '',
    projectId: activeProject?.id,
    presentationLink: presentationLink
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!activeProject || !activeProject.id) {
      alert('Project ID tidak tersedia');
      return;
    }
    onSubmit({ ...planData, projectId: activeProject.id });
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="xl"
      isCentered
    >
      <ModalOverlay style={{ background: 'rgba(0, 0, 0, 0.4)' }} />
      <ModalContent className={`modal-form ${isDarkMode ? 'dark-mode' : ''}`}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            <Text fontSize="xl" fontWeight="bold">
              {editingPlan ? 'Edit Plan' : 'Create New Plan'}
            </Text>
          </ModalHeader>

          <ModalBody>
            <div className="form-group">
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                value={planData.title}
                onChange={(e) => setPlanData({ ...planData, title: e.target.value })}
                required
                placeholder="Enter plan title"
              />
            </div>

            <div className="form-group">
              <FormLabel>Description</FormLabel>
              <Textarea
                value={planData.description}
                onChange={(e) => setPlanData({ ...planData, description: e.target.value })}
                required
                placeholder="Enter plan description"
              />
            </div>

            <div className="form-group">
              <FormLabel>Organizer</FormLabel>
              <select
                className="form-select"
                value={planData.organizerId}
                onChange={(e) => setPlanData({ ...planData, organizerId: parseInt(e.target.value) })}
                required
              >
                <option value="">Select Organizer</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.username || user.email}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={planData.date}
                onChange={(e) => setPlanData({ ...planData, date: e.target.value })}
                required
              />
            </div>

            <div className="form-group">
              <FormLabel>Time</FormLabel>
              <Input
                type="time"
                value={planData.time}
                onChange={(e) => setPlanData({ ...planData, time: e.target.value })}
                required
              />
            </div>

            <div className="form-group">
              <FormLabel>Duration (minutes)</FormLabel>
              <Input
                type="number"
                value={planData.duration}
                onChange={(e) => setPlanData({ ...planData, duration: parseInt(e.target.value) })}
                required
                min="1"
              />
            </div>

            <div className="form-group">
              <FormLabel>Location</FormLabel>
              <Input
                type="text"
                value={planData.location}
                onChange={(e) => setPlanData({ ...planData, location: e.target.value })}
                required
                placeholder="Enter meeting location"
              />
            </div>

            <div className="form-group">
              <FormLabel>Type</FormLabel>
              <select
                className="form-select"
                value={planData.type}
                onChange={(e) => setPlanData({ ...planData, type: e.target.value })}
                required
              >
                <option value="meeting">Meeting</option>
                <option value="review">Review</option>
                <option value="deadline">Deadline</option>
              </select>
            </div>

            <div className="form-group">
              <FormLabel>Attendees</FormLabel>
              <Select
                isMulti
                options={users.map(user => ({
                  value: user.id,
                  label: user.username || user.email
                }))}
                value={planData.attendees.map(id => {
                  const user = users.find(u => u.id === id);
                  return {
                    value: id,
                    label: user ? (user.username || user.email) : ''
                  };
                })}
                onChange={(selected) => setPlanData({
                  ...planData,
                  attendees: selected ? selected.map(option => option.value) : []
                })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            {planData.type === 'review' && (
              <div className="form-group">
                <FormLabel>Presentation Link</FormLabel>
                <Input
                  type="url"
                  value={planData.presentationLink}
                  onChange={(e) => setPlanData({ ...planData, presentationLink: e.target.value })}
                  required
                  placeholder="Enter presentation link"
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit">
              {editingPlan ? 'Update Plan' : 'Create Plan'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PlanForm;