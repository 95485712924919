import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import './TopBar.css';
import axiosInstance from '../config/axios';
import { useDarkMode } from '../../App';
import { FaSun, FaMoon } from 'react-icons/fa';

const TopBar = ({ activeProject, onLogout, onSelectProject, userRole, socket }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isProjectDropdownVisible, setIsProjectDropdownVisible] = useState(false);
  const [isTaskDropdownVisible, setIsTaskDropdownVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const userDropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);
  const taskDropdownRef = useRef(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/api/projects');
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) setUsername(storedUsername);
  }, []);

  // Gunakan socket dari props
  useEffect(() => {
    if (socket) {
      console.log('TopBar using socket, ID:', socket.id);

      socket.on('connect', () => {
        console.log('TopBar socket connected');
      });

      socket.on('unreadMessagesCount', (count) => {
        console.log('TopBar received unread count:', count);
        setUnreadMessages(count);
      });

      socket.on('newMessage', (message) => {
        const userId = localStorage.getItem('userId');
        if (message.receiverId === userId && !message.read) {
          setUnreadMessages(prev => prev + 1);
        }
      });

      socket.on('disconnect', (reason) => {
        console.log('TopBar socket disconnected, Reason:', reason);
      });

      return () => {
        console.log('TopBar cleanup, removing listeners');
        socket.off('unreadMessagesCount');
        socket.off('newMessage');
        socket.off('connect');
        socket.off('disconnect');
      };
    }
  }, [socket]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
      if (projectDropdownRef.current && !projectDropdownRef.current.contains(event.target)) {
        setIsProjectDropdownVisible(false);
      }
      if (taskDropdownRef.current && !taskDropdownRef.current.contains(event.target)) {
        setIsTaskDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleUserButtonClick = (e) => {
    e.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
    setIsProjectDropdownVisible(false);
    setIsTaskDropdownVisible(false);
  };

  const handleLogout = () => {
    onLogout();
    setIsDropdownVisible(false);
    localStorage.removeItem('username');
  };

  const handleProjectSelect = (project) => {
    onSelectProject(project);
    setIsProjectDropdownVisible(false);
  };

  const handleProjectButtonClick = (e) => {
    e.stopPropagation();
    setIsProjectDropdownVisible(!isProjectDropdownVisible);
    setIsDropdownVisible(false);
    setIsTaskDropdownVisible(false);
  };

  const handleTaskButtonClick = (e) => {
    e.stopPropagation();
    setIsTaskDropdownVisible(!isTaskDropdownVisible);
    setIsDropdownVisible(false);
    setIsProjectDropdownVisible(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className={`topbar ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="top-bar-left">
        <Link to="/dashboard" className="logo">Smood</Link>
        <nav>
          <ul>
            <li><NavLink to="/sequences">Sequence</NavLink></li>
            <li><NavLink to="/shots">Shots</NavLink></li>
            <li><NavLink to="/assets">Assets</NavLink></li>
            <li className="task-menu-container" ref={taskDropdownRef}>
              <button className="nav-button" onClick={handleTaskButtonClick}>Tasks</button>
              {isTaskDropdownVisible && (
                <ul className="task-dropdown-menu">
                  <li><NavLink to="/tasks">All Tasks</NavLink></li>
                  <li><NavLink to="/my-tasks">My Tasks</NavLink></li>
                </ul>
              )}
            </li>
            <li><NavLink to="/projects">Projects</NavLink></li>
            <li><NavLink to="/myspace">MySpace</NavLink></li>
            <li><NavLink to="/plan">Plans</NavLink></li>
            <li><NavLink to="/present">Present</NavLink></li>
            <li><NavLink to="/admin">Admin Panel</NavLink></li>
          </ul>
        </nav>
      </div>
      <div className="top-bar-right">
        <div className="active-project-dropdown" ref={projectDropdownRef}>
          <button onClick={handleProjectButtonClick}>
            Active Project: {activeProject ? activeProject.name : 'None'}
          </button>
          {isProjectDropdownVisible && (
            <ul className="project-dropdown-menu">
              {projects.map(project => (
                <li
                  key={project.id}
                  onClick={() => handleProjectSelect(project)}
                  className={activeProject?.id === project.id ? 'active' : ''}
                >
                  {project.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <input type="text" placeholder="Search..." className="search-input" />
        <div className="user-menu-container" ref={userDropdownRef}>
          <button className="user-menu" onClick={handleUserButtonClick}>
            {username || 'User'}
          </button>
          {isDropdownVisible && (
            <div className="user-dropdown">
              <div style={{ position: 'relative' }}>
                <button onClick={() => navigate('/messenger')}>
                  Messages
                  {unreadMessages > 0 && (
                    <span className="notification-badge">{unreadMessages}</span>
                  )}
                </button>
              </div>
              <button onClick={handleLogout}>Log out</button>
              <button onClick={toggleDarkMode} className="mode-toggle">
                {isDarkMode ? <FaSun /> : <FaMoon />}
              </button>
              <button onClick={() => navigate('/tracking-settings')}>Tracking Setting</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;