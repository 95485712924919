import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { FaUsers, FaFilm, FaLayerGroup, FaTasks, FaChartLine, FaList, FaBell, FaCalendarAlt, FaClock, FaMapMarkerAlt, FaLink } from 'react-icons/fa';
import { BiCameraMovie } from 'react-icons/bi';
import { MdDashboard } from 'react-icons/md';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useDarkMode } from '../../App';
import { API_BASE_URL } from '../config/config';
import '../styles/global.css';

const Dashboard = ({ activeProject, currentUser }) => {
  const { isDarkMode } = useDarkMode();
  const [userProfile, setUserProfile] = useState(null);
  const [statistics, setStatistics] = useState({
    shots: 0,
    sequences: 0,
    assets: 0,
    tasks: 0
  });
  const [departments, setDepartments] = useState([]);
  const [levels, setLevels] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [myTasks, setMyTasks] = useState({
    total: 0,
    completed: 0,
    inProgress: 0,
    pending: 0
  });
  const [reminders, setReminders] = useState([]);
  const [sequenceDetails, setSequenceDetails] = useState([]);
  const [sequenceStats, setSequenceStats] = useState({
    total: 0,
    statusCounts: {}
  });
  const [shotStats, setShotStats] = useState({
    total: 0,
    statusCounts: {}
  });
  const [assetStats, setAssetStats] = useState({
    total: 0,
    statusCounts: {}
  });
  const [taskStats, setTaskStats] = useState({
    total: 0,
    statusCounts: {}
  });
  const [plans, setPlans] = useState([]);

  // Fetch semua data yang diperlukan
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [usersResponse, departmentsResponse, levelsResponse, rolesResponse] = await Promise.all([
          axiosInstance.get('/api/users'),
          axiosInstance.get('/api/departments'),
          axiosInstance.get('/api/levels'),
          axiosInstance.get('/api/roles')
        ]);

        const currentUserProfile = usersResponse.data.find(
          user => user.id === parseInt(localStorage.getItem('userId'))
        );

        setUserProfile(currentUserProfile);
        setDepartments(departmentsResponse.data);
        setLevels(levelsResponse.data);
        setRoles(rolesResponse.data);

        // Generate dummy activity data
        const dummyData = generateActivityData();
        setActivityData(dummyData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllData();
  }, []);

  // Fetch project statistics
  useEffect(() => {
    const fetchStatistics = async () => {
      if (activeProject) {
        try {
          const [shotsResponse, sequencesResponse, assetsResponse, tasksResponse] = await Promise.all([
            axiosInstance.get(`/api/shots/${activeProject.id}`),
            axiosInstance.get(`/api/sequences/${activeProject.id}`),
            axiosInstance.get(`/api/assets/${activeProject.id}`),
            axiosInstance.get(`/api/tasks/${activeProject.id}`)
          ]);

          // Process sequences and their shots
          const sequences = sequencesResponse.data;
          setSequenceDetails(sequences);
          
          // Update statistics
          setStatistics({
            shots: shotsResponse.data.length,
            sequences: sequences.length,
            assets: assetsResponse.data.length,
            tasks: tasksResponse.data.length
          });

          // Update sequence stats
          const sequenceStatusCounts = sequences.reduce((acc, seq) => {
            const status = seq.sequenceStatus?.name || 'Unknown';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
          }, {});

          setSequenceStats({
            total: sequences.length,
            statusCounts: sequenceStatusCounts
          });

          // Update shot stats
          const shots = shotsResponse.data;
          const shotStatusCounts = shots.reduce((acc, shot) => {
            const status = shot.shotStatus?.name || 'Unknown';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
          }, {});

          setShotStats({
            total: shots.length,
            statusCounts: shotStatusCounts
          });

        } catch (error) {
          console.error('Error fetching statistics:', error);
        }
      }
    };

    fetchStatistics();
  }, [activeProject]);

  // Tambahkan useEffect untuk fetch my tasks
  useEffect(() => {
    const fetchMyTasks = async () => {
      if (activeProject && userProfile) {
        try {
          const [tasksResponse, statusesResponse] = await Promise.all([
            axiosInstance.get(`/api/tasks/${activeProject.id}`),
            axiosInstance.get('/api/task-statuses')
          ]);
          
          const allTasks = tasksResponse.data;
          const allStatuses = statusesResponse.data;
          
          // Filter task berdasarkan user yang sedang login
          const userTasks = allTasks.filter(task => task.userId === userProfile.id);
          
          // Hitung total task dan breakdown berdasarkan status
          const taskStats = {
            total: userTasks.length,
            completed: 0,
            inProgress: 0,
            pending: 0
          };

          // Cari status default untuk completed, in-progress, dan pending
          const completedStatus = allStatuses.find(s => s.name.toLowerCase() === 'completed')?.id;
          const inProgressStatus = allStatuses.find(s => s.name.toLowerCase() === 'in progress')?.id;
          const pendingStatus = allStatuses.find(s => s.name.toLowerCase() === 'pending')?.id;

          // Hitung jumlah task untuk setiap status
          userTasks.forEach(task => {
            if (task.statusId === completedStatus) {
              taskStats.completed++;
            } else if (task.statusId === inProgressStatus) {
              taskStats.inProgress++;
            } else if (task.statusId === pendingStatus) {
              taskStats.pending++;
            }
          });

          setMyTasks(taskStats);
        } catch (error) {
          console.error('Error fetching my tasks:', error);
        }
      }
    };

    fetchMyTasks();
  }, [activeProject, userProfile]);

  // Tambahkan useEffect untuk mengambil dan memfilter task yang mendekati deadline
  useEffect(() => {
    const fetchReminders = async () => {
      if (activeProject && userProfile) {
        try {
          const response = await axiosInstance.get(`/api/tasks/${activeProject.id}`);
          const allTasks = response.data;
          
          // Filter task yang diassign ke user dan belum selesai
          const userTasks = allTasks.filter(task => 
            task.userId === userProfile.id && 
            task.statusId !== 3 // Asumsikan status completed memiliki id 3
          );
          
          // Filter task berdasarkan deadline 7 hari ke depan
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset jam ke 00:00:00
          
          const sevenDaysLater = new Date(today);
          sevenDaysLater.setDate(today.getDate() + 7);
          sevenDaysLater.setHours(23, 59, 59, 999); // Set jam ke 23:59:59.999
          
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - 7);
          sevenDaysAgo.setHours(0, 0, 0, 0);
          
          console.log('7 days ago:', sevenDaysAgo.toISOString());
          console.log('Today:', today.toISOString());
          console.log('7 days later:', sevenDaysLater.toISOString());
          
          // Log data task untuk debugging
          console.log('Task data sample:', userTasks.length > 0 ? JSON.stringify(userTasks[0]) : 'No tasks');
          
          const reminderTasks = userTasks.filter(task => {
            // Cek apakah task memiliki dueDate atau endDate
            const dueDateValue = task.dueDate || task.endDate;
            if (!dueDateValue) {
              console.log('Task tanpa tanggal tenggat:', task.taskName || task.id);
              return false;
            }
            
            // Parse tanggal dengan benar
            const deadline = new Date(dueDateValue);
            deadline.setHours(0, 0, 0, 0); // Reset jam ke 00:00:00 untuk perbandingan yang konsisten
            
            // Debug log untuk setiap task
            const isOverdue = deadline < today;
            const isInRange = deadline >= sevenDaysAgo && deadline <= sevenDaysLater;
            
            console.log(
              'Task:', task.taskName || 'Unnamed', 
              'Deadline:', dueDateValue, 
              'Parsed:', deadline.toISOString(),
              'Status ID:', task.statusId,
              'Is overdue:', isOverdue,
              'Is in range (7 days ago to 7 days later):', isInRange,
              'Should show:', isInRange || (isOverdue && task.statusId !== 3)
            );
            
            // Tampilkan task yang deadline-nya dari 7 hari yang lalu sampai 7 hari ke depan
            // Ini akan menampilkan task yang sudah lewat tenggat (overdue) dan yang akan datang
            return (deadline >= sevenDaysAgo && deadline <= sevenDaysLater) || 
                   // Atau task yang sudah lewat tenggat tapi statusnya belum selesai
                   (deadline < today && task.statusId !== 3);
          });

          // Log untuk debugging
          console.log('User tasks:', userTasks.length);
          console.log('Reminder tasks:', reminderTasks.length);
          
          // Sort berdasarkan deadline terdekat
          reminderTasks.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
          setReminders(reminderTasks);
        } catch (error) {
          console.error('Error fetching reminders:', error);
        }
      }
    };

    fetchReminders();
  }, [activeProject, userProfile]);

  useEffect(() => {
    const fetchSequenceDetails = async () => {
      if (activeProject) {
        try {
          const [sequencesResponse, statusesResponse] = await Promise.all([
            axiosInstance.get(`/api/sequences/${activeProject.id}`),
            axiosInstance.get('/api/sequence-statuses')
          ]);
          
          const sequences = sequencesResponse.data;
          const statuses = statusesResponse.data;
          
          const detailedSequences = sequences.map(seq => ({
            ...seq,
            status: statuses.find(s => s.id === seq.statusId)
          }));
          
          setSequenceDetails(detailedSequences);
        } catch (error) {
          console.error('Error fetching sequence details:', error);
        }
      }
    };

    fetchSequenceDetails();
  }, [activeProject]);

  useEffect(() => {
    fetchSequenceStats();
  }, [activeProject]);

  useEffect(() => {
    fetchShotStats();
  }, [activeProject]);

  useEffect(() => {
    fetchAssetStats();
  }, [activeProject]);

  useEffect(() => {
    fetchTaskStats();
  }, [activeProject]);

  useEffect(() => {
    const fetchUpcomingPlans = async () => {
      if (activeProject?.id) {
        try {
          const response = await axiosInstance.get(`/api/plans/${activeProject.id}`);
          const allPlans = response.data;
          
          // Filter rencana yang akan datang (7 hari ke depan)
          const today = new Date();
          const upcomingPlans = allPlans.filter(plan => {
            const planDate = new Date(plan.date);
            const diffTime = planDate - today;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays >= 0 && diffDays <= 7;
          });

          // Sort berdasarkan tanggal terdekat
          upcomingPlans.sort((a, b) => new Date(a.date) - new Date(b.date));
          setPlans(upcomingPlans);
        } catch (error) {
          console.error('Error fetching plans:', error);
        }
      }
    };

    fetchUpcomingPlans();
  }, [activeProject]);

  const generateActivityData = () => {
    const days = ['Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab', 'Min'];
    return days.map(day => ({
      name: day,
      tasks: Math.floor(Math.random() * 20),
      progress: Math.floor(Math.random() * 100)
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric'
    };
    return new Date(dateString).toLocaleDateString('id-ID', options);
  };

  const getDaysLeft = (dateString) => {
    if (!dateString) return '-';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset jam ke 00:00:00
    
    const deadline = new Date(dateString);
    deadline.setHours(0, 0, 0, 0); // Reset jam ke 00:00:00
    
    const diffTime = deadline - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      return 'Hari ini';
    } else if (diffDays === 1) {
      return 'Besok';
    } else if (diffDays > 1) {
      return `${diffDays} hari lagi`;
    } else {
      return 'Tenggat terlewat';
    }
  };
  
  const getDaysLeftClass = (dateString) => {
    if (!dateString) return '';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const deadline = new Date(dateString);
    deadline.setHours(0, 0, 0, 0);
    
    const diffTime = deadline - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) {
      return 'overdue'; // Tenggat sudah terlewat
    } else if (diffDays === 0) {
      return 'due-today'; // Tenggat hari ini
    } else if (diffDays <= 2) {
      return 'due-soon'; // Tenggat dalam 1-2 hari
    } else {
      return 'due-later'; // Tenggat masih lama
    }
  };
  
  // Tambahkan CSS untuk styling Task Reminders
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .reminder-item {
        border-left: 4px solid #ccc;
        margin-bottom: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 4px;
        transition: all 0.2s ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
      }
      .reminder-item:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .reminder-info {
        margin-bottom: 8px;
      }
      .reminder-info h4 {
        margin: 0 0 5px 0;
        font-size: 16px;
      }
      .reminder-info p {
        margin: 0;
        font-size: 14px;
        color: #666;
      }
      .reminder-meta {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
      .reminder-meta .icon {
        margin-right: 4px;
        vertical-align: middle;
      }
      .due-date, .days-left {
        display: flex;
        align-items: center;
      }
      .reminder-item.overdue {
        border-left-color: #ff3b30;
      }
      .reminder-item.due-today {
        border-left-color: #ff9500;
      }
      .reminder-item.due-soon {
        border-left-color: #ffcc00;
      }
      .reminder-item.due-later {
        border-left-color: #34c759;
      }
      .days-left.overdue {
        color: #ff3b30;
        font-weight: bold;
      }
      .days-left.due-today {
        color: #ff9500;
        font-weight: bold;
      }
      .days-left.due-soon {
        color: #ffcc00;
      }
      .days-left.due-later {
        color: #34c759;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const fetchSequenceStats = async () => {
    if (activeProject) {
      try {
        const [sequencesResponse, statusesResponse] = await Promise.all([
          axiosInstance.get(`/api/sequences/${activeProject.id}`),
          axiosInstance.get('/api/sequence-statuses')
        ]);

        const sequenceStats = {
          total: sequencesResponse.data.length,
          statusCounts: {}
        };

        // Inisialisasi counter untuk setiap status
        statusesResponse.data.forEach(status => {
          sequenceStats.statusCounts[status.name] = {
            count: 0,
            color: status.color
          };
        });

        // Hitung jumlah sequence untuk setiap status
        sequencesResponse.data.forEach(sequence => {
          const status = statusesResponse.data.find(s => s.id === sequence.statusId);
          if (status) {
            sequenceStats.statusCounts[status.name].count++;
          }
        });

        setSequenceStats(sequenceStats);
      } catch (error) {
        console.error('Error fetching sequence stats:', error);
      }
    }
  };

  const fetchShotStats = async () => {
    if (activeProject) {
      try {
        const [shotsResponse, statusesResponse] = await Promise.all([
          axiosInstance.get(`/api/shots/${activeProject.id}`),
          axiosInstance.get('/api/shot-statuses')
        ]);

        const shotStats = {
          total: shotsResponse.data.length,
          statusCounts: {}
        };

        // Inisialisasi counter untuk setiap status
        statusesResponse.data.forEach(status => {
          shotStats.statusCounts[status.name] = {
            count: 0,
            color: status.color
          };
        });

        // Hitung jumlah shot untuk setiap status
        shotsResponse.data.forEach(shot => {
          const status = statusesResponse.data.find(s => s.id === shot.statusId);
          if (status) {
            shotStats.statusCounts[status.name].count++;
          }
        });

        setShotStats(shotStats);
      } catch (error) {
        console.error('Error fetching shot stats:', error);
      }
    }
  };

  const fetchAssetStats = async () => {
    if (activeProject) {
      try {
        const [assetsResponse, statusesResponse] = await Promise.all([
          axiosInstance.get(`/api/assets/${activeProject.id}`),
          axiosInstance.get('/api/asset-statuses')
        ]);

        const assetStats = {
          total: assetsResponse.data.length,
          statusCounts: {}
        };

        // Inisialisasi counter untuk setiap status
        statusesResponse.data.forEach(status => {
          assetStats.statusCounts[status.name] = {
            count: 0,
            color: status.color
          };
        });

        // Hitung jumlah asset untuk setiap status
        assetsResponse.data.forEach(asset => {
          const status = statusesResponse.data.find(s => s.id === asset.statusId);
          if (status) {
            assetStats.statusCounts[status.name].count++;
          }
        });

        setAssetStats(assetStats);
      } catch (error) {
        console.error('Error fetching asset stats:', error);
      }
    }
  };

  const fetchTaskStats = async () => {
    if (activeProject) {
      try {
        const [tasksResponse, statusesResponse] = await Promise.all([
          axiosInstance.get(`/api/tasks/${activeProject.id}`),
          axiosInstance.get('/api/task-statuses')
        ]);

        const taskStats = {
          total: tasksResponse.data.length,
          statusCounts: {}
        };

        // Inisialisasi counter untuk setiap status
        statusesResponse.data.forEach(status => {
          taskStats.statusCounts[status.name] = {
            count: 0,
            color: status.color
          };
        });

        // Hitung jumlah task untuk setiap status
        tasksResponse.data.forEach(task => {
          const status = statusesResponse.data.find(s => s.id === task.statusId);
          if (status) {
            taskStats.statusCounts[status.name].count++;
          }
        });

        setTaskStats(taskStats);
      } catch (error) {
        console.error('Error fetching task stats:', error);
      }
    }
  };

  const renderUpcomingPlans = () => {
    const today = new Date();
    const upcomingPlans = plans
      .filter(plan => new Date(plan.date) >= today)
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .slice(0, 3); // Tampilkan maksimal 3 rencana

    if (upcomingPlans.length === 0) {
      return <div className="no-plans">Tidak ada rencana yang akan datang</div>;
    }

    return (
      <div className="upcoming-plans">
        {upcomingPlans.map(plan => (
          <div key={plan.id} className="plan-item">
            <div className="plan-header">
              <FaCalendarAlt className="plan-icon" />
              <span className="plan-date">{formatDate(plan.date)}</span>
            </div>
            <div className="plan-content">
              <h4 className="plan-title">{plan.title}</h4>
              <div className="plan-details">
                <span className="plan-time">
                  <FaClock /> {plan.time} ({plan.duration} menit)
                </span>
                <span className="plan-location">
                  <FaMapMarkerAlt /> {plan.location}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="content-container">
      <div className="global-header">
        <h2>Dashboard</h2>
        {activeProject && (
          <div className="project-info">
            <span className="project-name">{activeProject.name}</span>
          </div>
        )}
      </div>

      <div className="dashboard-container">
        {/* Container Kiri */}
        <div className="dashboard-left">
          {/* Profile Section */}
          <div className="section profile-section">
            <div className="section-card">
              {userProfile && (
                <div className="user-profile">
                  <div className="profile-main">
                    <div className="profile-image-container">
                      {userProfile.profileImage ? (
                        <img
                          className="profile-image"
                          src={`${API_BASE_URL}${userProfile.profileImage}`}
                          alt={userProfile.username}
                        />
                      ) : (
                        <div className="profile-avatar">
                          {userProfile.username[0].toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="profile-info">
                      <div className="profile-header">
                        <h3 className="profile-name">{userProfile.username}</h3>
                        <span className="profile-role">
                          {roles.find(r => r.id === userProfile.roleId)?.name || '-'}
                        </span>
                      </div>
                      <div className="profile-metadata">
                        <div className="metadata-item">
                          <span className="metadata-label">Department</span>
                          <span className="metadata-value">
                            {departments.find(d => d.id === userProfile.departmentId)?.name || '-'}
                          </span>
                        </div>
                        <div className="metadata-item">
                          <span className="metadata-label">Level</span>
                          <span className="metadata-value">
                            {levels.find(l => l.id === userProfile.levelId)?.name || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-stats">
                    <div className="stat-item">
                      <span className="stat-value">{myTasks.total}</span>
                      <span className="stat-label">Total Tasks</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-value completed">{myTasks.completed}</span>
                      <span className="stat-label">Completed</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-value in-progress">{myTasks.inProgress}</span>
                      <span className="stat-label">In Progress</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-value pending">{myTasks.pending}</span>
                      <span className="stat-label">Pending</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Activity Section */}
          <div className="section activity-section">
            <div className="section-card">
              <div className="card-header">
                <FaChartLine className="card-icon" />
                <h3>Activity Overview</h3>
              </div>
              <div className="card-content">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={activityData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="tasks" stroke="#8884d8" />
                    <Line type="monotone" dataKey="progress" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Container Kanan */}
        <div className="dashboard-right">
          {/* Stats Section */}
          <div className="section stats-section">
            <div className="section-card">
              <div className="card-header">
                <FaLayerGroup className="card-icon" />
                <h3>Sequences</h3>
              </div>
              <div className="card-content">
                <div className="stat-number">{statistics.sequences}</div>
                <div className="status-list">
                  {Object.entries(sequenceStats.statusCounts).map(([status, data]) => (
                    <div key={status} className="status-item">
                      <span className="status-indicator" style={{ backgroundColor: data.color }} />
                      <span className="status-name">{status}</span>
                      <span className="status-count">{data.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="stats-card">
              <div className="card-header">
                <BiCameraMovie className="card-icon" />
                <h3>Shots</h3>
              </div>
              <div className="card-content">
                <div className="stat-number">{statistics.shots}</div>
                <div className="status-list">
                  {Object.entries(shotStats.statusCounts).map(([status, data]) => (
                    <div key={status} className="status-item">
                      <span className="status-indicator" style={{ backgroundColor: data.color }} />
                      <span className="status-name">{status}</span>
                      <span className="status-count">{data.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="stats-card">
              <div className="card-header">
                <FaLayerGroup className="card-icon" />
                <h3>Assets</h3>
              </div>
              <div className="card-content">
                <div className="stat-number">{statistics.assets}</div>
                <div className="status-list">
                  {Object.entries(assetStats.statusCounts).map(([status, data]) => (
                    <div key={status} className="status-item">
                      <span className="status-indicator" style={{ backgroundColor: data.color }} />
                      <span className="status-name">{status}</span>
                      <span className="status-count">{data.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="stats-card">
              <div className="card-header">
                <FaTasks className="card-icon" />
                <h3>Tasks</h3>
              </div>
              <div className="card-content">
                <div className="stat-number">{statistics.tasks}</div>
                <div className="status-list">
                  {Object.entries(taskStats.statusCounts).map(([status, data]) => (
                    <div key={status} className="status-item">
                      <span className="status-indicator" style={{ backgroundColor: data.color }} />
                      <span className="status-name">{status}</span>
                      <span className="status-count">{data.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>



          {/* Reminders Section */}
          <div className="section reminders-section">
            <div className="section-card">
              <div className="card-header">
                <FaBell className="card-icon" />
                <h3>Task Reminders</h3>
              </div>
              <div className="card-content">
                {reminders.length > 0 ? (
                  <div className="reminder-list">
                    {reminders.map(task => (
                      <div key={task.id} className={`reminder-item ${getDaysLeftClass(task.dueDate || task.endDate)}`}>
                        <div className="reminder-info">
                          <h4>{task.taskName}</h4>
                          <p>{task.description}</p>
                        </div>
                        <div className="reminder-meta">
                          <span className="due-date">
                            <FaCalendarAlt className="icon" />
                            {formatDate(task.dueDate || task.endDate)}
                          </span>
                          <span className={`days-left ${getDaysLeftClass(task.dueDate || task.endDate)}`}>
                            <FaClock className="icon" />
                            {getDaysLeft(task.dueDate || task.endDate)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-data">No upcoming deadlines</div>
                )}
              </div>
            </div>
          </div>

          {/* Plans Section */}
          <div className="section plans-section">
            <div className="section-card">
              <div className="card-header">
                <FaCalendarAlt className="card-icon" />
                <h3>Rencana Mendatang</h3>
              </div>
              <div className="card-content">
                {renderUpcomingPlans()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
