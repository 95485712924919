import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './client/components/Login';
import Dashboard from './client/components/Dashboard';
import PrivateRoute from './client/components/PrivateRoute';
import NotFound from './client/components/NotFound';
import TopBar from './client/components/TopBar';
import ProjectList from './client/components/ProjectList';
import ProjectDetail from './client/components/ProjectDetail';
import TrackingSettings from './client/components/TrackingSettings';
import Sequence from './client/components/Sequence';
import SequenceOverview from './client/components/SequenceOverview';
import Shot from './client/components/Shot';
import ShotOverview from './client/components/ShotOverview';
import AssetOverview from './client/components/AssetOverview';
import TaskOverview from './client/components/TaskOverview';
import Asset from './client/components/Asset';
import Task from './client/components/Task';
import MySpace from './client/components/MySpace';
import ShotStatusManager from './client/components/ShotStatusManager';
import Plan from './client/components/Plans';
import PresentPanel from './client/components/PresentPanel';
import AdminPanel from './client/components/AdminPanel';
import { io } from 'socket.io-client';
import PresentationViewer from './client/components/PresentationViewer';
import Issues from './client/components/Issues';
import MyTasks from './client/components/MyTasks';
import MessengerPage from './client/components/MessengerPage';
import './client/styles/global.css';
import './client/styles/darkmode.css';
import { API_BASE_URL, WS_BASE_URL } from './client/config/constants';
import axiosInstance from './client/config/axios';
import { jwtDecode } from 'jwt-decode';

// Log konfigurasi awal
console.log('WS_BASE_URL:', WS_BASE_URL);
console.log('Socket.io import:', typeof io === 'function' ? 'Berhasil' : 'Gagal');

// Create dark mode context
export const DarkModeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

// Custom hook for using dark mode
export const useDarkMode = () => useContext(DarkModeContext);

const AppContent = () => {
  const [activeProject, setActiveProject] = useState(() => {
    const savedProject = localStorage.getItem('activeProject');
    return savedProject ? JSON.parse(savedProject) : null;
  });
  const [isProjectFormVisible, setIsProjectFormVisible] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userRole, setUserRole] = useState(null);
  const [sequences, setSequences] = useState(() => JSON.parse(localStorage.getItem('sequences')) || []);
  const [publishedLibrary, setPublishedLibrary] = useState(() => JSON.parse(localStorage.getItem('publishedLibrary') || '[]'));
  const [activities, setActivities] = useState([]);
  const [shots, setShots] = useState(() => JSON.parse(localStorage.getItem('shots')) || []);
  const [assets, setAssets] = useState(() => JSON.parse(localStorage.getItem('assets')) || []);
  const [tasks, setTasks] = useState(() => JSON.parse(localStorage.getItem('tasks')) || []);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved ? JSON.parse(saved) : false;
  });

  const navigate = useNavigate();
  const socketRef = useRef(null);

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode(prev => {
      const newValue = !prev;
      localStorage.setItem('darkMode', JSON.stringify(newValue));
      return newValue;
    });
  };

  useEffect(() => {
    document.body.classList.toggle('darkmode', isDarkMode);
    document.documentElement.classList.toggle('darkmode', isDarkMode);
  }, [isDarkMode]);

  // Validasi Token
  const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return false;
    }
  };

  // Safe LocalStorage Setter
  const safeSetItem = (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if (e.name === 'QuotaExceededError') {
        localStorage.removeItem('darkMode');
        try {
          localStorage.setItem(key, value);
        } catch (e) {
          console.error('Masih tidak bisa menyimpan ke localStorage:', e);
        }
      }
    }
  };

  // Setup Socket Global
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, skipping socket setup');
      navigate('/login');
      return;
    }

    console.group('Global Socket Setup');
    console.log('Initializing socket with WS_BASE_URL:', WS_BASE_URL);

    const socket = io(WS_BASE_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      randomizationFactor: 0.5,
      timeout: 10000,
      auth: { token },
      query: { token },
      forceNew: true // Pastikan hanya satu koneksi
    });

    socket.on('connect', () => {
      console.log('Socket connected successfully, ID:', socket.id, 'User:', jwtDecode(token).id);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connect error:', error.message);
      if (error.message.includes('Authentication')) {
        console.error('Authentication failed, redirecting to login');
        localStorage.removeItem('token');
        navigate('/login');
      }
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected, ID:', socket.id, 'Reason:', reason, 'Was connected:', socket.connected);
    });

    socket.on('reconnect', (attempt) => {
      console.log('Socket reconnected after', attempt, 'attempts');
    });

    // Listener untuk Shots, Assets, Tasks
    socket.on('newShot', (shot) => {
      if (shot.projectId === activeProject?.id) {
        setShots(prev => [...prev, shot]);
      }
    });

    socket.on('updateShot', (updatedShot) => {
      if (updatedShot.projectId === activeProject?.id) {
        setShots(prev => prev.map(shot => shot.id === updatedShot.id ? updatedShot : shot));
      }
    });

    socket.on('deleteShot', (deletedId) => {
      setShots(prev => prev.filter(shot => shot.id !== deletedId));
    });

    socket.on('newAsset', (asset) => {
      if (asset.projectId === activeProject?.id) {
        setAssets(prev => [...prev, asset]);
      }
    });

    socket.on('updateAsset', (updatedAsset) => {
      if (updatedAsset.projectId === activeProject?.id) {
        setAssets(prev => prev.map(asset => asset.id === updatedAsset.id ? updatedAsset : asset));
      }
    });

    socket.on('deleteAsset', (deletedId) => {
      setAssets(prev => prev.filter(asset => asset.id !== deletedId));
    });

    socket.on('newTask', (task) => {
      if (task.projectId === activeProject?.id) {
        setTasks(prev => [...prev, task]);
      }
    });

    socket.on('updateTask', (updatedTask) => {
      if (updatedTask.projectId === activeProject?.id) {
        setTasks(prev => prev.map(task => task.id === updatedTask.id ? updatedTask : task));
      }
    });

    socket.on('deleteTask', (deletedId) => {
      setTasks(prev => prev.filter(task => task.id !== deletedId));
    });

    // Listener untuk Activities
    socket.on('newActivity', (activity) => {
      console.log('New activity received:', activity);
      setActivities(prev => [activity, ...prev]);
    });

    socket.on('updateActivity', (updatedActivity) => {
      console.log('Activity updated:', updatedActivity);
      setActivities(prev => prev.map(act => act.id === updatedActivity.id ? updatedActivity : act));
    });

    socket.on('deleteActivity', (deletedId) => {
      console.log('Activity deleted:', deletedId);
      setActivities(prev => prev.filter(act => act.id !== deletedId));
    });

    // Listener untuk Messages (dari TopBar)
    socket.on('unreadMessagesCount', (count) => {
      console.log('App received unread messages count:', count);
    });

    socket.on('newMessage', (message) => {
      console.log('App received new message:', message);
    });

    socketRef.current = socket;

    return () => {
      console.log('Cleaning up socket on App unmount, ID:', socket.id);
      socket.disconnect();
    };
  }, []); // Hanya jalan sekali saat App mount

  // Fetch Data saat activeProject Berubah
  useEffect(() => {
    if (activeProject && activeProject.id) {
      const fetchData = async () => {
        try {
          const [shotsResponse, sequencesResponse, assetsResponse, tasksResponse] = await Promise.all([
            axiosInstance.get(`/api/shots/${activeProject.id}`),
            axiosInstance.get(`/api/sequences/${activeProject.id}`),
            axiosInstance.get(`/api/assets/${activeProject.id}`),
            axiosInstance.get(`/api/tasks/${activeProject.id}`)
          ]);
          setShots(shotsResponse.data || []);
          setSequences(sequencesResponse.data || []);
          setAssets(assetsResponse.data || []);
          setTasks(tasksResponse.data || []);
        } catch (error) {
          console.error('Error fetching data:', error);
          setShots([]);
          setSequences([]);
          setAssets([]);
          setTasks([]);
        }
      };
      fetchData();
    } else {
      setShots([]);
      setSequences([]);
      setAssets([]);
      setTasks([]);
    }
  }, [activeProject]);

  // Handle Login dan Role
  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'));
    safeSetItem('darkMode', JSON.stringify(isDarkMode));
    safeSetItem('activeProject', JSON.stringify(activeProject));

    const fetchUserRole = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.roleId) {
          const response = await axiosInstance.get(`/api/roles/${user.roleId}`);
          setUserRole(response.data);
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };
    fetchUserRole();
  }, [isDarkMode, activeProject]);

  const handleSelectProject = (project) => {
    setActiveProject(project);
    safeSetItem('activeProject', JSON.stringify(project));
  };

  const handleAddProject = (newProject) => {
    try {
      const projectWithUniqueId = { ...newProject, id: Date.now() };
      const storedProjects = JSON.parse(localStorage.getItem('projects')) || [];
      const updatedProjects = [...storedProjects, projectWithUniqueId];
      safeSetItem('projects', JSON.stringify(updatedProjects));
      setActiveProject(projectWithUniqueId);
      setIsProjectFormVisible(false);
      setProjects(updatedProjects);
    } catch (error) {
      console.error('Error saat menambah project:', error);
    }
  };

  const handleLogout = () => {
    setActiveProject(null);
    setIsLoggedIn(false);
    setUserRole(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
    navigate('/login');
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <div className={`app ${isDarkMode ? 'darkmode' : ''}`}>
        {isLoggedIn && (
          <TopBar
            activeProject={activeProject}
            onLogout={handleLogout}
            onSelectProject={handleSelectProject}
            userRole={userRole}
            socket={socketRef.current} // Teruskan socket ke TopBar
          />
        )}
        <div className={`content ${isDarkMode ? 'darkmode' : ''}`}>
          <Routes>
            <Route 
              path="/" 
              element={isTokenValid() ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />}
            />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard activeProject={activeProject} />} />
              <Route path="/projects" element={
                <ProjectList
                  projects={projects}
                  setProjects={setProjects}
                  onSelectProject={handleSelectProject}
                  onAddProject={() => setIsProjectFormVisible(true)}
                  isProjectFormVisible={isProjectFormVisible}
                  handleAddProject={handleAddProject}
                  handleCloseProjectForm={() => setIsProjectFormVisible(false)}
                  activeProject={activeProject}
                  isDarkMode={isDarkMode}
                />
              } />
              <Route path="/projects/:id" element={<ProjectDetail />} />
              <Route path="/tracking-settings" element={<TrackingSettings />} />
              <Route path="/messenger" element={<MessengerPage socket={socketRef.current} />} />
              <Route path="/sequences" element={
                <Sequence
                  activeProject={activeProject}
                  userRole={userRole}
                  sequences={sequences}
                  setSequences={setSequences}
                  tasks={tasks}
                  shots={shots}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/sequence-overview/:id" element={<SequenceOverview sequences={sequences} activeProject={activeProject} />} />
              <Route path="/shots/*" element={
                <Shot
                  activeProject={activeProject}
                  userRole={userRole}
                  shots={shots}
                  setShots={setShots}
                  sequences={sequences}
                  setSequences={setSequences}
                  assets={assets}
                  setAssets={setAssets}
                  isDarkMode={isDarkMode}
                  currentUser={userRole}
                  tasks={tasks}
                  setTasks={setTasks}
                  socket={socketRef.current}
                />
              } />
              <Route path="/assets" element={
                <Asset
                  activeProject={activeProject}
                  userRole={userRole}
                  assets={assets}
                  setAssets={setAssets}
                  shots={shots}
                  setShots={setShots}
                  tasks={tasks}
                  setTasks={setTasks}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/shot-overview/:id" element={
                <ShotOverview
                  shots={shots}
                  activeProject={activeProject}
                  currentUser={userRole}
                  userRole={userRole}
                  tasks={tasks}
                  setTasks={setTasks}
                  assets={assets}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/asset-overview/:id" element={
                <AssetOverview
                  assets={assets}
                  activeProject={activeProject}
                  currentUser={userRole}
                  userRole={userRole}
                  tasks={tasks}
                  setTasks={setTasks}
                  shots={shots}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/tasks/*" element={
                <Task
                  activeProject={activeProject}
                  userRole={userRole}
                  tasks={tasks}
                  setTasks={setTasks}
                  shots={shots}
                  setShots={setShots}
                  assets={assets}
                  setAssets={setAssets}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/task-overview/:id" element={
                <TaskOverview tasks={tasks} activeProject={activeProject} socket={socketRef.current} />
              } />
              <Route path="/issues" element={
                <Issues
                  activeProject={activeProject}
                  sequences={sequences}
                  shots={shots}
                  assets={assets}
                  tasks={tasks}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/issues/:id" element={
                <Issues
                  activeProject={activeProject}
                  sequences={sequences}
                  shots={shots}
                  assets={assets}
                  tasks={tasks}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/myspace" element={<MySpace activeProject={activeProject} shots={shots} setShots={setShots} socket={socketRef.current} />} />
              <Route path="/admin" element={<AdminPanel activeProject={activeProject} socket={socketRef.current} />} />
              <Route path="/plan" element={
                <Plan
                  activeProject={activeProject}
                  sequences={sequences}
                  shots={shots}
                  assets={assets}
                  tasks={tasks}
                  isDarkMode={isDarkMode}
                  socket={socketRef.current}
                />
              } />
              <Route path="/my-tasks" element={<MyTasks activeProject={activeProject} socket={socketRef.current} />} />
            </Route>
            <Route path="/present" element={
              <PresentPanel
                activeProject={activeProject}
                publishedLibrary={publishedLibrary}
                activities={activities}
                setActivities={setActivities}
                socket={socketRef.current}
              />
            } />
            <Route path="/present/:id" element={<PresentationViewer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </DarkModeContext.Provider>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;