import React, { useEffect, useRef } from 'react';
import './SpreadsheetEditor.css';

const SpreadsheetEditor = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const iframeContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Luckysheet</title>
          <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/css/pluginsCss.css' />
          <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/plugins.css' />
          <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/luckysheet/dist/css/luckysheet.css' />
          <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/luckysheet/dist/assets/iconfont/iconfont.css' />
          <script src="https://cdn.jsdelivr.net/npm/jquery/dist/jquery.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/luckysheet/dist/plugins/js/plugin.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/luckysheet/dist/luckysheet.umd.js"></script>
          <style>
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            #luckysheet {
              width: 100%;
              height: 100vh;
              padding: 0;
              margin: 0;
            }
            .luckysheet-grid-container {
              background-color: #ffffff !important;
            }
            .luckysheet-cols-h, 
            .luckysheet-rows-h {
              background: #f8f9fa !important;
              font-weight: 600 !important;
            }
          </style>
        </head>
        <body>
          <div id="luckysheet"></div>
          <script>
            $(function() {
              const options = {
                container: 'luckysheet',
                title: 'MBOpen Spreadsheet',
                lang: 'en',
                showinfobar: false,
                data: [{
                  name: "Sheet1",
                  color: "",
                  status: 1,
                  order: 0,
                  data: [
                    ["Name", "Age", "City", "Email"],
                    ["John Doe", 30, "New York", "john@example.com"],
                    ["Jane Smith", 25, "Los Angeles", "jane@example.com"],
                    ["Bob Johnson", 35, "Chicago", "bob@example.com"]
                  ],
                  config: {
                    columnlen: {
                      0: 150,
                      1: 80,
                      2: 120,
                      3: 200
                    },
                    rowlen: {
                      0: 32
                    }
                  }
                }],
                showtoolbar: true,
                showsheetbar: true,
                showstatisticBar: true,
                showformulaBar: true,
                enableAddRow: true,
                defaultColWidth: 100,
                defaultRowHeight: 28,
                row: 60,
                column: 26,
                allowUpdate: true,
                cellRightClickConfig: {
                  copy: true,
                  copyAs: true,
                  paste: true,
                  insertRow: true,
                  insertColumn: true,
                  deleteRow: true,
                  deleteColumn: true,
                  deleteCell: true,
                  hideRow: true,
                  hideColumn: true,
                  rowHeight: true,
                  columnWidth: true,
                  clear: true,
                  matrix: true,
                  sort: true,
                  filter: true,
                  chart: false,
                  image: true,
                  link: true,
                  data: true,
                  cellFormat: true
                }
              };

              luckysheet.create(options);
            });

            // Handle messages from parent
            window.addEventListener('message', function(e) {
              if (e.data === 'export') {
                const csvContent = luckysheet.getAllSheets().map(sheet => {
                  const rows = sheet.data;
                  if (!rows) return '';
                  return rows.map(row => 
                    row.map(cell => (cell?.v ?? '')).join(',')
                  ).join('\\n');
                }).join('\\n\\n');

                // Send CSV content back to parent
                window.parent.postMessage({ type: 'csv', content: csvContent }, '*');
              }
            });
          </script>
        </body>
      </html>
    `;

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(iframeContent);
    iframeDoc.close();
  }, []);

  const handleExport = () => {
    iframeRef.current?.contentWindow?.postMessage('export', '*');
  };

  useEffect(() => {
    const handleMessage = (e) => {
      if (e.data?.type === 'csv') {
        const blob = new Blob([e.data.content], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'spreadsheet-export.csv';
        link.click();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <div className="spreadsheet-editor-wrapper">
      <div className="spreadsheet-editor">
        <div className="spreadsheet-toolbar">
          <button 
            className="export-button"
            onClick={handleExport}
          >
            Export to CSV
          </button>
        </div>
        <iframe
          ref={iframeRef}
          className="spreadsheet-iframe"
          title="Luckysheet Spreadsheet"
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
            minHeight: '500px'
          }}
        />
      </div>
    </div>
  );
};

export default SpreadsheetEditor;
