import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import VideoModal from './VideoModal';
import '../styles/globaloverview.css';
import { API_BASE_URL } from '../config/config';
import OverviewHeader from './OverviewHeader';
import IssueForm from './IssueForm';

const TaskOverview = ({ tasks, activeProject, currentUser, userRole, assets, shots }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTask, setCurrentTask] = useState(null);
  const [activeTab, setActiveTab] = useState('Task Info');
  const [activities, setActivities] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [isIssueFormVisible, setIsIssueFormVisible] = useState(false);

  const filteredTasks = tasks.filter(task => task.projectId === activeProject?.id);

  useEffect(() => {
    if (tasks && id) {
      const task = tasks.find(s => s.id.toString() === id);
      if (task) {
        setCurrentTask(task);
        // Fetch activities saat task ditemukan
        fetchActivities(task.id);
      }
    }
  }, [tasks, id]);

  const fetchActivities = async (taskId) => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/activities/tasks/${taskId}`);
      // Urutkan activities berdasarkan timestamp terbaru
      const sortedActivities = response.data.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivities(sortedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handlePrevious = () => {
    const currentIndex = filteredTasks.findIndex(s => s.id.toString() === id);
    if (currentIndex > 0) {
      navigate(`/task-overview/${filteredTasks[currentIndex - 1].id}`);
    }
  };

  const handleNext = () => {
    const currentIndex = filteredTasks.findIndex(s => s.id.toString() === id);
    if (currentIndex < filteredTasks.length - 1) {
      navigate(`/task-overview/${filteredTasks[currentIndex + 1].id}`);
    }
  };

  const handleAddComment = async (taskId, comment) => {
    try {
      const response = await axiosInstance.post('${API_BASE_URL}/api/activities', {
        taskId,
        action: 'add_comment',
        details: comment.text,
        user: currentUser,
        timestamp: new Date().toISOString()
      });
      
      if (response.data) {
        fetchActivities(taskId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleEditComment = async (taskId, commentId, updatedComment) => {
    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}`, {
        details: updatedComment.text
      });
      
      if (response.data) {
        fetchActivities(taskId);
      }
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (taskId, commentId) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/activities/${commentId}`);
      fetchActivities(taskId);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handlePinComment = async (taskId, commentId) => {
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}/pin`);
      fetchActivities(taskId);
    } catch (error) {
      console.error('Error pinning comment:', error);
    }
  };

  const handleApproveVideo = async (activityId, version) => {
    try {
      const response = await axiosInstance.post(`${API_BASE_URL}/api/activities/${activityId}/approve`, {
        taskId: currentTask.id,
        user: "Admin",
        version: version,
        timestamp: new Date().toISOString()
      });

      if (response.data) {
        await fetchActivities(currentTask.id);
        setApprovalStatus(prev => ({
          ...prev,
          [activityId]: response.data.isApproved
        }));
      }
    } catch (error) {
      console.error('Error saat mengubah status approval video:', error);
      alert('Gagal mengubah status approval video');
    }
  };

  const calculateTaskStatus = (task) => {
    const today = new Date();
    const dueDate = new Date(task.dueDate);
    
    if (task.progress === 100) {
      return 'Completed';
    } else if (today > dueDate) {
      return 'Overdue';
    } else {
      return 'In Progress';
    }
  };

  const getTaskThumbnail = (task, assets = [], shots = []) => {
    // Prioritaskan thumbnail task itu sendiri
    if (task.thumbnailUrl) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (task.thumbnailUrl.startsWith('http')) return task.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${task.thumbnailUrl.startsWith('/') ? task.thumbnailUrl : '/' + task.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari asset terkait
    const relatedAsset = assets.find(asset => 
      task.selectedAssets?.includes(asset.id) && asset.thumbnailUrl
    );
    
    if (relatedAsset) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedAsset.thumbnailUrl.startsWith('http')) return relatedAsset.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedAsset.thumbnailUrl.startsWith('/') ? relatedAsset.thumbnailUrl : '/' + relatedAsset.thumbnailUrl}`;
    }
    
    // Cari thumbnail dari shot terkait
    const relatedShot = shots.find(shot => 
      task.selectedShots?.includes(shot.id) && shot.thumbnailUrl
    );
    
    if (relatedShot) {
      // Jika sudah URL lengkap, kembalikan sebagaimana adanya
      if (relatedShot.thumbnailUrl.startsWith('http')) return relatedShot.thumbnailUrl;
      
      // Jika adalah path relatif, tambahkan base URL
      return `${API_BASE_URL}${relatedShot.thumbnailUrl.startsWith('/') ? relatedShot.thumbnailUrl : '/' + relatedShot.thumbnailUrl}`;
    }
    
    // Jika tidak ada thumbnail, kembalikan placeholder
    return '/assets/images/task-placeholder.png';
  };

  if (!currentTask) {
    return (
      <div className="task-not-found">
        <h2>Task tidak ditemukan</h2>
        <button onClick={() => navigate('/tasks')}>Kembali ke Daftar Task</button>
      </div>
    );
  }

  const tabs = ['Task Info', 'Activity', 'Notes', 'Published', 'Client Approved'];

  return (
    <div className="task-overview">
      <OverviewHeader
        item={currentTask}
        type="Task"
        thumbnailUrl={getTaskThumbnail(currentTask, assets, shots)}
        onPrevious={handlePrevious}
        onNext={handleNext}
        currentUser={currentUser}
      />

      <div className="task-details">
        <div className="task-info">
          <p><strong>Status:</strong> {calculateTaskStatus(currentTask)}</p>
          <p><strong>Deskripsi:</strong> {currentTask.description}</p>
          <p><strong>Project:</strong> {activeProject?.name}</p>
        </div>
        <div className="task-dates">
          <p><strong>Start Date:</strong> {new Date(currentTask.startDate).toLocaleDateString()}</p>
          <p><strong>Due Date:</strong> {new Date(currentTask.dueDate).toLocaleDateString()}</p>
        </div>
      </div>

      <div className="tab-container">
        {tabs.map(tab => (
          <button 
            key={tab} 
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === 'Task Info' && (
          <div className="task-info-content">
            <h3>Detail Task</h3>
            <p>Nama: {currentTask.taskName}</p>
            <p>Deskripsi: {currentTask.description}</p>
            <p>Status: {calculateTaskStatus(currentTask)}</p>
          </div>
        )}
        {activeTab === 'Activity' && (
          <div className="activity-tab-content">
            <ActivityPanel
              item={currentTask}
              type="task"
              isOpen={true}
              onClose={() => {}}
              onAddComment={handleAddComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
              onPinComment={handlePinComment}
              currentUser={currentUser}
              activities={activities}
            />
          </div>
        )}
        {activeTab === 'Published' && (
          <div className="published-content">
            <div className="library-grid">
              {activities
                .filter(activity => activity.action === 'publish_video')
                .map((activity, index) => (
                  <div key={index} className="library-item">
                    <div className="video-preview" onClick={() => setSelectedVideo(activity.data)}>
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        {activity.data.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>{activity.user}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.timestamp).toLocaleDateString()}</span>
                      </div>
                      {activity.action === 'publish_video' && (
                        <button 
                          className={`approve-button ${activity.data.isApproved ? 'cancel' : ''}`}
                          onClick={() => handleApproveVideo(activity.id, activity.data.version)}
                        >
                          {activity.data.isApproved ? 'Cancel Approve' : 'Client Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {selectedVideo && (
              <VideoModal
                isOpen={true}
                onClose={() => setSelectedVideo(null)}
                videoPath={selectedVideo.videoPath}
                annotations={selectedVideo.annotations || []}
              />
            )}
          </div>
        )}
        {activeTab === 'Client Approved' && (
          <div className="approved-content">
            <div className="library-grid">
              {activities
                .filter(activity => 
                  activity.action === 'publish_video' && 
                  activity.data.isApproved
                )
                .map((activity, index) => (
                  <div 
                    key={index} 
                    className="library-item"
                  >
                    <div 
                      className="video-preview"
                      onClick={() => setSelectedVideo(activity.data)}
                    >
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        <span className="approved-badge">Client Approved</span>
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>Approved by: {activity.data.approvedBy}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.data.approvedAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Container History */}
            <div className="approval-history-container">
              <h3>Approval History</h3>
              <div className="approval-history-list">
                {activities
                  .filter(activity => 
                    activity.action === 'approve_video' || 
                    activity.action === 'cancel_approve_video'
                  )
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((activity, index) => (
                    <div key={index} className="history-item">
                      <div className="history-icon">
                        {activity.action === 'approve_video' ? '✅' : '❌'}
                      </div>
                      <div className="history-content">
                        <p className="history-action">
                          {activity.action === 'approve_video' 
                            ? 'Video Approved' 
                            : 'Approval Cancelled'}
                        </p>
                        <p className="history-details">
                          Version: {activity.data.version} • 
                          By: {activity.user} • 
                          {new Date(activity.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <IssueForm
        isOpen={isIssueFormVisible}
        onClose={() => setIsIssueFormVisible(false)}
        itemId={currentTask.id}
        itemType="Task"
        currentUser={currentUser}
        location={`Task: ${currentTask.taskName}`}
      />
    </div>
  );
};

export default TaskOverview;
