import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import './HashtagManager.css';

const HashtagManager = () => {
  const [hashtags, setHashtags] = useState([]);
  const [newHashtag, setNewHashtag] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchHashtags();
  }, []);

  const fetchHashtags = async () => {
    try {
      const response = await axiosInstance.get('/api/hashtags');
      setHashtags(response.data);
    } catch (error) {
      console.error('Error fetching hashtags:', error);
      setError('Gagal mengambil data hashtag');
    }
  };

  const handleAdd = async () => {
    if (!newHashtag.trim()) {
      setError('Hashtag tidak boleh kosong');
      return;
    }

    if (!newHashtag.startsWith('#')) {
      setNewHashtag(`#${newHashtag}`);
    }

    try {
      const response = await axiosInstance.post('/api/hashtags', {
        name: newHashtag
      });
      setHashtags([...hashtags, response.data]);
      setNewHashtag('');
      setError('');
    } catch (error) {
      console.error('Error adding hashtag:', error);
      setError('Gagal menambahkan hashtag');
    }
  };

  const handleEdit = (hashtag) => {
    setEditingId(hashtag.id);
    setEditValue(hashtag.name);
  };

  const handleSaveEdit = async () => {
    if (!editValue.trim()) {
      setError('Hashtag tidak boleh kosong');
      return;
    }

    if (!editValue.startsWith('#')) {
      setEditValue(`#${editValue}`);
    }

    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/hashtags/${editingId}`, {
        name: editValue
      });
      setHashtags(hashtags.map(h => h.id === editingId ? response.data : h));
      setEditingId(null);
      setEditValue('');
      setError('');
    } catch (error) {
      console.error('Error updating hashtag:', error);
      setError('Gagal mengupdate hashtag');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/hashtags/${id}`);
      setHashtags(hashtags.filter(h => h.id !== id));
      setError('');
    } catch (error) {
      console.error('Error deleting hashtag:', error);
      setError('Gagal menghapus hashtag');
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditValue('');
    setError('');
  };

  return (
    <div className="hashtag-manager">
      <div className="hashtag-header">
        <h3>Pengaturan Hashtag</h3>
        <div className="hashtag-add">
          <input
            type="text"
            value={newHashtag}
            onChange={(e) => setNewHashtag(e.target.value)}
            placeholder="Tambah hashtag baru"
            className="hashtag-input"
          />
          <button onClick={handleAdd} className="add-btn">
            <FaPlus /> Tambah
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="hashtag-list">
        {hashtags.map(hashtag => (
          <div key={hashtag.id} className="hashtag-item">
            {editingId === hashtag.id ? (
              <>
                <input
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  className="edit-input"
                />
                <div className="hashtag-actions">
                  <button onClick={handleSaveEdit} className="save-btn">
                    <FaSave />
                  </button>
                  <button onClick={handleCancelEdit} className="cancel-btn">
                    <FaTimes />
                  </button>
                </div>
              </>
            ) : (
              <>
                <span className="hashtag-name">{hashtag.name}</span>
                <div className="hashtag-actions">
                  <button onClick={() => handleEdit(hashtag)} className="edit-btn">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(hashtag.id)} className="delete-btn">
                    <FaTrash />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HashtagManager;
