import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShotTreeView from './ShotTreeView';
import GanttChart from './GanttChart_myspace';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import { gantt } from 'dhtmlx-gantt';

const MySpace = ({ activeProject, userRole,isDarkMode }) => {
  const navigate = useNavigate();
  const [editingTask, setEditingTask] = useState(null);
  const [socket, setSocket] = useState(null);
  const [shots, setShots] = useState([]);
  const [assets, setAssets] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [currentTab, setCurrentTab] = useState('tree');
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('username'));

  useEffect(() => {
    const fetchData = async () => {
      if (activeProject?.id) {
        try {
          const shotsResponse = await axiosInstance.get(`/api/shots/${activeProject.id}`);
          setShots(shotsResponse.data);
          const assetsResponse = await axiosInstance.get(`/api/assets/project/${activeProject.id}`);
          setAssets(assetsResponse.data);
          const tasksResponse = await axiosInstance.get(`/api/tasks/${activeProject.id}`);
          setTasks(tasksResponse.data);
          const sequencesResponse = await axiosInstance.get(`/api/sequences/${activeProject.id}`);
          setSequences(sequencesResponse.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [activeProject]);
  const handleDeleteTask = async (id) => {
    try {
      const response = await axiosInstance.delete(`api/tasks/${id}`);
      if (response.status === 200) {
        socket.emit('deleteTask', id);
        console.log('Task deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Gagal menghapus task');
    }
  };
  const handleEditTask = async (id, updatedFields) => {
    try {
      // Pastikan taskName tidak null
      if (!updatedFields.taskName) {
        updatedFields.taskName = 'Unnamed Task';
      }

      // Tentukan status berdasarkan progress
      let status = '';
      if (updatedFields.progress === 100) {
        status = 'done';
      } else if (updatedFields.progress > 0 && updatedFields.progress < 100) {
        status = 'in_progress';
      } else {
        status = 'todo';
      }

      const response = await axiosInstance.put(`api/tasks/${id}`, {
        ...updatedFields,
        status,
        projectId: activeProject.id
      });

      // Cek apakah socket tersedia sebelum emit
      if (socket && typeof socket.emit === 'function') {
        socket.emit('updateTask', { ...updatedFields, id, status });
      } else {
        console.warn('Socket tidak tersedia untuk emit updateTask');
      }

      console.log('Task updated successfully');
      return response.data;

    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };
  const formatDataForGantt = (sequences, shots, assets, tasks) => {
    let formattedTasks = [];

    sequences.forEach(sequence => {
      formattedTasks.push({
        id: `seq-${sequence.id}`,
        text: sequence.name,
        type: 'project'
      });

      shots.filter(shot => shot.sequenceId === sequence.id).forEach(shot => {
        formattedTasks.push({
          id: `shot-${shot.id}`,
          text: shot.name,
          parent: `seq-${sequence.id}`,
          type: 'task'
        });

        // Cari tasks yang terkait dengan shot ini
        const relatedTasks = tasks.filter(task => 
          task.selectedShots?.includes(shot.id) || 
          shot.selectedTasks?.includes(task.id)
        );

        relatedTasks.forEach(task => {
          formattedTasks.push({
            id: `task-${task.id}`,
            text: task.name,
            parent: `shot-${shot.id}`,
            type: 'task',
            start_date: task.startDate,
            end_date: task.dueDate,
            progress: task.progress / 100
          });
        });
      });
    });

    return formattedTasks;
  };


  const isDataLoaded = sequences.length > 0 && shots.length > 0 && assets.length > 0 && tasks.length > 0;

  return (
    <div>
      <div className="global-header">
        <h1>My Space</h1>
        <div className="tab-buttons">
          <button onClick={() => setCurrentTab('tree')}>Tree View</button>
          <button onClick={() => setCurrentTab('gantt')}>Gantt View</button>
        </div>
      </div>
      {currentTab === 'tree' && (
        <ShotTreeView 
          shots={shots} 
          assets={assets} 
          tasks={tasks} 
        />
      )}
      {currentTab === 'gantt' && isDataLoaded && (
        <GanttChart 
        tasks={tasks}
        shots={shots}
        assets={assets}
        sequences={[]}
        onDeleteTask={handleDeleteTask}
        onEditTask={handleEditTask}
        userRole={userRole}
        isDarkMode={isDarkMode}
        activeProject={activeProject}
        socket={socket}
        />
      )}
    </div>
  );
};

export default MySpace;
