import React, { useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/globalform.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import { FaEdit, FaTrash, FaEye, FaPlus, FaList, FaThLarge, FaFilter } from 'react-icons/fa';
import SequenceForm from './SequenceForm';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import axiosInstance from '../config/axios';

const SequenceList = ({ 
  sequences = [], 
  shots = [], 
  onDeleteSequence,
  onEditSequence,
  userRole,
  isDarkMode,
  activeProject,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  selectedSequence,
  setSelectedSequence,
  socket,
  users
}) => {
  console.log('SequenceList - Received props:', {
    sequences,
    shots,
    activeProject
  });

  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statuses, setStatuses] = useState([]);
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('list');
  const [filters, setFilters] = useState({
    status: null,
    name: ''
  });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    console.log('SequenceList - Sequences updated:', sequences);
    sequences.forEach(sequence => {
      console.log(`Sequence ${sequence.sequenceName} (${sequence.id}):`, {
        shots: sequence.shots,
        shotCount: sequence.shots?.length || 0
      });
    });
  }, [sequences]);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/sequence-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('shotUpdated', (updatedShot) => {
        console.log('Socket: Shot updated', updatedShot);
      });

      socket.on('sequenceUpdated', (updatedSequence) => {
        console.log('Socket: Sequence updated', updatedSequence);
      });

      return () => {
        socket.off('shotUpdated');
        socket.off('sequenceUpdated');
      };
    }
  }, [socket]);

  useEffect(() => {
    if (shots && shots.length > 0) {
      console.log('Syncing shots with sequences');
      
      // Create a map of sequences with their shots
      const sequenceShots = shots.reduce((acc, shot) => {
        if (shot.sequenceId) {
          if (!acc[shot.sequenceId]) {
            acc[shot.sequenceId] = [];
          }
          acc[shot.sequenceId].push(shot);
        }
        return acc;
      }, {});

      // Update sequences with their associated shots
      sequences.forEach(sequence => {
        if (sequenceShots[sequence.id]) {
          sequence.shots = sequenceShots[sequence.id];
        }
      });

      console.log('Updated sequences with shots:', sequences);
    }
  }, [shots, sequences]);

  useEffect(() => {
    if (socket) {
      socket.on('shotUpdated', (updatedShot) => {
        console.log('Socket: Shot updated', updatedShot);
        
        // Update sequences when a shot is updated
        if (updatedShot.sequenceId) {
          sequences.forEach(sequence => {
            if (sequence.id === updatedShot.sequenceId) {
              // Update or add the shot
              const shotIndex = sequence.shots?.findIndex(s => s.id === updatedShot.id);
              if (shotIndex !== -1) {
                sequence.shots[shotIndex] = updatedShot;
              } else {
                sequence.shots = [...(sequence.shots || []), updatedShot];
              }
            } else {
              // Remove the shot if it was moved from this sequence
              sequence.shots = sequence.shots?.filter(s => s.id !== updatedShot.id) || [];
            }
          });
        }
      });

      socket.on('sequenceUpdated', (updatedSequence) => {
        console.log('Socket: Sequence updated', updatedSequence);
      });

      return () => {
        socket.off('shotUpdated');
        socket.off('sequenceUpdated');
      };
    }
  }, [socket, sequences]);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_sequences', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleEditClick = (sequence) => {
    console.log('Editing sequence:', sequence);
    setEditingId(sequence.id);
    setEditFormData(sequence);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      console.log('Submitting sequence form:', formData);
      if (editingId) {
        await onEditSequence(editingId, formData);
      }
      handleFormClose();
    } catch (error) {
      console.error('Error updating sequence:', error);
    }
  };

  const handleSequenceClick = async (sequence) => {
    console.log('Sequence clicked:', sequence);
    let isMounted = true;
    
    try {
      const response = await axiosInstance.get(`/activities/sequences/${sequence.id}`);
      const activities = response.data;
      
      if (isMounted) {
        const updatedSequence = {
          ...sequence,
          activities: activities
        };
        
        setSelectedSequence(updatedSequence);
        setIsActivityPanelOpen(true);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      if (isMounted) {
        setSelectedSequence(sequence);
        setIsActivityPanelOpen(true);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const handleOverviewClick = (sequenceId) => {
    navigate(`/sequence-overview/${sequenceId}`);
  };

  const getStatusBadge = (statusId) => {
    if (!statusId) return (
      <span className="status-badge pending">
        Pending
      </span>
    );
    
    const status = statuses.find(s => s.id === parseInt(statusId));
    if (!status) return (
      <span className="status-badge unknown">
        Unknown
      </span>
    );

    const statusClass = status.name.toLowerCase().replace(/\s+/g, '-');
    return (
      <span className={`status-badge ${statusClass}`}>
        {status.name}
      </span>
    );
  };

  const handleAddSequence = async (formData) => {
    try {
      console.log('Adding new sequence:', formData);
      const response = await axiosInstance.post('/api/sequences', {
        ...formData,
        projectId: activeProject.id
      });

      if (response.data) {
        console.log('New sequence created:', response.data);
        socket.emit('createSequence', response.data);
        setIsFormVisible(false);

        const username = typeof userRole === 'object' ? 
          userRole.username || 'unknown' : 
          userRole || 'unknown';

        await axiosInstance.post('/api/activities', {
          sequenceId: response.data.id,
          user: username,
          action: 'create_sequence',
          details: `Sequence "${formData.sequenceName}" telah dibuat`,
          data: {
            sequenceName: formData.sequenceName,
            description: formData.description,
            statusId: formData.statusId,
            selectedShots: formData.selectedShots
          },
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error creating sequence:', error);
      alert('Gagal membuat sequence. Silakan coba lagi.');
    }
  };

  const handleAddClick = () => {
    setIsFormVisible(true);
  };

  const onViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: ''
    });
  };

  const filteredSequences = sequences.filter(sequence => {
    if (filters.status && sequence.statusId !== filters.status.value) return false;
    if (filters.name && !sequence.sequenceName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    if (searchTerm && !sequence.sequenceName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  return (
    <div className={`content-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>List View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search sequences..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={handleAddClick}
            >
              <FaPlus /> Add Sequence
            </button>
          )}
          <div className="view-toggle">
            <button
              className={`toggle-button ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => onViewModeChange('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => onViewModeChange('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      {isFormVisible && (
        <div className="modal-overlay">
          <div className="modal-form">
            <SequenceForm
              onSubmit={editingId ? handleFormSubmit : handleAddSequence}
              onClose={handleFormClose}
              shots={shots}
              isEditing={!!editingId}
              initialSequence={editFormData}
              currentUser={userRole?.username}
              isDarkMode={isDarkMode}
            />
          </div>
        </div>
      )}

      <div className="card mt-4">
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Shots</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredSequences
                .map(sequence => {
                  console.log(`Rendering sequence ${sequence.sequenceName}:`, {
                    id: sequence.id,
                    shots: sequence.shots,
                    shotCount: sequence.shots?.length || 0
                  });
                  return (
                    <tr 
                      key={sequence.id}
                      className={`sequence-row ${selectedSequence?.id === sequence.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
                      onClick={() => handleSequenceClick(sequence)}
                    >
                      <td>{sequence.sequenceName}</td>
                      <td>{sequence.description}</td>
                      <td>{getStatusBadge(sequence.statusId)}</td>
                      <td>
                        <div className="flex flex-wrap gap-1">
                          {sequence.shots?.map(shot => (
                            <div key={shot.id} className="shot-badge-container">
                              <span className={`shot-badge ${shot.statusId ? `status-${shot.statusId}` : 'status-pending'}`}>
                                {shot.shotName}
                                {shot.relatedTasks && shot.relatedTasks.length > 0 && (
                                  <span className="task-count">{shot.relatedTasks.length}</span>
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                        {/* Progress bar removed */}
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {hasManagePermission(userRole) && (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(sequence);
                                }}
                                className="btn btn-icon btn-sm"
                              >
                                <FaEdit />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onDeleteSequence(sequence.id);
                                }}
                                className="btn btn-icon btn-sm btn-danger"
                              >
                                <FaTrash />
                              </button>
                            </>
                          )}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOverviewClick(sequence.id);
                            }}
                            className="btn btn-icon btn-sm"
                          >
                            <FaEye />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedSequence?.id && isActivityPanelOpen && (
        <ActivityPanel
          item={selectedSequence}
          type="sequence"
          isOpen={isActivityPanelOpen}
          onClose={() => {
            setSelectedSequence(null);
            setIsActivityPanelOpen(false);
          }}
          currentUser={userRole?.username}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default SequenceList;
