// Base configuration
const config = {
  development: {
    HOST: process.env.REACT_APP_HOST || 'localhost',
    PORT: process.env.REACT_APP_PORT || '8000',
    PROTOCOL: process.env.REACT_APP_PROTOCOL || 'http',
    WS_PROTOCOL: process.env.REACT_APP_WS_PROTOCOL || 'ws'
  },
  production: {
    HOST: process.env.REACT_APP_HOST || 'localhost',
    PORT: process.env.REACT_APP_PORT || '8000',
    PROTOCOL: process.env.REACT_APP_PROTOCOL || 'https',
    WS_PROTOCOL: process.env.REACT_APP_WS_PROTOCOL || 'wss'
  }
};

// Get current environment
const env = process.env.NODE_ENV || 'development';
const currentConfig = config[env];

// Construct URLs
const API_BASE_URL = `${currentConfig.PROTOCOL}://${currentConfig.HOST}:${currentConfig.PORT}`;
const WS_BASE_URL = `${currentConfig.WS_PROTOCOL}://${currentConfig.HOST}:${currentConfig.PORT}`;

// Export configurations
export { 
  API_BASE_URL, 
  WS_BASE_URL, 
  currentConfig as config 
};
