import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import './LevelManager.css';

const LevelManager = () => {
  const [levels, setLevels] = useState([]);
  const [newLevel, setNewLevel] = useState({
    name: '',
    description: '',
    rank: 0
  });
  const [editingLevel, setEditingLevel] = useState(null);

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    try {
      const response = await axiosInstance.get('/api/levels');
      setLevels(response.data);
    } catch (error) {
      console.error('Error mengambil data level:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingLevel) {
        await axiosInstance.put(`${API_BASE_URL}/api/levels/${editingLevel.id}`, newLevel);
      } else {
        await axiosInstance.post('/api/levels', newLevel);
      }
      setNewLevel({ name: '', description: '', rank: 0 });
      setEditingLevel(null);
      fetchLevels();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Yakin ingin menghapus level ini?')) {
      try {
        await axiosInstance.delete(`${API_BASE_URL}/api/levels/${id}`);
        fetchLevels();
      } catch (error) {
        console.error('Error menghapus level:', error);
      }
    }
  };

  return (
    <div className="level-manager">
      <form onSubmit={handleSubmit} className="level-form">
        <input
          type="text"
          placeholder="Nama Level"
          value={newLevel.name}
          onChange={(e) => setNewLevel({...newLevel, name: e.target.value})}
          required
        />
        <textarea
          placeholder="Deskripsi"
          value={newLevel.description}
          onChange={(e) => setNewLevel({...newLevel, description: e.target.value})}
        />
        <input
          type="number"
          placeholder="Ranking"
          value={newLevel.rank}
          onChange={(e) => setNewLevel({...newLevel, rank: parseInt(e.target.value)})}
          required
        />
        <button type="submit">
          {editingLevel ? 'Update Level' : 'Tambah Level'}
        </button>
      </form>

      <div className="level-list">
        {levels.map(level => (
          <div key={level.id} className="level-item">
            <div className="level-info">
              <h3>{level.name}</h3>
              <p>{level.description}</p>
              <span>Ranking: {level.rank}</span>
            </div>
            <div className="level-actions">
              <button onClick={() => {
                setEditingLevel(level);
                setNewLevel(level);
              }}>Edit</button>
              <button onClick={() => handleDelete(level.id)}>Hapus</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LevelManager; 