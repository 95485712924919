import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IssueForm from './IssueForm';

const OverviewHeader = ({ 
  item, 
  type, 
  thumbnailUrl, 
  onPrevious, 
  onNext, 
  currentUser 
}) => {
  const navigate = useNavigate();
  const [isIssueFormVisible, setIsIssueFormVisible] = useState(false);

  const handleReportIssue = () => {
    setIsIssueFormVisible(true);
  };

  return (
    <div className="overview-header">
      <div className="header-content">
        <img 
          src={thumbnailUrl} 
          alt={`${type} Thumbnail`} 
          className="overview-thumbnail" 
          onError={(e) => {
            e.target.src = `/assets/images/${type.toLowerCase()}-placeholder.png`;
          }}
        />
        <h2>{item[`${type.toLowerCase()}Name`]}</h2>
        <div className="header-actions">
          <div className="navigation-buttons">
            <button onClick={onPrevious}>Sebelumnya</button>
            <button onClick={onNext}>Selanjutnya</button>
          </div>
          <button 
            className="report-issue-button"
            onClick={handleReportIssue}
          >
            Report Issue
          </button>
        </div>
      </div>

      {isIssueFormVisible && (
        <IssueForm
          isOpen={true}
          onClose={() => setIsIssueFormVisible(false)}
          itemId={item.id}
          itemType={type}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default OverviewHeader; 