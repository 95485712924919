import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import './ReportManager.css';
import '../styles/global.css';
import { API_BASE_URL } from '../config/config';

const SequenceShotReport = ({ isDarkMode, selectedProject }) => {
  const [sequenceShotStats, setSequenceShotStats] = useState({
    sequenceAnalytics: {
      totalSequences: 0,
      statusBreakdown: {},
      topSequences: []
    },
    shotMetrics: {
      totalShots: 0,
      statusDistribution: {},
      criticalShots: []
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({
    sequence: '',
    status: '',
    critical: false
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!selectedProject) return;
      
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/stats/sequence-shot-stats/${selectedProject}`);
        if (!response.ok) {
          throw new Error('Failed to fetch sequence and shot statistics');
        }
        const data = await response.json();
        if (isMounted) {
          setSequenceShotStats(data);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching sequence and shot stats:', err);
          setError('Gagal mengambil statistik sequence dan shot');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedProject]);

  // Fungsi untuk sorting
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Fungsi untuk mengecek apakah shot kritis (deadline < 14 hari)
  const isCriticalShot = (dueDate) => {
    if (!dueDate) return false;
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = due.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 && diffDays <= 14;
  };

  // Fungsi untuk mendapatkan data yang sudah difilter dan disort
  const getFilteredAndSortedData = () => {
    let filteredData = [...(sequenceShotStats.shotMetrics.criticalShots || [])];

    // Filter data
    filteredData = filteredData.filter(shot => {
      const matchesSequence = !filters.sequence || (shot.sequence && shot.sequence.toLowerCase().includes(filters.sequence.toLowerCase()));
      const matchesStatus = !filters.status || (shot.status && shot.status.toLowerCase().includes(filters.status.toLowerCase()));
      const matchesCritical = !filters.critical || isCriticalShot(shot.dueDate);
      
      return matchesSequence && matchesStatus && matchesCritical;
    });

    // Sort data
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  };

  const downloadExcel = () => {
    const filteredData = getFilteredAndSortedData();
    
    // Menyiapkan data untuk Excel
    const excelData = filteredData.map(shot => ({
      'Shot ID': shot.id,
      'Sequence': shot.sequence,
      'Status': shot.status,
      'Department': shot.department || '-',
      'Due Date': shot.dueDate ? new Date(shot.dueDate).toLocaleDateString() : '-'
    }));

    // Membuat workbook dan worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sequence Shot Report');

    // Download file
    XLSX.writeFile(wb, 'sequence_shot_report.xlsx');
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
    cutout: '60%'
  };

  if (loading) {
    return <div className="loading">Memuat data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className={`sequence-shot-report ${isDarkMode ? 'dark' : ''}`}>
      <div className="report-header">
        <h2>Laporan Sequence dan Shot</h2>
        <div className="summary-stats">
          <div className="stat-card">
            <h3>Total Sequences</h3>
            <p>{sequenceShotStats.sequenceAnalytics.totalSequences}</p>
          </div>
          <div className="stat-card">
            <h3>Total Shots</h3>
            <p>{sequenceShotStats.shotMetrics.totalShots}</p>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <div className="chart-section">
          <h3>Status Sequence</h3>
          <div className="chart-wrapper">
            <Pie 
              data={{
                labels: Object.keys(sequenceShotStats.sequenceAnalytics.statusBreakdown),
                datasets: [{
                  backgroundColor: Object.keys(sequenceShotStats.sequenceAnalytics.statusBreakdown).map(status => {
                    const statusObj = sequenceShotStats.sequenceAnalytics.statusColors[status];
                    return statusObj ? `${statusObj.color}80` : '#CCCCCC80'; // 80 adalah 50% opacity dalam hex
                  }),
                  borderColor: Object.keys(sequenceShotStats.sequenceAnalytics.statusBreakdown).map(status => {
                    const statusObj = sequenceShotStats.sequenceAnalytics.statusColors[status];
                    return statusObj ? statusObj.color : '#CCCCCC';
                  }),
                  borderWidth: 1,
                  data: Object.values(sequenceShotStats.sequenceAnalytics.statusBreakdown)
                }]
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className="chart-section">
          <h3>Status Shot</h3>
          <div className="chart-wrapper">
            <Pie 
              data={{
                labels: Object.keys(sequenceShotStats.shotMetrics.statusDistribution),
                datasets: [{
                  backgroundColor: Object.keys(sequenceShotStats.shotMetrics.statusDistribution).map(status => {
                    const statusObj = sequenceShotStats.shotMetrics.statusColors[status];
                    return statusObj ? `${statusObj.color}80` : '#CCCCCC80'; // 80 adalah 50% opacity dalam hex
                  }),
                  borderColor: Object.keys(sequenceShotStats.shotMetrics.statusDistribution).map(status => {
                    const statusObj = sequenceShotStats.shotMetrics.statusColors[status];
                    return statusObj ? statusObj.color : '#CCCCCC';
                  }),
                  borderWidth: 1,
                  data: Object.values(sequenceShotStats.shotMetrics.statusDistribution)
                }]
              }}
              options={chartOptions}
            />
          </div>
        </div>


      </div>

      <div className="critical-shots">
        <h3>Critical Shots</h3>
        <div className="filter-controls">
          <button
            onClick={downloadExcel}
            className="btn-primary"
            style={{ marginRight: '10px' }}
          >
            Download Excel
          </button>
          <input
            type="text"
            placeholder="Filter by Sequence"
            value={filters.sequence}
            onChange={(e) => setFilters({ ...filters, sequence: e.target.value })}
            className="filter-input"
          />
          <select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            className="filter-select"
          >
            <option value="">All Status</option>
            <option value="Not Started">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Review">Review</option>
            <option value="Final">Final</option>
            <option value="Approved">Approved</option>
          </select>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
            <input
              type="checkbox"
              id="criticalFilter"
              checked={filters.critical}
              onChange={(e) => setFilters({ ...filters, critical: e.target.checked })}
              style={{ marginRight: '8px' }}
            />
            <label htmlFor="criticalFilter">Tampilkan Shot Kritis (kurang dari 14 hari)</label>
          </div>
        </div>

        <div className="table-responsive">
          <table className="report-table">
            <thead>
              <tr>
                <th onClick={() => requestSort('shotName')} className="sortable-header">
                  Shot {sortConfig.key === 'shotName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('sequence')} className="sortable-header">
                  Sequence {sortConfig.key === 'sequence' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('status')} className="sortable-header">
                  Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>

                <th onClick={() => requestSort('dueDate')} className="sortable-header">
                  Due Date {sortConfig.key === 'dueDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
              </tr>
            </thead>
            <tbody>
              {getFilteredAndSortedData().map((shot, index) => (
                <tr key={index}>
                  <td>{shot.shotName}</td>
                  <td>{shot.sequence}</td>
                  <td>
                    <span className={`status-badge ${shot.status.toLowerCase().replace(' ', '-')}`}>
                      {shot.status}
                    </span>
                  </td>

                  <td style={isCriticalShot(shot.dueDate) ? { color: '#dc3545', fontWeight: 500 } : {}}>
                    {shot.dueDate ? new Date(shot.dueDate).toLocaleDateString() : '-'}
                    {isCriticalShot(shot.dueDate) && (
                      <span style={{
                        backgroundColor: '#dc3545',
                        color: 'white',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '0.8em',
                        marginLeft: '8px'
                      }}>Kritis</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SequenceShotReport;
