import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import TaskList from './TaskList';
import DccFilePanel from './DccFilePanel';
import { useDarkMode } from '../../App';
import { API_BASE_URL } from '../config/config';
import './MyTasks.css';

const MyTasks = ({ activeProject }) => {
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [shots, setShots] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const { isDarkMode } = useDarkMode();
  const currentUserId = localStorage.getItem('userId');
  const [filters, setFilters] = useState({
    assignee: null,
    startDate: null,
    dueDate: null,
    progress: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Current userId:', currentUserId);
        console.log('Active project:', activeProject);
        
        // Fetch tasks for current user
        let tasksResponse;
        if (activeProject) {
          // Jika ada proyek aktif, ambil tugas untuk user dan proyek tersebut
          tasksResponse = await axiosInstance.get(`/api/tasks/user/${currentUserId}/project/${activeProject.id}`);
          console.log('My tasks for project:', tasksResponse.data);
        } else {
          // Jika tidak ada proyek aktif, ambil semua tugas user
          tasksResponse = await axiosInstance.get(`/api/tasks/user/${currentUserId}`);
          console.log('All my tasks:', tasksResponse.data);
        }
        
        const tasksWithProgress = tasksResponse.data.map(task => ({
          ...task,
          progress: task.progress !== null && task.progress !== undefined ? 
            Number(task.progress) : 0
        }));
        
        setTasks(tasksWithProgress);
        
        // Fetch other data
        const [statusesRes, usersRes, shotsRes, assetsRes] = await Promise.all([
          axiosInstance.get('/api/task-statuses'),
          axiosInstance.get('/api/users'),
          axiosInstance.get(`/api/shots/${activeProject.id}`),
          axiosInstance.get(`/api/assets/${activeProject.id}`)
        ]);

        setStatuses(statusesRes.data);
        setUsers(usersRes.data);
        setShots(shotsRes.data);
        setAssets(assetsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (activeProject) {
      fetchData();
    }
  }, [activeProject, currentUserId]);

  const handleDeleteTask = async (id) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/tasks/${id}`);
      setTasks(tasks.filter(task => task.id !== id));
      if (selectedTask?.id === id) {
        setSelectedTask(null);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task');
    }
  };

  const handleEditTask = async (id, updatedFields) => {
    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/tasks/${id}`, updatedFields);
      setTasks(tasks.map(task => 
        task.id === id ? response.data : task
      ));
      if (selectedTask?.id === id) {
        setSelectedTask(response.data);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      alert('Failed to update task');
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const handleSort = (key, direction) => {
    const sortedTasks = [...tasks].sort((a, b) => {
      if (direction === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setTasks(sortedTasks);
  };

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
  };

  return (
    <div className={`container ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="content-container">
        <div className="global-header">
          <h2>My Tasks</h2>
        </div>
        <div className="my-tasks-content">
          <div className="tasks-list-panel">
            <TaskList
              tasks={tasks}
              shots={shots}
              assets={assets}
              users={users}
              statuses={statuses}
              onDeleteTask={handleDeleteTask}
              onEditTask={handleEditTask}
              isDarkMode={isDarkMode}
              handleSort={handleSort}
              handleFilterChange={handleFilterChange}
              filters={filters}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              onTaskSelect={handleTaskSelect}
              selectedTaskId={selectedTask?.id}
            />
          </div>
          <div className="dcc-panel">
            <DccFilePanel 
              task={selectedTask}
              activeProject={activeProject}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTasks;
