import React, { useState, useEffect } from 'react';
import { FaRoad, FaChartBar, FaExclamationCircle, FaRobot, FaColumns, FaFilm, FaFileAlt, FaTable, FaHdd } from 'react-icons/fa';
import RoadmapManager from './RoadmapManager';
import ReportManager from './ReportManager';
import IssueList from './IssueList';
import ProjectAnalytics from './ProjectAnalytics';
import RenderManager from './RenderManager';
import DocumentEditor from './DocumentEditor';
import SpreadsheetEditor from './SpreadsheetEditor';
import Board from './Board';
import StorageManagement from './StorageManagement';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import { useToast } from '@chakra-ui/react';
import { useDarkMode } from '../../App';
import '../styles/global.css';

const AdminPanel = ({ activeProject, userRole }) => {
  const [activeComponent, setActiveComponent] = useState(() => {
    return localStorage.getItem('adminPanelActiveComponent') || 'roadmap';
  });
  const [issues, setIssues] = useState([]); 
  const toast = useToast();
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    localStorage.setItem('adminPanelActiveComponent', activeComponent);
  }, [activeComponent]);

  const menuItems = [
    { id: 'roadmap', label: 'Roadmap', icon: <FaRoad /> },
    { id: 'board', label: 'Board', icon: <FaColumns /> },
    { id: 'reports', label: 'Reports', icon: <FaChartBar /> },
    { id: 'issues', label: 'Issues', icon: <FaExclamationCircle /> },
    { id: 'analytics', label: 'AI Analytics', icon: <FaRobot /> },
    { id: 'renders', label: 'Render Farm', icon: <FaFilm /> },
    { id: 'documents', label: 'Documents', icon: <FaFileAlt /> },
    { id: 'spreadsheets', label: 'Spreadsheets', icon: <FaTable /> },
    { id: 'storage', label: 'Storage', icon: <FaHdd /> }
  ];

  const renderComponent = () => {
    switch (activeComponent) {
      case 'roadmap':
        return <RoadmapManager />;
      case 'board':
        return <Board />;
      case 'reports':
        return <ReportManager />;
      case 'issues':
        return <IssueList activeProject={activeProject} userRole={userRole} isDarkMode={isDarkMode} />;
      case 'analytics':
        return <ProjectAnalytics isDarkMode={isDarkMode} />;
      case 'renders':
        return <RenderManager />;
      case 'documents':
        return <DocumentEditor isDarkMode={isDarkMode} />;
      case 'spreadsheets':
        return <SpreadsheetEditor isDarkMode={isDarkMode} />;
      case 'storage':
        return <StorageManagement isDarkMode={isDarkMode} />;
      default:
        return <div>Pilih menu dari sidebar</div>;
    }
  };

  const handleDeleteIssue = async (issueId) => {
    if (!window.confirm('Are you sure you want to delete this issue?')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`${API_BASE_URL}/api/issues/${issueId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Remove issue from state
      setIssues(prevIssues => prevIssues.filter(issue => issue.id !== issueId));
      
      toast({
        title: 'Issue deleted',
        description: 'The issue has been successfully deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    } catch (error) {
      console.error('Error deleting issue:', error);
      
      toast({
        title: 'Error deleting issue',
        description: error.response?.data?.message || 'An error occurred while deleting the issue',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    }
  };

  return (
    <div className={`app ${isDarkMode ? 'darkmode' : ''}`}>
      <div className={`admin-panel ${isDarkMode ? 'darkmode' : ''}`}>
        <div className={`admin-topbar ${isDarkMode ? 'darkmode' : ''}`}>
          <div className={`topbar-header ${isDarkMode ? 'darkmode' : ''}`}>
            <h2>Admin Panel</h2>
          </div>
          <div className="topbar-menu">
            {menuItems.map(item => (
              <button 
                key={item.id}
                className={`${activeComponent === item.id ? 'active' : ''} ${isDarkMode ? 'darkmode' : ''}`}
                onClick={() => setActiveComponent(item.id)}
              >
                {item.icon}
                {item.label}
              </button>
            ))}
          </div>
        </div>
        <div className={`admin-content ${isDarkMode ? 'darkmode' : ''}`}>
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;