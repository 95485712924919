import React, { useState, useEffect } from 'react';
import HashtagReport from './HashtagReport';
import SequenceShotReport from './SequenceShotReport';
import AssetTrackingReport from './AssetTrackingReport';
import TaskTrackingReport from './TaskTrackingReport';
import WeeklyReport from './WeeklyReport';
import { useDarkMode } from '../../App';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
} from 'chart.js';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import './ReportManager.css';
import '../styles/darkmode.css';
import { API_BASE_URL } from '../config/config';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const dummyData = {
  projectOverview: {
    totalProjects: 5,
    activeProjects: [
      {
        id: 'PRJ001',
        name: 'Feature Film A',
        progress: 65,
        sequences: 24,
        shots: 1250,
        assets: 340,
        tasks: 2800,
        status: 'In Production'
      },
      {
        id: 'PRJ002',
        name: 'TV Series B',
        progress: 40,
        sequences: 12,
        shots: 850,
        assets: 220,
        tasks: 1500,
        status: 'Pre-Production'
      }
    ]
  },
  sequenceAnalytics: {
    totalSequences: 36,
    statusBreakdown: {
      'In Progress': 15,
      'Review': 8,
      'Completed': 10,
      'On Hold': 3
    },
    topSequences: [
      { id: 'SEQ001', name: 'Opening Battle', shots: 85, completion: 75 },
      { id: 'SEQ002', name: 'Chase Scene', shots: 65, completion: 60 },
      { id: 'SEQ003', name: 'Finale', shots: 95, completion: 30 }
    ]
  },
  shotMetrics: {
    totalShots: 2100,
    statusDistribution: {
      'Not Started': 300,
      'In Progress': 800,
      'Review': 400,
      'Final': 450,
      'Approved': 150
    },
    departmentProgress: {
      'Layout': 75,
      'Animation': 60,
      'Lighting': 45,
      'FX': 40,
      'Comp': 35
    },
    criticalShots: [
      { id: 'SH001', sequence: 'SEQ001', status: 'Review', dueDate: '2024-04-01' },
      { id: 'SH002', sequence: 'SEQ002', status: 'In Progress', dueDate: '2024-03-25' }
    ]
  },
  assetTracking: {
    totalAssets: 560,
    byCategory: {
      'Characters': 45,
      'Props': 280,
      'Environments': 95,
      'Vehicles': 140
    },
    statusSummary: {
      'Modeling': { completed: 400, total: 560, inProgress: 120, blocked: 40 },
      'Texturing': { completed: 350, total: 560, inProgress: 150, blocked: 60 },
      'Rigging': { completed: 280, total: 450, inProgress: 130, blocked: 40 },
      'Shading': { completed: 320, total: 560, inProgress: 180, blocked: 60 }
    },
    assetDetails: [
      {
        id: 'AST001',
        name: 'Main Character',
        category: 'Characters',
        priority: 'High',
        status: 'In Progress',
        progress: 75,
        dueDate: '2024-04-01',
        assignedTo: {
          id: 'USR001',
          name: 'John Smith',
          avatar: 'https://example.com/avatar1.jpg',
          role: 'Senior Character Artist'
        },
        stages: [
          { name: 'Concept', status: 'Completed', completedAt: '2024-02-15' },
          { name: 'Modeling', status: 'Completed', completedAt: '2024-03-01' },
          { name: 'Texturing', status: 'In Progress', progress: 80 },
          { name: 'Rigging', status: 'In Progress', progress: 60 },
          { name: 'Shading', status: 'Not Started', progress: 0 }
        ],
        recentActivities: [
          { type: 'update', description: 'Updated textures for face', date: '2024-03-15' },
          { type: 'feedback', description: 'Rigging adjustments needed', date: '2024-03-14' },
          { type: 'milestone', description: 'Base modeling completed', date: '2024-03-01' }
        ],
        notes: 'Facial expressions need refinement'
      },
      {
        id: 'AST002',
        name: 'Hero Vehicle',
        category: 'Vehicles',
        priority: 'High',
        status: 'Review',
        progress: 90,
        dueDate: '2024-03-25',
        assignedTo: {
          id: 'USR002',
          name: 'Sarah Johnson',
          avatar: 'https://example.com/avatar2.jpg',
          role: 'Senior Vehicle Artist'
        },
        stages: [
          { name: 'Concept', status: 'Completed', completedAt: '2024-02-10' },
          { name: 'Modeling', status: 'Completed', completedAt: '2024-02-25' },
          { name: 'Texturing', status: 'Completed', completedAt: '2024-03-10' },
          { name: 'Rigging', status: 'Completed', completedAt: '2024-03-15' },
          { name: 'Shading', status: 'In Progress', progress: 90 }
        ],
        recentActivities: [
          { type: 'review', description: 'Final shading review', date: '2024-03-15' },
          { type: 'update', description: 'Added damage variations', date: '2024-03-14' },
          { type: 'milestone', description: 'All textures completed', date: '2024-03-10' }
        ],
        notes: 'Ready for final approval'
      }
    ],
    departmentWorkload: {
      'Character': { assigned: 45, capacity: 50 },
      'Environment': { assigned: 95, capacity: 100 },
      'Vehicle': { assigned: 140, capacity: 150 },
      'Props': { assigned: 280, capacity: 300 }
    }
  },
  taskManagement: {
    totalTasks: 4300,
    byPriority: {
      'High': 850,
      'Medium': 2200,
      'Low': 1250
    },
    byStatus: {
      'To Do': 1200,
      'In Progress': 1800,
      'Review': 800,
      'Done': 500
    },
    departmentLoad: {
      'Animation': 1200,
      'Lighting': 800,
      'FX': 600,
      'Comp': 900,
      'Art': 800
    }
  },
  teamMetrics: {
    departmentPerformance: {
      labels: ['Animation', 'Compositing', 'Lighting', 'FX', 'Pipeline', 'Rigging'],
      datasets: [{
        label: 'Tasks Completed',
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }]
    },
    workloadDistribution: {
      labels: ['Animation', 'Compositing', 'Lighting', 'FX', 'Pipeline', 'Rigging'],
      datasets: [{
        label: 'Current Workload',
        data: [30, 25, 20, 15, 10, 5],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }]
    },
    userMetrics: {
      users: [
        {
          id: 'USR001',
          name: 'John Smith',
          role: 'Senior Animator',
          department: 'Animation',
          metrics: {
            tasksCompleted: 45,
            tasksInProgress: 8,
            avgCompletionTime: '2.5 days',
            onTimeDelivery: '92%'
          },
          recentActivity: [
            { type: 'task_completed', item: 'Character Animation Review', date: '2024-03-15' },
            { type: 'feedback_given', item: 'Walk Cycle Animation', date: '2024-03-14' },
            { type: 'task_started', item: 'Fight Scene Animation', date: '2024-03-13' }
          ],
          currentAssignments: [
            { id: 'TSK045', name: 'Hero Animation', status: 'In Progress', deadline: '2024-03-20' },
            { id: 'TSK046', name: 'Crowd Scene', status: 'Review', deadline: '2024-03-22' }
          ]
        },
        {
          id: 'USR002',
          name: 'Sarah Johnson',
          role: 'Lead Compositor',
          department: 'Compositing',
          metrics: {
            tasksCompleted: 38,
            tasksInProgress: 5,
            avgCompletionTime: '1.8 days',
            onTimeDelivery: '95%'
          },
          recentActivity: [
            { type: 'review_completed', item: 'Final Battle Comp', date: '2024-03-15' },
            { type: 'task_completed', item: 'Environment Integration', date: '2024-03-14' },
            { type: 'feedback_given', item: 'Lighting Setup', date: '2024-03-13' }
          ],
          currentAssignments: [
            { id: 'TSK047', name: 'City Scene Comp', status: 'In Progress', deadline: '2024-03-21' },
            { id: 'TSK048', name: 'FX Integration', status: 'Pending', deadline: '2024-03-23' }
          ]
        },
        {
          id: 'USR003',
          name: 'Mike Wilson',
          role: 'Technical Director',
          department: 'Pipeline',
          metrics: {
            tasksCompleted: 52,
            tasksInProgress: 6,
            avgCompletionTime: '2.1 days',
            onTimeDelivery: '88%'
          },
          recentActivity: [
            { type: 'tool_deployed', item: 'Render Farm Manager', date: '2024-03-15' },
            { type: 'bug_fixed', item: 'Asset Publisher', date: '2024-03-14' },
            { type: 'code_review', item: 'Pipeline Tools', date: '2024-03-13' }
          ],
          currentAssignments: [
            { id: 'TSK049', name: 'Pipeline Optimization', status: 'In Progress', deadline: '2024-03-24' },
            { id: 'TSK050', name: 'Tool Documentation', status: 'Review', deadline: '2024-03-25' }
          ]
        }
      ]
    }
  },
  timeline: {
    projectMilestones: [
      { name: 'Pre-Production Complete', date: '2024-02-15', status: 'Completed' },
      { name: 'Production Milestone 1', date: '2024-04-01', status: 'In Progress' },
      { name: 'First Cut Review', date: '2024-06-15', status: 'Pending' }
    ],
    upcomingDeadlines: [
      { task: 'Sequence 5 Animation', date: '2024-03-30', assignee: 'Team A' },
      { task: 'Character Assets Final', date: '2024-04-10', assignee: 'Team B' }
    ],
    delays: {
      total: 12,
      critical: 5,
      byDepartment: {
        'Animation': 4,
        'Lighting': 3,
        'FX': 5
      }
    }
  },
  hashtagAnalytics: {
    totalHashtags: 2450,
    trendingHashtags: {
      '#urgent': { count: 156, trend: 'up', change: '+23%' },
      '#review': { count: 245, trend: 'up', change: '+15%' },
      '#feedback': { count: 189, trend: 'down', change: '-5%' },
      '#approved': { count: 134, trend: 'up', change: '+12%' },
      '#revision': { count: 98, trend: 'down', change: '-8%' }
    },
    hashtagsByDepartment: {
      'Animation': {
        '#wip': 45,
        '#review': 78,
        '#approved': 34
      },
      'Lighting': {
        '#render': 67,
        '#comp': 89,
        '#final': 23
      }
    },
    hashtagsByShots: {
      '#comp': { 
        count: 245, 
        trend: 'up',
        shots: [
          { id: 'SH001', name: 'Hero Entry', sequence: 'SEQ001', status: 'In Progress', department: 'Compositing' },
          { id: 'SH002', name: 'Battle Scene', sequence: 'SEQ002', status: 'Review', department: 'Compositing' },
          { id: 'SH003', name: 'Chase Shot', sequence: 'SEQ002', status: 'Final', department: 'Compositing' }
        ]
      },
      '#render': { 
        count: 198, 
        trend: 'up',
        shots: [
          { id: 'SH004', name: 'City View', sequence: 'SEQ003', status: 'Rendering', department: 'Lighting' },
          { id: 'SH005', name: 'Night Scene', sequence: 'SEQ003', status: 'Queue', department: 'Lighting' }
        ]
      },
      '#animation': { 
        count: 167, 
        trend: 'down',
        shots: [
          { id: 'SH006', name: 'Character Walk', sequence: 'SEQ001', status: 'In Progress', department: 'Animation' },
          { id: 'SH007', name: 'Fight Scene', sequence: 'SEQ002', status: 'Review', department: 'Animation' }
        ]
      },
      '#lighting': { 
        count: 145, 
        trend: 'up',
        shots: [
          { id: 'SH008', name: 'Interior Shot', sequence: 'SEQ004', status: 'In Progress', department: 'Lighting' },
          { id: 'SH009', name: 'Exterior Shot', sequence: 'SEQ004', status: 'Review', department: 'Lighting' }
        ]
      }
    },
    hashtagsBySequences: {
      '#final': { 
        count: 178, 
        trend: 'up',
        sequences: [
          { id: 'SEQ001', name: 'Opening Scene', shots: 45, status: 'Final Review', supervisor: 'John Doe' },
          { id: 'SEQ002', name: 'Chase Sequence', shots: 32, status: 'Final Review', supervisor: 'Jane Smith' }
        ]
      },
      '#review': { 
        count: 156, 
        trend: 'up',
        sequences: [
          { id: 'SEQ003', name: 'Battle Scene', shots: 28, status: 'Review', supervisor: 'Mike Johnson' },
          { id: 'SEQ004', name: 'Climax Scene', shots: 35, status: 'Review', supervisor: 'Sarah Wilson' }
        ]
      },
      '#inProgress': { 
        count: 112, 
        trend: 'up',
        sequences: [
          { id: 'SEQ005', name: 'Character Intro', shots: 15, status: 'In Progress', supervisor: 'Tom Brown' },
          { id: 'SEQ006', name: 'Ending Scene', shots: 25, status: 'In Progress', supervisor: 'Lisa Davis' }
        ]
      }
    },
    hashtagsByAssets: {
      '#modeling': { 
        count: 167, 
        trend: 'up',
        assets: [
          { id: 'AST001', name: 'Hero Character', type: 'Character', status: 'In Progress', artist: 'Alex Kim' },
          { id: 'AST002', name: 'Vehicle', type: 'Prop', status: 'Review', artist: 'Chris Lee' }
        ]
      },
      '#texturing': { 
        count: 145, 
        trend: 'up',
        assets: [
          { id: 'AST003', name: 'Environment', type: 'Environment', status: 'In Progress', artist: 'Maya Chen' },
          { id: 'AST004', name: 'Weapons', type: 'Prop', status: 'Final', artist: 'David Park' }
        ]
      },
      '#rigging': { 
        count: 123, 
        trend: 'down',
        assets: [
          { id: 'AST005', name: 'Secondary Character', type: 'Character', status: 'Review', artist: 'Emma White' },
          { id: 'AST006', name: 'Creature', type: 'Character', status: 'In Progress', artist: 'James Wilson' }
        ]
      }
    },
    hashtagsByTasks: {
      '#pending': { 
        count: 234, 
        trend: 'down',
        tasks: [
          { id: 'TSK001', name: 'Character Animation Review', assignee: 'John Smith', deadline: '2024-03-25', priority: 'High' },
          { id: 'TSK002', name: 'Environment Lighting', assignee: 'Sarah Johnson', deadline: '2024-03-26', priority: 'Medium' }
        ]
      },
      '#inProgress': { 
        count: 189, 
        trend: 'up',
        tasks: [
          { id: 'TSK003', name: 'FX Integration', assignee: 'Mike Wilson', deadline: '2024-03-24', priority: 'High' },
          { id: 'TSK004', name: 'Render Setup', assignee: 'Lisa Brown', deadline: '2024-03-25', priority: 'High' }
        ]
      },
      '#review': { 
        count: 167, 
        trend: 'up',
        tasks: [
          { id: 'TSK005', name: 'Comp Review', assignee: 'David Lee', deadline: '2024-03-23', priority: 'Medium' },
          { id: 'TSK006', name: 'Animation Polish', assignee: 'Emma Davis', deadline: '2024-03-24', priority: 'High' }
        ]
      }
    },
    hashtagTimeline: [
      { date: '2024-03-15', hashtags: ['#urgent', '#review'], count: 45 },
      { date: '2024-03-14', hashtags: ['#feedback', '#approved'], count: 38 },
      { date: '2024-03-13', hashtags: ['#revision', '#wip'], count: 52 }
    ]
  },
  weeklyAnalytics: {
    overview: {
      totalTasks: {
        completed: 245,
        created: 312,
        inProgress: 180,
        blocked: 45,
        trend: '+15%'
      },
      productivity: {
        thisWeek: 87,
        lastWeek: 82,
        trend: '+5%'
      },
      timeTracking: {
        totalHours: 840,
        billableHours: 756,
        utilization: 90
      }
    },
    weeklyGanttData: {
      data: [
        { 
          id: 1, 
          text: 'Character Rigging Review', 
          start_date: '2024-03-20', 
          duration: 3, 
          progress: 0.75, 
          assignee: 'John Smith',
          status: 'In Progress'
        },
        { 
          id: 2, 
          text: 'Environment Lighting Setup', 
          start_date: '2024-03-21', 
          duration: 2, 
          progress: 0.9, 
          assignee: 'Sarah Johnson',
          status: 'Review'
        },
        { 
          id: 3, 
          text: 'FX Integration', 
          start_date: '2024-03-22', 
          duration: 4, 
          progress: 0, 
          assignee: 'Mike Wilson',
          status: 'Not Started'
        },
        { 
          id: 4, 
          text: 'Final Render Review', 
          start_date: '2024-03-23', 
          duration: 3, 
          progress: 0.45, 
          assignee: 'Emily Brown',
          status: 'In Progress'
        }
      ],
      links: [
        { id: 1, source: 1, target: 2, type: '0' },
        { id: 2, source: 2, target: 3, type: '0' },
        { id: 3, source: 3, target: 4, type: '0' }
      ]
    },
    weeklyTasks: {
      high_priority: [
        {
          id: 'TSK001',
          name: 'Character Rigging Review',
          assignee: 'John Smith',
          status: 'In Progress',
          dueDate: '2024-03-22',
          progress: 75
        },
        {
          id: 'TSK002',
          name: 'Environment Lighting Setup',
          assignee: 'Sarah Johnson',
          status: 'Review',
          dueDate: '2024-03-23',
          progress: 90
        }
      ],
      upcoming_deadlines: [
        {
          id: 'TSK003',
          name: 'FX Integration',
          assignee: 'Mike Wilson',
          status: 'Not Started',
          dueDate: '2024-03-24',
          progress: 0
        },
        {
          id: 'TSK004',
          name: 'Final Render Review',
          assignee: 'Emily Brown',
          status: 'In Progress',
          dueDate: '2024-03-25',
          progress: 45
        }
      ]
    },
    dailyProgress: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [
        {
          label: 'Tasks Completed',
          data: [42, 38, 45, 40, 35, 25, 20],
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          fill: true
        },
        {
          label: 'Tasks Created',
          data: [48, 45, 52, 48, 43, 39, 37],
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: true
        }
      ]
    },
    departmentPerformance: {
      labels: ['Animation', 'Modeling', 'Rigging', 'FX', 'Lighting', 'Comp'],
      datasets: [
        {
          label: 'Completed Tasks',
          data: [85, 78, 92, 88, 76, 82],
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        },
        {
          label: 'Target',
          data: [80, 80, 80, 80, 80, 80],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ]
    },
    timeDistribution: {
      labels: ['Development', 'Reviews', 'Meetings', 'Planning', 'Support'],
      datasets: [{
        data: [45, 20, 15, 12, 8],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)'
        ]
      }]
    },
    topPerformers: [
      {
        id: 'USR001',
        name: 'John Smith',
        role: 'Senior Animator',
        metrics: {
          tasksCompleted: 28,
          efficiency: 95,
          qualityScore: 92
        },
        highlights: ['Completed 3 critical tasks ahead of schedule', 'Highest quality score in Animation']
      },
      {
        id: 'USR002',
        name: 'Sarah Johnson',
        role: 'Lead Modeler',
        metrics: {
          tasksCompleted: 25,
          efficiency: 93,
          qualityScore: 94
        },
        highlights: ['Optimized workflow reduced review time by 20%', 'Zero revisions needed']
      }
    ],
    keyMilestones: [
      {
        id: 'MS001',
        name: 'Character Animation Complete',
        dueDate: '2024-03-25',
        progress: 85,
        status: 'On Track'
      },
      {
        id: 'MS002',
        name: 'Environment Assets Delivery',
        dueDate: '2024-03-28',
        progress: 70,
        status: 'At Risk'
      }
    ]
  }
};

const ReportManager = ({ activeProject }) => {
  const { isDarkMode } = useDarkMode();
  const [selectedReport, setSelectedReport] = useState('overview');
  const [dateRange, setDateRange] = useState('week');
  const [selectedProject, setSelectedProject] = useState('all');
  const [projectData, setProjectData] = useState({
    totalProjects: 0,
    activeProjects: [],
    sequenceAnalytics: {
      totalSequences: 0,
      statusBreakdown: {},
      topSequences: []
    },
    shotMetrics: {
      totalShots: 0,
      statusDistribution: {},
      departmentProgress: {},
      criticalShots: []
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamMemberPerformance, setTeamMemberPerformance] = useState([]);
  const [teamMemberLoading, setTeamMemberLoading] = useState(false);
  const [teamMemberError, setTeamMemberError] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/api/reports/project-overview`);
        if (!response.ok) {
          throw new Error('Failed to fetch project data');
        }
        const projects = await response.json();
                const transformedData = {
          totalProjects: projects.length,
          activeProjects: projects.map(project => {
            // Handle tasks and their statuses
            const tasks = Array.isArray(project.tasks) ? project.tasks : [];
            const totalTasks = tasks.length;
            const completedTasks = tasks.filter(task => task.status === 'Completed').length;
            const inProgressTasks = tasks.filter(task => task.status === 'In Progress').length;
            const pendingTasks = tasks.filter(task => task.status === 'Pending').length;
            const progress = totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;

            return {
              id: project.id,
              name: project.name,
              progress: progress,
              sequences: Array.isArray(project.sequences) ? project.sequences.map(sequence => ({
                ...sequence,
                shots: Array.isArray(sequence.shots) ? sequence.shots : []
              })) : [],
              shots: Array.isArray(project.shots) ? project.shots.map(shot => ({
                id: shot.id,
                name: shot.shotName,
                status: shot.status || 'Not Started',
                department: shot.department || 'Unassigned'
              })) : [],
              assets: Array.isArray(project.assets) ? project.assets.map(asset => ({
                id: asset.id,
                name: asset.assetName,
                status: asset.assetStatus?.name || 'Not Started',
                relatedTasks: Array.isArray(asset.relatedTasks) ? asset.relatedTasks : []
              })) : [],
              tasks: {
                total: totalTasks,
                completed: completedTasks,
                inProgress: inProgressTasks,
                pending: pendingTasks
              },
              status: project.status || 'In Production'
            };
          })
        };
        
        setProjectData(transformedData);
        if (transformedData.activeProjects.length > 0 && selectedProject === 'all') {
          setSelectedProject(transformedData.activeProjects[0].id.toString());
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching project data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [selectedProject]);



  useEffect(() => {
    const fetchTeamMemberPerformance = async () => {
      setTeamMemberLoading(true);
      setTeamMemberError(null);
      try {
        const response = await fetch(`${API_BASE_URL}/api/reports/team-member-performance`);
        if (!response.ok) {
          throw new Error('Failed to fetch team member performance data');
        }
        const data = await response.json();
        console.log('Team member performance data:', data);
        
        // Transform the data to match the expected format
        const transformedData = data.map(member => ({
          id: member.id,
          name: member.username,
          role: member.role || 'Team Member',
          department: member.department || 'Unassigned',
          metrics: {
            totalTasks: member.metrics?.totalTasks || 0,
            completedTasks: member.metrics?.completedTasks || 0,
            inProgressTasks: member.metrics?.inProgressTasks || 0,
            completionRate: member.metrics?.completionRate || 0
          },
          currentTasks: (member.currentTasks || []).map(task => ({
            id: task.id,
            name: task.name,
            status: task.status,
            deadline: new Date(task.dueDate).toLocaleDateString(),
            progress: task.progress
          })),
          recentActivities: (member.recentActivities || []).map(activity => ({
            id: activity.id,
            type: activity.type,
            description: activity.details,
            time: new Date(activity.timestamp).toLocaleString()
          }))
        }));

        console.log('Transformed data:', transformedData);
        setTeamMemberPerformance(transformedData);
      } catch (error) {
        console.error('Error fetching team member performance:', error);
        setTeamMemberError(error.message);
      } finally {
        setTeamMemberLoading(false);
      }
    };

    fetchTeamMemberPerformance();
  }, []);

  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      },
      x: {
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: isDarkMode ? '#ffffff' : '#333333'
        }
      }
    }
  };

  const renderUserMetrics = () => (
    <div className="user-metrics">
      <h3>Team Member Performance</h3>
      <div className="user-metrics-grid">
        {dummyData.teamMetrics.userMetrics.users.map(user => (
          <div key={user.id} className="user-card">
            <div className="user-header">
              <div className="user-info">
                <h4>{user.name}</h4>
                <span className="user-role">{user.role}</span>
                <span className="user-department">{user.department}</span>
              </div>
              <div className="user-stats">
                <div className="stat-item">
                  <label>Completed</label>
                  <span>{user.metrics.tasksCompleted}</span>
                </div>
                <div className="stat-item">
                  <label>In Progress</label>
                  <span>{user.metrics.tasksInProgress}</span>
                </div>
                <div className="stat-item">
                  <label>Avg Time</label>
                  <span>{user.metrics.avgCompletionTime}</span>
                </div>
                <div className="stat-item">
                  <label>On Time</label>
                  <span>{user.metrics.onTimeDelivery}</span>
                </div>
              </div>
            </div>
            
            <div className="user-current-work">
              <h5>Current Assignments</h5>
              <div className="assignments-list">
                {user.currentAssignments.map(assignment => (
                  <div key={assignment.id} className="assignment-item">
                    <span className="assignment-name">{assignment.name}</span>
                    <div className="assignment-details">
                      <span className={`status ${assignment.status.toLowerCase().replace(' ', '-')}`}>
                        {assignment.status}
                      </span>
                      <span className="deadline">Due: {assignment.deadline}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="user-activity">
              <h5>Recent Activity</h5>
              <div className="activity-timeline">
                {user.recentActivity && user.recentActivity.map((activity, index) => (
                  <div key={activity.id || index} className="activity-item">
                    <span className={`activity-type ${activity.type?.toLowerCase()}`}>
                      {activity.type}
                    </span>
                    <span className="activity-details">{activity.details}</span>
                    <span className="activity-time">{activity.timestamp}</span>
                  </div>
                ))}
                {(!user.recentActivity || user.recentActivity.length === 0) && (
                  <div className="no-activity">No recent activity</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );



  const TeamPerformanceSection = () => {
    const [filters, setFilters] = useState({
      taskCompletion: 0,
      role: 'all',
      department: 'all'
    });

    const roles = [
      'All Roles',
      'Artist',
      'Lead Artist',
      'Supervisor',
      'Producer',
      'Coordinator'
    ];

    const departments = [
      'All Departments',
      'Animation',
      'Compositing',
      'Lighting',
      'FX',
      'Pipeline',
      'Rigging'
    ];

    const handleFilterChange = (filterType, value) => {
      setFilters(prev => ({
        ...prev,
        [filterType]: value
      }));
    };

    return (
      <div>
        <div className="team-performance-filters">
          {/* Task Completion Filter */}
          <div className="filter-group">
            <label>Task Completion</label>
            <div className="completion-range">
              <input
                type="range"
                min="0"
                max="100"
                value={filters.taskCompletion}
                onChange={(e) => handleFilterChange('taskCompletion', parseInt(e.target.value))}
              />
              <div className="completion-value">{filters.taskCompletion}% or higher</div>
            </div>
          </div>

          {/* Role Filter */}
          <div className="filter-group">
            <label>Role</label>
            <select
              value={filters.role}
              onChange={(e) => handleFilterChange('role', e.target.value)}
            >
              {roles.map(role => (
                <option key={role.toLowerCase()} value={role.toLowerCase()}>
                  {role}
                </option>
              ))}
            </select>
          </div>

          {/* Department Filter */}
          <div className="filter-group">
            <label>Department</label>
            <select
              value={filters.department}
              onChange={(e) => handleFilterChange('department', e.target.value)}
            >
              {departments.map(dept => (
                <option key={dept.toLowerCase()} value={dept.toLowerCase()}>
                  {dept}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={`team-performance-grid ${isDarkMode ? 'darkmode' : ''}`}>
          {/* Team member cards will be filtered and rendered here */}
          {teamMemberPerformance
            .filter(member => {
              return (
                (filters.taskCompletion === 0 || member.metrics.completedTasks >= filters.taskCompletion) &&
                (filters.role === 'all' || member.role.toLowerCase() === filters.role) &&
                (filters.department === 'all' || member.department.toLowerCase() === filters.department)
              );
            })
            .map(member => (
              <div key={member.id} className={`user-card ${isDarkMode ? 'darkmode' : ''}`}>
                {/* Existing card content */}
                <div className={`member-header ${isDarkMode ? 'darkmode' : ''}`}>
                  <h4>{member.name}</h4>
                  <div>
                    <span className={`role ${isDarkMode ? 'darkmode' : ''}`}>{member.role}</span>
                    <span className={`role ${isDarkMode ? 'darkmode' : ''}`}>{member.department}</span>
                  </div>
                </div>

                <div className="performance-metrics">
                  <div className="metric">
                    <label>Tasks Completed</label>
                    <span>{member.metrics.completedTasks}</span>
                  </div>
                  <div className="metric">
                    <label>In Progress</label>
                    <span>{member.metrics.inProgressTasks}</span>
                  </div>
                  <div className="metric">
                    <label>Efficiency</label>
                    <span>{member.metrics.completionRate}%</span>
                  </div>
                </div>

                <div className="current-tasks">
                  <h5>Current Tasks</h5>
                  <ul>
                    {member.currentTasks.map((task) => (
                      <li key={task.id}>
                        <span className="task-name">{task.name}</span>
                        <span className={`task-status ${task.status.toLowerCase()}`}>
                          {task.status}
                        </span>
                        <span className="task-deadline">{task.deadline}</span>
                        <span className="task-progress">{task.progress}%</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="recent-activity">
                  <h5>Recent Activity</h5>
                  <ul>
                    {member.recentActivities.map((activity) => (
                      <li key={activity.id}>
                        <span className="activity-type">{activity.type}</span>
                        <span className="activity-item">{activity.description}</span>
                        <span className="activity-time">{activity.time}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };



  const renderHashtagAnalytics = () => (
    <div className="report-section">
      <h3>Hashtag Analytics</h3>
      <div className="hashtag-overview">
        <div className="hashtag-analytics-grid">
          <div className="trending-hashtags">
            <h4>Trending Hashtags</h4>
            <div className="hashtag-list">
              {Object.entries(dummyData.hashtagAnalytics.trendingHashtags).map(([tag, data]) => (
                <div key={tag} className={`hashtag-item ${data.trend}`}>
                  <span className="tag">{tag}</span>
                  <span className="count">{data.count}</span>
                  <span className={`trend ${data.trend}`}>{data.change}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="department-hashtags">
            <h4>Hashtags by Department</h4>
            {Object.entries(dummyData.hashtagAnalytics.hashtagsByDepartment).map(([dept, tags]) => (
              <div key={dept} className="department-hashtags-item">
                <h5>{dept}</h5>
                <div className="tag-list">
                  {Object.entries(tags).map(([tag, count]) => (
                    <div key={tag} className="tag-item">
                      <span>{tag}</span>
                      <span>{count}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="hashtag-timeline">
            <h4>Recent Hashtag Activity</h4>
            <div className="timeline-list">
              {dummyData.hashtagAnalytics.hashtagTimeline.map((day, index) => (
                <div key={index} className="timeline-item">
                  <span className="date">{day.date}</span>
                  <div className="hashtags">
                    {day.hashtags.map(tag => (
                      <span key={tag} className="tag">{tag}</span>
                    ))}
                  </div>
                  <span className="count">{day.count} uses</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="hashtag-categories">
          <div className="category-section">
            <h4>Shots Hashtags</h4>
            <div className="hashtag-list">
              {Object.entries(dummyData.hashtagAnalytics.hashtagsByShots).map(([tag, data]) => (
                <div key={tag} className="hashtag-item">
                  <div className="hashtag-info">
                    <span className="hashtag-name">{tag}</span>
                    <span className="hashtag-count">{data.count} mentions</span>
                  </div>
                  <div className="hashtag-trend">
                    <span className={`trend-indicator ${data.trend}`}>
                      {data.trend === 'up' ? '↑' : data.trend === 'down' ? '↓' : '→'}
                    </span>
                  </div>
                  {data.shots && renderDetailedList(data.shots, 'shots')}
                </div>
              ))}
            </div>
          </div>

          <div className="category-section">
            <h4>Sequences Hashtags</h4>
            <div className="hashtag-list">
              {Object.entries(dummyData.hashtagAnalytics.hashtagsBySequences).map(([tag, data]) => (
                <div key={tag} className="hashtag-item">
                  <div className="hashtag-info">
                    <span className="hashtag-name">{tag}</span>
                    <span className="hashtag-count">{data.count} mentions</span>
                  </div>
                  <div className="hashtag-trend">
                    <span className={`trend-indicator ${data.trend}`}>
                      {data.trend === 'up' ? '↑' : data.trend === 'down' ? '↓' : '→'}
                    </span>
                  </div>
                  {data.sequences && renderDetailedList(data.sequences, 'sequences')}
                </div>
              ))}
            </div>
          </div>

          <div className="category-section">
            <h4>Assets Hashtags</h4>
            <div className="hashtag-list">
              {Object.entries(dummyData.hashtagAnalytics.hashtagsByAssets).map(([tag, data]) => (
                <div key={tag} className="hashtag-item">
                  <div className="hashtag-info">
                    <span className="hashtag-name">{tag}</span>
                    <span className="hashtag-count">{data.count} mentions</span>
                  </div>
                  <div className="hashtag-trend">
                    <span className={`trend-indicator ${data.trend}`}>
                      {data.trend === 'up' ? '↑' : data.trend === 'down' ? '↓' : '→'}
                    </span>
                  </div>
                  {data.assets && renderDetailedList(data.assets, 'assets')}
                </div>
              ))}
            </div>
          </div>

          <div className="category-section">
            <h4>Tasks Hashtags</h4>
            <div className="hashtag-list">
              {Object.entries(dummyData.hashtagAnalytics.hashtagsByTasks).map(([tag, data]) => (
                <div key={tag} className="hashtag-item">
                  <div className="hashtag-info">
                    <span className="hashtag-name">{tag}</span>
                    <span className="hashtag-count">{data.count} mentions</span>
                  </div>
                  <div className="hashtag-trend">
                    <span className={`trend-indicator ${data.trend}`}>
                      {data.trend === 'up' ? '↑' : data.trend === 'down' ? '↓' : '→'}
                    </span>
                  </div>
                  {data.tasks && renderDetailedList(data.tasks, 'tasks')}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderTimeline = () => (
    <div className="report-section">
      <h3>Project Timeline</h3>
      <div className="timeline-container">
        <div className="milestones">
          <h4>Project Milestones</h4>
          {dummyData.timeline.projectMilestones.map((milestone, index) => (
            <div key={index} className={`milestone-item ${milestone.status.toLowerCase()}`}>
              <span>{milestone.name}</span>
              <span>{milestone.date}</span>
              <span className="status">{milestone.status}</span>
            </div>
          ))}
        </div>
        <div className="deadlines">
          <h4>Upcoming Deadlines</h4>
          {dummyData.timeline.upcomingDeadlines.map((deadline, index) => (
            <div key={index} className="deadline-item">
              <span>{deadline.task}</span>
              <span>{deadline.date}</span>
              <span>{deadline.assignee}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderDetailedList = (items, type) => {
    const getStatusClass = (status) => {
      const statusMap = {
        'In Progress': 'status-progress',
        'Review': 'status-review',
        'Final': 'status-final',
        'Queue': 'status-queue',
        'Rendering': 'status-rendering',
        'Final Review': 'status-final-review',
        'Pending': 'status-pending'
      };
      return statusMap[status] || 'status-default';
    };

    const getPriorityClass = (priority) => {
      const priorityMap = {
        'High': 'priority-high',
        'Medium': 'priority-medium',
        'Low': 'priority-low'
      };
      return priorityMap[priority] || 'priority-default';
    };

    switch(type) {
      case 'shots':
        return (
          <div className="detailed-list">
            {items.map(shot => (
              <div key={shot.id} className="detail-item">
                <div className="detail-header">
                  <span className="detail-id">{shot.id}</span>
                  <span className={`detail-status ${getStatusClass(shot.status)}`}>{shot.status}</span>
                </div>
                <div className="detail-content">
                  <h5>{shot.name}</h5>
                  <div className="detail-info">
                    <span>Sequence: {shot.sequence}</span>
                    <span>Department: {shot.department}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case 'sequences':
        return (
          <div className="detailed-list">
            {items.map(seq => (
              <div key={seq.id} className="detail-item">
                <div className="detail-header">
                  <span className="detail-id">{seq.id}</span>
                  <span className={`detail-status ${getStatusClass(seq.status)}`}>{seq.status}</span>
                </div>
                <div className="detail-content">
                  <h5>{seq.name}</h5>
                  <div className="detail-info">
                    <span>Shots: {seq.shots}</span>
                    <span>Supervisor: {seq.supervisor}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case 'assets':
        return (
          <div className="detailed-list">
            {items.map(asset => (
              <div key={asset.id} className="detail-item">
                <div className="detail-header">
                  <span className="detail-id">{asset.id}</span>
                  <span className={`detail-status ${getStatusClass(asset.status)}`}>{asset.status}</span>
                </div>
                <div className="detail-content">
                  <h5>{asset.name}</h5>
                  <div className="detail-info">
                    <span>Type: {asset.type}</span>
                    <span>Artist: {asset.artist}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case 'tasks':
        return (
          <div className="detailed-list">
            {items.map(task => (
              <div key={task.id} className="detail-item">
                <div className="detail-header">
                  <span className="detail-id">{task.id}</span>
                  <span className={`detail-priority ${getPriorityClass(task.priority)}`}>{task.priority}</span>
                </div>
                <div className="detail-content">
                  <h5>{task.name}</h5>
                  <div className="detail-info">
                    <span>Assignee: {task.assignee}</span>
                    <span>Deadline: {task.deadline}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      'Completed': 'var(--success-color)',
      'In Progress': 'var(--warning-color)',
      'Not Started': 'var(--danger-color)',
      'Review': 'var(--info-color)',
      'Blocked': 'var(--danger-color)'
    };
    return colors[status] || 'var(--text-secondary)';
  };

  const calculateProgress = (stages) => {
    const totalStages = stages.length;
    const completedStages = stages.filter(stage => stage.status === 'Completed').length;
    const inProgressStages = stages.filter(stage => stage.status === 'In Progress')
      .reduce((acc, stage) => acc + (stage.progress / 100), 0);
    return Math.round(((completedStages + inProgressStages) / totalStages) * 100);
  };

  const renderAssetTracking = () => {
    if (!projectData?.activeProjects?.length) {
      return (
        <div className="report-section">
          <h3>Asset Tracking</h3>
          <p>No assets found for the selected project.</p>
        </div>
      );
    }

    const selectedProjectData = projectData.activeProjects.find(p => p.id.toString() === selectedProject);
    const selectedProjectAssets = selectedProjectData?.assets || [];

    if (!Array.isArray(selectedProjectAssets) || selectedProjectAssets.length === 0) {
      return (
        <div className="report-section">
          <h3>Asset Tracking</h3>
          <p>No assets available for this project.</p>
        </div>
      );
    }

    // Group assets by category (using first word of assetName as category)
    const assetsByCategory = selectedProjectAssets.reduce((acc, asset) => {
      const category = asset.assetName?.split(' ')?.[0] || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(asset);
      return acc;
    }, {});

    // Calculate department workload based on related tasks
    const departmentWorkload = selectedProjectAssets.reduce((acc, asset) => {
      const tasks = asset.relatedTasks || [];
      tasks.forEach(task => {
        const dept = task.department || 'Unassigned';
        if (!acc[dept]) {
          acc[dept] = { assigned: 0, capacity: 10 }; // Default capacity of 10 per department
        }
        acc[dept].assigned++;
      });
      return acc;
    }, {});

    return (
      <div className="metrics-section asset-tracking">
        <div className="section-header">
          <h3>Asset Tracking</h3>
          <div className="header-controls">
            <select className="category-filter">
              <option value="all">All Categories</option>
              {Object.keys(assetsByCategory).map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <select className="status-filter">
              <option value="all">All Statuses</option>
              <option value="in-progress">In Progress</option>
              <option value="review">In Review</option>
              <option value="completed">Completed</option>
              <option value="blocked">Blocked</option>
            </select>
          </div>
        </div>

        <div className="asset-overview">
          <div className="chart-container">
            <h4>Assets by Category</h4>
            <Doughnut
              data={{
                labels: Object.keys(assetsByCategory),
                datasets: [{
                  data: Object.values(assetsByCategory).map(assets => assets.length),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)'
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)'
                  ],
                  borderWidth: 1
                }]
              }}
              options={{
                ...chartOptions,
                cutout: '60%'
              }}
            />
          </div>

          <div className="department-workload">
            <h4>Department Workload</h4>
            {Object.entries(departmentWorkload).map(([dept, data]) => (
              <div key={dept} className="workload-item">
                <div className="workload-header">
                  <span className="dept-name">{dept}</span>
                  <span className="workload-ratio">
                    {data.assigned}/{data.capacity}
                  </span>
                </div>
                <div className="workload-bar">
                  <div 
                    className="workload-fill"
                    style={{ 
                      width: `${(data.assigned / data.capacity) * 100}%`,
                      backgroundColor: data.assigned > data.capacity ? 'var(--danger-color)' : 'var(--primary-color)'
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="asset-details">
          <h4>Asset Details</h4>
          <div className="asset-cards">
            {selectedProjectAssets.map(asset => {
              const lastActivity = asset.activities && asset.activities[0];
              const lastComment = lastActivity?.replies?.[0]?.comment || lastActivity?.comment;
              const status = asset.assetStatus?.name || 'Pending';
              const progress = asset.relatedTasks?.reduce((acc, task) => acc + (task.progress || 0), 0) / 
                             (asset.relatedTasks?.length || 1);

              return (
                <div key={asset.id} className="asset-card">
                  <div className="asset-header">
                    <div className="asset-title">
                      <h5>{asset.assetName}</h5>
                      <span className={`priority ${getPriorityFromProgress(progress).toLowerCase()}`}>
                        {getPriorityFromProgress(progress)}
                      </span>
                    </div>
                    <div className="asset-meta">
                      <span className="asset-id">#{asset.id}</span>
                      <span className={`status ${status.toLowerCase().replace(' ', '-')}`}>
                        {status}
                      </span>
                    </div>
                  </div>

                  <div className="asset-progress">
                    <div className="progress-header">
                      <span>Overall Progress</span>
                      <span>{Math.round(progress)}%</span>
                    </div>
                    <div className="progress-bar">
                      <div 
                        className="progress-fill"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>

                  <div className="asset-stages">
                    {asset.relatedTasks?.map((task, index) => (
                      <div key={index} className="stage-item">
                        <span className="stage-name">{task.taskName}</span>
                        <div className="stage-status">
                          <span className="status-indicator" style={{ backgroundColor: getStatusColor(task.status) }}></span>
                          <span className="progress-percent">{task.progress || 0}%</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  {asset.activities?.length > 0 && (
                    <div className="asset-activities">
                      <h6>Recent Activities</h6>
                      <div className="activity-list">
                        {asset.activities.map((activity, index) => (
                          <div key={index} className={`activity-item ${activity.type || 'comment'}`}>
                            <span className="activity-icon"></span>
                            <div className="activity-content">
                              <span className="activity-description">{activity.comment}</span>
                              <span className="activity-date">
                                {new Date(activity.createdAt).toLocaleDateString()}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {lastComment && (
                    <div className="asset-notes">
                      <h6>Latest Note</h6>
                      <p>{lastComment}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getPriorityFromProgress = (progress) => {
    if (progress < 30) return 'High';
    if (progress < 70) return 'Medium';
    return 'Low';
  };

  const renderWeeklyAnalytics = () => {
    const getTrendClass = (trend) => {
      if (trend.startsWith('+')) return 'trend-up';
      if (trend.startsWith('-')) return 'trend-down';
      return '';
    };

    const transformWeeklyTasksToGantt = (weeklyData) => {
      if (!weeklyData) return { data: [], links: [] };

      // Get tasks from weeklyGanttData
      const ganttTasks = weeklyData.weeklyGanttData?.data || [];

      // Get tasks from hashtags
      const hashtagTasks = Object.values(weeklyData.hashtagsByTasks || {})
        .flatMap(category => 
          category.tasks.map(task => ({
            id: task.id,
            text: task.name,
            start_date: task.deadline,
            duration: 1,
            progress: 0.1,
            assignee: task.assignee,
            priority: task.priority,
            status: 'In Progress'
          }))
        );

      return {
        data: [...ganttTasks, ...hashtagTasks],
        links: []
      };
    };

    return (
      <div className="metrics-section weekly-analytics">
        <div className="section-header">

        </div>

        {/* Overview Cards */}
        <div className="analytics-overview">
          <div className="overview-card">
            <h4>Task Summary</h4>
            <div className="metrics-grid">
              <div className="metric-item">
                <span className="label">Completed</span>
                <span className="value">{dummyData.weeklyAnalytics.overview.totalTasks.completed}</span>
              </div>
              <div className="metric-item">
                <span className="label">Created</span>
                <span className="value">{dummyData.weeklyAnalytics.overview.totalTasks.created}</span>
              </div>
              <div className="metric-item">
                <span className="label">In Progress</span>
                <span className="value">{dummyData.weeklyAnalytics.overview.totalTasks.inProgress}</span>
              </div>
              <div className="metric-item">
                <span className="label">Blocked</span>
                <span className="value">{dummyData.weeklyAnalytics.overview.totalTasks.blocked}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Weekly Tasks */}
        <div className="weekly-tasks">
          <h4>Weekly Tasks</h4>
          <div className="tasks-grid">
            <div className="task-section">
              <h5>High Priority Tasks</h5>
              <div className="task-list">
                {dummyData.weeklyAnalytics.weeklyTasks.high_priority.map(task => (
                  <div key={task.id} className="task-item">
                    <span className="task-name">{task.name}</span>
                    <span className={`task-status ${task.status.toLowerCase()}`}>
                      {task.status}
                    </span>
                    <span className="task-due">Due: {task.dueDate}</span>
                    <span className="task-progress">{task.progress}%</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="task-section">
              <h5>Upcoming Deadlines</h5>
              <div className="task-list">
                {dummyData.weeklyAnalytics.weeklyTasks.upcoming_deadlines.map(task => (
                  <div key={task.id} className="task-item">
                    <span className="task-name">{task.name}</span>
                    <span className={`task-status ${task.status.toLowerCase()}`}>
                      {task.status}
                    </span>
                    <span className="task-due">Due: {task.dueDate}</span>
                    <span className="task-progress">{task.progress}%</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="analytics-charts">
          <div className="chart-container">
            <h4>Daily Progress</h4>
            <Line 
              data={dummyData.weeklyAnalytics.dailyProgress}
              options={{
                ...chartOptions,
                scales: {
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Number of Tasks'
                    }
                  }
                }
              }}
            />
          </div>

          <div className="chart-container">
            <h4>Department Performance vs Target</h4>
            <Bar 
              data={dummyData.weeklyAnalytics.departmentPerformance}
              options={{
                ...chartOptions,
                scales: {
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Completion Rate (%)'
                    }
                  }
                }
              }}
            />
          </div>

          <div className="chart-container">
            <h4>Time Distribution</h4>
            <Doughnut 
              data={dummyData.weeklyAnalytics.timeDistribution}
              options={{
                ...chartOptions,
                cutout: '60%'
              }}
            />
          </div>
        </div>

        {/* Top Performers */}
        <div className="top-performers">
          <h4>Top Performers</h4>
          <div className="performers-grid">
            {dummyData.weeklyAnalytics.topPerformers.map(performer => (
              <div key={performer.id} className="performer-card">
                <div className="performer-header">
                  <div className="performer-info">
                    <h5>{performer.name}</h5>
                    <span className="role">{performer.role}</span>
                  </div>
                  <div className="performer-metrics">
                    <div className="metric">
                      <span className="label">Tasks</span>
                      <span className="value">{performer.metrics.tasksCompleted}</span>
                    </div>
                    <div className="metric">
                      <span className="label">Efficiency</span>
                      <span className="value">{performer.metrics.efficiency}%</span>
                    </div>
                    <div className="metric">
                      <span className="label">Quality</span>
                      <span className="value">{performer.metrics.qualityScore}%</span>
                    </div>
                  </div>
                </div>
                <div className="performer-highlights">
                  {performer.highlights.map((highlight, index) => (
                    <div key={index} className="highlight-item">
                      <span className="highlight-icon">✓</span>
                      {highlight}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Key Milestones */}
        <div className="key-milestones">
          <h4>Key Milestones</h4>
          <div className="milestones-grid">
            {dummyData.weeklyAnalytics.keyMilestones.map(milestone => (
              <div key={milestone.id} className="milestone-card">
                <div className="milestone-header">
                  <h5>{milestone.name}</h5>
                  <span className={`status ${milestone.status.toLowerCase().replace(' ', '-')}`}>
                    {milestone.status}
                  </span>
                </div>
                <div className="milestone-progress">
                  <div className="progress-bar">
                    <div 
                      className="progress-fill"
                      style={{ width: `${milestone.progress}%` }}
                    ></div>
                  </div>
                  <span className="progress-label">{milestone.progress}%</span>
                </div>
                <div className="milestone-due">
                  Due: {milestone.dueDate}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };


  const renderProjectOverview = () => {
    const calculateProjectStats = (project) => {
      // Tasks stats are now already calculated in project.tasks
      const tasks = project.tasks || { total: 0, completed: 0, inProgress: 0, pending: 0 };
      
      // Handle shots stats
      const shots = Array.isArray(project.shots) ? project.shots : [];
      const totalShots = shots.length;
      const completedShots = shots.filter(shot => shot.status === 'Completed').length;
      const inProgressShots = shots.filter(shot => shot.status === 'In Progress').length;
      
      // Handle assets stats
      const assets = Array.isArray(project.assets) ? project.assets : [];
      const totalAssets = assets.length;
      const completedAssets = assets.filter(asset => asset.status === 'Completed').length;
      const inProgressAssets = assets.filter(asset => asset.status === 'In Progress').length;
      
      return {
        tasks,
        shots: { total: totalShots, completed: completedShots, inProgress: inProgressShots },
        assets: { total: totalAssets, completed: completedAssets, inProgress: inProgressAssets },
      };
    };

    return (
      <div className="metrics-section project-overview">
        <h3>Project Overview</h3>
        {loading ? (
          <div className="loading-state">
            <div className="loading-spinner"></div>
            <p>Loading project data...</p>
          </div>
        ) : error ? (
          <div className="error-state">
            <p>{error}</p>
          </div>
        ) : (
          <div className="projects-grid">
            {projectData.activeProjects.map(project => {
              const stats = calculateProjectStats(project);
              return (
                <div key={project.id} className="project-card">
                  <div className="project-header">
                    <h4>{project.name}</h4>
                    <span className={`status ${project.status.toLowerCase().replace(' ', '-')}`}>
                      {project.status}
                    </span>
                  </div>
                  
                  <div className="project-progress">
                    <div className="progress-bar">
                      <div 
                        className="progress-fill"
                        style={{ width: `${project.progress}%` }}
                      ></div>
                    </div>
                    <span className="progress-label">{project.progress}% Complete</span>
                  </div>

                  <div className="project-stats">
                    <div className="stat-group">
                      <h5>Tasks</h5>
                      <div className="stat-details">
                        <div className="stat-item">
                          <label>Total</label>
                          <span>{stats.tasks.total}</span>
                        </div>
                        <div className="stat-item">
                          <label>Completed</label>
                          <span className="completed">{stats.tasks.completed}</span>
                        </div>
                        <div className="stat-item">
                          <label>In Progress</label>
                          <span className="in-progress">{stats.tasks.inProgress}</span>
                        </div>
                        <div className="stat-item">
                          <label>Pending</label>
                          <span className="pending">{stats.tasks.pending}</span>
                        </div>
                      </div>
                    </div>

                    <div className="stat-group">
                      <h5>Shots</h5>
                      <div className="stat-details">
                        <div className="stat-item">
                          <label>Total</label>
                          <span>{stats.shots.total}</span>
                        </div>
                        <div className="stat-item">
                          <label>Completed</label>
                          <span className="completed">{stats.shots.completed}</span>
                        </div>
                        <div className="stat-item">
                          <label>In Progress</label>
                          <span className="in-progress">{stats.shots.inProgress}</span>
                        </div>
                      </div>
                    </div>

                    <div className="stat-group">
                      <h5>Assets</h5>
                      <div className="stat-details">
                        <div className="stat-item">
                          <label>Total</label>
                          <span>{stats.assets.total}</span>
                        </div>
                        <div className="stat-item">
                          <label>Completed</label>
                          <span className="completed">{stats.assets.completed}</span>
                        </div>
                        <div className="stat-item">
                          <label>In Progress</label>
                          <span className="in-progress">{stats.assets.inProgress}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="project-sequences">
                    <h5>Sequences ({project.sequences?.length || 0})</h5>
                    <div className="sequences-list">
                      {project.sequences?.slice(0, 3).map(sequence => (
                        <div key={sequence.id} className="sequence-item">
                          <span className="sequence-name">{sequence.name}</span>
                          <span className="sequence-shots">{sequence.shots?.length || 0} shots</span>
                        </div>
                      ))}
                      {(project.sequences?.length || 0) > 3 && (
                        <div className="more-sequences">
                          +{project.sequences.length - 3} more sequences
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };



  const renderTeamMemberPerformance = () => {
    if (teamMemberLoading) {
      return <div className="loading">Loading team member performance data...</div>;
    }

    if (teamMemberError) {
      return <div className="error">{teamMemberError}</div>;
    }

    return (
      <div className="team-performance-grid">
        {teamMemberPerformance.map((member) => (
          <div key={member.id} className="user-card">
            <div className="user-card-header">
              <h4>{member.name}</h4>
              <div>
                <span className="role">{member.role}</span>
                <span className="role">{member.department}</span>
              </div>
            </div>

            <div className="user-card-body">
              <div className="metric">
                <label>Tasks Completed</label>
                <span>{member.metrics.completedTasks}</span>
              </div>
              <div className="metric">
                <label>In Progress</label>
                <span>{member.metrics.inProgressTasks}</span>
              </div>
              <div className="metric">
                <label>Efficiency</label>
                <span>{member.metrics.completionRate}%</span>
              </div>
            </div>

            <div className="current-tasks">
              <h5>Current Tasks</h5>
              <ul>
                {member.currentTasks.map((task) => (
                  <li key={task.id}>
                    <span className="task-name">{task.name}</span>
                    <span className={`task-status ${task.status.toLowerCase()}`}>
                      {task.status}
                    </span>
                    <span className="task-deadline">{task.deadline}</span>
                    <span className="task-progress">{task.progress}%</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="recent-activity">
              <h5>Recent Activity</h5>
              <ul>
                {member.recentActivities.map((activity) => (
                  <li key={activity.id}>
                    <span className="activity-type">{activity.type}</span>
                    <span className="activity-item">{activity.description}</span>
                    <span className="activity-time">{activity.time}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getActivityIcon = (type) => {
    switch(type) {
      case 'task_completed':
        return '✓';
      case 'feedback_given':
        return '💬';
      case 'task_started':
        return '🕒';
      default:
        return '';
    }
  };

  const formatActivityType = (type) => {
    switch(type) {
      case 'task_completed':
        return 'Task Completed';
      case 'feedback_given':
        return 'Feedback Given';
      case 'task_started':
        return 'Task Started';
      default:
        return type;
    }
  };

  const renderContent = () => {
    if (selectedReport === 'hashtags') {
      return <HashtagReport isDarkMode={isDarkMode} />;
    }
    
    // Existing report content
    return (
      <div className="report-content">
        {selectedReport === 'overview' && renderProjectOverview()}
        {selectedReport === 'sequence-shots' && (
          <SequenceShotReport 
            isDarkMode={isDarkMode} 
            selectedProject={selectedProject} 
          />
        )}
        {selectedReport === 'assets' && renderAssetTracking()}
        {selectedReport === 'weekly' && (
          <WeeklyReport 
            isDarkMode={isDarkMode} 
            selectedProject={selectedProject} 
          />
        )}
      </div>
    );
  };

  return (
    <div className="report-manager-container">
      <div className={`report-manager ${isDarkMode ? 'darkmode' : ''}`}>
        <div className={`report-header ${isDarkMode ? 'darkmode' : ''}`}>
          <div className="report-controls">
            <select 
              value={selectedProject} 
              onChange={(e) => handleProjectChange(e.target.value)}
              className={isDarkMode ? 'darkmode' : ''}
            >
              <option value="all">All Projects</option>
              {projectData.activeProjects.map(project => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
            
            <div className="report-tabs">
              <button 
                className={selectedReport === 'overview' ? 'active' : ''} 
                onClick={() => setSelectedReport('overview')}
              >
                Overview
              </button>
              <button 
                className={selectedReport === 'sequence-shots' ? 'active' : ''} 
                onClick={() => setSelectedReport('sequence-shots')}
              >
                Sequence & Shots
              </button>
              <button 
                className={selectedReport === 'assets' ? 'active' : ''} 
                onClick={() => setSelectedReport('assets')}
              >
                Asset Tracking
              </button>
              <button 
                className={selectedReport === 'tasks' ? 'active' : ''} 
                onClick={() => setSelectedReport('tasks')}
              >
                Task Tracking
              </button>

              <button 
                className={selectedReport === 'hashtags' ? 'active' : ''} 
                onClick={() => setSelectedReport('hashtags')}
              >
                Hashtag Report
              </button>
              <button 
                className={selectedReport === 'timeline' ? 'active' : ''} 
                onClick={() => setSelectedReport('timeline')}
              >
                Timeline
              </button>
              <button 
                className={selectedReport === 'weekly' ? 'active' : ''} 
                onClick={() => setSelectedReport('weekly')}
              >
                Weekly
              </button>
              <button 
                className={selectedReport === 'team-member-performance' ? 'active' : ''} 
                onClick={() => setSelectedReport('team-member-performance')}
              >
                Team Member Performance
              </button>
            </div>
          </div>
        </div>

        <div className={`report-content ${isDarkMode ? 'darkmode' : ''}`}>
          {loading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
            </div>
          ) : error ? (
            <div className="error-state">
              <p>{error}</p>
            </div>
          ) : (
            <>
              {selectedReport === 'overview' && renderProjectOverview()}
              {selectedReport === 'sequence-shots' && (
                <SequenceShotReport 
                  isDarkMode={isDarkMode} 
                  selectedProject={selectedProject} 
                />
              )}
              {selectedReport === 'assets' && (
                <AssetTrackingReport 
                  isDarkMode={isDarkMode} 
                  selectedProject={selectedProject} 
                />
              )}
              {selectedReport === 'tasks' && (
                <TaskTrackingReport 
                  isDarkMode={isDarkMode} 
                  selectedProject={selectedProject} 
                />
              )}

              {selectedReport === 'hashtags' && <HashtagReport isDarkMode={isDarkMode} />}
              {selectedReport === 'timeline' && renderTimeline()}
              {selectedReport === 'weekly' && (
                <WeeklyReport 
                  isDarkMode={isDarkMode} 
                  selectedProject={selectedProject} 
                />
              )}
              {selectedReport === 'team-member-performance' && <TeamPerformanceSection />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportManager;