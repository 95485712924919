import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import VideoModal from './VideoModal';
import IssueForm from './IssueForm';
import { API_BASE_URL } from '../config/config';
import '../styles/globaloverview.css';

const SequenceOverview = ({ sequences, activeProject, currentUser, userRole }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentSequence, setCurrentSequence] = useState(null);
  const [activeTab, setActiveTab] = useState('Sequence Info');
  const [activities, setActivities] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [isIssueFormVisible, setIsIssueFormVisible] = useState(false);

  const filteredSequences = sequences.filter(sequence => sequence.projectId === activeProject?.id);

  useEffect(() => {
    if (sequences && id) {
      const sequence = sequences.find(s => s.id.toString() === id);
      if (sequence) {
        setCurrentSequence(sequence);
        // Fetch activities saat sequence ditemukan
        fetchActivities(sequence.id);
      }
    }
  }, [sequences, id]);

  const fetchActivities = async (sequenceId) => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/activities/sequences/${sequenceId}`);
      // Urutkan activities berdasarkan timestamp terbaru
      const sortedActivities = response.data.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      setActivities(sortedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handlePrevious = () => {
    const currentIndex = filteredSequences.findIndex(s => s.id.toString() === id);
    if (currentIndex > 0) {
      navigate(`/sequence-overview/${filteredSequences[currentIndex - 1].id}`);
    }
  };

  const handleNext = () => {
    const currentIndex = filteredSequences.findIndex(s => s.id.toString() === id);
    if (currentIndex < filteredSequences.length - 1) {
      navigate(`/sequence-overview/${filteredSequences[currentIndex + 1].id}`);
    }
  };

  const handleAddComment = async (sequenceId, comment) => {
    try {
      const response = await axiosInstance.post('${API_BASE_URL}/api/activities', {
        sequenceId,
        action: 'add_comment',
        details: comment.text,
        user: currentUser,
        timestamp: new Date().toISOString()
      });
      
      if (response.data) {
        fetchActivities(sequenceId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleEditComment = async (sequenceId, commentId, updatedComment) => {
    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}`, {
        details: updatedComment.text
      });
      
      if (response.data) {
        fetchActivities(sequenceId);
      }
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (sequenceId, commentId) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/activities/${commentId}`);
      fetchActivities(sequenceId);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handlePinComment = async (sequenceId, commentId) => {
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/activities/${commentId}/pin`);
      fetchActivities(sequenceId);
    } catch (error) {
      console.error('Error pinning comment:', error);
    }
  };

  const handleApproveVideo = async (activityId, version) => {
    try {
      const response = await axiosInstance.post(`${API_BASE_URL}/api/activities/${activityId}/approve`, {
        sequenceId: currentSequence.id,
        user: "Admin",
        version: version,
        timestamp: new Date().toISOString()
      });

      if (response.data) {
        await fetchActivities(currentSequence.id);
        setApprovalStatus(prev => ({
          ...prev,
          [activityId]: response.data.isApproved
        }));
      }
    } catch (error) {
      console.error('Error saat mengubah status approval video:', error);
      alert('Gagal mengubah status approval video');
    }
  };

  const handleIssueCreated = async (issue, activity) => {
    // Refresh activities after issue is created
    await fetchActivities(currentSequence.id);
    setIsIssueFormVisible(false);
  };

  if (!currentSequence) {
    return (
      <div className="sequence-not-found">
        <h2>Sequence tidak ditemukan</h2>
        <button onClick={() => navigate('/sequences')}>Kembali ke Daftar Sequence</button>
      </div>
    );
  }

  const tabs = ['Sequence Info', 'Activity', 'Notes', 'Published', 'Client Approved'];

  return (
    <div className="sequence-overview">
      <div className="sequence-header">
        <h2>{currentSequence.sequenceName}</h2>
        <div className="header-actions">
          <button onClick={() => setIsIssueFormVisible(true)} className="report-issue-btn">
            Report Issue
          </button>
          <div className="navigation-buttons">
            <button onClick={handlePrevious}>Sebelumnya</button>
            <button onClick={handleNext}>Selanjutnya</button>
          </div>
        </div>
      </div>

      {isIssueFormVisible && (
        <IssueForm
          isOpen={isIssueFormVisible}
          onClose={() => setIsIssueFormVisible(false)}
          itemType="SEQUENCE"
          itemId={currentSequence.id}
          itemName={currentSequence.sequenceName}
          onIssueCreated={handleIssueCreated}
          isDarkMode={false}
        />
      )}

      <div className="sequence-details">
        <div className="sequence-info">
          <p><strong>Status:</strong> {currentSequence.status}</p>
          <p><strong>Deskripsi:</strong> {currentSequence.description}</p>
          <p><strong>Project:</strong> {activeProject?.name}</p>
        </div>
      </div>

      <div className="tab-container">
        {tabs.map(tab => (
          <button 
            key={tab} 
            className={`tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === 'Sequence Info' && (
          <div className="sequence-info-content">
            <h3>Detail Sequence</h3>
            <p>Nama: {currentSequence.sequenceName}</p>
            <p>Deskripsi: {currentSequence.description}</p>
            <p>Status: {currentSequence.status}</p>
          </div>
        )}
        {activeTab === 'Activity' && (
          <div className="activity-tab-content">
            <ActivityPanel
              item={currentSequence}
              type="sequence"
              isOpen={true}
              onClose={() => {}}
              onAddComment={handleAddComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
              onPinComment={handlePinComment}
              currentUser={currentUser}
              activities={activities}
            />
          </div>
        )}
        {activeTab === 'Published' && (
          <div className="published-content">
            <div className="library-grid">
              {activities
                .filter(activity => activity.action === 'publish_video')
                .map((activity, index) => (
                  <div key={index} className="library-item">
                    <div className="video-preview" onClick={() => setSelectedVideo(activity.data)}>
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        {activity.data.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>{activity.user}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.timestamp).toLocaleDateString()}</span>
                      </div>
                      {activity.action === 'publish_video' && (
                        <button 
                          className={`approve-button ${activity.data.isApproved ? 'cancel' : ''}`}
                          onClick={() => handleApproveVideo(activity.id, activity.data.version)}
                        >
                          {activity.data.isApproved ? 'Cancel Approve' : 'Client Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {selectedVideo && (
              <VideoModal
                isOpen={true}
                onClose={() => setSelectedVideo(null)}
                videoPath={selectedVideo.videoPath}
                annotations={selectedVideo.annotations || []}
              />
            )}
          </div>
        )}
        {activeTab === 'Client Approved' && (
          <div className="approved-content">
            <div className="library-grid">
              {activities
                .filter(activity => 
                  activity.action === 'publish_video' && 
                  activity.data.isApproved
                )
                .map((activity, index) => (
                  <div 
                    key={index} 
                    className="library-item"
                  >
                    <div 
                      className="video-preview"
                      onClick={() => setSelectedVideo(activity.data)}
                    >
                      <img 
                        src={activity.data.thumbnailUrl} 
                        alt="Video preview"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{activity.data.version}</span>
                        <span className="approved-badge">Client Approved</span>
                      </div>
                    </div>
                    <div className="video-details">
                      <h4>Version {activity.data.version}</h4>
                      <p className="description">{activity.data.description}</p>
                      <div className="meta-info">
                        <span>Approved by: {activity.data.approvedBy}</span>
                        <span className="dot">•</span>
                        <span>{new Date(activity.data.approvedAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Container History */}
            <div className="approval-history-container">
              <h3>Approval History</h3>
              <div className="approval-history-list">
                {activities
                  .filter(activity => 
                    activity.action === 'approve_video' || 
                    activity.action === 'cancel_approve_video'
                  )
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((activity, index) => (
                    <div key={index} className="history-item">
                      <div className="history-icon">
                        {activity.action === 'approve_video' ? '✅' : '❌'}
                      </div>
                      <div className="history-content">
                        <p className="history-action">
                          {activity.action === 'approve_video' 
                            ? 'Video Approved' 
                            : 'Approval Cancelled'}
                        </p>
                        <p className="history-details">
                          Version: {activity.data.version} • 
                          By: {activity.user} • 
                          {new Date(activity.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SequenceOverview;
