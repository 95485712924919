import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, 
         useToast, Progress, Badge, Flex, Text, Image, 
         Modal, ModalOverlay, ModalContent, ModalHeader, 
         ModalBody, ModalCloseButton, useDisclosure,
         Accordion, AccordionItem, AccordionButton, 
         AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import axiosInstance from '../config/axios';
import './RenderManager.css';

const RenderManager = () => {
    const [renderJobs, setRenderJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobTasks, setJobTasks] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        fetchRenderJobs();
        const interval = setInterval(fetchRenderJobs, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (selectedJob) {
            fetchJobTasks(selectedJob._id);
        }
    }, [selectedJob]);

    const fetchRenderJobs = async () => {
        try {
            const response = await axiosInstance.get('/api/renders', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRenderJobs(response.data);
        } catch (error) {
            toast({
                title: 'Error fetching render jobs',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const fetchJobTasks = async (jobId) => {
        try {
            const response = await axiosInstance.get(`/api/renders/jobs/${jobId}/tasks`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setJobTasks(response.data);
        } catch (error) {
            toast({
                title: 'Error fetching job tasks',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handlePreviewClick = async (job) => {
        try {
            const outputPath = job.Props.PlugInfo?.OutputDirectory || job.Props.PlugInfo?.OutputFilename;
            if (!outputPath) {
                toast({
                    title: 'No output available',
                    description: 'This job does not have any output files yet.',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }

            setSelectedJob(job);
            const isImageSequence = outputPath.includes('#') || outputPath.includes('%');
            const previewPath = isImageSequence 
                ? outputPath.replace(/[#%]d/, '1').replace(/[#%]\d+d/, '1')
                : outputPath;
            
            setPreviewUrl(`/api/renders/output${previewPath}`);
            onOpen();
        } catch (error) {
            toast({
                title: 'Error loading preview',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const getStatusBadge = (status) => {
        const statusColors = {
            'Active': 'green',
            'Completed': 'blue',
            'Failed': 'red',
            'Pending': 'yellow',
            'Suspended': 'orange',
            'Queued': 'purple',
            'Rendering': 'cyan'
        };
        return <Badge colorScheme={statusColors[status] || 'gray'}>{status}</Badge>;
    };

    const getTaskStatusColor = (task) => {
        if (task.Stat === 2) return 'green.500'; // Completed
        if (task.Stat === 3) return 'red.500';   // Failed
        if (task.Stat === 1) return 'blue.500';  // Rendering
        return 'gray.500';                       // Other states
    };

    return (
        <Box p={5}>
            <Heading mb={4}>Render Jobs</Heading>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Status</Th>
                        <Th>Progress</Th>
                        <Th>User</Th>
                        <Th>Priority</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderJobs.map((job) => (
                        <Tr key={job._id}>
                            <Td>{job.Props.Name || 'Untitled'}</Td>
                            <Td>{getStatusBadge(job.Props.Status || 'Pending')}</Td>
                            <Td>
                                <Flex align="center">
                                    <Progress 
                                        value={parseInt(job.SnglTskPrg) || 0} 
                                        size="sm" 
                                        width="100px" 
                                        mr={2}
                                    />
                                    <Text>{job.SnglTskPrg || '0 %'}</Text>
                                </Flex>
                            </Td>
                            <Td>{job.Props.User}</Td>
                            <Td>{job.Props.Pri}</Td>
                            <Td>
                                <Button 
                                    size="sm" 
                                    colorScheme="blue" 
                                    mr={2}
                                    onClick={() => setSelectedJob(job)}
                                >
                                    Details
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="green"
                                    onClick={() => handlePreviewClick(job)}
                                >
                                    Preview
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {selectedJob && (
                <Box mt={4} p={4} borderWidth={1} borderRadius="lg">
                    <Heading size="md" mb={2}>Job Details</Heading>
                    <Text><strong>Job ID:</strong> {selectedJob._id}</Text>
                    <Text><strong>Scene File:</strong> {selectedJob.Props.PlugInfo?.SceneFile}</Text>
                    <Text><strong>Frames:</strong> {selectedJob.Props.Frames}</Text>
                    <Text><strong>Machine:</strong> {selectedJob.Mach}</Text>
                    <Text><strong>Submission Date:</strong> {new Date(selectedJob.Date).toLocaleString()}</Text>
                    <Text><strong>Output Path:</strong> {selectedJob.Props.PlugInfo?.OutputDirectory || selectedJob.Props.PlugInfo?.OutputFilename || 'Not specified'}</Text>

                    <Accordion allowToggle mt={4}>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        Frame Details
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Frame</Th>
                                            <Th>Status</Th>
                                            <Th>Progress</Th>
                                            <Th>Worker</Th>
                                            <Th>Start Time</Th>
                                            <Th>End Time</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {jobTasks.map((task, index) => (
                                            <Tr key={index}>
                                                <Td>{task.Frames || task.TaskId}</Td>
                                                <Td>{getStatusBadge(task.Status)}</Td>
                                                <Td>
                                                    <Progress 
                                                        value={task.Progress || 0}
                                                        size="xs"
                                                        colorScheme={getTaskStatusColor(task)}
                                                    />
                                                </Td>
                                                <Td>{task.Worker || '-'}</Td>
                                                <Td>{task.StartTime ? new Date(task.StartTime).toLocaleString() : '-'}</Td>
                                                <Td>{task.EndTime ? new Date(task.EndTime).toLocaleString() : '-'}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Render Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {previewUrl && (
                            previewUrl.toLowerCase().match(/\.(mp4|mov|avi)$/) ? (
                                <video 
                                    controls 
                                    style={{ width: '100%', maxHeight: '80vh' }}
                                    src={previewUrl}
                                >
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <Image 
                                    src={previewUrl}
                                    alt="Render Preview"
                                    maxH="80vh"
                                    w="auto"
                                    mx="auto"
                                />
                            )
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default RenderManager;
