import React, { useState, useEffect } from 'react';
import './UploadModal.css';

const UploadModal = ({ isOpen, onClose, onPublish, onReview, onAttachment }) => {
  const [selectedMode, setSelectedMode] = useState(null);
  const [version, setVersion] = useState('');
  const [publishText, setPublishText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const generateThumbnail = (file) => {
    return new Promise((resolve) => {
      if (file.type.startsWith('image/')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const ratio = 200 / img.width;
          canvas.width = 200;
          canvas.height = img.height * ratio;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL('image/jpeg'));
        };
        return;
      }

      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          video.currentTime = Math.min(1, video.duration * 0.1);
          const ratio = 200 / video.videoWidth;
          canvas.width = 200;
          canvas.height = video.videoHeight * ratio;
        };
        
        video.onseeked = () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL('image/jpeg'));
        };
      } else {
        resolve(null);
      }
    });
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
    setSelectedFile(file);
    
    if (file) {
      const thumb = await generateThumbnail(file);
      setThumbnail(thumb);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup URL.createObjectURL kalau ada
      if (thumbnail) URL.revokeObjectURL(thumbnail);
    };
  }, [thumbnail]);

  const handleSubmit = () => {
    console.log('Submitting with mode:', selectedMode);
    console.log('Form data:', {
      version,
      publishText,
      selectedFile,
      thumbnail
    });

    if (!selectedFile) {
      alert('Pilih file terlebih dahulu');
      return;
    }

    if (selectedMode === 'publish') {
      if (!version || !publishText) {
        alert('Mohon lengkapi semua field untuk Publish');
        return;
      }
      console.log('Publishing video with data:', {
        file: selectedFile,
        type: 'publish',
        version,
        publishText,
        thumbnail
      });
      onPublish({ file: selectedFile, type: 'publish', version, publishText, thumbnail });
    } else if (selectedMode === 'review') {
      console.log('Uploading review video:', {
        file: selectedFile,
        type: 'review'
      });
      onReview({ file: selectedFile, type: 'review' });
    } else if (selectedMode === 'attachment') {
      console.log('Uploading attachment:', {
        file: selectedFile,
        type: 'attachment'
      });
      onAttachment({ file: selectedFile, type: 'attachment' });
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="upload-modal-overlay">
      <div className="upload-modal">
        <div className="modal-header">
          <h3>Upload File</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        {!selectedMode ? (
          <div className="mode-selection">
            <button onClick={() => setSelectedMode('publish')}>Publish</button>
            <button onClick={() => setSelectedMode('review')}>Review</button>
            <button onClick={() => setSelectedMode('attachment')}>Attachment</button>
          </div>
        ) : (
          <div className="upload-form">
            {selectedMode === 'publish' && (
              <>
                <div className="form-group">
                  <label>Version:</label>
                  <input
                    type="text"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    placeholder="v1.0.0"
                  />
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    value={publishText}
                    onChange={(e) => setPublishText(e.target.value)}
                    placeholder="Masukkan deskripsi..."
                  />
                </div>
              </>
            )}
            <div className="form-group">
              <label>File:</label>
              <input
                type="file"
                accept="video/*,.mov,.exr,.dpx,image/*,.pdf,.xlsx,.docx"
                onChange={handleFileSelect}
              />
              {thumbnail && (
                <div className="thumbnail-preview">
                  <img src={thumbnail} alt="Thumbnail preview" />
                </div>
              )}
            </div>
            <div className="button-group">
              <button onClick={handleSubmit} className="submit-button">
                Upload
              </button>
              <button onClick={onClose} className="cancel-button">
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadModal;