import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import VideoPlayer from './VideoPlayer';
import { API_BASE_URL } from '../config/config';
import './PresentPanel.css';

const PresentationViewer = ({ activeProject }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [presentation, setPresentation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(0);
  const videoPlayerRef = useRef(null);

  useEffect(() => {
    const fetchPresentation = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`${API_BASE_URL}/api/presentations/${id}`);
        const presentationData = response.data;

        if (activeProject && presentationData.projectId !== activeProject.id) {
          setError('Presentasi ini bukan bagian dari project yang sedang aktif');
          setLoading(false);
          return;
        }

        setPresentation(presentationData);
        setCurrentPlayingIndex(presentationData.currentPlayingIndex || 0);
        setError(null);
      } catch (error) {
        console.error('Error fetching presentation:', error);
        setError('Gagal memuat presentasi');
      } finally {
        setLoading(false);
      }
    };

    if (activeProject) {
      fetchPresentation();
    }
  }, [id, activeProject]);

  if (!activeProject) {
    return <div className="error">Silakan pilih project terlebih dahulu</div>;
  }

  if (loading) return <div className="loading">Memuat presentasi...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!presentation) return <div>Presentasi tidak ditemukan</div>;

  const handleVideoEnd = () => {
    if (presentation && currentPlayingIndex < presentation.playlist.length - 1) {
      setCurrentPlayingIndex(prev => prev + 1);
    }
  };

  return (
    <div className="present-container">
      <div className="present-topbar">
        <div className="present-topbar-left">
          <h2>{presentation.name}</h2>
        </div>
        <div className="present-topbar-right">
          <button onClick={() => navigate('/presentations')}>Kembali</button>
        </div>
      </div>

      <div className="present-content">
        <div className="present-main-content">
          {presentation.selectedItems.length > 0 && (
            <div>
              <div className="selected-videos-scroll">
                {presentation.selectedItems.map((item, index) => (
                  <div 
                    key={item.id}
                    className={`selected-video-card ${index === currentPlayingIndex ? 'playing' : ''}`}
                    onClick={() => setCurrentPlayingIndex(index)}
                  >
                    <div className="video-preview">
                      <img 
                        src={item.data?.thumbnailUrl} 
                        alt="Video preview"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/assets/images/video-placeholder.png';
                        }}
                      />
                      <div className="video-info-overlay">
                        <span className="version">v{item.data?.version}</span>
                        {item.data?.isApproved && (
                          <span className="approved-badge">Client Approved</span>
                        )}
                      </div>
                    </div>
                    <div className="video-card-info">
                      <small>
                        {index + 1}. {item.shot?.shotName || 
                          item.sequence?.sequenceName || 
                          item.asset?.assetName || 
                          item.task?.taskName}
                      </small>
                    </div>
                  </div>
                ))}
              </div>

              <div className="video-container">
                <VideoPlayer
                  ref={videoPlayerRef}
                  filepath={presentation.playlist[currentPlayingIndex]}
                  watchMode={true}
                  onEnded={handleVideoEnd}
                />
              </div>

              <div className="playlist-controls">
                <button 
                  onClick={() => setCurrentPlayingIndex(prev => Math.max(0, prev - 1))}
                  disabled={currentPlayingIndex === 0}
                >
                  Previous
                </button>
                <button 
                  onClick={() => setCurrentPlayingIndex(prev => 
                    Math.min(presentation.playlist.length - 1, prev + 1))}
                  disabled={currentPlayingIndex === presentation.playlist.length - 1}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresentationViewer; 