import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import './UserManager.css';

const UserManager = ({ isDarkMode }) => {
  const fileInputRef = useRef();
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [levels, setLevels] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    phoneNumber: '',
    birthDate: '',
    departmentId: '',
    levelId: '',
    roleId: '',
    profileImage: null
  });
  const [editingUser, setEditingUser] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
    fetchLevels();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error mengambil data user:', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axiosInstance.get('/api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Error mengambil data department:', error);
    }
  };

  const fetchLevels = async () => {
    try {
      const response = await axiosInstance.get('/api/levels');
      setLevels(response.data);
    } catch (error) {
      console.error('Error mengambil data level:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get('/api/roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Error mengambil data role:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({...newUser, profileImage: file});
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      Object.keys(newUser).forEach(key => {
        if (key === 'profileImage' && newUser[key]) {
          formData.append('profileImage', newUser[key]);
        } else {
          formData.append(key, newUser[key]);
        }
      });

      if (editingUser) {
        await axiosInstance.put(`/api/users/${editingUser.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        await axiosInstance.post('/api/users', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      setNewUser({
        username: '',
        email: '',
        password: '',
        phoneNumber: '',
        birthDate: '',
        departmentId: '',
        levelId: '',
        roleId: '',
        profileImage: null
      });
      setPreviewImage(null);
      setEditingUser(null);
      fetchUsers();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Apakah Anda yakin ingin menghapus user ini?')) {
      try {
        await axiosInstance.delete(`/api/users/${userId}`);
        // Hapus file gambar profil jika ada
        const user = users.find(u => u.id === userId);
        if (user && user.profileImage) {
          await axiosInstance.delete(`/api/uploads/${user.profileImage.split('/').pop()}`);
        }
        fetchUsers();
      } catch (error) {
        console.error('Error menghapus user:', error);
        alert('Gagal menghapus user');
      }
    }
  };

  return (
    <div className={`user-manager ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Manajemen Pengguna</h2>
      
      <form onSubmit={handleSubmit} className={`user-form ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="profile-image-section">
          {previewImage && (
            <img src={previewImage} alt="Preview" className="profile-preview" />
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="upload-button"
          >
            Upload Foto Profil
          </button>
        </div>

        <input
          type="text"
          placeholder="Username"
          value={newUser.username}
          onChange={(e) => setNewUser({...newUser, username: e.target.value})}
          required
        />
        
        <input
          type="tel"
          placeholder="Nomor Telepon"
          value={newUser.phoneNumber}
          onChange={(e) => setNewUser({...newUser, phoneNumber: e.target.value})}
        />

        <input
          type="date"
          placeholder="Tanggal Lahir"
          value={newUser.birthDate}
          onChange={(e) => setNewUser({...newUser, birthDate: e.target.value})}
        />

        <input
          type="email"
          placeholder="Email"
          value={newUser.email}
          onChange={(e) => setNewUser({...newUser, email: e.target.value})}
          required
        />
        {!editingUser && (
          <input
            type="password"
            placeholder="Password"
            value={newUser.password}
            onChange={(e) => setNewUser({...newUser, password: e.target.value})}
            required
          />
        )}
        <select
          value={newUser.departmentId}
          onChange={(e) => setNewUser({...newUser, departmentId: e.target.value})}
          required
        >
          <option value="">Pilih Department</option>
          {departments.map(dept => (
            <option key={dept.id} value={dept.id}>{dept.name}</option>
          ))}
        </select>
        <select
          value={newUser.levelId}
          onChange={(e) => setNewUser({...newUser, levelId: e.target.value})}
          required
        >
          <option value="">Pilih Level</option>
          {levels.map(level => (
            <option key={level.id} value={level.id}>{level.name}</option>
          ))}
        </select>
        <select
          value={newUser.roleId}
          onChange={(e) => setNewUser({...newUser, roleId: e.target.value})}
          required
        >
          <option value="">Pilih Role</option>
          {roles.map(role => (
            <option key={role.id} value={role.id}>{role.name}</option>
          ))}
        </select>
        <button type="submit">
          {editingUser ? 'Update User' : 'Tambah User'}
        </button>
      </form>

      <div className="user-cards">
        {users.map(user => (
          <div key={user.id} className={`user-card ${isDarkMode ? 'dark-mode' : ''}`}>
            <div className={`user-card-header ${isDarkMode ? 'dark-mode' : ''}`}>
              <div className="profile-image-container">
                {user.profileImage ? (
                  <img 
                    src={`${API_BASE_URL}${user.profileImage}`} 
                    alt={user.username}
                    className="profile-image"
                  />
                ) : (
                  <div className={`profile-image-placeholder ${isDarkMode ? 'dark-mode' : ''}`}>
                    {user.username[0].toUpperCase()}
                  </div>
                )}
              </div>
              <h3>{user.username}</h3>
            </div>
            
            <div className={`user-card-body ${isDarkMode ? 'dark-mode' : ''}`}>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Telepon:</strong> {user.phoneNumber || '-'}</p>
              <p><strong>Tanggal Lahir:</strong> {user.birthDate ? new Date(user.birthDate).toLocaleDateString() : '-'}</p>
              <p><strong>Departemen:</strong> {departments.find(d => d.id === user.departmentId)?.name || '-'}</p>
              <p><strong>Level:</strong> {levels.find(l => l.id === user.levelId)?.name || '-'}</p>
              <p><strong>Role:</strong> {roles.find(r => r.id === user.roleId)?.name || '-'}</p>
            </div>

            <div className="user-card-actions">
              <button 
                onClick={() => {
                  setEditingUser(user);
                  setNewUser({
                    username: user.username,
                    email: user.email,
                    phoneNumber: user.phoneNumber || '',
                    birthDate: user.birthDate || '',
                    departmentId: user.departmentId || '',
                    levelId: user.levelId || '',
                    roleId: user.roleId || '',
                    profileImage: null
                  });
                }}
                className={`edit-button ${isDarkMode ? 'dark-mode' : ''}`}
              >
                Edit
              </button>
              <button 
                onClick={() => handleDeleteUser(user.id)}
                className={`delete-button ${isDarkMode ? 'dark-mode' : ''}`}
              >
                Hapus
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserManager;
