import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axiosInstance.get('/api/departments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Error mengambil data department:', error);
    }
  };

  return (
    <div className="department-list-container">
      <table className="department-table">
        <thead>
          <tr>
            <th>Nama Department</th>
            <th>Deskripsi</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {departments.map(dept => (
            <tr key={dept.id}>
              <td>{dept.name}</td>
              <td>{dept.description}</td>
              <td>
                <span className={`status-badge ${dept.isActive ? 'active' : 'inactive'}`}>
                  {dept.isActive ? 'Aktif' : 'Tidak Aktif'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DepartmentList; 