import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { useDarkMode } from '../../App';
import {
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, AreaChart, Area
} from 'recharts';
import './ProjectAnalytics.css';

const ProjectAnalytics = () => {
  const { isDarkMode } = useDarkMode();
  const [timelineData, setTimelineData] = useState(null);
  const [resourceData, setResourceData] = useState(null);
  const [riskData, setRiskData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [comparisonData, setComparisonData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ startDate: '', endDate: '' }); // Hapus department filter
  const [notifications, setNotifications] = useState([]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (selectedProjects.length > 0) {
      fetchAnalyticsData(selectedProjects[0]);
      if (selectedProjects.length > 1) fetchComparisonData();
      const interval = setInterval(() => fetchAnalyticsData(selectedProjects[0]), 30000);
      return () => clearInterval(interval);
    }
  }, [selectedProjects, filters]);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data);
      if (response.data.length > 0) setSelectedProjects([response.data[0].id]);
    } catch (err) {
      setError('Failed to fetch projects');
    }
  };

  const fetchAnalyticsData = async (projectId) => {
    try {
      setLoading(true);
      setError(null);
      const params = { ...filters };
      const [projectRes, timelineRes, resourceRes, riskRes] = await Promise.all([
        axiosInstance.get(`/api/analytics/projects/${projectId}`, { params }),
        axiosInstance.get(`/api/analytics/timeline/${projectId}`, { params }),
        axiosInstance.get(`/api/analytics/resources/${projectId}`, { params }),
        axiosInstance.get(`/api/analytics/risks/${projectId}`, { params })
      ]);
  
      console.log('Raw Project Response:', projectRes.data);
      console.log('Timeline Data:', timelineRes.data);
      console.log('Resource Data:', resourceRes.data);
      console.log('Risk Data:', riskRes.data);
  
      setProjectData(projectRes.data);
      console.log('Set Project Data:', projectRes.data);
  
      setTimelineData(timelineRes.data);
      setResourceData(resourceRes.data);
      setRiskData(riskRes.data);
  
      const newNotifications = [];
      const overdueTasks = timelineRes.data.bottlenecks?.filter(b => b.area === 'Timeline' && b.impact > 20);
      if (overdueTasks && overdueTasks.length > 0) {
        newNotifications.push(`Critical overdue tasks detected! (${overdueTasks[0].description})`);
      }
      if (riskRes.data?.highRiskTasks?.length > 5) {
        newNotifications.push('More than 5 high-risk tasks identified!');
      }
      setNotifications(newNotifications);
    } catch (err) {
      console.error('Fetch analytics error:', err);
      let errorMessage = 'Failed to fetch analytics data';
      if (err.response) {
        errorMessage += `: ${err.response.status} - ${err.response.data?.error || 'Unknown error'}`;
      } else if (err.request) {
        errorMessage += ': No response from server. Is the backend running?';
      } else {
        errorMessage += `: ${err.message}`;
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  


  const fetchComparisonData = async () => {
    if (selectedProjects.length < 2) {
      setComparisonData([]);
      return;
    }
    try {
      const response = await axiosInstance.get('/api/analytics/compare', {
        params: { projectIds: selectedProjects.join(',') }
      });
      console.log('Comparison Data:', response.data);
      setComparisonData(response.data);
    } catch (err) {
      console.error('Fetch comparison error:', err);
      setError('Failed to fetch comparison data');
    }
  };

  useEffect(() => {
    fetchComparisonData();
  }, [selectedProjects]);


  const handleProjectChange = (projectId) => {
    setSelectedProjects(prev => 
      prev.includes(projectId) ? prev.filter(id => id !== projectId) : [...prev, projectId]
    );
  };

  const exportToCSV = (data, filename) => {
    const csvContent = [
      ['Department', 'Total Tasks', 'Completed', 'Percentage', 'Avg Duration'],
      ...Object.entries(data.departmentProgress).map(([dept, d]) => [
        dept, d.total, d.completed, d.percentage, d.avgDuration
      ])
    ].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
  };

  const renderFilters = () => (
    <div className={`filters ${isDarkMode ? 'darkmode' : ''}`}>
      <input 
        type="date" 
        value={filters.startDate} 
        onChange={(e) => setFilters({...filters, startDate: e.target.value})} 
      />
      <input 
        type="date" 
        value={filters.endDate} 
        onChange={(e) => setFilters({...filters, endDate: e.target.value})} 
      />
    </div>
  );

  const renderSummary = () => (
    <div className={`summary ${isDarkMode ? 'darkmode' : ''}`}>
      <div>Total Tasks: {projectData?.totalTasks || 0}</div>
      <div>Completed Tasks: {projectData?.completionRate || 0}%</div>
      <div>High Risk: {riskData?.highRiskPercentage || 0}%</div>
      <div>Avg Completion Time: {timelineData?.avgCompletionTime || 0} days</div>
      <div>Predicted Finish: {projectData?.predictedCompletion || 'N/A'}</div>
    </div>
  );

  const renderProjectOverview = () => {
    console.log('Project Data in render:', projectData);
    if (!projectData || !projectData.departmentProgress) {
      console.log('No department progress because:', { projectData: !!projectData, departmentProgress: !!projectData?.departmentProgress });
      return <div>No department progress data available</div>;
    }
  
    console.log('Rendering Department Progress:', projectData.departmentProgress);
    return (
      <div className={`analytics-section ${isDarkMode ? 'darkmode' : ''}`}>
        <h3>Project Overview</h3>
        <button onClick={() => exportToCSV(projectData, `project_${selectedProjects[0]}`)}>Export CSV</button>
        <h4>Status Distribution</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={Object.entries(projectData.statusDistribution || {}).map(([status, count]) => ({
            status,
            count
          }))}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="status" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" name="Task Count" />
          </BarChart>
        </ResponsiveContainer>
        <h4>Department Progress</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={Object.entries(projectData.departmentProgress || {}).map(([dept, data]) => ({
            name: dept,
            percentage: data.percentage || 0,
            avgDuration: data.avgDuration || 0
          }))}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, 100]} />
            <Tooltip content={({ payload }) => payload?.length ? (
              <div className="custom-tooltip">
                <p>{payload[0].payload.name}</p>
                <p>Completion: {payload[0].value}%</p>
                <p>Total Tasks: {projectData.departmentProgress[payload[0].payload.name].total}</p>
                <p>Completed: {projectData.departmentProgress[payload[0].payload.name].completed}</p>
                <p>Avg Duration: {payload[0].payload.avgDuration} days</p>
                <p>Status: {JSON.stringify(projectData.departmentProgress[payload[0].payload.name].statusBreakdown)}</p>
              </div>
            ) : null} />
            <Legend />
            <Bar dataKey="percentage" fill="#8884d8" name="Completion %" />
          </BarChart>
        </ResponsiveContainer>
        <h4>Top 5 Longest & Shortest Tasks</h4>
        <div className="task-lists">
          <div>
            <h5>Longest</h5>
            <ul>
              {(projectData.topLongestTasks || []).map((task, idx) => (
                <li key={idx}>{task.name} ({task.duration} days) - {task.department || 'N/A'}</li>
              ))}
            </ul>
          </div>
          <div>
            <h5>Shortest</h5>
            <ul>
              {(projectData.topShortestTasks || []).map((task, idx) => (
                <li key={idx}>{task.name} ({task.duration} days) - {task.department || 'N/A'}</li>
              ))}
            </ul>
          </div>
        </div>
        <h4>User Productivity</h4>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={projectData.userProductivity || []}>
            <XAxis dataKey="username" />
            <YAxis domain={[0, 100]} />
            <Tooltip />
            <Bar dataKey="completionRate" fill="#82ca9d" name="Completion %" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderResourceAnalysis = () => {
    if (!resourceData || !resourceData.utilization) {
      return <div>No resource utilization data available</div>;
    }

    return (
      <div className={`analytics-section ${isDarkMode ? 'darkmode' : ''}`}>
        <h3>Resource Utilization</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={resourceData.utilization}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="department" />
            <YAxis domain={[0, 100]} />
            <Tooltip content={({ payload }) => payload?.length ? (
              <div className="custom-tooltip">
                <p>{payload[0].payload.department}</p>
                <p>Current: {payload[0].payload.current}%</p>
                <p>Active Tasks: {payload[0].payload.activeTasks}</p>
                <p>Total Tasks: {payload[0].payload.totalTasks}</p>
              </div>
            ) : null} />
            <Legend />
            <Bar dataKey="current" fill="#8884d8" name="Current" />
            <Bar dataKey="activeTasks" fill="#FF8042" name="Active Tasks" />
          </BarChart>
        </ResponsiveContainer>
        <h4>Suggestions</h4>
        <ul className="suggestions-list">
          {resourceData.suggestions?.map((suggestion, idx) => (
            <li key={idx}>
              <strong>{suggestion.title}</strong>: {suggestion.description}
              <div>Potential Savings: {suggestion.potentialSavings}</div>
            </li>
          ))}
        </ul>
        <h4>User Metrics</h4>
        <table className="data-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Department</th>
              <th>Total Tasks</th>
              <th>Completed Tasks</th>
              <th>Active Tasks</th>
            </tr>
          </thead>
          <tbody>
            {resourceData.userMetrics?.map(user => (
              <tr key={user.userId}>
                <td>{user.userName}</td>
                <td>{user.department}</td>
                <td>{user.totalTasks}</td>
                <td>{user.completedTasks}</td>
                <td>{user.activeTasks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTimelineAnalysis = () => (
    <div className={`analytics-section ${isDarkMode ? 'darkmode' : ''}`}>
      <h3>Timeline Prediction</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={timelineData?.predictions || []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="predicted" stroke="#8884d8" name="Predicted" strokeWidth={2} dot={false} />
          <Line type="monotone" dataKey="actual" stroke="#82ca9d" name="Actual" strokeWidth={2} dot={false} />
        </LineChart>
      </ResponsiveContainer>
      <h4>Completed Tasks Trend</h4>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={timelineData?.trends || []}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="completed" stroke="#FF8042" fill="#FF8042" name="Completed Tasks" />
        </AreaChart>
      </ResponsiveContainer>
      <h4>Overdue Tasks by Department</h4>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={Object.entries(timelineData?.overdueByDept || {}).map(([dept, count]) => ({ dept, count }))}>
          <XAxis dataKey="dept" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#FFBB28" name="Overdue Tasks" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const renderRiskAnalysis = () => {
    if (!riskData || !riskData.distribution) {
      return <div>No risk assessment data available</div>;
    }

    return (
      <div className={`analytics-section ${isDarkMode ? 'darkmode' : ''}`}>
        <h3>Risk Assessment</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={riskData.distribution || []}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              label
            >
              {(riskData.distribution || []).map((_, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
        <h4>High Risk Tasks</h4>
        <table className="data-table">
          <thead>
            <tr>
              <th>Task Name</th>
              <th>Assignee</th>
              <th>Due Date</th>
              <th>Risk Score</th>
            </tr>
          </thead>
          <tbody>
            {(riskData.highRiskTasks || []).map(task => (
              <tr key={task.taskId}>
                <td>{task.taskName || 'N/A'}</td>
                <td>{task.assignee || 'Unassigned'}</td>
                <td>{task.dueDate || 'N/A'}</td>
                <td>{task.riskScore || 0}</td>
              </tr>
            ))}
            {(!riskData.highRiskTasks || riskData.highRiskTasks.length === 0) && (
              <tr>
                <td colSpan="4">No high-risk tasks identified</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderComparison = () => {
    if (!comparisonData.length) {
      return <div>No comparison data available</div>;
    }

    return (
      <div className={`analytics-section ${isDarkMode ? 'darkmode' : ''}`}>
        <h3>Project Comparison</h3>

        {/* Grafik Completion Rate */}
        <h4>Completion Rate</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={comparisonData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="projectName" 
              label={{ value: 'Project Name', position: 'insideBottom', offset: -5 }} 
              interval={0} 
              angle={-45} 
              textAnchor="end" 
              height={60}
            />
            <YAxis domain={[0, 100]} label={{ value: 'Percentage (%)', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="completionRate" fill="#8884d8" name="Completion %" />
          </BarChart>
        </ResponsiveContainer>

        {/* Grafik Total Tasks */}
        <h4>Total Tasks</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={comparisonData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="projectName" 
              label={{ value: 'Project Name', position: 'insideBottom', offset: -5 }} 
              interval={0} 
              angle={-45} 
              textAnchor="end" 
              height={60}
            />
            <YAxis label={{ value: 'Number of Tasks', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalTasks" fill="#00C49F" name="Total Tasks" />
          </BarChart>
        </ResponsiveContainer>

        {/* Tabel Ringkasan */}
        <h4>Summary</h4>
        <table className="data-table">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Total Tasks</th>
              <th>Completion Rate (%)</th>
              <th>Predicted Completion</th>
            </tr>
          </thead>
          <tbody>
            {comparisonData.map(project => (
              <tr key={project.projectId}>
                <td>{project.projectName}</td>
                <td>{project.totalTasks}</td>
                <td>{project.completionRate}</td>
                <td>{project.predictedCompletion}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Status Distribution */}
        <h4>Status Distribution</h4>
        {comparisonData.map(project => (
          <div key={project.projectId} className="status-distribution-section">
            <h5>{project.projectName}</h5>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(project.statusDistribution || {}).map(([status, count]) => (
                  <tr key={status}>
                    <td>{status}</td>
                    <td>{count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* Department Progress */}
        <h4>Department Progress</h4>
        {comparisonData.map(project => (
          <div key={project.projectId} className="department-progress-section">
            <h5>{project.projectName}</h5>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart 
                data={Object.entries(project.departmentProgress || {}).map(([dept, data]) => ({
                  name: dept,
                  percentage: data.percentage || 0
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip 
                  content={({ payload }) => payload?.length ? (
                    <div className="custom-tooltip">
                      <p>{payload[0].payload.name}</p>
                      <p>Completion: {payload[0].value}%</p>
                      <p>Total Tasks: {project.departmentProgress[payload[0].payload.name].total}</p>
                      <p>Completed: {project.departmentProgress[payload[0].payload.name].completed}</p>
                    </div>
                  ) : null}
                />
                <Legend />
                <Bar dataKey="percentage" fill="#82ca9d" name="Completion %" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    );
  };

  if (loading && !projects.length) {
    return <div className={`loading-container ${isDarkMode ? 'darkmode' : ''}`}>Loading projects...</div>;
  }

  return (
    <div className={`project-analytics ${isDarkMode ? 'darkmode' : ''}`}>
      <div className={`analytics-header ${isDarkMode ? 'darkmode' : ''}`}>
        <h2>Project Analytics</h2>
        <div className="project-selector">
          {projects.map(project => (
            <label key={project.id}>
              <input 
                type="checkbox" 
                checked={selectedProjects.includes(project.id)} 
                onChange={() => handleProjectChange(project.id)} 
              />
              {project.name || `Project ${project.id}`}
            </label>
          ))}
        </div>
        {renderFilters()}
        {renderSummary()}
        <div className={`tab-navigation ${isDarkMode ? 'darkmode' : ''}`}>
          <button className={activeTab === 'overview' ? 'active' : ''} onClick={() => setActiveTab('overview')}>Overview</button>
          <button className={activeTab === 'timeline' ? 'active' : ''} onClick={() => setActiveTab('timeline')}>Timeline</button>
          <button className={activeTab === 'resources' ? 'active' : ''} onClick={() => setActiveTab('resources')}>Resources</button>
          <button className={activeTab === 'risks' ? 'active' : ''} onClick={() => setActiveTab('risks')}>Risks</button>
          {selectedProjects.length > 1 && (
            <button className={activeTab === 'comparison' ? 'active' : ''} onClick={() => setActiveTab('comparison')}>Comparison</button>
          )}
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      {notifications.map((note, idx) => (
        <div key={idx} className="notification">{note}</div>
      ))}

      {loading ? (
        <div className={`loading-container ${isDarkMode ? 'darkmode' : ''}`}>Loading analytics...</div>
      ) : !selectedProjects.length ? (
        <div className={`loading-container ${isDarkMode ? 'darkmode' : ''}`}>No projects selected</div>
      ) : (
        <div className={`analytics-content ${isDarkMode ? 'darkmode' : ''}`}>
          {activeTab === 'overview' && renderProjectOverview()}
          {activeTab === 'timeline' && renderTimelineAnalysis()}
          {activeTab === 'resources' && renderResourceAnalysis()}
          {activeTab === 'risks' && renderRiskAnalysis()}
          {activeTab === 'comparison' && renderComparison()}
        </div>
      )}
    </div>
  );
};

export default ProjectAnalytics;