import { useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from '../config/config';

const usePersonalMessages = ({ currentUser, socket, toast, isOpen, onUnreadCountChange }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});

  const fetchConversations = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Token tidak ditemukan');
      const response = await fetch(`${API_BASE_URL}/api/personalmessages/conversations`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching personal conversations');
      const data = await response.json();
      console.log('Fetched personal conversations:', data);
      const validConversations = data.filter(conv => conv?.user?.id && conv?.user?.username);
      setConversations(validConversations);
      console.log('Selected conversation:', selectedConversation);
      if (validConversations.length > 0 && !selectedConversation) {
        setSelectedConversation(validConversations[0]);
        console.log('Set selected conversation to:', validConversations[0]);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 5000 });
    }
  };

  const fetchMessages = useCallback(() => {
    if (!selectedConversation?.user?.id || !socket || !currentUser?.id) return;
    console.log('Fetching conversation messages for:', {
      currentUserId: currentUser.id,
      otherUserId: selectedConversation.user.id,
    });
    socket.emit('fetch_conversation_messages', {
      currentUserId: currentUser.id,
      otherUserId: selectedConversation.user.id,
    });

    const handleMessages = (fetchedMessages) => {
      console.log('Received conversation messages:', fetchedMessages);
      const userId = parseInt(localStorage.getItem('userId'));
      const processedMessages = fetchedMessages.map(msg => ({
        ...msg,
        likes: Array.isArray(msg.likes) ? msg.likes : [],
        likeEmojis: msg.likeEmojis || {},
        userLiked: msg.likes.includes(userId),
        userEmoji: msg.likeEmojis?.[userId] || '❤️',
        isSentByCurrentUser: msg.senderId === currentUser.id,
      }));
      setMessages(processedMessages);
      console.log('Updated messages state:', processedMessages);
    };
    const handleError = (error) =>
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });

    socket.on('conversation_messages', handleMessages);
    socket.on('conversation_messages_error', handleError);

    return () => {
      socket.off('conversation_messages', handleMessages);
      socket.off('conversation_messages_error', handleError);
    };
  }, [selectedConversation, socket, currentUser, toast]);

  const fetchPinnedMessages = async () => {
    if (!selectedConversation?.user?.id || !currentUser?.id) return;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/personalmessages/pinned`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching pinned messages');
      const data = await response.json();
      setPinnedMessages(data || []);
    } catch (error) {
      console.error('Error fetching pinned messages:', error);
      setPinnedMessages([]);
    }
  };

  const markMessagesAsRead = async (messageId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/personalmessages/read/${messageId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error marking messages as read');
      }

      socket.emit('mark_message_read', {
        messageId,
        userId: currentUser.id,
      });

      console.log(`Pesan ${messageId} berhasil ditandai sebagai dibaca`);
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, read: true } : msg
        )
      );
    } catch (error) {
      console.error('Error marking messages as read:', error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

const deleteMessage = async (messageId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/api/personalmessages/${messageId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Pesan tidak ditemukan di server');
      }
      // Jika bukan 404, coba parsing error dari JSON
      const errorData = await response.text(); // Gunakan text() untuk menghindari parsing JSON langsung
      throw new Error(errorData || 'Error deleting personal message');
    }

    // Perbarui state lokal hanya jika penghapusan sukses
    setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
    toast({
      title: 'Berhasil',
      description: 'Pesan dihapus',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    socket.emit('delete_message', { messageId });
    console.log(`Pesan ${messageId} berhasil dihapus`);
  } catch (error) {
    console.error('Error deleting personal message:', error);
    toast({
      title: 'Error',
      description: error.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
};

  useEffect(() => {
    if (isOpen) fetchConversations();
  }, [isOpen]);

  useEffect(() => {
    if (selectedConversation?.user?.id && currentUser?.id) {
      const cleanup = fetchMessages();
      return cleanup;
    }
  }, [selectedConversation, currentUser, fetchMessages]);

  useEffect(() => {
    if (selectedConversation?.user?.id && currentUser?.id) {
      fetchPinnedMessages();
    }
  }, [selectedConversation, currentUser]);

  useEffect(() => {
    if (selectedConversation && messages.some(msg => !msg.read && msg.receiverId === currentUser.id)) {
      const unreadMessageIds = messages
        .filter(msg => !msg.read && msg.receiverId === currentUser.id)
        .map(msg => msg.id);
      unreadMessageIds.forEach(markMessagesAsRead);
    }
  }, [messages, selectedConversation, currentUser]);

  useEffect(() => {
    const totalUnread = Object.values(unreadCounts).reduce((sum, count) => sum + count, 0);
    onUnreadCountChange?.(totalUnread);
  }, [unreadCounts, onUnreadCountChange]);

  useEffect(() => {
    if (!socket) {
      console.log('Socket not available');
      return;
    }

    console.log('Setting up personal message listeners...');

    const handleNewMessage = (newMessage) => {
      if (!newMessage?.id) {
        console.error('Pesan tidak valid:', newMessage);
        return;
      }

      console.log('Pesan baru diterima:', newMessage);
      setMessages(prevMessages => {
        if (!prevMessages.some(msg => msg.id === newMessage.id)) {
          return [...prevMessages, newMessage];
        }
        return prevMessages;
      });
    };

    socket.on('new_personal_message', handleNewMessage);

    return () => {
      console.log('Cleaning up personal message listeners...');
      socket.off('new_personal_message', handleNewMessage);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    const handleMessageRead = ({ messageId }) => {
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, read: true } : msg
        )
      );
    };

    // Tambahkan handler untuk message_pinned
    const handleMessagePinned = ({ messageId, isPinned }) => {
      console.log(`Message ${messageId} pinned status: ${isPinned}`);
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, pinned: isPinned } : msg
        )
      );
      setPinnedMessages(prevPinned => {
        const msg = messages.find(m => m.id === messageId);
        if (isPinned) {
          return msg && !prevPinned.some(pm => pm.id === messageId) ? [...prevPinned, { ...msg, pinned: true }] : prevPinned;
        } else {
          return prevPinned.filter(pm => pm.id !== messageId);
        }
      });
    };

    socket.on('message_read', handleMessageRead);
    socket.on('message_pinned', handleMessagePinned);

    return () => {
      socket.off('message_read', handleMessageRead);
      socket.off('message_pinned', handleMessagePinned);
    };
  }, [socket, messages]);

  return {
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    messages,
    setMessages,
    pinnedMessages,
    setPinnedMessages,
    unreadCounts,
    setUnreadCounts,
    fetchMessages,
    fetchPinnedMessages,
    markMessagesAsRead,
    deleteMessage,
  };
};

export default usePersonalMessages;