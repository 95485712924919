import axios from 'axios';
import { API_BASE_URL } from './config';
import { useNavigate } from 'react-router-dom'; // Untuk redirect dalam React

// Buat fungsi untuk membuat instance dengan navigate
const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    timeout: 900000, // 15 menit (900 detik)
    headers: {
      'Accept': 'application/json'
    }
  });

  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      console.log('Request Config:', {
        url: config.url,
        method: config.method,
        headers: config.headers,
        data: config.data
      });
      return config;
    },
    (error) => {
      console.error('Request Error:', error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      console.log('Response:', {
        status: response.status,
        headers: response.headers,
        data: response.data
      });
      return response;
    },
    (error) => {
      if (error.response) {
        console.error('Response error:', {
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data
        });

        // Handle 401 Unauthorized atau 403 Forbidden
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('userRole');
          alert('Sesi Anda telah habis atau akses ditolak. Silakan login kembali.');
          // Gunakan window.location untuk saat ini karena navigate membutuhkan context
          window.location.href = '/login';
        }
      } else if (error.request) {
        console.error('Request error:', error.request);
      } else {
        console.error('Error:', error.message);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance();