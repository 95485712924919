import React, { useState, useEffect } from 'react';
import './TrackingSettings.css';
import UserManager from './UserManager';
import ShotStatusManager from './ShotStatusManager';
import SequenceStatusManager from './SequenceStatusManager';
import AssetStatusManager from './AssetStatusManager';
import TaskStatusManager from './TaskStatusManager';
import HashtagManager from './HashtagManager';
import DepartmentManager from './DepartmentManager';
import DepartmentList from './DepartmentList';
import LevelManager from './LevelManager';
import LevelList from './LevelList';
import RoleManager from './RoleManager';
import RoleList from './RoleList';
import AssetTypeManager from './AssetTypeManager';

const TrackingSettings = () => {
  const [activeTab, setActiveTab] = useState('project');
  const [openDropdown, setOpenDropdown] = useState('');
  const [activeSubTab, setActiveSubTab] = useState('general');

  const tabs = [
    {
      name: 'Project',
      key: 'project',
      children: ['Sequence', 'Shots', 'Assets', 'Tasks']
    },
    {
      name: 'System',
      key: 'system',
      children: ['Pengaturan Hashtag', 'Users', 'Departments', 'Levels', 'Roles']
    }
  ];

  const toggleDropdown = (tabKey) => {
    setOpenDropdown(openDropdown === tabKey ? '' : tabKey);
  };

  const renderContent = (tab, subTab) => {
    if (tab === 'Project' && subTab === 'shots') {
      return (
        <div className="content">
          <h2>Shots Settings</h2>
          <div className="shots-tabs">
            <button className={activeSubTab === 'general' ? 'active' : ''} onClick={() => setActiveSubTab('general')}>General</button>
            <button className={activeSubTab === 'statuses' ? 'active' : ''} onClick={() => setActiveSubTab('statuses')}>Statuses</button>
          </div>
          {activeSubTab === 'general' && <h3>General Shots Settings</h3>}
          {activeSubTab === 'statuses' && <ShotStatusManager />}
        </div>
      );
    } else if (tab === 'Project' && subTab === 'assets') {
      return (
        <div className="content">
          <h2>Asset Settings</h2>
          <div className="shots-tabs">
            <button className={activeSubTab === 'general' ? 'active' : ''} onClick={() => setActiveSubTab('general')}>General</button>
            <button className={activeSubTab === 'types' ? 'active' : ''} onClick={() => setActiveSubTab('types')}>Types</button>
            <button className={activeSubTab === 'statuses' ? 'active' : ''} onClick={() => setActiveSubTab('statuses')}>Statuses</button>
          </div>
          {activeSubTab === 'general' && <h3>General Asset Settings</h3>}
          {activeSubTab === 'types' && <AssetTypeManager />}
          {activeSubTab === 'statuses' && <AssetStatusManager />}
        </div>
      );
    } else if (tab === 'Project' && subTab === 'sequence') {
      return (
        <div className="content">
          <h2>Sequence Settings</h2>
          <div className="shots-tabs">
            <button className={activeSubTab === 'general' ? 'active' : ''} onClick={() => setActiveSubTab('general')}>General</button>
            <button className={activeSubTab === 'statuses' ? 'active' : ''} onClick={() => setActiveSubTab('statuses')}>Statuses</button>
          </div>
          {activeSubTab === 'general' && <h3>General Sequence Settings</h3>}
          {activeSubTab === 'statuses' && <SequenceStatusManager />}
        </div>
      );
    } else if (tab === 'Project' && subTab === 'tasks') {
      return (
        <div className="content">
          <h2>Task Settings</h2>
          <div className="shots-tabs">
            <button 
              className={activeSubTab === 'general' ? 'active' : ''} 
              onClick={() => setActiveSubTab('general')}
            >
              General
            </button>
            <button 
              className={activeSubTab === 'statuses' ? 'active' : ''} 
              onClick={() => setActiveSubTab('statuses')}
            >
              Statuses
            </button>
          </div>
          {activeSubTab === 'general' && (
            <div className="general-settings">
              <h3>General Task Settings</h3>
            </div>
          )}
          {activeSubTab === 'statuses' && <TaskStatusManager />}
        </div>
      );
    } else if (tab === 'System') {
      switch(subTab) {
        case 'pengaturan hashtag':
          return (
            <div className="content">
              <h2>Pengaturan Hashtag</h2>
              <HashtagManager />
            </div>
          );
        case 'users':
          return (
            <div className="content">
              <h2>User Management</h2>
              <UserManager />
            </div>
          );
        case 'departments':
          return (
            <div className="content">
              <h2>Department Settings</h2>
              <div className="shots-tabs">
                <button 
                  className={activeSubTab === 'general' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('general')}
                >
                  General
                </button>
                <button 
                  className={activeSubTab === 'list' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('list')}
                >
                  Department List
                </button>
              </div>
              {activeSubTab === 'general' && <DepartmentManager />}
              {activeSubTab === 'list' && <DepartmentList />}
            </div>
          );
        case 'levels':
          return (
            <div className="content">
              <h2>Level Settings</h2>
              <div className="shots-tabs">
                <button 
                  className={activeSubTab === 'general' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('general')}
                >
                  General
                </button>
                <button 
                  className={activeSubTab === 'list' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('list')}
                >
                  Level List
                </button>
              </div>
              {activeSubTab === 'general' && <LevelManager />}
              {activeSubTab === 'list' && <LevelList />}
            </div>
          );
        case 'roles':
          return (
            <div className="content">
              <h2>Role Settings</h2>
              <div className="shots-tabs">
                <button 
                  className={activeSubTab === 'general' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('general')}
                >
                  General
                </button>
                <button 
                  className={activeSubTab === 'list' ? 'active' : ''} 
                  onClick={() => setActiveSubTab('list')}
                >
                  Role List
                </button>
              </div>
              {activeSubTab === 'general' && <RoleManager />}
              {activeSubTab === 'list' && <RoleList />}
            </div>
          );
        default:
          return null;
      }
    }
    return (
      <div className="content">
        <h2>{tab} Settings</h2>
        {subTab && <h3>{subTab}</h3>}
      </div>
    );
  };

  return (
    <div className="tracking-settings">
      <div className="sidebar">
        {tabs.map((tab) => (
          <div key={tab.key}>
            <button
              className={`tab ${activeTab === tab.key ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(tab.key);
                toggleDropdown(tab.key);
              }}
            >
              {tab.name}
            </button>
            {tab.children.length > 0 && (
              <div className={`dropdown ${openDropdown === tab.key ? 'open' : ''}`}>
                {tab.children.map((child) => (
                  <button
                    key={child}
                    className="sub-tab"
                    onClick={() => setActiveTab(`${tab.key}-${child.toLowerCase()}`)}
                  >
                    {child}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="main-content">
        {renderContent(
          tabs.find(tab => tab.key === activeTab.split('-')[0])?.name,
          activeTab.includes('-') ? activeTab.split('-')[1] : null
        )}
      </div>
    </div>
  );
};

export default TrackingSettings;
