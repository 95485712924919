import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';

const getFullThumbnailUrl = (thumbnailUrl) => {
  if (!thumbnailUrl) return '/assets/images/asset-placeholder.png';
  if (thumbnailUrl.startsWith('http')) return thumbnailUrl;
  if (thumbnailUrl.startsWith('data:')) return thumbnailUrl;
  const cleanUrl = thumbnailUrl.replace(/^\/+/, '');
  return `${API_BASE_URL}/${cleanUrl}`;
};

const AssetForm = ({ 
  onSubmit, 
  onClose, 
  isEditing = false, 
  initialAsset = null, 
  currentUser, 
  isDarkMode, 
  tasks = [],
  activeProject 
}) => {
  const [assetData, setAssetData] = useState({
    assetName: initialAsset?.assetName || '',
    description: initialAsset?.description || '',
    statusId: initialAsset?.statusId || '',
    projectId: activeProject?.id,
    selectedTasks: (initialAsset?.selectedTasks || []).map(taskId => {
      const task = tasks.find(t => t.id === taskId);
      return task ? { value: task.id, label: task.taskName } : null;
    }).filter(Boolean),
    typeId: initialAsset?.typeId || ''
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(
    initialAsset?.thumbnailUrl ? `${initialAsset.thumbnailUrl}` : null
  );
  const [statuses, setStatuses] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/asset-statuses');
        setStatuses(response.data);
        if (!assetData.statusId && response.data.length > 0) {
          const defaultStatus = response.data.find(s => s.isDefault) || response.data[0];
          setAssetData(prev => ({ ...prev, statusId: defaultStatus.id }));
        }
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    fetchStatuses();
  }, []);

  useEffect(() => {
    if (initialAsset?.thumbnailUrl) {
      setThumbnailPreview(initialAsset.thumbnailUrl);
    }
  }, [initialAsset]);

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const response = await axiosInstance.get('/api/asset-types');
        setAssetTypes(response.data);
      } catch (error) {
        console.error('Error mengambil tipe asset:', error);
      }
    };
    fetchAssetTypes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssetData(prev => ({ ...prev, [name]: value }));
  };

  const handleTasksChange = (selectedOptions) => {
    setAssetData(prev => ({ ...prev, selectedTasks: selectedOptions || [] }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!activeProject) {
      console.error('No active project selected');
      alert('Please select a project first');
      return;
    }

    if (!assetData.assetName || !assetData.statusId) {
      alert('Please fill in all required fields');
      return;
    }

    const formData = new FormData();
    formData.append('assetName', assetData.assetName);
    formData.append('description', assetData.description || '');
    formData.append('statusId', assetData.statusId.toString());
    formData.append('projectId', activeProject.id.toString());
    formData.append('typeId', assetData.typeId || '');
    
    if (thumbnail) {
      formData.append('thumbnail', thumbnail, thumbnail.name);
    } else if (initialAsset?.thumbnailUrl) {
      formData.append('thumbnailUrl', initialAsset.thumbnailUrl);
    }

    const selectedTaskIds = assetData.selectedTasks.map(task => task.value);
    formData.append('selectedTasks', JSON.stringify(selectedTaskIds));

    try {
      let response;
      if (isEditing && initialAsset) {
        response = await axiosInstance.put(`/api/assets/${initialAsset.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await onSubmit(formData);
      }
      
      console.log('Asset submission response:', {
        thumbnailUrl: response?.data?.thumbnailUrl || response?.thumbnailUrl || 'No thumbnail URL',
        thumbnailFile: thumbnail ? thumbnail.name : 'No new thumbnail'
      });

      onClose();
    } catch (error) {
      console.error('Error submitting asset:', error);
      
      if (error.response) {
        console.error('Server response:', error.response.data);
        console.error('Server status:', error.response.status);
      }
      
      alert(`Failed to submit asset: ${error.response?.data?.message || error.message || 'Unknown error'}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`form-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="form-header">
        <h2>{isEditing ? 'Edit Asset' : 'Add New Asset'}</h2>
        <button type="button" className="close-button" onClick={onClose}>×</button>
      </div>

      <div className="form-body">
        <div className="form-group">
          <label>Asset Name *</label>
          <input
            type="text"
            name="assetName"
            value={assetData.assetName}
            onChange={handleInputChange}
            required
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            value={assetData.description}
            onChange={handleInputChange}
            className="form-control"
            rows="3"
          />
        </div>

        <div className="form-group">
          <label>Status *</label>
          <select
            name="statusId"
            value={assetData.statusId}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            <option value="">Select Status</option>
            {statuses.map(status => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Type *</label>
          <select
            name="typeId"
            value={assetData.typeId}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            <option value="">Select Type</option>
            {assetTypes.map(type => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Related Tasks</label>
          <Select
            isMulti
            value={assetData.selectedTasks}
            onChange={handleTasksChange}
            options={tasks.map(task => ({
              value: task.id,
              label: task.taskName
            }))}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>

        <div className="form-group">
          <label>Thumbnail</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleThumbnailChange}
            className="form-control"
          />
          {thumbnailPreview && (
            <div className="thumbnail-preview">
              <img 
                src={getFullThumbnailUrl(thumbnailPreview)} 
                alt="Thumbnail Preview" 
                className="preview-image" 
              />
              <button 
                type="button" 
                className="remove-thumbnail-btn" 
                onClick={() => {
                  setThumbnailPreview(null);
                  setThumbnail(null);
                }}
              >
                Remove Thumbnail
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="form-footer">
        <button type="button" onClick={onClose} className="btn btn-secondary">
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          {isEditing ? 'Update Asset' : 'Create Asset'}
        </button>
      </div>
    </form>
  );
};

export default AssetForm;