import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import './ProjectDetail.css';

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/projects/${id}`);
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        setError('Gagal mengambil detail proyek');
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!project) return <div>Proyek tidak ditemukan</div>;

  return (
    <div className="project-detail">
      <h2>{project.name}</h2>
      {project.thumbnail && (
        <img src={project.thumbnail} alt={project.name} className="project-thumbnail" />
      )}
      <p><strong>Deskripsi:</strong> {project.description}</p>
      <p><strong>Tanggal Mulai:</strong> {project.startDate}</p>
      <p><strong>Tanggal Selesai:</strong> {project.endDate}</p>
      <p><strong>Penanggung Jawab:</strong> {project.responsiblePerson}</p>
    </div>
  );
};

export default ProjectDetail;