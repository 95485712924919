import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import ShotList from './ShotList';
import ShotCardView from './ShotCardView';
import ShotOverview from './ShotOverview';
import ActivityPanel from './ActivityPanel'; 
import { FaThLarge, FaList } from 'react-icons/fa';
import '../styles/global.css';

const Shot = ({ 
  activeProject, 
  userRole, 
  shots = [], 
  setShots, 
  sequences = [], 
  setSequences, 
  assets = [], 
  setAssets, 
  isDarkMode, 
  currentUser, 
  tasks = [], 
  setTasks, 
  setSelectedShot 
}) => {
  const navigate = useNavigate();
  console.log('Shot component props:', { activeProject, currentUser });
  const [editingShot, setEditingShot] = useState(null);
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [selectedShotState, setSelectedShotState] = useState(null);
  const [isActivityPanelOpen, setIsActivityPanelOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    status: null,
    assignee: null,
    start_date: null,
    dueDate: null,
    progress: null
  });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedShotDetails, setSelectedShotDetails] = useState(null);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_shots', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const fetchShots = async (projectId) => {
    try {
      const response = await axiosInstance.get(`/api/shots/${projectId}`);
      setShots(response.data);
    } catch (error) {
      console.error('Error fetching shots:', error);
    }
  };

  const fetchShotStatuses = async () => {
    try {
      const response = await axiosInstance.get('/api/shot-statuses');
      setStatuses(response.data);
    } catch (error) {
      console.error('Error fetching shot statuses:', error);
    }
  };

  const refreshSequences = async () => {
    try {
      if (activeProject?.id) {
        const response = await axiosInstance.get(`/api/sequences/${activeProject.id}`);
        setSequences(response.data);
      }
    } catch (error) {
      console.error('Error refreshing sequences:', error);
    }
  };

  const fetchShotDetails = async (shotId) => {
    try {
      const response = await axiosInstance.get(`/shots/detail/${shotId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching shot details:', error);
      return null;
    }
  };

  const handleShowShotDetails = async (shotId) => {
    const details = await fetchShotDetails(shotId);
    setSelectedShotDetails(details);
    // Tambahkan logika untuk membuka modal atau panel detail shot
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found');
      return;
    }

    const newSocket = io(WS_BASE_URL, {
      auth: { token },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });

    newSocket.on('connect', () => {
      console.log('Socket connected successfully');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusesRes, usersRes] = await Promise.all([
          axiosInstance.get('/api/task-statuses'),
          axiosInstance.get('/api/users')
        ]);

        setStatuses(statusesRes.data);
        setUsers(usersRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('shotUpdated', async (updatedShot) => {
        setShots(prevShots => 
          prevShots.map(shot => 
            shot.id === updatedShot.id ? updatedShot : shot
          )
        );
        await refreshSequences();
      });

      socket.on('shotDeleted', async (deletedShotId) => {
        setShots(prevShots => 
          prevShots.filter(shot => shot.id !== deletedShotId)
        );
        await refreshSequences();
      });

      socket.on('shotCreated', async (newShot) => {
        setShots(prevShots => [...prevShots, newShot]);
        await refreshSequences();
      });
    }
  }, [socket, setShots, activeProject?.id, setSequences]);

  useEffect(() => {
    if (activeProject?.id) {
      fetchShots(activeProject.id);
      fetchShotStatuses();
    }
  }, [activeProject]);

  const handleDeleteShot = async (id) => {
    try {
      await axiosInstance.delete(`/api/shots/${id}`);
      setShots(shots.filter(shot => shot.id !== id));
      socket.emit('deleteShot', id);
      await refreshSequences();
    } catch (error) {
      console.error('Error deleting shot:', error);
    }
  };

  const handleEditShot = async (id, updatedData) => {
    try {
      if (!userRole) {
        throw new Error('User role tidak tersedia');
      }

      const oldShot = shots.find(s => s.id === id);
      if (!oldShot) {
        throw new Error('Shot tidak ditemukan');
      }

      let shotData;
      if (updatedData instanceof FormData) {
        shotData = {
          shotName: updatedData.get('shotName'),
          description: updatedData.get('description'),
          statusId: updatedData.get('statusId'),
          dueDate: updatedData.get('dueDate'),
          sequenceId: updatedData.get('sequenceId'),
          selectedAssets: JSON.parse(updatedData.get('selectedAssets') || '[]'),
          selectedTasks: JSON.parse(updatedData.get('selectedTasks') || '[]')
        };
      } else {
        shotData = updatedData;
      }

      const dataToSend = {
        shotName: shotData.shotName || oldShot.shotName,
        description: shotData.description || oldShot.description,
        statusId: shotData.statusId || oldShot.statusId,
        dueDate: shotData.dueDate !== undefined ? shotData.dueDate : oldShot.dueDate,
        sequenceId: shotData.sequenceId !== undefined ? shotData.sequenceId : oldShot.sequenceId,
        selectedAssets: shotData.selectedAssets || oldShot.selectedAssets || [],
        selectedTasks: shotData.selectedTasks || oldShot.selectedTasks || []
      };

      const response = await axiosInstance.put(`/api/shots/${id}`, dataToSend);

      if (response.data) {
        socket.emit('updateShot', response.data);

        // Ambil username dari localStorage atau currentUser
        const username = localStorage.getItem('username') || currentUser || 'unknown';

        await axiosInstance.post('/api/activities', {
          shotId: id,
          user: username,
          action: 'edit_shot',
          details: `Shot "${dataToSend.shotName}" telah diubah`,
          data: {
            before: {
              shotName: oldShot.shotName,
              description: oldShot.description,
              statusId: oldShot.statusId,
              dueDate: oldShot.dueDate,
              sequenceId: oldShot.sequenceId,
              selectedAssets: oldShot.selectedAssets || [],
              selectedTasks: oldShot.selectedTasks || []
            },
            after: dataToSend
          },
          timestamp: new Date().toISOString()
        });

        // Update local state
        setShots(prevShots => 
          prevShots.map(shot => 
            shot.id === id ? response.data : shot
          )
        );

        // Update sequences with the new shot data
        if (oldShot.sequenceId !== shotData.sequenceId) {
          setSequences(prevSequences => 
            prevSequences.map(seq => {
              if (seq.id === oldShot.sequenceId) {
                // Remove shot from old sequence
                return {
                  ...seq,
                  shots: seq.shots.filter(s => s.id !== id)
                };
              }
              if (seq.id === shotData.sequenceId) {
                // Add shot to new sequence
                return {
                  ...seq,
                  shots: [...seq.shots, response.data]
                };
              }
              return seq;
            })
          );
        } else {
          // Update shot in current sequence
          setSequences(prevSequences => 
            prevSequences.map(seq => {
              if (seq.id === shotData.sequenceId) {
                return {
                  ...seq,
                  shots: seq.shots.map(s => 
                    s.id === id ? response.data : s
                  )
                };
              }
              return seq;
            })
          );
        }
      }
    } catch (error) {
      console.error('Error updating shot:', error);
      throw error;
    }
  };

  const handleAddShot = async (formData) => {
    try {
      if (!userRole) {
        throw new Error('User role tidak tersedia');
      }

      let shotData;
      if (formData instanceof FormData) {
        shotData = {
          shotName: formData.get('shotName'),
          description: formData.get('description'),
          statusId: formData.get('statusId'),
          dueDate: formData.get('dueDate'),
          selectedSequences: JSON.parse(formData.get('selectedSequences') || '[]'),
          selectedAssets: JSON.parse(formData.get('selectedAssets') || '[]'),
          selectedTasks: JSON.parse(formData.get('selectedTasks') || '[]')
        };
      } else {
        shotData = formData;
      }

      const response = await axiosInstance.post('/api/shots', shotData);
      const newShot = response.data;

      setShots([...shots, newShot]);
      socket.emit('addShot', newShot);

      // Tambahkan aktivitas saat membuat shot
      const username = localStorage.getItem('username') || currentUser || 'unknown';
      await axiosInstance.post('/api/activities', {
        shotId: newShot.id,
        user: username,
        action: 'create_shot',
        details: `Shot "${shotData.shotName}" telah dibuat`,
        data: {
          shot: newShot
        },
        timestamp: new Date().toISOString()
      });

      await refreshSequences();
      return newShot;
    } catch (error) {
      console.error('Error adding shot:', error);
      throw error;
    }
  };

  const handleEditClick = (shot) => {
    setEditingShot(shot);
    setIsFormVisible(true);
  };

  const handleShotSelect = (shot) => {
    setSelectedShotState(shot);
    setIsActivityPanelOpen(true);
  };

  const filteredShots = activeProject
    ? shots.filter(shot => shot.projectId === activeProject.id)
    : [];

  return (
    <div className={`app-container ${isDarkMode ? 'dark' : ''} ${isActivityPanelOpen ? 'panel-open' : ''}`}>
      <div className="content-container">
        <div className="global-header">
          <h2>Shot Manager</h2>
          <div className="view-toggle">
            <button 
              className={`toggle-btn ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => setViewMode('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>

        {!activeProject ? (
          <div className="alert alert-warning" style={{ padding: '1rem', margin: '1rem', borderRadius: '4px', backgroundColor: '#fff3cd', color: '#856404', border: '1px solid #ffeeba' }}>
            <p style={{ margin: 0 }}>Please select a project first to manage shots.</p>
          </div>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                viewMode === 'list' ? (
                  <ShotList
                    shots={shots}
                    sequences={sequences}
                    assets={assets}
                    tasks={tasks}
                    onDeleteShot={handleDeleteShot}
                    onEditShot={handleEditShot}
                    onAddShot={handleAddShot}
                    userRole={userRole}
                    isDarkMode={isDarkMode}
                    activeProject={activeProject}
                    socket={socket}
                    isActivityPanelOpen={isActivityPanelOpen}
                    setIsActivityPanelOpen={setIsActivityPanelOpen}
                    selectedShot={selectedShotState}
                    setSelectedShot={setSelectedShotState}
                    currentUser={currentUser}
                    setSequences={setSequences}
                    setShots={setShots}
                    statuses={statuses}
                  />
                ) : (
                  <ShotCardView
                    shots={filteredShots}
                    tasks={tasks}
                    assets={assets}
                    onDeleteShot={handleDeleteShot}
                    onEditShot={handleEditShot}
                    userRole={userRole}
                    isDarkMode={isDarkMode}
                    activeProject={activeProject}
                    isActivityPanelOpen={isActivityPanelOpen}
                    setIsActivityPanelOpen={setIsActivityPanelOpen}
                    selectedShot={selectedShotState}
                    setSelectedShot={setSelectedShotState}
                    onShotSelect={handleShotSelect}
                    hasManagePermission={hasManagePermission(userRole)}
                    statuses={statuses}
                    users={users}
                    setShots={setShots}
                    setSequences={setSequences}
                  />
                )
              }
            />
            <Route
              path="/overview/:id"
              element={
                <ShotOverview
                  shots={shots}
                  activeProject={activeProject}
                  currentUser={currentUser}
                  userRole={userRole}
                  tasks={tasks}
                  assets={assets}
                  isDarkMode={isDarkMode}
                />
              }
            />
          </Routes>
        )}
      </div>
    </div>
  );
};

export default Shot;