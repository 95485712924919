import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
const RoleList = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get('/api/roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Error mengambil data role:', error);
    }
  };

  return (
    <div className="role-list-container">
      <table className="role-table">
        <thead>
          <tr>
            <th>Nama Role</th>
            <th>Deskripsi</th>
            <th>Permissions</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {roles.map(role => (
            <tr key={role.id}>
              <td>{role.name}</td>
              <td>{role.description}</td>
              <td>
                <div className="permission-tags">
                  {role.permissions.map(permission => (
                    <span key={permission} className="permission-tag">
                      {permission}
                    </span>
                  ))}
                </div>
              </td>
              <td>
                <span className={`status-badge ${role.isActive ? 'active' : 'inactive'}`}>
                  {role.isActive ? 'Aktif' : 'Tidak Aktif'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoleList; 