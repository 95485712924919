import React, { useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/globalform.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import ActivityPanel from './ActivityPanel';
import axiosInstance from '../config/axios';
import { API_BASE_URL } from '../config/config';
import { FaEdit, FaTrash, FaEye, FaFilter, FaPlus, FaList, FaThLarge } from 'react-icons/fa';
import AssetForm from './AssetForm';

const AssetList = ({ 
  assets = [], 
  shots = [], 
  tasks = [], 
  onDeleteAsset, 
  onEditAsset, 
  userRole,
  isDarkMode,
  activeProject,
  socket,
  isActivityPanelOpen,
  setIsActivityPanelOpen,
  selectedAsset,
  setSelectedAsset
}) => {
  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    relatedShot: null,
    relatedTask: null
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/asset-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };
    fetchStatuses();
  }, []);

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const response = await axiosInstance.get('/api/asset-types');
        setAssetTypes(response.data);
      } catch (error) {
        console.error('Error fetching asset types:', error);
      }
    };
    fetchAssetTypes();
  }, []);

  useEffect(() => {
    if (shots && shots.length > 0) {
      console.log('Shots data received:', shots);
    }
  }, [shots]);

  useEffect(() => {
    if (socket) {
      socket.on('shotUpdated', async (updatedShot) => {
        console.log('Socket: Shot updated', updatedShot);
        
        try {
          // Fetch the latest asset data from the server
          const response = await axiosInstance.get('/api/assets/project/' + activeProject.id, {
            params: {
              include: ['assetType', 'assetStatus']
            }
          });
          const updatedAssets = response.data;
          
          // Update the assets state with the new data
          if (typeof onEditAsset === 'function') {
            updatedAssets.forEach(asset => {
              onEditAsset(asset);
            });
          }
        } catch (error) {
          console.error('Error fetching updated assets:', error);
        }
      });

      return () => {
        socket.off('shotUpdated');
      };
    }
  }, [socket, activeProject, onEditAsset]);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_assets', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleEditClick = (asset) => {
    setEditingId(asset.id);
    setEditFormData(asset);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      console.group('Asset Form Submission');
      console.log('Submitting asset form data:');
      
      // Log form data contents
      for (let [key, value] of formData.entries()) {
        if (key === 'thumbnail') {
          console.log(`${key}: File - ${value.name}, Size: ${value.size} bytes, Type: ${value.type}`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }

      let response;
      if (editingId) {
        response = await axiosInstance.put(`/api/assets/${editingId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Asset updated successfully:', response.data);
        if (response.data) {
          socket.emit('updateAsset', response.data);
          onEditAsset(response.data);
        }
      } else {
        response = await axiosInstance.post('/api/assets', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Asset created successfully:', response.data);
        if (response.data) {
          socket.emit('createAsset', response.data);
        }
      }
      
      // Log thumbnail details from response
      if (response.data.thumbnailUrl) {
        console.log('Thumbnail URL:', response.data.thumbnailUrl);
      } else {
        console.warn('No thumbnail URL in server response');
      }

      handleFormClose();

      console.groupEnd();

      // Return the entire response
      return response;
    } catch (error) {
      console.groupEnd();
      console.error('Error submitting asset:', error);
      
      // More detailed error logging
      if (error.response) {
        console.error('Server error response:', error.response.data);
        console.error('Server error status:', error.response.status);
      }
      
      alert(`Failed to create asset: ${error.message}`);
      
      // Rethrow the error to be caught by the caller
      throw error;
    }
  };

  const handleAssetClick = async (asset) => {
    let isMounted = true;
    
    try {
      const response = await axiosInstance.get(`/activities/assets/${asset.id}`);
      const activities = response.data;
      
      if (isMounted) {
        const updatedAsset = {
          ...asset,
          activities: activities
        };
        
        setSelectedAsset(updatedAsset);
        setIsActivityPanelOpen(true);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      if (isMounted) {
        setSelectedAsset(asset);
        setIsActivityPanelOpen(true);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const handleOverviewClick = (assetId) => {
    navigate(`/asset-overview/${assetId}`);
  };

  const handleDeleteClick = async (asset) => {
    if (window.confirm('Are you sure you want to delete this asset?')) {
      try {
        await axiosInstance.delete(`/api/assets/${asset.id}`);
        onDeleteAsset(asset.id);
        socket.emit('deleteAsset', asset.id);
      } catch (error) {
        console.error('Error deleting asset:', error);
        alert('Failed to delete asset. Please try again.');
      }
    }
  };

  // Helper function to get full thumbnail URL
  const getFullThumbnailUrl = (thumbnailUrl) => {
    // Jika tidak ada thumbnail, kembalikan placeholder
    if (!thumbnailUrl) return '/assets/images/asset-placeholder.png';
    
    // Hapus duplikasi path '/uploads/thumbnails/'
    let cleanUrl = thumbnailUrl.replace(/^\/uploads\/thumbnails\/\/uploads\/thumbnails\//, '/uploads/thumbnails/');
    
    // Hapus duplikasi path di awal
    cleanUrl = cleanUrl.replace(/^\/+/, '/');
    
    // Log untuk debugging
    console.log('Original thumbnailUrl:', thumbnailUrl);
    console.log('Cleaned thumbnailUrl:', cleanUrl);
    
    // Jika sudah URL lengkap, kembalikan sebagaimana adanya
    if (cleanUrl.startsWith('http')) return cleanUrl;
    
    // Tambahkan base URL jika diperlukan
    const finalUrl = cleanUrl.startsWith('/') 
      ? `${API_BASE_URL}${cleanUrl}` 
      : `${API_BASE_URL}/${cleanUrl}`;
    
    console.log('Final thumbnailUrl:', finalUrl);
    return finalUrl;
  };

  // Helper function to get thumbnail or placeholder
  const getAssetThumbnail = (asset) => {
    return asset.thumbnailUrl 
      ? getFullThumbnailUrl(asset.thumbnailUrl)
      : '/assets/images/asset-placeholder.png';
  };

  const getStatusBadge = (statusId) => {
    if (!statusId) return (
      <span className="status-badge pending">
        Pending
      </span>
    );
    
    const status = statuses.find(s => s.id === parseInt(statusId));
    if (!status) return (
      <span className="status-badge unknown">
        Unknown
      </span>
    );

    const statusClass = status.name.toLowerCase().replace(/\s+/g, '-');
    return (
      <span className={`status-badge ${statusClass}`}>
        {status.name}
      </span>
    );
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      name: '',
      relatedShot: null,
      relatedTask: null
    });
  };

  const filteredAssets = assets.filter(asset => {
    if (filters.status && asset.statusId !== filters.status.value) return false;
    if (filters.name && !asset.assetName.toLowerCase().includes(filters.name.toLowerCase())) return false;
    
    if (filters.relatedShot) {
      const hasRelatedShot = shots.some(shot => 
        shot.id === filters.relatedShot.value && 
        shot.selectedAssets?.includes(asset.id)
      );
      if (!hasRelatedShot) return false;
    }
    
    if (filters.relatedTask) {
      // Cek semua kemungkinan relasi task
      const hasRelatedTask = 
        // Task langsung terkait dengan asset
        asset.selectedTasks?.includes(filters.relatedTask.value) ||
        // Task terkait melalui shot yang memiliki asset ini
        shots.some(shot => 
          shot.selectedAssets?.includes(asset.id) && 
          shot.selectedTasks?.includes(filters.relatedTask.value)
        ) ||
        // Task yang memiliki asset ini sebagai selectedAsset
        tasks.some(task => 
          task.id === filters.relatedTask.value && 
          task.selectedAssets?.includes(asset.id)
        );
        
      if (!hasRelatedTask) return false;
    }
    
    if (searchTerm && !asset.assetName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  return (
    <div className={`list-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>List View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={() => {
                setEditingId(null);
                setEditFormData(null);
                setIsFormVisible(true);
              }}
            >
              <FaPlus /> Add Asset
            </button>
          )}
          <div className="view-toggle">
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="List View"
            >
              <FaList />
            </button>
            <button
              className={`toggle-button`}
              onClick={() => {}}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="filters-content">
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={filters.status}
                onChange={(value) => handleFilterChange('status', value)}
                options={statuses.map(status => ({
                  value: status.id,
                  label: status.name
                }))}
                isClearable
                placeholder="Filter by status"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Name</label>
              <input
                type="text"
                value={filters.name}
                onChange={(e) => handleFilterChange('name', e.target.value)}
                placeholder="Filter by name"
                className="form-control"
              />
            </div>

            <div className="filter-group">
              <label>Related Shot</label>
              <Select
                value={filters.relatedShot}
                onChange={(value) => handleFilterChange('relatedShot', value)}
                options={shots.map(shot => ({
                  value: shot.id,
                  label: shot.shotName
                }))}
                isClearable
                placeholder="Filter by related shot"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className="filter-group">
              <label>Related Task</label>
              <Select
                value={filters.relatedTask}
                onChange={(value) => handleFilterChange('relatedTask', value)}
                options={tasks.map(task => ({
                  value: task.id,
                  label: task.taskName
                }))}
                isClearable
                placeholder="Filter by related task"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <button 
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      )}

      <div className="card mt-4">
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Thumbnail</th>
              <th>Name</th>
              <th>Status</th>
              <th>Description</th>
              <th>Type</th>
              <th>Related Shot</th>
              <th>Related Tasks</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAssets.map((asset, index) => (
              <tr 
                key={`${asset.id}-${index}`} 
                className={`asset-item ${selectedAsset?.id === asset.id && isActivityPanelOpen ? 'list-item-with-open-panel' : ''}`}
                onClick={() => handleAssetClick(asset)}
              >
                <td className="asset-thumbnail-cell">
                  <img 
                    src={getAssetThumbnail(asset)} 
                    alt={`${asset.assetName} thumbnail`} 
                    className="asset-thumbnail" 
                  />
                </td>
                <td>{asset.assetName}</td>
                <td>{getStatusBadge(asset.statusId)}</td>
                <td>{asset.description}</td>
                <td>
                  {assetTypes.find(type => type.id === asset.typeId)?.name || '-'}
                </td>
                <td>
                  <div className="related-shots-container">
                    {shots.filter(shot => shot.selectedAssets?.includes(asset.id)).map(shot => (
                      <div key={shot.id} className="shot-badge-container">
                        <span className={`shot-badge status-${shot.statusId}`}>
                          {shot.shotName}
                        </span>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="related-tasks-container">
                    {tasks.filter(task => {
                      // Cek apakah task terkait dengan asset ini langsung
                      const isDirectlyRelated = asset.selectedTasks?.includes(task.id);
                      
                      return isDirectlyRelated;
                    }).map(task => (
                      <div key={task.id} className="task-badge-container">
                        <span className={`task-badge ${task.statusId ? `status-${task.statusId}` : 'status-pending'}`}>
                          <div className="task-badge-content">
                            <span className="task-name">{task.taskName}</span>
                          </div>
                          {task.assignee && (
                            <div className="task-stats">
                              <span className="assignee-count" title={`Assigned to: ${task.assignee.username}`}>
                                {task.assignee.username.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="asset-actions">
                    {hasManagePermission(userRole) && (
                      <>
                        <button 
                          className="edit-button" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(asset);
                          }}
                        >
                          <FaEdit />
                        </button>
                        <button 
                          className="delete-button" 
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteAsset(asset);
                          }}
                        >
                          <FaTrash />
                        </button>
                      </>
                    )}
                    <button 
                      className="overview-button" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOverviewClick(asset.id);
                      }}
                    >
                      <FaEye />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      {isFormVisible && (
        <div className="modal-overlay">
          <div className="modal-form">
            <AssetForm
              onSubmit={handleFormSubmit}
              onClose={handleFormClose}
              shots={shots}
              tasks={tasks}
              isEditing={!!editingId}
              initialAsset={editFormData}
              currentUser={userRole}
              isDarkMode={isDarkMode}
              activeProject={activeProject}
            />
          </div>
        </div>
      )}

{selectedAsset?.id && isActivityPanelOpen && (
          <ActivityPanel
            item={selectedAsset}
            type="asset"
            isOpen={isActivityPanelOpen}
            onClose={() => {
              setSelectedAsset(null);
              setIsActivityPanelOpen(false);
            }}
            onAddComment={() => {
              if (activeProject?.id) {
                // fetchAssets();
              }
            }}
            onEditComment={() => {
              if (activeProject?.id) {
                // fetchAssets();
              }
            }}
            onDeleteComment={() => {
              if (activeProject?.id) {
                // fetchAssets();
              }
            }}
            onPinComment={() => {
              if (activeProject?.id) {
                // fetchAssets();
              }
            }}
            currentUser={userRole?.username}
            isDarkMode={isDarkMode}
          />
        )}
    </div>
  );
};

export default AssetList;
