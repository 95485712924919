import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { io } from 'socket.io-client';
import { WS_BASE_URL } from '../config/config';
import { API_BASE_URL } from '../config/config';
import SequenceList from './SequenceList';
import SequenceForm from './SequenceForm';
import SequenceCardView from './SequenceCardView';
import ActivityPanel from './ActivityPanel';
import { FaThLarge, FaList } from 'react-icons/fa';
import '../styles/global.css';

const Sequence = ({ activeProject, userRole, sequences = [], setSequences, shots = [], setShots, assets = [], setAssets, isDarkMode, currentUser, tasks = [], setTasks, setSelectedSequence }) => {
  const navigate = useNavigate();
  const [editingSequence, setEditingSequence] = useState(null);
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [selectedSequenceState, setSelectedSequenceState] = useState(null);
  const [isActivityPanelOpen, setIsActivityPanelOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    status: null,
    assignee: null,
    startDate: null,
    dueDate: null,
    progress: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusesRes, usersRes] = await Promise.all([
          axiosInstance.get('/api/task-statuses'),
          axiosInstance.get('/api/users')
        ]);

        setStatuses(statusesRes.data);
        setUsers(usersRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const hasManagePermission = (userRole) => {
    const allowedPermissions = ['manage_sequences', 'create_project'];
    return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
  };

  const handleSequenceSelect = (sequence) => {
    setSelectedSequenceState(sequence);
    setIsActivityPanelOpen(true);
  };

  useEffect(() => {
    const newSocket = io(WS_BASE_URL);
    setSocket(newSocket);

    if (newSocket) {
      newSocket.on('sequenceUpdated', (updatedSequence) => {
        setSequences(prevSequences => 
          prevSequences.map(sequence => 
            sequence.id === updatedSequence.id ? updatedSequence : sequence
          )
        );
      });

      newSocket.on('sequenceDeleted', (deletedSequenceId) => {
        setSequences(prevSequences => 
          prevSequences.filter(sequence => sequence.id !== deletedSequenceId)
        );
      });

      newSocket.on('sequenceCreated', (newSequence) => {
        setSequences(prevSequences => [...prevSequences, newSequence]);
      });
    }

    return () => newSocket.close();
  }, [setSequences]);

  const handleAddSequence = async (data) => {
    try {
      const response = await axiosInstance.post('/api/sequences', {
        ...data,
        projectId: activeProject.id
      });

      if (response.data) {
        socket.emit('createSequence', response.data);

        const username = typeof userRole === 'object' ? 
          userRole.username || 'unknown' : 
          userRole || 'unknown';

        await axiosInstance.post('/api/activities', {
          sequenceId: response.data.id,
          user: username,
          action: 'create_sequence',
          details: `Sequence "${data.sequenceName}" telah dibuat`,
          data: {
            sequenceName: data.sequenceName,
            description: data.description,
            statusId: data.statusId,
            selectedShots: data.selectedShots
          },
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error creating sequence:', error);
      alert('Gagal membuat sequence. Silakan coba lagi.');
    }
  };

  const handleEditClick = (sequence) => {
    setEditingSequence(sequence);
    setIsEditFormVisible(true);
  };

  const handleDeleteSequence = async (sequenceId) => {
    try {
      await axiosInstance.delete(`/api/sequences/${sequenceId}`);
      socket.emit('deleteSequence', sequenceId);
    } catch (error) {
      console.error('Error deleting sequence:', error);
      alert('Failed to delete sequence. Please try again.');
    }
  };

  const handleSequenceClick = (sequence) => {
    setSelectedSequenceState(sequence);
    setIsActivityPanelOpen(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleEditSequence = async (id, updatedFields) => {
    try {
      if (!userRole) {
        throw new Error('User role tidak tersedia');
      }

      const oldSequence = sequences.find(seq => seq.id === id);
      
      const dataToUpdate = {
        sequenceName: updatedFields.sequenceName,
        description: updatedFields.description,
        statusId: parseInt(updatedFields.statusId),
        projectId: activeProject.id,
        selectedShots: Array.isArray(updatedFields.selectedShots) 
          ? updatedFields.selectedShots 
          : []
      };

      const response = await axiosInstance.put(`/api/sequences/${id}`, dataToUpdate);
      
      if (response.data) {
        socket.emit('updateSequence', response.data);

        // Ambil username dari localStorage atau currentUser
        const username = localStorage.getItem('username') || currentUser || 'unknown';

        await axiosInstance.post('/api/activities', {
          sequenceId: id,
          user: username,
          action: 'edit_sequence',
          details: `Sequence "${updatedFields.sequenceName}" telah diubah`,
          data: {
            before: {
              sequenceName: oldSequence.sequenceName,
              description: oldSequence.description,
              statusId: oldSequence.statusId,
              selectedShots: oldSequence.selectedShots || []
            },
            after: {
              sequenceName: dataToUpdate.sequenceName,
              description: dataToUpdate.description,
              statusId: dataToUpdate.statusId,
              selectedShots: dataToUpdate.selectedShots
            }
          },
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating sequence:', error);
      alert('Gagal mengupdate sequence. Silakan coba lagi.');
    }
  };

  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  return (
    <div className={`app-container ${isDarkMode ? 'dark' : ''} ${isActivityPanelOpen ? 'panel-open' : ''}`}>
      <div className="content-container">
        <div className="global-header">
          <h2>Sequence Manager</h2>
          <div className="view-toggle">
            <button 
              className={`toggle-btn ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
              title="List View"
            >
              <FaList />
            </button>
            <button 
              className={`toggle-btn ${viewMode === 'card' ? 'active' : ''}`}
              onClick={() => setViewMode('card')}
              title="Card View"
            >
              <FaThLarge />
            </button>
          </div>
        </div>

        {isEditFormVisible && (
          <SequenceForm
            onAddSequence={(data) => {
              handleEditSequence(editingSequence.id, data);
              setIsEditFormVisible(false);
            }}
            onClose={() => {
              setIsEditFormVisible(false);
              setEditingSequence(null);
            }}
            shots={shots}
            isEditing={true}
            initialSequence={editingSequence}
            currentUser={userRole?.username}
            isDarkMode={isDarkMode}
          />
        )}

        {viewMode === 'list' ? (
          <SequenceList
            sequences={sequences}
            tasks={tasks}
            shots={shots}
            onDeleteSequence={handleDeleteSequence}
            onEditSequence={handleEditSequence}
            userRole={userRole}
            users={users}
            statuses={statuses}
            isDarkMode={isDarkMode}
            activeProject={activeProject}
            isActivityPanelOpen={isActivityPanelOpen}
            setIsActivityPanelOpen={setIsActivityPanelOpen}
            selectedSequence={selectedSequenceState}
            setSelectedSequence={setSelectedSequenceState}
            socket={socket}
          />
        ) : (
          <SequenceCardView
            sequences={sequences}
            tasks={tasks}
            shots={shots}
            onDeleteSequence={handleDeleteSequence}
            handleEditClick={(sequence) => {
              setEditingSequence(sequence);
              setIsEditFormVisible(true);
            }}
            onEditSequence={handleEditSequence}
            userRole={userRole}
            isDarkMode={isDarkMode}
            activeProject={activeProject}
            isActivityPanelOpen={isActivityPanelOpen}
            setIsActivityPanelOpen={setIsActivityPanelOpen}
            onSequenceSelect={setSelectedSequenceState}
            selectedSequence={selectedSequenceState}
            setSelectedSequence={setSelectedSequenceState}
            statuses={statuses}
            users={users}
            hasManagePermission={hasManagePermission(userRole)}
            onViewModeChange={setViewMode}
            viewMode={viewMode}
          />
        )}
      </div>
    </div>
  );
};

export default Sequence;
