import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';

const SequenceForm = ({ 
  onSubmit, 
  onClose, 
  isEditing = false,
  initialSequence = null,
  currentUser,
  isDarkMode 
}) => {
  const [formData, setFormData] = useState({
    sequenceName: initialSequence?.sequenceName || '',
    description: initialSequence?.description || '',
    statusId: initialSequence?.statusId || ''
  });

  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/sequence-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };
    fetchStatuses();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const submitData = {
        sequenceName: formData.sequenceName,
        description: formData.description,
        statusId: parseInt(formData.statusId)
      };

      await onSubmit(submitData);
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h2 className="text-xl font-semibold mb-4">
        {isEditing ? 'Edit Sequence' : 'Add New Sequence'}
      </h2>

      <div className="form-group">
        <label htmlFor="sequenceName">Sequence Name</label>
        <input
          type="text"
          id="sequenceName"
          className="form-control"
          value={formData.sequenceName}
          onChange={(e) => setFormData({ ...formData, sequenceName: e.target.value })}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          className="form-control"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          rows={3}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="status">Status</label>
        <Select
          id="status"
          value={statuses.find(s => s.id === parseInt(formData.statusId))}
          onChange={(selected) => setFormData({ ...formData, statusId: selected.id })}
          options={statuses}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          className="select-container"
          classNamePrefix="select"
        />
      </div>

      <div className="button-group">
        <button
          type="button"
          onClick={onClose}
          className="form-btn form-btn-secondary"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="form-btn form-btn-primary"
          disabled={loading}
        >
          {loading ? 'Saving...' : (isEditing ? 'Save Changes' : 'Create Sequence')}
        </button>
      </div>
    </form>
  );
};

export default SequenceForm;