import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { WS_BASE_URL, API_BASE_URL } from '../config/config';
import '../styles/messenger.css';
import './Messenger.css';
import { FiUser, FiMoreVertical } from 'react-icons/fi';
import { useToast } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton, Tooltip } from '@chakra-ui/react';
import usePersonalMessages from './personalmessage';
import useGroupMessages from './groupmessage';

const Messenger = ({ isOpen, onClose, onUnreadCountChange, isDarkMode, activeProject }) => {
  const toast = useToast();
  const notificationSound = new Audio('/assets/sounds/notification.mp3');
  const [currentUser, setCurrentUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  const personal = usePersonalMessages({ currentUser, socket, toast, isOpen, onUnreadCountChange });
  const group = useGroupMessages({ currentUser, socket, toast, isOpen });

  const messages = [...personal.messages, ...group.messages];

  useEffect(() => {
    const token = localStorage.getItem('token');
    const newSocket = io(WS_BASE_URL, {
      auth: { token },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });
    setSocket(newSocket);

    newSocket.on('connect', () => console.log('Socket connected'));
    newSocket.on('disconnect', () => console.log('Socket disconnected'));

    return () => {
      newSocket.disconnect();
      console.log('Socket cleanup on unmount');
    };
  }, []);

  useEffect(() => {
    if (!socket || !currentUser?.id) return;

    const handleNewMessage = (message) => {
      console.log('Received new personal message:', message);
      if (personal.selectedConversation?.user?.id === message.senderId) {
        personal.setMessages(prev => [...prev, message]);
      } else {
        personal.setUnreadCounts(prev => ({
          ...prev,
          [message.senderId]: (prev[message.senderId] || 0) + 1,
        }));
        notificationSound.play().catch(err => console.error('Error playing sound:', err));
        const sender = users.find(u => u.id === message.senderId)?.username || 'Pengguna';
        toast({ title: `Pesan Baru dari ${sender}`, description: message.content, status: 'info', duration: 10000 });
      }
    };

    const handleNewGroupMessage = (message) => {
      console.log('Received new group message:', message);
      if (group.selectedGroup?.id === message.groupId) {
        group.setMessages(prev => [...prev, {
          ...message,
          isGroupMessage: true,
          isSentByCurrentUser: message.senderId === currentUser.id,
          likes: Array.isArray(message.likes) ? message.likes : [],
          likeEmojis: message.likeEmojis || {},
          userLiked: message.likes?.includes(currentUser.id),
          userEmoji: message.likeEmojis?.[currentUser.id] || '❤️',
        }]);
      }
    };

    const handleMessageDeleted = ({ messageId }) => personal.setMessages(prev => prev.filter(msg => msg.id !== messageId));
    const handleGroupMessageDeleted = ({ messageId }) => group.setMessages(prev => prev.filter(msg => msg.id !== messageId));

    const handleMessagePinned = ({ messageId, isPinned }) => {
      setPinnedMessages(prev => {
        const msg = messages.find(m => m.id === messageId);
        return isPinned ? [...prev, { ...msg, pinned: true }] : prev.filter(pm => pm.id !== messageId);
      });
      personal.setMessages(prev => prev.map(msg => (msg.id === messageId ? { ...msg, pinned: isPinned } : msg)));
    };

    const handleGroupMessagePinned = ({ messageId, isPinned }) => {
      setPinnedMessages(prev => {
        const msg = messages.find(m => m.id === messageId);
        return isPinned ? [...prev, { ...msg, pinned: true }] : prev.filter(pm => pm.id !== messageId);
      });
      group.setMessages(prev => prev.map(msg => (msg.id === messageId ? { ...msg, pinned: isPinned } : msg)));
    };

    const handleMessageLiked = ({ messageId, likes, likeEmojis }) => {
      const userId = parseInt(localStorage.getItem('userId'));
      setShowEmojiPicker(prev => ({ ...prev, [messageId]: false }));
      personal.setMessages(prev =>
        prev.map(msg =>
          msg.id === messageId
            ? { ...msg, likes, likeEmojis, userLiked: likes.includes(userId), userEmoji: likeEmojis[userId] || '❤️' }
            : msg
        )
      );
    };

    const handleGroupMessageLiked = ({ messageId, likes, likeEmojis }) => {
      const userId = parseInt(localStorage.getItem('userId'));
      setShowEmojiPicker(prev => ({ ...prev, [messageId]: false }));
      group.setMessages(prev =>
        prev.map(msg =>
          msg.id === messageId
            ? { ...msg, likes, likeEmojis, userLiked: likes.includes(userId), userEmoji: likeEmojis[userId] || '❤️' }
            : msg
        )
      );
    };

    socket.on('new_message', handleNewMessage);
    socket.on('new_group_message', handleNewGroupMessage);
    socket.on('message_deleted', handleMessageDeleted);
    socket.on('group_message_deleted', handleGroupMessageDeleted);
    socket.on('message_pinned', handleMessagePinned);
    socket.on('group_message_pinned', handleGroupMessagePinned);
    socket.on('message_liked', handleMessageLiked);
    socket.on('group_message_liked', handleGroupMessageLiked);

    return () => {
      socket.off('new_message', handleNewMessage);
      socket.off('new_group_message', handleNewGroupMessage);
      socket.off('message_deleted', handleMessageDeleted);
      socket.off('group_message_deleted', handleGroupMessageDeleted);
      socket.off('message_pinned', handleMessagePinned);
      socket.off('group_message_pinned', handleGroupMessagePinned);
      socket.off('message_liked', handleMessageLiked);
      socket.off('group_message_liked', handleGroupMessageLiked);
    };
  }, [socket, currentUser, personal.selectedConversation, group.selectedGroup, users, personal.setUnreadCounts]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        if (!userId || !token) throw new Error('User ID atau token tidak ditemukan');
        const response = await fetch(`${API_BASE_URL}/api/users/${userId}`, {
          headers: { 'Authorization': `Bearer ${token}` },
          credentials: 'include',
        });
        if (!response.ok) throw new Error('Error fetching current user');
        setCurrentUser(await response.json());
      } catch (error) {
        console.error('Error fetching current user:', error);
        toast({ title: 'Error', description: 'Gagal memuat data pengguna', status: 'error', duration: 3000 });
      }
    };
    fetchCurrentUser();
  }, [toast]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/users`, {
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching users');
      setUsers(await response.json());
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({ title: 'Error', description: 'Gagal memuat daftar pengguna', status: 'error', duration: 3000 });
    }
  };

  useEffect(() => {
    if (isOpen) fetchUsers();
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!personal.selectedConversation && !group.selectedGroup) {
      toast({ title: 'Error', description: 'Pilih penerima terlebih dahulu', status: 'error', duration: 3000 });
      return;
    }
    const messageData = {
      content: newMessage,
      groupId: group.selectedGroup?.id || null,
      receiverId: personal.selectedConversation?.user?.id || null,
      attachment: attachment ? URL.createObjectURL(attachment) : null,
      senderId: currentUser?.id,
    };
    console.log('Sending message:', messageData);
    if (group.selectedGroup) {
      socket?.emit('send_group_message', messageData);
    } else {
      socket?.emit('send_message', messageData);
    }
    setNewMessage('');
    setAttachment(null);
  };

  const selectRoom = (room, type) => {
    personal.setSelectedConversation(type === 'personal' ? room : null);
    group.setSelectedGroup(type === 'group' ? (room.group || room) : null);
    if (type === 'personal' && room?.user?.id) {
      personal.setUnreadCounts(prev => ({ ...prev, [room.user.id]: 0 }));
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        toast({ title: 'Ukuran File Terlalu Besar', description: 'Maks 10MB', status: 'warning', duration: 3000 });
        return;
      }
      setAttachment(file);
    }
  };

  const removeAttachment = () => {
    setAttachment(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handlePinMessage = (message) => {
    const isPinned = message.pinned; // Gunakan status pinned dari pesan
    if (group.selectedGroup) {
      socket?.emit('toggle_pin_group_message', { messageId: message.id, isPinning: !isPinned });
    } else {
      socket?.emit('toggle_pin_message', { messageId: message.id, isPinning: !isPinned });
    }
  };

  const handleLikeMessage = (messageId, emoji = '❤️') => {
    if (!socket || !currentUser?.id) return;
    setShowEmojiPicker(prev => ({ ...prev, [messageId]: false }));
    const userId = localStorage.getItem('userId');
    localStorage.setItem(`message_emoji_${messageId}_${userId}`, emoji);
    const message = messages.find(msg => msg.id === messageId);
    const isGroupMessage = message?.isGroupMessage || message?.groupId;
    socket.emit(isGroupMessage ? 'toggle_group_message_like' : 'toggle_message_like', { messageId, emoji });
  };

  const toggleEmojiPicker = (messageId) => {
    setShowEmojiPicker(prev => ({ ...prev, [messageId]: !prev[messageId] }));
  };

  const renderMessageActions = (message) => {
    const isOwnMessage = message.senderId === currentUser?.id;
    const isPinned = message.pinned; // Gunakan status pinned dari pesan itu sendiri
    const likes = Array.isArray(message.likes) ? message.likes : [];
    const likeCount = likes.length;
    const likeEmojis = message.likeEmojis || {};
    const userId = parseInt(localStorage.getItem('userId'));
    const userLiked = likes.includes(userId);
    const displayEmoji = userLiked ? (likeEmojis[userId] || localStorage.getItem(`message_emoji_${message.id}_${userId}`) || '❤️') : '❤️';
    const availableEmojis = ['❤️', '👍', '😂', '😍', '🎉', '👏', '🔥', '💯', '✅'];
    const isGroupMessage = message.isGroupMessage || message.groupId;

    return (
      <div className="message-actions">
        {isOwnMessage && (
          <button
            onClick={() => {
              console.log('Deleting message:', message);
              if (isGroupMessage) {
                socket?.emit('delete_group_message', { messageId: message.id });
                group.deleteGroupMessage(message.id);
              } else {
                socket?.emit('delete_message', { messageId: message.id });
                personal.deleteMessage(message.id);
              }
            }}
            className="delete-message-btn"
          >
            🗑️
          </button>
        )}
        <button
          className={`pin-message-btn ${isPinned ? 'pinned' : ''}`}
          onClick={() => handlePinMessage(message)}
        >
          {isPinned ? '📌' : '📍'}
        </button>
        <div className="like-section">
          <button
            onClick={() => (userLiked ? handleLikeMessage(message.id) : toggleEmojiPicker(message.id))}
            className={`like-message-btn ${userLiked ? 'liked' : ''}`}
          >
            {userLiked ? `${displayEmoji} ${likeCount}` : likeCount > 0 ? `🤍 ${likeCount}` : '🤍'}
          </button>
          {showEmojiPicker[message.id] && (
            <div className="emoji-picker">
              {availableEmojis.map(emoji => (
                <button key={emoji} onClick={() => handleLikeMessage(message.id, emoji)} className="emoji-btn">
                  {emoji}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPinnedMessages = () => {
    if (!Array.isArray(personal.pinnedMessages) && !Array.isArray(group.pinnedMessages)) return null;
    const pinnedForRoom = personal.selectedConversation
      ? personal.pinnedMessages.filter(pm => 
          pm.senderId === personal.selectedConversation.user.id || 
          pm.receiverId === personal.selectedConversation.user.id
        )
      : group.selectedGroup
      ? group.pinnedMessages.filter(pm => pm.groupId === group.selectedGroup.id)
      : [];
    if (pinnedForRoom.length === 0) return null;
  
    const scrollToMessage = (messageId) => {
      const messageElement = document.querySelector(`[data-message-id="${messageId}"]`);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        messageElement.classList.add('highlighted-message');
        setTimeout(() => messageElement.classList.remove('highlighted-message'), 2000);
      }
    };
  
    return (
      <div className="pinned-messages-section">
        {pinnedForRoom.map(pm => (
          <div key={pm.id} className="pinned-message" onClick={() => scrollToMessage(pm.id)}>
            <span className="pinned-message-icon">📌</span>
            <span className="pinned-message-content">{pm.content.length > 30 ? `${pm.content.substring(0, 30)}...` : pm.content}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderMarkdownLink = (text) => {
    if (!text) return text;
    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    const parts = [];
    let lastIndex = 0;
    let match;
    while ((match = linkRegex.exec(text)) !== null) {
      if (match.index > lastIndex) parts.push(text.substring(lastIndex, match.index));
      parts.push(
        <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
          {match[1]}
        </a>
      );
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < text.length) parts.push(text.substring(lastIndex));
    return parts.length > 0 ? parts : text;
  };

  return (
    <div className={`messenger ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="messenger-wrapper">
        <div className="messenger-content">
          <div className="conversations-list">
            {group.isCreatingGroup ? (
              <div className="new-group-form">
                <input
                  type="text"
                  placeholder="Nama Group"
                  value={group.newGroupName}
                  onChange={e => group.setNewGroupName(e.target.value)}
                  className="new-group-input"
                />
                <div className="group-members-selection">
                  {users
                    .filter(user => user.id !== currentUser?.id && !group.newGroupMembers.some(m => m.id === user.id))
                    .map(user => (
                      <div key={user.id} className="user-item" onClick={() => group.addGroupMember(user)}>
                        <img src={`${API_BASE_URL}${user.profileImage}`} alt={user.username} className="user-avatar" />
                        <span>{user.username}</span>
                      </div>
                    ))}
                </div>
                <div className="selected-members">
                  {group.newGroupMembers.map(member => (
                    <div key={member.id} className="selected-member">
                      <span>{member.username}</span>
                      <button onClick={() => group.removeGroupMember(member.id)}>✖</button>
                    </div>
                  ))}
                </div>
                <div className="group-form-actions">
                  <button onClick={group.submitNewGroup} className="create-group-btn">
                    Buat Group
                  </button>
                  <button onClick={() => group.setIsCreatingGroup(false)} className="cancel-group-btn">
                    Batal
                  </button>
                </div>
              </div>
            ) : group.isEditingGroup ? (
              <div className="edit-group-form">
                <input
                  type="text"
                  placeholder="Nama Group"
                  value={group.editGroupName}
                  onChange={e => group.setEditGroupName(e.target.value)}
                  className="edit-group-input"
                />
                <div className="group-members-selection">
                  {users.map(user => (
                    <div
                      key={user.id}
                      className={`user-item ${group.editGroupMembers.some(m => m.id === user.id) ? 'selected' : ''}`}
                      onClick={() => group.addEditGroupMember(user)}
                    >
                      <img src={`${API_BASE_URL}${user.profileImage}`} alt={user.username} className="user-avatar" />
                      <span>{user.username}</span>
                    </div>
                  ))}
                </div>
                <div className="selected-members">
                  {group.editGroupMembers.map(member => (
                    <div key={member.id} className="selected-member">
                      <span>{member.username}</span>
                      <button onClick={() => group.removeEditGroupMember(member.id)}>✖</button>
                    </div>
                  ))}
                </div>
                <div className="group-form-actions">
                  <button onClick={group.submitEditGroup} className="edit-group-btn">
                    Simpan Perubahan
                  </button>
                  <button onClick={() => group.setIsEditingGroup(false)} className="cancel-group-btn">
                    Batal
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="sidebar-actions">
                  <button onClick={() => setShowNewMessage(true)} className="new-message-btn">
                    Pesan Baru
                  </button>
                  <button onClick={group.startCreateGroup} className="new-group-btn">
                    Group Baru
                  </button>
                </div>
                {showNewMessage ? (
                  <div className="new-message-users">
                    {users.map(user => (
                      <div key={user.id} className="user-item" onClick={() => selectRoom({ user }, 'personal')}>
                        {user.username}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {personal.conversations.map(conv => (
                      <div
                        key={conv.user.id}
                        className={`conversation-item ${personal.selectedConversation?.user?.id === conv.user.id ? 'active' : ''}`}
                        onClick={() => selectRoom(conv, 'personal')}
                      >
                        <div className="conversation-avatar">
                          <img src={`${API_BASE_URL}${conv.user.profileImage}`} alt={conv.user.username} className="avatar" />
                        </div>
                        <div className="conversation-details">
                          <div className="conversation-name">{conv.user.username}</div>
                          <div className="conversation-last-message">{conv.lastMessage?.content || 'Belum ada pesan'}</div>
                        </div>
                      </div>
                    ))}
                    {group.groups.map(groupItem => (
                      <div
                        key={groupItem.id}
                        className={`conversation-item ${group.selectedGroup?.id === groupItem.id ? 'active' : ''}`}
                        onClick={() => selectRoom({ group: groupItem }, 'group')}
                      >
                        <div className="conversation-avatar">
                          <div className="group-avatar">👥</div>
                        </div>
                        <div className="conversation-details">
                          <div className="conversation-name">{groupItem.name}</div>
                          <div className="conversation-last-message">{groupItem.lastMessage?.content || 'Belum ada pesan'}</div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          {(personal.selectedConversation || group.selectedGroup) && (
            <div className="messages-container">
              <div className="messages-header">
                <div className="user-info">
                  <div className="user-avatar">
                    {personal.selectedConversation?.user?.profileImage ? (
                      <img
                        src={personal.selectedConversation.user.profileImage.startsWith('http')
                          ? personal.selectedConversation.user.profileImage
                          : `${API_BASE_URL}${personal.selectedConversation.user.profileImage}`}
                        alt={personal.selectedConversation.user.username}
                      />
                    ) : group.selectedGroup ? (
                      <div className="group-avatar">{group.selectedGroup.name.charAt(0)}</div>
                    ) : (
                      <FiUser size={24} />
                    )}
                  </div>
                  <div className="user-name">
                    {personal.selectedConversation?.user?.username || group.selectedGroup?.name}
                    {group.selectedGroup && (
                      <div className="group-members">
                        {group.selectedGroup.members?.map(member => (
                          <span key={`group-member-${member.id}`} className={`group-member ${member.GroupMembers?.role === 'admin' ? 'admin' : ''}`}>
                            {member.username}
                            {member.GroupMembers?.role === 'admin' && ' (Admin)'}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {group.selectedGroup && (
                  <div className="group-options-menu">
                    <Menu>
                      <MenuButton as={IconButton} icon={<FiMoreVertical />} variant="ghost" aria-label="Group Options" size="sm" />
                      <MenuList>
                        <MenuItem onClick={() => group.handleEditGroup(group.selectedGroup)}>Edit Group</MenuItem>
                        <MenuItem color="red.500" onClick={() => group.handleDeleteGroup(group.selectedGroup)}>
                          Delete Group
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                )}
              </div>
              {renderPinnedMessages()}
              <div className="messages-list">
                {messages
                  .filter(message => {
                    if (personal.selectedConversation) {
                      return (
                        ((message.senderId === personal.selectedConversation.user.id && message.receiverId === currentUser?.id) ||
                          (message.senderId === currentUser?.id && message.receiverId === personal.selectedConversation.user.id)) &&
                        !message.groupId
                      );
                    }
                    if (group.selectedGroup) {
                      return message.groupId === group.selectedGroup.id;
                    }
                    return false;
                  })
                  .map((message, index) => (
                    <div key={index} className={`message ${message.senderId === currentUser?.id ? 'sent' : 'received'}`} data-message-id={message.id}>
                      <div className={`message-balloon ${message.senderId === currentUser?.id ? 'sent' : 'received'}`}>
                        <div className="message-sender-container">
                          <div className="message-avatar">
                            {message.sender?.profileImage ? (
                              <img
                                src={message.sender.profileImage.startsWith('http')
                                  ? message.sender.profileImage
                                  : `${API_BASE_URL}${message.sender.profileImage}`}
                                alt={message.sender.username}
                                onError={e => (e.target.src = '/assets/images/default-avatar.png')}
                              />
                            ) : (
                              <FiUser size={20} />
                            )}
                          </div>
                          <div className="sender-name">{message.sender?.username}</div>
                        </div>
                        <div className="message-content">
                          {renderMarkdownLink(message.content)}
                          {message.attachment && (
                            <div className="message-attachment">
                              {(() => {
                                const fileExtension = message.attachment.split('.').pop().toLowerCase();
                                const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
                                return imageExtensions.includes(fileExtension) ? (
                                  <img
                                    src={message.attachment}
                                    alt="Attachment"
                                    className="message-attachment-image"
                                    onClick={() => window.open(message.attachment, '_blank')}
                                  />
                                ) : (
                                  <a href={message.attachment} target="_blank" rel="noopener noreferrer" className="message-attachment-link">
                                    <i className="fa fa-file"></i> {message.attachment.split('/').pop()}
                                  </a>
                                );
                              })()}
                            </div>
                          )}
                          <div className="message-time">{new Date(message.createdAt).toLocaleTimeString()}</div>
                          {renderMessageActions(message)}
                        </div>
                      </div>
                    </div>
                  ))}
                <div ref={messagesEndRef} />
              </div>
              <form onSubmit={handleSubmit} className="message-input">
                <div className="input-container">
                  <textarea
                    rows="2"
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    placeholder={group.selectedGroup ? `Kirim pesan ke group ${group.selectedGroup.name}` : 'Type a message...'}
                    className="message-textarea"
                  />
                  {attachment && (
                    <div className="attachment-preview">
                      <span>{attachment.name}</span>
                      <button type="button" onClick={removeAttachment}>
                        Hapus
                      </button>
                    </div>
                  )}
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
                  <button type="button" onClick={() => fileInputRef.current.click()}>
                    Attach File
                  </button>
                  <button type="submit">{group.selectedGroup ? 'Kirim Group' : 'Kirim'}</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messenger;