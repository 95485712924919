import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaPlus, FaEllipsisH, FaFilter, FaSearch, FaList, FaChartBar, FaClock, FaSpinner, FaCheck, FaArrowUp, FaMinus, FaArrowDown, FaFileExport, FaFileCsv, FaFilePdf, FaFileExcel, FaFileImport } from 'react-icons/fa';
import GanttChart from './GanttChart';
import './RoadmapManager.css';
import MultipleSelect from './MultipleSelect';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { utils as XLSXUtils, write as XLSXWrite } from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { epicService } from '../services/epicService';
import EpicForm from './EpicForm';
import { useDarkMode } from '../../App';



const RoadmapManager = () => {
  const { isDarkMode } = useDarkMode();
  const [view, setView] = useState('list');
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    priority: 'all',
    assignee: 'all'
  });
  const [showFilters, setShowFilters] = useState(false);
  const [epics, setEpics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timelineView, setTimelineView] = useState('month');
  const [advancedFilters, setAdvancedFilters] = useState({
    dateRange: {
      start: '',
      end: ''
    },
    progressRange: {
      min: 0,
      max: 100
    },
    selectedAssignees: [],
    selectedPriorities: [],
    selectedStatuses: []
  });
  const [importError, setImportError] = useState(null);
  const [showEpicForm, setShowEpicForm] = useState(false);
  const [editingEpic, setEditingEpic] = useState(null);

  useEffect(() => {
    fetchEpics();
  }, []);

  const fetchEpics = async () => {
    try {
      setLoading(true);
      const data = await epicService.getAllEpics();
      setEpics(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch epics');
      console.error('Error fetching epics:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEpic = async (epicData) => {
    try {
      setLoading(true);
      const newEpic = await epicService.createEpic(epicData);
      setEpics([...epics, newEpic]);
      setError(null);
    } catch (err) {
      setError('Failed to create epic');
      console.error('Error creating epic:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEpic = async (id, epicData) => {
    try {
      setLoading(true);
      const updatedEpic = await epicService.updateEpic(id, epicData);
      setEpics(epics.map(epic => epic.id === id ? updatedEpic : epic));
      setError(null);
    } catch (err) {
      setError('Failed to update epic');
      console.error('Error updating epic:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEpic = async (id) => {
    try {
      setLoading(true);
      await epicService.deleteEpic(id);
      setEpics(epics.filter(epic => epic.id !== id));
      setError(null);
    } catch (err) {
      setError('Failed to delete epic');
      console.error('Error deleting epic:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(epics);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setEpics(items);
  };

  const formatEpicsForGantt = () => {
    return epics.map(epic => ({
      id: epic.id,
      taskName: epic.title,
      startDate: epic.startDate,
      endDate: epic.endDate,
      progress: epic.progress,
      assignee: epic.assignee,
      status: epic.status,
      description: epic.description
    }));
  };

  const filteredEpics = epics.filter(epic => {
    const matchesSearch = epic.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         epic.description.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = filters.status === 'all' || epic.status === filters.status;
    const matchesPriority = filters.priority === 'all' || epic.priority === filters.priority;
    const matchesAssignee = filters.assignee === 'all' || epic.assignee === filters.assignee;

    return matchesSearch && matchesStatus && matchesPriority && matchesAssignee;
  });

  const uniqueAssignees = [...new Set(epics.map(epic => epic.assignee))];

  const applyAdvancedFilters = (epic) => {
    const dateInRange = (!advancedFilters.dateRange.start || new Date(epic.startDate) >= new Date(advancedFilters.dateRange.start)) &&
                       (!advancedFilters.dateRange.end || new Date(epic.endDate) <= new Date(advancedFilters.dateRange.end));
    
    const progressInRange = epic.progress >= advancedFilters.progressRange.min && 
                           epic.progress <= advancedFilters.progressRange.max;
    
    const matchesAssignees = advancedFilters.selectedAssignees.length === 0 || 
                            advancedFilters.selectedAssignees.includes(epic.assignee);
    
    const matchesPriorities = advancedFilters.selectedPriorities.length === 0 || 
                             advancedFilters.selectedPriorities.includes(epic.priority);
    
    const matchesStatuses = advancedFilters.selectedStatuses.length === 0 || 
                           advancedFilters.selectedStatuses.includes(epic.status);
    
    return dateInRange && progressInRange && matchesAssignees && matchesPriorities && matchesStatuses;
  };

  const FilterPanel = () => (
    <div className={`filter-panel ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="filter-group">
        <label>Status</label>
        <select 
          value={filters.status}
          onChange={(e) => setFilters({...filters, status: e.target.value})}
        >
          <option value="all">Semua Status</option>
          <option value="todo">To Do</option>
          <option value="in-progress">In Progress</option>
          <option value="done">Done</option>
        </select>
      </div>

      <div className="filter-group">
        <label>Prioritas</label>
        <select 
          value={filters.priority}
          onChange={(e) => setFilters({...filters, priority: e.target.value})}
        >
          <option value="all">Semua Prioritas</option>
          <option value="high">Tinggi</option>
          <option value="medium">Sedang</option>
          <option value="low">Rendah</option>
        </select>
      </div>

      <div className="filter-group">
        <label>Assignee</label>
        <select 
          value={filters.assignee}
          onChange={(e) => setFilters({...filters, assignee: e.target.value})}
        >
          <option value="all">Semua Assignee</option>
          {uniqueAssignees.map(assignee => (
            <option key={assignee} value={assignee}>{assignee}</option>
          ))}
        </select>
      </div>
    </div>
  );

  const AdvancedFilterPanel = () => (
    <div className={`advanced-filter-panel ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="filter-section">
        <h4>Rentang Tanggal</h4>
        <div className="date-range">
          <input
            type="date"
            value={advancedFilters.dateRange.start}
            onChange={(e) => setAdvancedFilters({
              ...advancedFilters,
              dateRange: { ...advancedFilters.dateRange, start: e.target.value }
            })}
          />
          <span>sampai</span>
          <input
            type="date"
            value={advancedFilters.dateRange.end}
            onChange={(e) => setAdvancedFilters({
              ...advancedFilters,
              dateRange: { ...advancedFilters.dateRange, end: e.target.value }
            })}
          />
        </div>
      </div>

      <div className="filter-section">
        <h4>Progress ({advancedFilters.progressRange.min}% - {advancedFilters.progressRange.max}%)</h4>
        <div className="progress-range">
          <input
            type="range"
            min="0"
            max="100"
            value={advancedFilters.progressRange.min}
            onChange={(e) => setAdvancedFilters({
              ...advancedFilters,
              progressRange: { ...advancedFilters.progressRange, min: parseInt(e.target.value) }
            })}
          />
          <input
            type="range"
            min="0"
            max="100"
            value={advancedFilters.progressRange.max}
            onChange={(e) => setAdvancedFilters({
              ...advancedFilters,
              progressRange: { ...advancedFilters.progressRange, max: parseInt(e.target.value) }
            })}
          />
        </div>
      </div>

      <MultipleSelect
        title="Assignee"
        options={uniqueAssignees}
        selected={advancedFilters.selectedAssignees}
        onChange={(selected) => setAdvancedFilters({
          ...advancedFilters,
          selectedAssignees: selected
        })}
      />
    </div>
  );

  const statistics = useMemo(() => {
    const total = epics.length;
    const completed = epics.filter(epic => epic.status === 'done').length;
    const inProgress = epics.filter(epic => epic.status === 'in-progress').length;
    const todo = epics.filter(epic => epic.status === 'todo').length;
    
    const averageProgress = epics.reduce((acc, epic) => acc + epic.progress, 0) / total;
    
    return {
      total,
      completed,
      inProgress,
      todo,
      averageProgress: Math.round(averageProgress)
    };
  }, [epics]);

  const groupedEpics = useMemo(() => {
    return epics.reduce((groups, epic) => {
      const startDate = new Date(epic.startDate);
      let period;

      if (timelineView === 'month') {
        period = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}`;
      } else if (timelineView === 'quarter') {
        const quarter = Math.floor(startDate.getMonth() / 3) + 1;
        period = `Q${quarter} ${startDate.getFullYear()}`;
      } else {
        period = startDate.getFullYear().toString();
      }

      if (!groups[period]) {
        groups[period] = [];
      }
      groups[period].push(epic);
      return groups;
    }, {});
  }, [epics, timelineView]);

  const EpicItem = ({ epic }) => (
    <div className={`epic-item ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="epic-header">
        <h3>{epic.title}</h3>
        <div className="epic-indicators">
          <span className={`epic-status ${epic.status}`}>
            {epic.status === 'in-progress' && <FaSpinner />}
            {epic.status === 'done' && <FaCheck />}
            {epic.status === 'todo' && <FaClock />}
            {epic.status}
          </span>
          <span className={`priority-indicator ${epic.priority}`}>
            {epic.priority === 'high' && <FaArrowUp />}
            {epic.priority === 'medium' && <FaMinus />}
            {epic.priority === 'low' && <FaArrowDown />}
            {epic.priority}
          </span>
        </div>
      </div>
      
      <div className="epic-progress">
        <div className="progress-container">
          <div 
            className={`progress-bar ${epic.priority}`} 
            style={{ width: `${epic.progress}%` }}
          />
        </div>
        <div className="progress-details">
          <span>{epic.progress}% selesai</span>
          <span>{new Date(epic.startDate).toLocaleDateString('id-ID')} - {new Date(epic.endDate).toLocaleDateString('id-ID')}</span>
        </div>
      </div>
    </div>
  );

  const exportToCSV = () => {
    const csvContent = epics.map(epic => ({
      Judul: epic.title,
      Deskripsi: epic.description,
      Status: epic.status,
      'Tanggal Mulai': epic.startDate,
      'Tanggal Selesai': epic.endDate,
      Progress: `${epic.progress}%`,
      Assignee: epic.assignee,
      Prioritas: epic.priority
    }));

    const csv = Papa.unparse(csvContent);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `roadmap_export_${new Date().toISOString()}.csv`);
  };

  const exportToExcel = () => {
    const ws = XLSXUtils.json_to_sheet(epics.map(epic => ({
      Judul: epic.title,
      Deskripsi: epic.description,
      Status: epic.status,
      'Tanggal Mulai': epic.startDate,
      'Tanggal Selesai': epic.endDate,
      Progress: `${epic.progress}%`,
      Assignee: epic.assignee,
      Prioritas: epic.priority
    })));

    const wb = XLSXUtils.book_new();
    XLSXUtils.book_append_sheet(wb, ws, 'Roadmap');
    const excelBuffer = XLSXWrite(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `roadmap_export_${new Date().toISOString()}.xlsx`);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    
    doc.setFontSize(16);
    doc.text('Roadmap Report', 14, 15);
    doc.setFontSize(10);
    
    const tableData = epics.map(epic => [
      epic.title,
      epic.status,
      `${epic.progress}%`,
      epic.assignee,
      epic.priority,
      new Date(epic.startDate).toLocaleDateString('id-ID'),
      new Date(epic.endDate).toLocaleDateString('id-ID')
    ]);

    doc.autoTable({
      head: [['Judul', 'Status', 'Progress', 'Assignee', 'Prioritas', 'Mulai', 'Selesai']],
      body: tableData,
      startY: 25,
      theme: 'grid',
      styles: { fontSize: 8 },
      headStyles: { fillColor: [41, 128, 185] }
    });

    doc.save(`roadmap_export_${new Date().toISOString()}.pdf`);
  };

  const validateImportedData = (data) => {
    const errors = [];
    const requiredFields = ['title', 'status', 'startDate', 'endDate', 'progress'];
    const validStatuses = ['todo', 'in-progress', 'done'];
    const validPriorities = ['high', 'medium', 'low'];

    data.forEach((item, index) => {
      // Validasi field yang required
      requiredFields.forEach(field => {
        if (!item[field]) {
          errors.push(`Baris ${index + 1}: ${field} tidak boleh kosong`);
        }
      });

      // Validasi format tanggal
      if (item.startDate) {
        const startDate = new Date(item.startDate);
        if (isNaN(startDate.getTime())) {
          errors.push(`Baris ${index + 1}: Format tanggal mulai tidak valid`);
        }
      }

      if (item.endDate) {
        const endDate = new Date(item.endDate);
        if (isNaN(endDate.getTime())) {
          errors.push(`Baris ${index + 1}: Format tanggal selesai tidak valid`);
        }
      }

      // Validasi tanggal mulai < tanggal selesai
      if (item.startDate && item.endDate) {
        const startDate = new Date(item.startDate);
        const endDate = new Date(item.endDate);
        if (startDate > endDate) {
          errors.push(`Baris ${index + 1}: Tanggal mulai tidak boleh lebih besar dari tanggal selesai`);
        }
      }

      // Validasi status
      if (item.status && !validStatuses.includes(item.status.toLowerCase())) {
        errors.push(`Baris ${index + 1}: Status tidak valid (${validStatuses.join(', ')})`);
      }

      // Validasi progress
      const progress = parseInt(item.progress);
      if (isNaN(progress) || progress < 0 || progress > 100) {
        errors.push(`Baris ${index + 1}: Progress harus berupa angka antara 0-100`);
      }

      // Validasi priority
      if (item.priority && !validPriorities.includes(item.priority.toLowerCase())) {
        errors.push(`Baris ${index + 1}: Prioritas tidak valid (${validPriorities.join(', ')})`);
      }
    });

    return errors;
  };

  const handleImportData = async (file) => {
    const fileExt = file.name.split('.').pop().toLowerCase();
    setImportError(null);
    
    try {
      if (fileExt === 'csv') {
        Papa.parse(file, {
          complete: (results) => {
            const importedData = results.data.slice(1).map((row, index) => ({
              id: (epics.length + index + 1).toString(),
              title: row[0],
              description: row[1],
              status: row[2],
              startDate: row[3],
              endDate: row[4],
              progress: parseInt(row[5]) || 0,
              assignee: row[6],
              priority: row[7]
            }));

            const validationErrors = validateImportedData(importedData);
            if (validationErrors.length > 0) {
              setImportError(validationErrors.join('\n'));
              return;
            }

            setEpics([...epics, ...importedData]);
          },
          header: true,
          error: (error) => setImportError(error.message)
        });
      } else if (fileExt === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          
          const importedEpics = jsonData.map((row, index) => ({
            id: (epics.length + index + 1).toString(),
            title: row['Judul'],
            description: row['Deskripsi'],
            status: row['Status'],
            startDate: row['Tanggal Mulai'],
            endDate: row['Tanggal Selesai'],
            progress: parseInt(row['Progress']) || 0,
            assignee: row['Assignee'],
            priority: row['Prioritas']
          }));
          setEpics([...epics, ...importedEpics]);
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      setImportError('Error importing file: ' + error.message);
    }
  };

  const handleOpenEpicForm = (epic = null) => {
    setEditingEpic(epic);
    setShowEpicForm(true);
  };

  const handleCloseEpicForm = () => {
    setEditingEpic(null);
    setShowEpicForm(false);
  };

  const handleEpicSubmit = async (formData) => {
    if (editingEpic) {
      await handleUpdateEpic(editingEpic.id, formData);
    } else {
      await handleAddEpic(formData);
    }
    handleCloseEpicForm();
  };

  const ToolbarButtons = () => (
    <div className={`toolbar-buttons ${isDarkMode ? 'darkmode' : ''}`}>
      <div className="import-export-group">
        <input
          type="file"
          id="import-file"
          accept=".csv,.xlsx"
          style={{ display: 'none' }}
          onChange={(e) => handleImportData(e.target.files[0])}
        />
        <label htmlFor="import-file" className="toolbar-button">
          <FaFileImport /> Import
        </label>
        
        <div className="export-dropdown">
          <button className="toolbar-button">
            <FaFileExport /> Export
          </button>
          <div className="export-options">
            <button onClick={() => exportToCSV()}>
              <FaFileCsv /> CSV
            </button>
            <button onClick={() => exportToExcel()}>
              <FaFileExcel /> Excel
            </button>
            <button onClick={() => exportToPDF()}>
              <FaFilePdf /> PDF
            </button>
          </div>
        </div>
      </div>
      
      <div className="view-buttons">
        <button 
          className={`view-btn ${view === 'list' ? 'active' : ''}`}
          onClick={() => setView('list')}
        >
          <FaList /> List
        </button>
        <button 
          className={`view-btn ${view === 'gantt' ? 'active' : ''}`}
          onClick={() => setView('gantt')}
        >
          <FaChartBar /> Gantt
        </button>
      </div>
    </div>
  );

  return (
    <div className={`roadmap-container ${isDarkMode ? 'darkmode' : ''}`}>
      <div className={`roadmap-statistics ${isDarkMode ? 'darkmode' : ''}`}>
        <div className="stat-item">
          <h3>Total Epic</h3>
          <span>{statistics.total}</span>
        </div>
        <div className="stat-item">
          <h3>Completed</h3>
          <span className="completed">{statistics.completed}</span>
        </div>
        <div className="stat-item">
          <h3>In Progress</h3>
          <span className="in-progress">{statistics.inProgress}</span>
        </div>
        <div className="stat-item">
          <h3>To Do</h3>
          <span className="todo">{statistics.todo}</span>
        </div>
        <div className="stat-item">
          <h3>Average Progress</h3>
          <span>{statistics.averageProgress}%</span>
        </div>
      </div>

      <div className={`roadmap-header ${isDarkMode ? 'darkmode' : ''}`}>
        <div className="left-actions">
          <button className="add-epic-btn" onClick={() => handleOpenEpicForm()}>
            <FaPlus /> Tambah Epic
          </button>
        </div>
        <div className="header-actions">
          <div className="search-bar">
            <FaSearch />
            <input 
              type="text" 
              placeholder="Cari epic..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button 
            className={`filter-btn ${showFilters ? 'active' : ''}`}
            onClick={() => setShowFilters(!showFilters)}
          >
            <FaFilter /> Filter
          </button>
          <ToolbarButtons />
        </div>
        <div className="timeline-toggle">
          <button 
            className={timelineView === 'month' ? 'active' : ''}
            onClick={() => setTimelineView('month')}
          >
            Monthly
          </button>
          <button 
            className={timelineView === 'quarter' ? 'active' : ''}
            onClick={() => setTimelineView('quarter')}
          >
            Quarterly
          </button>
          <button 
            className={timelineView === 'year' ? 'active' : ''}
            onClick={() => setTimelineView('year')}
          >
            Yearly
          </button>
        </div>
      </div>

      {showFilters && <FilterPanel />}

      {view === 'list' ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="epics">
            {(provided) => (
              <div 
                className={`epics-list ${isDarkMode ? 'darkmode' : ''}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {filteredEpics.map((epic, index) => (
                  <Draggable 
                    key={epic.id} 
                    draggableId={epic.id} 
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={`epic-item priority-${epic.priority} status-${epic.status} ${isDarkMode ? 'darkmode' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <EpicItem epic={epic} />
                        <button className="epic-menu">
                          <FaEllipsisH />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={`gantt-view ${isDarkMode ? 'darkmode' : ''}`}>
          <GanttChart tasks={formatEpicsForGantt(filteredEpics)} />
        </div>
      )}

      {view === 'list' && (
        <div className={`timeline-groups ${isDarkMode ? 'darkmode' : ''}`}>
          {Object.entries(groupedEpics).map(([period, periodEpics]) => (
            <div key={period} className="timeline-group">
              <h3 className="period-header">
                <FaClock /> {period}
                <span className="epic-count">{periodEpics.length} epics</span>
              </h3>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`droppable-${period}`}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`epics-list ${isDarkMode ? 'darkmode' : ''}`}
                    >
                      {periodEpics.map((epic, index) => (
                        <Draggable 
                          key={epic.id} 
                          draggableId={epic.id} 
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`epic-item priority-${epic.priority} status-${epic.status} ${isDarkMode ? 'darkmode' : ''}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <EpicItem epic={epic} />
                              <button className="epic-menu">
                                <FaEllipsisH />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ))}
        </div>
      )}

      {showEpicForm && (
        <EpicForm
          onClose={handleCloseEpicForm}
          onSubmit={handleEpicSubmit}
          editData={editingEpic}
        />
      )}

      {importError && <div className={`import-error ${isDarkMode ? 'darkmode' : ''}`}>{importError}</div>}
      {error && <div className={`error-message ${isDarkMode ? 'darkmode' : ''}`}>{error}</div>}
      {loading && <div className={`loading-indicator ${isDarkMode ? 'darkmode' : ''}`}>Loading...</div>}
    </div>
  );
};

export default RoadmapManager;