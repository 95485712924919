import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './GanttChart.css';
import { FaSearchPlus, FaSearchMinus, FaExpand, FaCompress, FaFilePdf, FaFileImage, FaFileExcel, FaFilter, FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import TaskForm from './TaskForm';
import '../styles/global.css';
import '../styles/globalform.css';
import axiosInstance from '../config/axios';

const hasManagePermission = (userRole) => {
  const allowedPermissions = ['manage_tasks', 'create_project'];
  return userRole?.permissions?.some(permission => allowedPermissions.includes(permission));
};

const GanttChart = ({ 
  tasks, 
  isDarkMode, 
  userRole,
  onEditTask,
  onDeleteTask,
  shots = [],
  assets = [],
  sequences = [],
  socket,
  activeProject
}) => {
  const ganttContainer = useRef(null);
  const [currentZoom, setCurrentZoom] = useState('Days');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    status: null,
    name: '',
    priority: null,
    progress: { min: 0, max: 100 },
    assignee: null,
    relatedShot: null,
    relatedAsset: null,
    startDate: null,
    dueDate: null
  });
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const columnOptions = [
    { value: 'text', label: 'Task Name' },
    { value: 'status', label: 'Status' },
    { value: 'start_date', label: 'Start Date' },
    { value: 'end_date', label: 'Due Date' },
    { value: 'progress', label: 'Progress' },
    { value: 'assignee', label: 'Assignee' },
    { value: 'priority', label: 'Priority' },
    { value: 'relatedShot', label: 'Related Shot' },
    { value: 'relatedAsset', label: 'Related Asset' }
  ];

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axiosInstance.get('/api/task-statuses');
        setStatuses(response.data);
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchStatuses();
    fetchUsers();
  }, []);

  const getStatusName = (statusId) => {
    if (!statusId) return 'Pending';
    const status = statuses.find(s => s.id === parseInt(statusId));
    return status ? status.name : 'Unknown';
  };

  const handleAddTask = () => {
    setEditFormData(null);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditFormData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      const response = await axiosInstance.post('/api/tasks', formData);
      if (socket) {
        socket.emit('createTask', response.data);
      }
      setIsFormVisible(false);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  // Fungsi untuk mendapatkan warna berdasarkan progress
  const getProgressColor = (progress) => {
    if (progress >= 0.5) return '#4CAF50'; // Hijau untuk progress >= 50%
    if (progress >= 0.25) return '#FFC107'; // Kuning untuk progress >= 25%
    return '#F44336'; // Merah untuk progress < 25%
  };

  // Format tasks untuk Gantt Chart
  const formatTasks = useCallback((tasksToFormat) => {
    return tasksToFormat
      .filter(task => {
        // Apply filters
        if (filters.status && task.statusId !== filters.status.value) return false;
        if (filters.name && !task.taskName.toLowerCase().includes(filters.name.toLowerCase())) return false;
        if (filters.priority && task.priority?.toLowerCase() !== filters.priority.value.toLowerCase()) return false;
        if (task.progress < filters.progress.min || task.progress > filters.progress.max) return false;
        if (filters.assignee && task.assignedUser?.id !== filters.assignee.value) return false;
        
        if (filters.relatedShot) {
          const hasRelatedShot = shots.some(shot => 
            shot.id === filters.relatedShot.value && 
            shot.selectedTasks?.includes(task.id)
          );
          if (!hasRelatedShot) return false;
        }
        
        if (filters.relatedAsset) {
          const hasRelatedAsset = assets.some(asset => 
            asset.id === filters.relatedAsset.value && 
            asset.selectedTasks?.includes(task.id)
          );
          if (!hasRelatedAsset) return false;
        }
        
        if (filters.startDate) {
          const startDate = new Date(filters.startDate);
          if (task.startDate < startDate.toISOString()) return false;
        }
        
        if (filters.dueDate) {
          const dueDate = new Date(filters.dueDate);
          if (task.dueDate > dueDate.toISOString()) return false;
        }
        
        if (searchTerm && !task.taskName.toLowerCase().includes(searchTerm.toLowerCase())) return false;
        return true;
      })
      .map(task => {
        // Pastikan tanggal valid
        const startDate = task.startDate ? new Date(task.startDate) : new Date();
        const endDate = task.dueDate ? new Date(task.dueDate) : new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
        
        // Validasi tanggal
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          console.error(`Invalid date for task ${task.id}:`, { startDate: task.startDate, endDate: task.dueDate });
          return null;
        }

        // Pastikan endDate tidak sebelum startDate
        const validEndDate = endDate < startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : endDate;

        // Get related shot and asset
        const relatedShot = shots.find(shot => shot.selectedTasks?.includes(task.id));
        const relatedAsset = assets.find(asset => asset.selectedTasks?.includes(task.id));

        // Get assignee name
        const assignee = users.find(user => user.id === task.assignedUser?.id);
        const assigneeName = assignee ? (assignee.username || assignee.email) : 'Unassigned';

        const progress = typeof task.progress === 'number' ? task.progress / 100 : 0;

        return {
          id: task.id,
          text: task.taskName || 'Untitled Task',
          start_date: startDate,
          end_date: validEndDate,
          progress: progress,
          statusId: task.statusId,
          priority: task.priority || 'medium',
          assignee: assigneeName,
          duration: Math.max(1, Math.ceil((validEndDate - startDate) / (24 * 60 * 60 * 1000))),
          parent: task.parentId || null,
          type: task.type || 'task',
          relatedShot: relatedShot ? (relatedShot.shotName || relatedShot.name) : '',
          relatedAsset: relatedAsset ? (relatedAsset.assetName || relatedAsset.name) : '',
          description: task.description
        };
      }).filter(Boolean); // Remove null entries
  }, [filters, searchTerm, shots, assets, users, statuses]);

  const getTaskColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'completed':
        return '#4CAF50';
      case 'in progress':
        return '#FF9800';
      case 'todo':
        return '#2196F3';
      case 'pending':
        return '#F44336';
      default:
        return '#9E9E9E';
    }
  };

  useEffect(() => {
    if (!ganttContainer.current) return;

    // Initialize plugins
    gantt.plugins({
      marker: true,
      fullscreen: true,
      tooltip: true,
      critical_path: true,
      auto_scheduling: true,
      zoom: true
    });

    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    gantt.config.order_branch = true;
    gantt.config.order_branch_free = true;
    gantt.config.grid_resize = true;
    gantt.config.min_column_width = 20;
    gantt.config.row_height = 35;
    gantt.config.grid_width = 380;
    gantt.config.task_height = 20;
    gantt.config.resize_column = true;
    gantt.config.resize_grid = true;
    gantt.config.grid_resizer = {
      width: 8,
      touch_area: 16,
      css_class: 'gantt_grid_resizer'
    };

    // Configure zoom levels
    gantt.ext.zoom.init({
      levels: [
        {
          name: "Hours",
          scale_height: 60,
          min_column_width: 30,
          scales: [
            {unit: "day", step: 1, format: "%d %M"},
            {unit: "hour", step: 1, format: "%H:%i"}
          ]
        },
        {
          name: "Days",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: "week", step: 1, format: "Week #%W"},
            {unit: "day", step: 1, format: "%d %M"}
          ]
        },
        {
          name: "Months",
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: "month", step: 1, format: "%F"},
            {unit: "week", step: 1, format: "#%W"}
          ]
        }
      ]
    });

    // Konfigurasi template untuk warna task berdasarkan status dan progress
    gantt.templates.task_class = function(start, end, task) {
      let classes = [];
      
      // Add progress class
      if (task.progress >= 0.5) {
        classes.push('high-progress');
      } else if (task.progress >= 0.25) {
        classes.push('medium-progress');
      } else {
        classes.push('low-progress');
      }

      // Add status class
      switch (task.statusId) {
        case 1:
          classes.push('completed');
          break;
        case 2:
        case 3:
          classes.push('in-progress');
          break;
        case 4:
        case 5:
        default:
          classes.push('todo');
          break;
      }

      return classes.join(' ');
    };

    // Konfigurasi template untuk progress bar
    gantt.templates.progress_text = function(start, end, task) {
      return ""; // Mengembalikan string kosong untuk menghilangkan teks persentase
    };

    // Konfigurasi kolom grid
    gantt.config.columns = [
      { name: "text", label: "Task Name", tree: true, width: 200, resize: true },
      { 
        name: "status", 
        label: "Status", 
        align: "center", 
        width: 100,
        template: function(task) {
          const statusName = getStatusName(task.statusId);
          const statusClass = statusName.toLowerCase().replace(/\s+/g, '-');
          return `<div class="gantt-status ${statusClass}">${statusName}</div>`;
        }
      },
      { name: "start_date", label: "Start Date", align: "center", width: 100, resize: true },
      { name: "end_date", label: "Due Date", align: "center", width: 100, resize: true },
      { 
        name: "progress", 
        label: "Progress",
        align: "center",
        width: 80,
        template: function(task) {
          return Math.round(task.progress * 100) + '%';
        }
      },
      { name: "assignee", label: "Assignee", align: "center", width: 100, resize: true },
      { name: "priority", label: "Priority", align: "center", width: 80, resize: true },
      { name: "relatedShot", label: "Related Shot", align: "center", width: 100, resize: true },
      { name: "relatedAsset", label: "Related Asset", align: "center", width: 100, resize: true }
    ];

    // Configure task template
    gantt.templates.task_text = function(start, end, task) {
      return task.text + (task.progress ? ' (' + Math.round(task.progress * 100) + '%)' : '');
    };

    // Configure tooltip
    gantt.templates.tooltip_text = function(start, end, task) {
      const startDate = gantt.date.date_to_str(gantt.config.task_date)(start);
      const endDate = gantt.date.date_to_str(gantt.config.task_date)(end);
      return `<div class="tooltip-header">${task.text}</div>
              <div class="tooltip-content">
                <div class="tooltip-row">
                  <span class="tooltip-label">Start:</span>
                  <span>${startDate}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Due:</span>
                  <span>${endDate}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Progress:</span>
                  <span>${Math.round(task.progress * 100)}%</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Status:</span>
                  <span>${task.statusId}</span>
                </div>
                <div class="tooltip-row">
                  <span class="tooltip-label">Assignee:</span>
                  <span>${task.assignee}</span>
                </div>
                ${task.description ? `
                <div class="tooltip-row">
                  <span class="tooltip-label">Description:</span>
                  <span>${task.description}</span>
                </div>` : ''}
              </div>`;
    };

    // Enable task editing
    gantt.config.drag_progress = true;
    gantt.config.drag_resize = true;
    gantt.config.drag_move = true;
    gantt.config.drag_links = true;
    gantt.config.details_on_dblclick = true;
    gantt.config.click_drag = true;

    // Enable inline editing
    gantt.config.readonly = false;
    gantt.config.editable_property = "text";

    // Configure task editing
    gantt.config.editor_types.text = {
      show: function(id, column, config, placeholder) {
        var task = gantt.getTask(id);
        var html = "<div class='gantt-edit-content'><input type='text' value='" + task.text + "'/></div>";
        return html;
      },
      hide: function() {
        var input = document.querySelector(".gantt-edit-content input");
        if (input) {
          return input.value;
        }
        return "";
      },
      set_value: function(value, id, column, node) {
        var input = node.querySelector("input");
        if (input) {
          input.value = value;
        }
      }
    };

    // Enable progress drag with animation
    gantt.config.drag_progress = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Enable task resizing with animation
    gantt.config.drag_resize = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Enable task moving with animation
    gantt.config.drag_move = {
      enabled: true,
      ignore: [],
      useRequestAnimation: true,
      render_delay: 0
    };

    // Konfigurasi scroll
    gantt.config.scroll_size = 20;
    gantt.config.scroll_step = 50;
    gantt.config.scroll_sensitivity = 30;
    gantt.config.autoscroll = true;
    gantt.config.autoscroll_speed = 20;

    // Configure gantt events
    gantt.attachEvent("onAfterTaskUpdate", async (id, task) => {
      try {
        // Get the old task data
        const oldTask = tasks.find(t => t.id === id);
        if (!oldTask) {
          throw new Error('Task not found');
        }

        // Convert gantt task format back to our API format
        const updatedTask = {
          id: task.id,
          taskName: task.text,
          startDate: task.start_date.toISOString(),
          dueDate: task.end_date.toISOString(),
          progress: Math.round(task.progress * 100),
          statusId: task.statusId,
          priority: task.priority,
          description: task.description,
          parentId: task.parent,
          // Preserve the existing selectedShots and selectedAssets
          selectedShots: oldTask.selectedShots || [],
          selectedAssets: oldTask.selectedAssets || []
        };

        // Call the onEditTask callback
        await onEditTask(id, updatedTask);

        // Emit socket event
        if (socket) {
          socket.emit('updateTask', updatedTask);
        }

      } catch (error) {
        console.error('Error updating task:', error);
        gantt.message({ type: "error", text: "Failed to update task" });
        // Revert changes on error
        gantt.refreshTask(id);
      }
    });

    gantt.attachEvent("onAfterTaskAdd", async (id, task) => {
      try {
        // Convert gantt task format to our API format
        const newTask = {
          taskName: task.text,
          startDate: task.start_date.toISOString(),
          dueDate: task.end_date.toISOString(),
          progress: Math.round(task.progress * 100),
          statusId: task.statusId,
          priority: task.priority || 'medium',
          description: task.description || '',
          parentId: task.parent
        };

        // Call the API to create task
        const response = await axiosInstance.post(`/api/tasks`, {
          ...newTask,
          projectId: activeProject.id
        });

        // Update gantt with the new task ID from server
        gantt.changeTaskId(id, response.data.id);

        // Emit socket event
        if (socket) {
          socket.emit('createTask', response.data);
        }

      } catch (error) {
        console.error('Error creating task:', error);
        gantt.message({ type: "error", text: "Failed to create task" });
        // Remove task on error
        gantt.deleteTask(id);
      }
    });

    gantt.attachEvent("onAfterTaskDelete", async (id) => {
      try {
        // Call the onDeleteTask callback
        await onDeleteTask(id);

        // Emit socket event
        if (socket) {
          socket.emit('deleteTask', id);
        }

      } catch (error) {
        console.error('Error deleting task:', error);
        gantt.message({ type: "error", text: "Failed to delete task" });
        // Note: Can't revert deletion easily, might need to refresh the whole chart
      }
    });

    gantt.attachEvent("onAfterTaskDrag", async (id, mode, e) => {
      const task = gantt.getTask(id);
      try {
        // Update task with new dates after drag
        const updatedTask = {
          id: task.id,
          startDate: task.start_date.toISOString(),
          dueDate: task.end_date.toISOString(),
          parentId: task.parent
        };

        // Call the onEditTask callback
        await onEditTask(id, updatedTask);

        // Emit socket event
        if (socket) {
          socket.emit('updateTask', updatedTask);
        }

      } catch (error) {
        console.error('Error updating task after drag:', error);
        gantt.message({ type: "error", text: "Failed to update task position" });
        gantt.refreshTask(id);
      }
    });

    gantt.attachEvent("onTaskDblClick", (id, e) => {
      if (e.target.className.includes('gantt_task_progress_drag')) {
        const task = gantt.getTask(id);
        onEditTask(id, {
          ...task,
          progress: Math.round(task.progress * 100)
        });
      }
    });

    gantt.attachEvent("onGridResize", function(new_width){
      console.log("New grid width:", new_width);
      // Lakukan sesuatu dengan new_width
    });

    // Initialize Gantt
    gantt.init(ganttContainer.current);

    // Format and load tasks
    const formattedTasks = formatTasks(tasks);
    const ganttData = {
      data: formattedTasks,
      links: [] // Jika ada dependencies antar task, bisa ditambahkan di sini
    };
    gantt.parse(ganttData);

    // Add today marker after initialization
    const today = new Date();
    const marker = gantt.addMarker({
      start_date: today,
      css: "today",
      text: "Today",
      title: today.toLocaleDateString()
    });

    // Handle window resize
    const handleResize = () => {
      if (ganttContainer.current) {
        gantt.render();
      }
    };

    window.addEventListener('resize', handleResize);

    const gridCell = document.querySelector('.gantt_layout_cell');
    const gridContent = document.querySelector('.gantt_layout_content');
    
    if (!gridCell || !gridContent) return;

    let isResizing = false;

    const handleMouseDown = (e) => {
      isResizing = true;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
      if (!isResizing) return;
      const newWidth = e.clientX - gridContent.getBoundingClientRect().left;
      gridContent.style.width = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      isResizing = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    gridCell.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (marker) {
        gantt.deleteMarker(marker);
      }
      gantt.clearAll();
      gantt.detachAllEvents();
      gridCell.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [tasks, formatTasks, onEditTask, onDeleteTask, socket, activeProject?.id]);

  // Zoom controls
  const handleZoomChange = (direction) => {
    const zoomLevels = ['Hours', 'Days', 'Months'];
    const currentIndex = zoomLevels.indexOf(currentZoom);

    if (direction === 'in' && currentIndex < zoomLevels.length - 1) {
      const newZoom = zoomLevels[currentIndex + 1];
      setCurrentZoom(newZoom);
      gantt.ext.zoom.setLevel(newZoom);
    } else if (direction === 'out' && currentIndex > 0) {
      const newZoom = zoomLevels[currentIndex - 1];
      setCurrentZoom(newZoom);
      gantt.ext.zoom.setLevel(newZoom);
    }
  };

  // Toggle fullscreen
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (ganttContainer.current.requestFullscreen) {
        ganttContainer.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  // Di dalam komponen GanttChart, tambahkan useEffect untuk mengatur kolom yang tersembunyi
  useEffect(() => {
    if (!ganttContainer.current) return;

    // Daftar kolom default sesuai dengan konfigurasi
    const defaultColumns = [
      { name: 'text', label: 'Task Name', width: 200, resize: true },
      { 
        name: 'status', 
        label: 'Status', 
        align: 'center', 
        width: 100,
        template: function(task) {
          const statusName = getStatusName(task.statusId);
          const statusClass = statusName.toLowerCase().replace(/\s+/g, '-');
          return `<div class="gantt-status ${statusClass}">${statusName}</div>`;
        }
      },
      { name: 'start_date', label: 'Start Date', align: 'center', width: 100, resize: true },
      { name: 'end_date', label: 'Due Date', align: 'center', width: 100, resize: true },
      { 
        name: 'progress', 
        label: 'Progress',
        align: 'center',
        width: 80,
        template: function(task) {
          return Math.round(task.progress * 100) + '%';
        }
      },
      { name: 'assignee', label: 'Assignee', align: 'center', width: 100, resize: true },
      { name: 'priority', label: 'Priority', align: 'center', width: 80, resize: true },
      { name: 'relatedShot', label: 'Related Shot', align: 'center', width: 100, resize: true },
      { name: 'relatedAsset', label: 'Related Asset', align: 'center', width: 100, resize: true }
    ];

    // Filter kolom berdasarkan hiddenColumns
    const visibleColumns = defaultColumns.filter(column => 
      !hiddenColumns.some(hidden => hidden.value === column.name)
    );

    // Atur kolom yang akan ditampilkan
    gantt.config.columns = visibleColumns;

    // Hitung ulang lebar grid
    gantt.config.grid_width = calculateGridWidth(visibleColumns);

    // Render ulang Gantt Chart
    gantt.render();
  }, [hiddenColumns]);

  const calculateGridWidth = (columns) => {
    return columns.reduce((total, col) => total + (col.width || 100), 0);
  };

  return (
    <div className={`list-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="global-header">
        <h2>Gantt View</h2>
        <div className="actions">
          <div className="search">
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
            <button 
              className="btn btn-secondary"
              onClick={() => setShowFilters(!showFilters)}
              title="Toggle Filters"
            >
              <FaFilter /> Filters
            </button>
          </div>

          {hasManagePermission(userRole) && (
            <button 
              className="btn btn-primary"
              onClick={handleAddTask}
            >
              <FaPlus /> Add Task
            </button>
          )}
        </div>
      </div>

      {showFilters && (
        <div className="filters-panel">
          <div className="form-group">
            <label>Status:</label>
            <Select
              value={filters.status}
              onChange={(value) => setFilters({ ...filters, status: value })}
              isClearable
              placeholder="Filter by status..."
              options={statuses.map(status => ({
                value: status.id,
                label: status.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Priority:</label>
            <Select
              value={filters.priority}
              onChange={(value) => setFilters({ ...filters, priority: value })}
              isClearable
              placeholder="Filter by priority..."
              options={[
                { value: 'high', label: 'High' },
                { value: 'medium', label: 'Medium' },
                { value: 'low', label: 'Low' }
              ]}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Start Date:</label>
            <input
              type="date"
              value={filters.startDate || ''}
              onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Due Date:</label>
            <input
              type="date"
              value={filters.dueDate || ''}
              onChange={(e) => setFilters({ ...filters, dueDate: e.target.value })}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Progress:</label>
            <div className="progress-range">
              <input
                type="number"
                min="0"
                max="100"
                value={filters.progress.min}
                onChange={(e) => setFilters({
                  ...filters,
                  progress: { ...filters.progress, min: parseInt(e.target.value) }
                })}
                className="form-control"
              />
              <span>to</span>
              <input
                type="number"
                min="0"
                max="100"
                value={filters.progress.max}
                onChange={(e) => setFilters({
                  ...filters,
                  progress: { ...filters.progress, max: parseInt(e.target.value) }
                })}
                className="form-control"
              />
              <span>%</span>
            </div>
          </div>
          <div className="form-group">
            <label>Assignee:</label>
            <Select
              value={filters.assignee}
              onChange={(value) => setFilters({ ...filters, assignee: value })}
              isClearable
              placeholder="Filter by assignee..."
              options={users.map(user => ({
                value: user.id,
                label: user.username || user.email
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Related Shot:</label>
            <Select
              value={filters.relatedShot}
              onChange={(value) => setFilters({ ...filters, relatedShot: value })}
              isClearable
              placeholder="Filter by related shot..."
              options={shots.map(shot => ({
                value: shot.id,
                label: shot.shotName || shot.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
          <div className="form-group">
            <label>Related Asset:</label>
            <Select
              value={filters.relatedAsset}
              onChange={(value) => setFilters({ ...filters, relatedAsset: value })}
              isClearable
              placeholder="Filter by related asset..."
              options={assets.map(asset => ({
                value: asset.id,
                label: asset.assetName || asset.name
              }))}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
        </div>
      )}

      <div className="gantt-wrapper">
        <div className="gantt-controls">
          <div className="zoom-buttons">
            <button onClick={() => handleZoomChange('in')} title="Zoom In">
              <FaSearchPlus />
            </button>
            <button onClick={() => handleZoomChange('out')} title="Zoom Out">
              <FaSearchMinus />
            </button>
          </div>
          
          <div className="fullscreen-button">
            <button onClick={toggleFullscreen} title="Toggle Fullscreen">
              {isFullscreen ? <FaCompress /> : <FaExpand />}
            </button>
          </div>

          <div className="export-buttons">
            <button onClick={() => gantt.exportToPDF()} title="Export to PDF">
              <FaFilePdf />
            </button>
            <button onClick={() => gantt.exportToPNG()} title="Export to Image">
              <FaFileImage />
            </button>
            <button onClick={() => gantt.exportToExcel()} title="Export to Excel">
              <FaFileExcel />
            </button>
          </div>
        </div>

        <div className="column-controls">
          <Select
            isMulti
            value={hiddenColumns}
            onChange={setHiddenColumns}
            options={columnOptions}
            placeholder="Hide columns..."
            className="select-container"
            classNamePrefix="select"
          />
        </div>

        <div className="gantt-container">
          <div ref={ganttContainer} style={{ width: '100%', height: '100%' }}></div>
        </div>
      </div>

      {isFormVisible && (
        <TaskForm
          onClose={handleFormClose}
          onSubmit={handleFormSubmit}
          initialData={editFormData}
          sequences={sequences}
          shots={shots}
          assets={assets}
        />
      )}
    </div>
  );
};

export default GanttChart;
