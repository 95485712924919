import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Messenger from './Messenger';
import { useDarkMode } from '../../App';
import { FiArrowLeft } from 'react-icons/fi';
import './MessengerPage.css';

const MessengerPage = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const savedProject = localStorage.getItem('activeProject');
    if (savedProject) setActiveProject(JSON.parse(savedProject));
  }, []);

  const handleBack = () => navigate(-1);

  return (
    <div className={`messenger-page ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="messenger-page-header">
        <button className="back-button" onClick={handleBack}>
          <FiArrowLeft /> Kembali
        </button>
        <h1>Messenger</h1>
      </div>
      <div className="messenger-container">
        <Messenger isOpen={true} onClose={() => navigate(-1)} activeProject={activeProject} isDarkMode={isDarkMode} />
      </div>
    </div>
  );
};

export default MessengerPage;