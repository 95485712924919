import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Select,
  HStack,
  Text,
  Flex,
  Spacer,
  IconButton,
  useToast,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import { 
  WarningIcon, 
  InfoIcon, 
  TimeIcon, 
  CheckIcon,
  EditIcon,
  SearchIcon
} from '@chakra-ui/icons';
import '../styles/global.css';
import IssueForm from './IssueForm'; // Assuming IssueForm is in the same directory

const Issues = ({ activeProject, sequences, shots, assets, tasks, isDarkMode }) => {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    priority: '',
    category: '',
    type: ''
  });
  const [editingIssue, setEditingIssue] = useState(null);
  const [isIssueFormOpen, setIsIssueFormOpen] = useState(false);

  const toast = useToast();
  
  // Color mode values
  const bgColor = useColorModeValue('white', '#121212');
  const textColor = useColorModeValue('#333333', '#e0e0e0');
  const borderColor = useColorModeValue('#dddddd', '#404040');
  const hoverBg = useColorModeValue('#f0f7ff', '#333333');
  const iconColor = useColorModeValue('current', '#e0e0e0');
  const tableBg = useColorModeValue('white', '#121212');
  const tableHeaderBg = useColorModeValue('#f8f9fa', '#1e1e1e');

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = async () => {
    try {
      const response = await fetch('/api/issues');
      if (!response.ok) throw new Error('Failed to fetch issues');
      const data = await response.json();
      setIssues(data);
    } catch (error) {
      console.error('Error fetching issues:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch issues',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      OPEN: 'red',
      IN_PROGRESS: 'yellow',
      RESOLVED: 'green',
      CLOSED: 'gray'
    };
    return colors[status] || 'gray';
  };

  const getPriorityColor = (priority) => {
    const colors = {
      LOW: 'green',
      MEDIUM: 'yellow',
      HIGH: 'orange',
      CRITICAL: 'red'
    };
    return colors[priority] || 'gray';
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'BUG':
        return <WarningIcon color={iconColor} />;
      case 'FEATURE':
        return <InfoIcon color={iconColor} />;
      case 'IMPROVEMENT':
        return <TimeIcon color={iconColor} />;
      case 'TECHNICAL_DEBT':
        return <TimeIcon color={iconColor} />;
      default:
        return null;
    }
  };

  const getRelatedItemName = (issue) => {
    const { type, relatedShotId, relatedSequenceId, relatedTaskId, relatedAssetId } = issue;
    
    switch (type) {
      case 'SHOT':
        return shots.find(s => s.id === relatedShotId)?.shotName || 'Unknown Shot';
      case 'SEQUENCE':
        return sequences.find(s => s.id === relatedSequenceId)?.sequenceName || 'Unknown Sequence';
      case 'TASK':
        return tasks.find(t => t.id === relatedTaskId)?.taskName || 'Unknown Task';
      case 'ASSET':
        return assets.find(a => a.id === relatedAssetId)?.assetName || 'Unknown Asset';
      default:
        return 'Unknown';
    }
  };

  const filteredIssues = issues.filter(issue => {
    const matchesSearch = searchQuery === '' || 
      issue.title.toLowerCase().includes(searchQuery.toLowerCase());

    return (
      matchesSearch &&
      (!filters.status || issue.status === filters.status) &&
      (!filters.priority || issue.priority === filters.priority) &&
      (!filters.category || issue.category === filters.category) &&
      (!filters.type || issue.type === filters.type)
    );
  });

  const handleEditIssue = (issue) => {
    setEditingIssue(issue);
    setIsIssueFormOpen(true);
  };

  const handleIssueUpdated = (updatedIssue) => {
    setIssues(issues.map(issue => 
      issue.id === updatedIssue.id ? updatedIssue : issue
    ));
    setEditingIssue(null);
    setIsIssueFormOpen(false);
    toast({
      title: 'Success',
      description: 'Issue updated successfully',
      status: 'success',
      duration: 3000,
      isClosable: true
    });
  };

  return (
    <div className={`app ${isDarkMode ? 'darkmode' : ''}`}>
      <Box className="admin-content">
        <Box className="admin-card">
          <Flex direction="column" gap={4}>
            <Flex align="center" justify="space-between" mb={4}>
              <Text fontSize="xl" fontWeight="600" color={textColor}>Issues Management</Text>
              <InputGroup maxW="300px" className="search-box">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color={iconColor} />
                </InputLeftElement>
                <Input
                  placeholder="Search issues..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  bg={bgColor}
                  color={textColor}
                  borderColor={borderColor}
                  _hover={{ borderColor: '#f3a321' }}
                  _focus={{ borderColor: '#f3a321', boxShadow: '0 0 0 2px rgba(243, 163, 33, 0.1)' }}
                />
              </InputGroup>
            </Flex>

            <Box className="filter-section">
              <Select
                placeholder="Status"
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                bg={bgColor}
                color={textColor}
                borderColor={borderColor}
                _hover={{ borderColor: '#f3a321' }}
                _focus={{ borderColor: '#f3a321', boxShadow: '0 0 0 2px rgba(243, 163, 33, 0.1)' }}
                size="sm"
                className="status-badge"
              >
                <option value="OPEN">Open</option>
                <option value="IN_PROGRESS">In Progress</option>
                <option value="RESOLVED">Resolved</option>
                <option value="CLOSED">Closed</option>
              </Select>
              <Select
                placeholder="Priority"
                value={filters.priority}
                onChange={(e) => setFilters({ ...filters, priority: e.target.value })}
                bg={bgColor}
                color={textColor}
                borderColor={borderColor}
                _hover={{ borderColor: '#f3a321' }}
                _focus={{ borderColor: '#f3a321', boxShadow: '0 0 0 2px rgba(243, 163, 33, 0.1)' }}
                size="sm"
              >
                <option value="LOW">Low</option>
                <option value="MEDIUM">Medium</option>
                <option value="HIGH">High</option>
                <option value="CRITICAL">Critical</option>
              </Select>
              <Select
                placeholder="Category"
                value={filters.category}
                onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                bg={bgColor}
                color={textColor}
                borderColor={borderColor}
                _hover={{ borderColor: '#f3a321' }}
                _focus={{ borderColor: '#f3a321', boxShadow: '0 0 0 2px rgba(243, 163, 33, 0.1)' }}
                size="sm"
              >
                <option value="BUG">Bug</option>
                <option value="FEATURE">Feature</option>
                <option value="IMPROVEMENT">Improvement</option>
                <option value="TECHNICAL_DEBT">Technical Debt</option>
              </Select>
              <Select
                placeholder="Type"
                value={filters.type}
                onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                bg={bgColor}
                color={textColor}
                borderColor={borderColor}
                _hover={{ borderColor: '#f3a321' }}
                _focus={{ borderColor: '#f3a321', boxShadow: '0 0 0 2px rgba(243, 163, 33, 0.1)' }}
                size="sm"
              >
                <option value="SHOT">Shot</option>
                <option value="SEQUENCE">Sequence</option>
                <option value="TASK">Task</option>
                <option value="ASSET">Asset</option>
              </Select>
            </Box>

            <Box overflowX="auto">
              <Table variant="simple" className="admin-table">
                <Thead>
                  <Tr>
                    <Th color={textColor}>Title</Th>
                    <Th color={textColor}>Status</Th>
                    <Th color={textColor}>Priority</Th>
                    <Th color={textColor}>Category</Th>
                    <Th color={textColor}>Type</Th>
                    <Th color={textColor}>Location</Th>
                    <Th color={textColor}>Reporter</Th>
                    <Th color={textColor}>Assigned To</Th>
                    <Th color={textColor}>Created At</Th>
                    <Th color={textColor}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredIssues.map(issue => (
                    <Tr key={issue.id} _hover={{ bg: hoverBg }}>
                      <Td color={textColor}>{issue.title}</Td>
                      <Td>
                        <Badge colorScheme={getStatusColor(issue.status)} className="status-badge">
                          {issue.status}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge colorScheme={getPriorityColor(issue.priority)} className="status-badge">
                          {issue.priority}
                        </Badge>
                      </Td>
                      <Td>
                        <HStack>
                          {getCategoryIcon(issue.category)}
                          <Text color={textColor}>{issue.category}</Text>
                        </HStack>
                      </Td>
                      <Td color={textColor}>{issue.type}</Td>
                      <Td color={textColor}>{getRelatedItemName(issue)}</Td>
                      <Td color={textColor}>{issue.reporter || 'admin'}</Td>
                      <Td color={textColor}>{issue.assignedTo || 'Unassigned'}</Td>
                      <Td color={textColor}>
                        {new Date(issue.createdAt).toLocaleDateString()}
                      </Td>
                      <Td>
                        <IconButton
                          icon={<EditIcon />}
                          size="sm"
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() => handleEditIssue(issue)}
                          aria-label="Edit issue"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Box>
      </Box>
      {isIssueFormOpen && (
        <IssueForm
          isOpen={isIssueFormOpen}
          onClose={() => {
            setIsIssueFormOpen(false);
            setEditingIssue(null);
          }}
          editMode={!!editingIssue}
          issueData={editingIssue}
          onIssueCreated={handleIssueUpdated}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default Issues;