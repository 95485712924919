import { useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from '../config/config';

const useGroupMessages = ({ currentUser, socket, toast, isOpen }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupMembers, setNewGroupMembers] = useState([]);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [editGroupName, setEditGroupName] = useState('');
  const [editGroupMembers, setEditGroupMembers] = useState([]);

// Di dalam useGroupMessages
const fetchGroups = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groups`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching groups');
      const groupData = await response.json();
      console.log('Fetched groups:', groupData); // Debug
      setGroups(groupData);
      if (groupData.length > 0 && !selectedGroup) setSelectedGroup(groupData[0]);
    } catch (error) {
      console.error('Error fetching groups:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };
  
  const fetchGroupMessages = async () => {
    if (!selectedGroup?.id || !currentUser?.id) return;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groupmessages/${selectedGroup.id}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching group messages');
      const messages = await response.json();
      console.log('Fetched group messages:', messages); // Debug
      setMessages(messages.map(msg => ({
        ...msg,
        likes: Array.isArray(msg.likes) ? msg.likes : [],
        likeEmojis: msg.likeEmojis || {},
        userLiked: msg.likes.includes(currentUser.id),
        userEmoji: msg.likeEmojis?.[currentUser.id] || '❤️',
        isSentByCurrentUser: msg.senderId === currentUser.id,
        isGroupMessage: true,
      })));
    } catch (error) {
      console.error('Error fetching group messages:', error);
      setMessages([]);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };
  const deleteGroupMessage = async (messageId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groupmessages/${selectedGroup.id}/${messageId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error deleting group message');
      
      // Update state lokal setelah penghapusan
      setMessages(messages.filter(msg => msg.id !== messageId));
      setPinnedMessages(pinnedMessages.filter(msg => msg.id !== messageId)); // Update pinnedMessages state
      toast({ title: 'Berhasil', description: 'Pesan dihapus', status: 'success', duration: 3000 });
  
      // Emit via socket
      socket.emit('delete_group_message', { messageId });
    } catch (error) {
      console.error('Error deleting group message:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };
  const fetchGroupPinnedMessages = async () => {
    if (!selectedGroup?.id || !currentUser?.id) return;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groupmessages/pinned/${selectedGroup.id}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error fetching group pinned messages');
      const data = await response.json();
      setPinnedMessages(data || []);
    } catch (error) {
      console.error('Error fetching group pinned messages:', error);
      setPinnedMessages([]);
    }
  };

  const startCreateGroup = () => {
    setIsCreatingGroup(true);
    setNewGroupName('');
    setNewGroupMembers([]);
  };

  const addGroupMember = (user) => {
    if (!newGroupMembers.some(member => member.id === user.id)) {
      setNewGroupMembers([...newGroupMembers, user]);
    }
  };

  const removeGroupMember = (userId) => {
    setNewGroupMembers(newGroupMembers.filter(member => member.id !== userId));
  };

  const submitNewGroup = async () => {
    if (!newGroupName.trim() || newGroupMembers.length === 0) {
      toast({ title: 'Error', description: 'Nama dan anggota group harus diisi', status: 'error', duration: 3000 });
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groups`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: newGroupName, memberIds: newGroupMembers.map(m => m.id) }),
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error creating group');
      const groupData = await response.json();
      setIsCreatingGroup(false);
      setNewGroupName('');
      setNewGroupMembers([]);
      fetchGroups();
      setSelectedGroup(groupData);
      toast({ title: 'Berhasil', description: 'Group dibuat', status: 'success', duration: 3000 });
    } catch (error) {
      console.error('Error creating group:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };

  const handleEditGroup = (group) => {
    setEditGroupName(group.name);
    setEditGroupMembers(group.members.map(member => ({ id: member.id, username: member.username })));
    setIsEditingGroup(true);
  };

  const addEditGroupMember = (user) => {
    if (!editGroupMembers.some(member => member.id === user.id)) {
      setEditGroupMembers([...editGroupMembers, user]);
    }
  };

  const removeEditGroupMember = (userId) => {
    setEditGroupMembers(editGroupMembers.filter(member => member.id !== userId));
  };

  const submitEditGroup = async () => {
    if (!editGroupName.trim() || editGroupMembers.length === 0) {
      toast({ title: 'Error', description: 'Nama dan anggota group harus diisi', status: 'error', duration: 3000 });
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groups/${selectedGroup.id}`, {
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: editGroupName, memberIds: editGroupMembers.map(m => m.id) }),
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error updating group');
      setIsEditingGroup(false);
      setEditGroupName('');
      setEditGroupMembers([]);
      fetchGroups();
      toast({ title: 'Berhasil', description: 'Group diperbarui', status: 'success', duration: 3000 });
    } catch (error) {
      console.error('Error updating group:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };

  const handleDeleteGroup = async (group) => {
    if (!window.confirm(`Apakah Anda yakin ingin menghapus group ${group.name}?`)) return;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/groups/${group.id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Error deleting group');
      fetchGroups();
      setSelectedGroup(null);
      toast({ title: 'Berhasil', description: 'Group dihapus', status: 'success', duration: 3000 });
    } catch (error) {
      console.error('Error deleting group:', error);
      toast({ title: 'Error', description: error.message, status: 'error', duration: 3000 });
    }
  };

  useEffect(() => {
    if (isOpen) fetchGroups();
  }, [isOpen]);

  useEffect(() => {
    if (selectedGroup?.id && currentUser?.id) {
      fetchGroupMessages();
      fetchGroupPinnedMessages();
    }
  }, [selectedGroup, currentUser]);

  return {
    groups,
    setGroups,
    selectedGroup,
    setSelectedGroup,
    messages,
    setMessages,
    pinnedMessages,
    setPinnedMessages,
    isCreatingGroup,
    setIsCreatingGroup,
    newGroupName,
    setNewGroupName,
    newGroupMembers,
    setNewGroupMembers,
    isEditingGroup,
    setIsEditingGroup,
    editGroupName,
    setEditGroupName,
    editGroupMembers,
    setEditGroupMembers,
    fetchGroups,
    fetchGroupMessages,
    fetchGroupPinnedMessages,
    startCreateGroup,
    addGroupMember,
    removeGroupMember,
    submitNewGroup,
    handleEditGroup,
    addEditGroupMember,
    removeEditGroupMember,
    submitEditGroup,
    handleDeleteGroup,
    deleteGroupMessage
  };
};

export default useGroupMessages;